import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FastField, ErrorMessage } from 'formik'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { OutlinedInput, Chip } from '@material-ui/core'
import PhoneInput from 'material-ui-phone-number'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import ButtonTextField from 'components/ButtonTextField'
import CustomField from 'classes/customFields'

const useStyles = makeStyles(theme => ({
  textBox: {
    margin: 0,
    width: '100%'
  },
  contactInput: {
    width: '100%',
    fontFamily: 'Poppins, sans serif'
  },
  inputField: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    paddingRight: 20
  },
  multiLine: {
  },
  multiLineInput: {
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    paddingTop: 10
  },
  inputLabel: {
    color: '#7C7C7C',
    fontSize: '13px',
    paddingTop: 5
  },
  inputText: {
    padding: '8px 12px 8px 12px',
    color: '#4A4A4A',
    fontSize: '14px'
  },
  errorDiv: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    color: 'red'
  },
  dropDown: {
    width: '100%',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px'
  },
  phoneInput: {
    width: 'calc(100% - 24px)',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px',
    padding: '7px 11px 7px 11px',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '4px'
  },
  calendarIcon: {
    color: 'red',
    padding: 0
  },
  descriptionField: {
    display: 'grid',
    gridTemplateColumns: '123px auto',
    paddingRight: 20
  },
  deleteIcon: {
    fill: 'white'
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginBottom: 5,
    marginRight: 5
  }
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    minHeight: 20,
    padding: '5px 15px 5px 25px',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px'
  }
}))(MenuItem)

function DropDownAttribute ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  ...props
}: any) {
  const classes = useStyles()

  let customField = false
  let propName = ''

  // Temporary Adapter
  // if (props.valueName.startsWith('employee_count')) {
  //   let current_value = values[props.valueName]
  //   current_value = current_value.replace(' ', '').replace('Employees', '')
  //   values[props.valueName] = current_value
  // }

  if (props.valueName.startsWith('custom_field')) {
    customField = true
    propName = props.valueName.split('.')[1]
  }

  return (
    <div style={{ width: '100%' }}>
      <Select
        value={customField ? values?.custom_fields[propName] : values[props.valueName]}
        onChange={e => setFieldValue(field.name, e.target.value)}
        onBlur={() => setFieldTouched(field.name)}
        className={classes.dropDown}
        variant='outlined'
        margin='dense'
        input={<OutlinedInput classes={{ input: classes.inputText }} />}
        error={errors[props.name] && touched[props.name]}
        displayEmpty
      >
        <StyledMenuItem value='' disabled>
          Select a value
        </StyledMenuItem>
        {props.values.map((name: any, index: number) => (
          <StyledMenuItem key={index} value={name}>
            {name}
          </StyledMenuItem>
        ))}
      </Select>
    </div>
  )
}

function numericInput (event: any) {
  let key = event.keyCode || event.which
  key = String.fromCharCode(key)

  const regex = /[0-9-]/
  if (!regex.test(key)) {
    event.preventDefault()
  }
}

function NumericAttribute ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  ...props
}: any) {
  const classes = useStyles()

  let customField = false
  let propName = ''

  if (props.valueName.startsWith('custom_field')) {
    customField = true
    propName = props.valueName.split('.')[1]
  }

  return (
    <div style={{ width: '100%' }}>
      <TextField
        variant='outlined'
        margin='dense'
        name={props.valueName}
        id={props.valueName}
        value={customField ? values.custom_fields[propName] : values[props.valueName]}
        onChange={e => setFieldValue(field.name, e.target.value)}
        onBlur={() => setFieldTouched(field.name)}
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        error={errors[props.valueName] && touched[props.valueName]}
        onKeyPress={numericInput}
      />
    </div>
  )
}

function DateAttribute ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  ...props
}: any) {
  const classes = useStyles()

  let customField = false
  let propName = ''

  if (props.valueName.startsWith('custom_field')) {
    customField = true
    propName = props.valueName.split('.')[1]
  }

  let value = values[props.valueName]
  value = customField ? values.custom_fields[propName] : values[props.valueName]
  if (value === '') { value = null }

  return (
    <div style={{ width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          onChange={date => setFieldValue(field.name, date)}
          onBlur={() => setFieldTouched(field.name)}
          className={classes.dropDown}
          format='MM/dd/yyyy'
          value={value}
          error={errors[props.name] && touched[props.name]}
          InputProps={{ classes: { input: classes.inputText } }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

function TextAttribute ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  ...props
}: any) {
  const classes = useStyles()

  let customField = false
  let propName = ''

  if (props.valueName.startsWith('custom_field')) {
    customField = true
    propName = props.valueName.split('.')[1]
  }

  return (
    <div style={{ width: '100%' }}>
      <TextField
        variant='outlined'
        margin='dense'
        name={props.valueName}
        id={props.valueName}
        value={customField ? values.custom_fields[propName] : values[props.valueName]}
        onChange={e => setFieldValue(field.name, e.target.value)}
        onBlur={() => setFieldTouched(field.name)}
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        error={errors[props.valueName] && touched[props.valueName]}
      />
    </div>
  )
}

interface ErrorDivProps {
  children: any
}

function ErrorDiv (props: ErrorDivProps): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.errorDiv}>
      <ErrorOutlineIcon style={{ color: 'red', height: 15, width: 15, paddingRight: 10 }} />
      {props.children}
    </div>
  )
}

function PhoneSelect ({
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  ...props
}: any) {
  const classes = useStyles()

  // Default country code to US if not provided
  if (values[props.valueName] && !values[props.valueName].startsWith('+')) {
    values[props.valueName] = '+1' + values[props.valueName]
  }

  return (
    <div style={{ width: '100%' }}>
      <PhoneInput
        value={values[props.valueName]}
        onChange={(value: any) => { setFieldValue(props.valueName, value) }}
        onBlur={() => setFieldTouched(props.valueName)}
        defaultCountry='us'
        disableAreaCodes
        variant='outlined'
        className={classes.textBox}
        InputProps={{ classes: { input: classes.inputText } }}
        regions={['north-america', 'south-america', 'central-america', 'europe', 'asia']}
        error={errors[props.valueName] && touched[props.valueName]}
      />
    </div>
  )
}

function ListAttribute ({
  field,
  form: { setFieldValue, values }
}: any) {
  const classes = useStyles()
  const [technologies, setTechnologies] = useState(values.company_tech)

  const addOption = (label: string) => {
    if (label === '') {
      return
    }

    const list = technologies.concat(label)
    setTechnologies(list)
    setFieldValue(field.name, list)
  }

  const handleDelete = (value: any) => {
    const list = technologies.filter((tech: any) => tech !== value)
    setTechnologies(list)
    setFieldValue(field.name, list)
  }
  return (
    <div className={classes.multiLineInput}>
      <div>
        {technologies.map((value: any, index: number) => (
          <Chip
            key={index}
            label={value}
            classes={{ deleteIcon: classes.deleteIcon }}
            className={classes.chip}
            onDelete={() => handleDelete(value)}
          />
        ))}
      </div>
      <div>
        <ButtonTextField addButton={addOption} placeholder='Type a technology and hit enter...' />
      </div>
    </div>
  )
}

interface AttributeProps {
  name: string
  label: string
  component: any
  type?: string
  values?: string[]
}

function Attribute (props: AttributeProps) {
  const classes = useStyles()

  return (
    <div className={classes.contactInput}>
      <div className={props.type === 'description' ? classes.descriptionField : props.type === 'multiple' ? classes.multiLine : classes.inputField}>
        <div className={classes.inputLabel}>
          {props.label}
        </div>
        <FastField
          component={props.component}
          name={props.name}
          valueName={props.name}
          label={props.label}
          values={props.values}
        />
        <div className={classes.errorDiv}>
          <ErrorMessage
            name={props.name}
            component={ErrorDiv}
          />
        </div>
      </div>
    </div>
  )
}

interface AttributeSelectorProps {
  name: string
  type: string
  label: string
  values?: string[]
}

function AttributeSelector (props: AttributeSelectorProps): JSX.Element {
  const name = props.name
  const type = props.type
  const label = props.label
  const values = props.values

  if (type === 'dropdown') {
    return (
      <Attribute name={name} label={label} component={DropDownAttribute} values={values} />
    )
  } else if (type === 'text') {
    return (
      <Attribute name={name} label={label} component={TextAttribute} />
    )
  } else if (type === 'numeric') {
    return (
      <Attribute name={name} label={label} component={NumericAttribute} />
    )
  } else if (type === 'date') {
    return (
      <Attribute name={name} label={label} component={DateAttribute} />
    )
  } else if (type === 'true-false') {
    return (
      <Attribute name={name} label={label} component={DropDownAttribute} values={['True', 'False']} />
    )
  } else if (type === 'phone') {
    return (
      <Attribute name={name} label={label} component={PhoneSelect} />
    )
  } else if (type === 'description') {
    return (
      <Attribute name={name} label={label} component={TextAttribute} type={type} />
    )
  } else if (type === 'multiple') {
    return (
      <Attribute name={name} label={label} component={ListAttribute} type={type} />
    )
  }
  return <></>
}

interface CustomFieldProps {
  field: CustomField
  notForm?: boolean

}

function CustomFieldElement (props: CustomFieldProps): JSX.Element {
  const name = 'custom_fields.' + props.field.id
  const type = props.field.kind
  const label = props.field.label
  const options = props.field.options

  return (
    <AttributeSelector
      name={name}
      label={label}
      type={type}
      values={options}
    />
  )
}

export { AttributeSelector, CustomFieldElement }
