import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InsightsUpgradeImg from 'img/insights_upgrade.png'
import NetworkBackground from 'img/network_background.png'
import { useEffect } from 'react';
import { acceptUserRequest } from 'api/users';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    backgroundImage: `url(${NetworkBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden'
  }
}))

export default function UserRequestAcceptedPage () {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    const requestID = Number(location.pathname.split('/')[2])
    acceptUserRequest(requestID)
    .then(() => {
      console.log('User Request Accepted')
    })
    // eslint-disable-next-line
  }, [])
  return (
    <div className={classes.root}>
      <Typography variant='h1'>Sign Up Request Accepted</Typography>
      <img src={InsightsUpgradeImg} alt='Insights plan information' />
    </div>
  )
}
