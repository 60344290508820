import { ChatSettings } from 'classes/chatSettings';
import User from 'classes/users';
import Loader from 'library/loading/Loader';
import { OnboardingWizard, WizardSectionType } from 'pages/onboarding/wizard/OnboardingWizard';
import { DomainSelection, domainSubtitle } from '../DomainSelection';
import { InstallationSection, installationSubtitle } from 'pages/onboarding/Installation';
import { IntegrationsSection, integrationsSubtitle } from 'pages/onboarding/adminSetup/IntegrationSection';
import { IntentPages, intentPagesSubtitle } from './IntentPages';
import { PaymentPage, paymentSubtitle } from './PaymentPage';
import { TeamInviteSection, teamInviteSubtitle } from './TeamInvite';

const adminSteps = [
  'admin',
  'team',
  'domain',
  'installation',
  'integrations',
  'intentPages'
]

type WizardProps = {
  open: boolean
  handleFinish: () => void
}

export function AdminSetupWizard ({ open, handleFinish }: WizardProps) {
  ChatSettings.loadOne()
  const { data: user, isLoading } = User.loadMe()
  const initialState = {
    open: open,
    step: 0
  }

  if (isLoading) {
    return <Loader type='spinner' />
  }

  const adminSections: Record<string, WizardSectionType> = {
    admin: {
      title: 'You\'re almost there, ' + user.firstName + '!',
      subtitle: 'Your personal account is all set up! Now, as an admin, there are a few extra things for you to do to get Signals up and running for your organization.',
      contents: () => <></>
    },
    team: {
      title: 'Invite your team',
      subtitle: teamInviteSubtitle,
      contents: TeamInviteSection
    },
    domain: {
      title: 'Set your domain',
      subtitle: domainSubtitle,
      contents: DomainSelection
    },
    installation: {
      title: 'Signals Installation',
      subtitle: installationSubtitle,
      contents: InstallationSection
    },
    intentPages: {
      title: 'High-Intent Pages',
      subtitle: intentPagesSubtitle,
      contents: IntentPages
    },
    pricing: {
      title: 'Payment Information',
      subtitle: paymentSubtitle,
      contents: PaymentPage
    },
    integrations: {
      title: 'Integrations',
      subtitle: integrationsSubtitle,
      contents: IntegrationsSection
    }
  }

  return (
    <OnboardingWizard
      sections={adminSections}
      steps={adminSteps}
      initialState={initialState}
      title='Admin Setup'
      handleFinish={handleFinish}
    />
  )
}
