import { useReducer, useState, useEffect } from 'react'
import { Button, Icon, TextBox, Chip, Typography, Tooltip } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { reducer } from 'library/helpers'
import { UserInvite } from 'classes/userInvites'

const useStyles = makeStyles(theme => ({
  userInfo: {
    display: 'grid',
    gridTemplateAreas: `"email email"
    "role perms"`,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
    gridGap: 15,
    padding: 15,
    backgroundColor: '#F6F2F8',
    border: '1px solid #9933FF',
    borderRadius: 5
  },
  additionalInfo: {
    marginTop: 20,
    color: '#707070',
    font: 'normal normal normal 14px/21px Poppins'
  },
  emailDiv: {
    gridArea: 'email',
    backgroundColor: 'white'
  },
  sentInvitesLabel: {
    font: 'normal normal normal 14px/21px Poppins',
    marginBottom: 8,
    color: '#000000'
  },
  sentInvites: {
    marginBottom: 15
  }
}))

const chatRoles = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Marketer' },
  { value: 3, label: 'Sales Development Rep' },
  { value: 4, label: 'Account Executive' },
  { value: 5, label: 'Customer Support Rep' },
  { value: 6, label: 'Sales Manager' },
  { value: 7, label: 'Customer Support Manager' },
  { value: 8, label: 'Calendar User' },
  { value: 9, label: 'Admin No Chat' }
]

const chatPermissions = [
  { value: 'conversations_all', label: 'All chats' },
  { value: 'conversations_mine', label: 'Own chats only' },
  { value: 'conversations_unclaimed', label: 'Own chats & empty chats' }
]

const tooltipMapping = [
  {
    text: 'marketing',
    tooltip: (
      <div>
        Marketing Manager - oversee agent chat performance
        <br />
        Marketer - gauge effectiveness of marketing campaigns
        <br />
        BDR's - engage inbound leads through chat
        <br />
        SDRs - engage inbound leads through chat
      </div>
    )
  },
  {
    text: 'sales',
    tooltip: (
      <div>
        Account Executives - have calendars shown
        <br />
        Account Managers - oversee and manage meetings
      </div>
    )
  },
  {
    text: 'client success',
    tooltip: (
      <div>
        Customer Success Representatives - respond to support chats
        <br />
        Customer Success Manager - oversee support chats
      </div>
    )
  }
]
const tooltipText = () => {
  const typoComp: JSX.Element[] = []
  tooltipMapping.forEach((obj, index) => {
    if (index === tooltipMapping.length - 1) {
      typoComp.push(<Typography key={'teaminvite-typo1-' + index} style={{ display: 'inline' }}> and </Typography>)
    }
    typoComp.push(
      <Tooltip
        key={'teaminvite-tooltip-' + index}
        title={obj.tooltip}
      >
        <Typography color='primary' style={{ textDecoration: 'underline', display: 'inline' }}>{obj.text}</Typography>
      </Tooltip>
    )
    if (index !== tooltipMapping.length - 1) {
      typoComp.push(<Typography style={{ display: 'inline' }} key={'teaminvite-typo2-' + index}>, </Typography>)
    }
  })
  return typoComp
}

export const teamInviteSubtitle = (
  <Typography>
    Bring your {tooltipText()} teams together in Signals to most effectively engage and convert your visitors. Hover over a department to see who to invite and why.
  </Typography>
)

const additionalMessage = (
  <Typography style={{ font: 'normal normal normal 14px/21px Poppins' }}>
    You can invite more team members later or update teams
    and permissions by navigating to
    <div
      style={{ display: 'inline-flex' }}
    >
      "<Icon icon='users' /> User Management"
    </div>  page in
    the settings menu.
  </Typography>
)

const initialState = {
  name: '',
  email: '',
  phone: '',
  role: '',
  permissions: 'conversations_all'
}

function SentInvitationSection ({ invites }: { invites: UserInvite[] }) {
  const classes = useStyles()
  return (
    <div className={classes.sentInvites}>
      <div className={classes.sentInvitesLabel}>
        Sent Invites:
      </div>
      <div style={{ maxHeight: 70, minHeight: 70, height: 70, overflowY: 'auto' }}>
        {invites.length ? invites.map((invite, index) => {
          return (
            <Chip
              key={index}
              color='primary'
              label={invite.email}
              style={{ marginBottom: 4, marginRight: 4 }}
            />
          )
        })
          : <Typography variant='subtitle2' color='textSecondary' style={{ display: 'flex', alignItems: 'center', height: 'inherit', justifyContent: 'center' }}>Sent invites will display here</Typography>}
      </div>
    </div>
  )
}

export function TeamInviteSection () {
  const classes = useStyles()
  const { data, isLoading } = UserInvite.loadAll()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [invites, setInvites] = useState<UserInvite[]>([])

  useEffect(() => {
    if (!isLoading) {
      setInvites(data.list)
    }
    // eslint-disable-next-line
  }, [isLoading])

  const createInvite = () => {
    const invite = { ...state }
    invite.permissions = UserInvite.permToDict(invite.permissions)
    UserInvite.save(invite).then(invite => {
      setInvites(old => [...old, invite])
      dispatch({ ...initialState })
    })
  }

  return (
    <div>
      <SentInvitationSection invites={invites} />
      <div className={classes.userInfo}>
        <div className={classes.emailDiv}>
          <TextBox
            value={state.email}
            onChange={(value: string) => dispatch({ email: value })}
            label='Email'
          />
        </div>
        <div style={{ backgroundColor: 'white', maxWidth: '201px' }}>
          <Select
            value={state.role}
            onChange={(value: number) => dispatch({ role: value })}
            options={chatRoles}
            placeholder='Select a role'
          />
        </div>
        <div style={{ backgroundColor: 'white', maxWidth: '201px' }}>
          <Select
            value={state.permissions}
            onChange={(value: string) => dispatch({ permissions: value })}
            options={chatPermissions}
            placeholder='Chat access'
          />
        </div>
      </div>
      <Button
        style={{ marginTop: 10 }}
        startIcon={<Icon icon='send' />}
        variant='outlined'
        color='primary'
        fullWidth
        disabled={!(state.email.includes('@') && state.role)}
        onClick={createInvite}
      >
        Send Invite
      </Button>
      <div className={classes.additionalInfo}>
        {additionalMessage}
      </div>
    </div>
  )
}
