/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Radio, TextBox } from 'library/materialUI'
import { useEffect, useState } from 'react'
import FilterView from 'classes/filterView'
import { TextField } from '@material-ui/core'
import FilterDrawer from './filters/FilterDrawer'
import { Modal, ModalSection } from 'library/Modal'
import { SummaryCompanies } from 'classes/summaryCompanies'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { QueryFilter } from 'classes/queryHelpers'

type FilterViewModalProps = {
  openModal: boolean
  setOpenModal: any
  currentView?: FilterView | null
  updateViews?: (view: FilterView) => void
}

/**
 * Filter view modal is responsible for editing a filter view.
 * Filters in the search params should never exceed 1 filter group.
 */
export default function FilterViewModal ({ openModal, setOpenModal, currentView, updateViews }: FilterViewModalProps) {
  const [name, setName] = useState(currentView?.name || '')
  const [description, setDescription] = useState(currentView?.description || '')
  const [sortOrder, setSortOrder] = useState(currentView?.searchParams?.sortOrder || 'desc')
  const [sortColumn, setSortColumn] = useState(currentView?.searchParams?.sortColumn || 'last_visit_timestamp')
  const [filters, setFilters] = useState(currentView?.searchParams?.filters || [])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const potentialFilterIndex = filters.length > 0 ? filters.at(0)?.findIndex((f: QueryFilter) => f.field === 'id' && f.operator === 'is') : -1
  const ownedFilterIndex = filters.length > 0 ? filters.at(0)?.findIndex((f: QueryFilter) => f.field === 'id' && f.operator === 'is not') : -1
  const [nameInputTouched, setNameInputTouched] = useState<boolean>(false)

  const createFilterView = () => {
    // Default views if edited should always create a new filter view
    if (currentView && currentView.id && (currentView.id as number) > 0) {
      FilterView.saveView({
        data: {
          id: currentView.id,
          name: name,
          searchParams: {
            filters: filters,
            pageNumber: 1,
            pageSize: 8,
            sortColumn: sortColumn,
            sortOrder: sortOrder
          },
          description: description
        }
      })
        .then(() => {
          currentView.name = name
          currentView.searchParams.filters = filters
          currentView.searchParams.sortColumn = sortColumn
          currentView.searchParams.sortOrder = sortOrder
          currentView.description = description
          updateViews && updateViews(currentView)
        })
    } else {
      FilterView.saveView({
        data: {
          name: name,
          searchParams: {
            filters: filters,
            pageNumber: 1,
            pageSize: 8,
            sortColumn: sortColumn,
            sortOrder: sortOrder
          },
          description: description
        }
      })
      .then((response: any) => {
        updateViews && updateViews(new FilterView({
          row: response.data
        }))
      })
    }
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setName('')
    setDescription('')
    setSortOrder('desc')
    setSortColumn('last_visit_timestamp')
    setFilters([])
    setOpenModal(null)
  }

  useEffect(() => {
    setName(currentView?.name || '')
    setDescription(currentView?.description || '')
    setSortOrder(currentView?.searchParams?.sortOrder || 'desc')
    setSortColumn(currentView?.searchParams?.sortColumn || 'last_visit_timestamp')
    setFilters(currentView?.searchParams?.filters || [])
    // eslint-disable-next-line
  }, [currentView])

  return (
    <div>
      <Modal
        onHide={() => { handleCloseModal() }}
        open={openModal}
        title={currentView && currentView.id ? 'Edit filter view' : 'Create filter view'}
        handleSave={() => {
          setNameInputTouched(false)
          createFilterView()
        }}
        saveIcon='save'
        saveDisabled={!name}
      >
        <ModalSection
          title='View Name'
          subtitle='Name your filter view'
        >
          <TextField
            margin='dense'
            variant='outlined'
            placeholder='View name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!name && nameInputTouched}
            onBlur={() => setNameInputTouched(true)}
            helperText={!name && nameInputTouched ? 'View name is required' : ''}
            style={{ borderColor: !name ? 'red' : 'inherit' }}
          />
        </ModalSection>
        <ModalSection
          title='Description'
          subtitle='Enter a description of your filter view'
        >
          <TextBox
            rows={3}
            value={description}
            placeholder='Enter your description here...'
            onChange={(value: string) => setDescription(value)}
          />
        </ModalSection>
        <ModalSection
          title='Filters'
          subtitle='Set the filters to be included in your view'
        >
          <Button variant='outlined' color='primary' onClick={() => setDrawerOpen(true)}>
            Edit Filters
          </Button>
          {/* {drawerOpen && ( */}
          <FilterDrawer
            open={drawerOpen}
            setOpen={setDrawerOpen}
            filters={filters[0] || []}
            setFilters={(filterGroup: QueryFilter[]) => setFilters([filterGroup])}
          />
          {/* )} */}
        </ModalSection>
        <ModalSection
          title='Default Sorting'
          subtitle="Choose how you'd like this view to be sorted by default"
        >
          <Select
            value={sortColumn}
            onChange={(value: string) => setSortColumn(value)}
            options={SummaryCompanies.getSortOptions()}
            width='50%'
            disableClear
          />
          <div style={{ paddingLeft: 5, marginTop: 10 }}>
            <Radio
              label='Descending Order'
              checked={sortOrder === 'desc'}
              handleChange={(_) => setSortOrder('desc')}
              value='desc'
            />
            <Radio
              label='Ascending Order'
              checked={sortOrder === 'asc'}
              handleChange={(_) => setSortOrder('asc')}
              value='asc'
            />
          </div>
        </ModalSection>
        <ModalSection
          title='Company Types'
          subtitle="Choose the types of companies you'd like to see in this view"
        >
          <div style={{ paddingLeft: 5 }}>
            <Radio
              label='Both Owned Accounts and Potential Accounts'
              checked={ownedFilterIndex === -1 && potentialFilterIndex === -1}
              handleChange={(checked) => {
                if (checked) {
                  const fg = [...filters]
                  if (fg.length === 0) {
                    return
                  }
                  fg[0] = fg[0].filter((f: QueryFilter) => f.field !== 'id')
                  setFilters(fg)
                }
              }}
              value='all'
            />
            <Radio
              label='Owned Accounts Only'
              checked={ownedFilterIndex !== -1 && potentialFilterIndex === -1}
              handleChange={(checked) => {
                if (checked) {
                  const fg = [...filters]
                  if (fg.length === 0) {
                    fg.push([])
                  }
                  fg[0] = fg[0].filter((f: QueryFilter) => f.field !== 'id')
                  fg[0].push({ field: 'id', operator: 'is not', value: null })
                  setFilters(fg)
                }
              }}
              value='owned'
            />
            <Radio
              label='Potential Accounts Only'
              checked={potentialFilterIndex !== -1 && ownedFilterIndex === -1}
              handleChange={(checked) => {
                if (checked) {
                  const fg = [...filters]
                  if (fg.length === 0) {
                    fg.push([])
                  }
                  fg[0] = fg[0].filter((f: QueryFilter) => f.field !== 'id')
                  fg[0].push({ field: 'id', operator: 'is', value: null })
                  setFilters(fg)
                }
              }}
              value='potential'
            />
          </div>
        </ModalSection>
      </Modal>
    </div>
  )
}
