import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormTextField from './FormTextField'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#9933FF',
    color: 'white'
  },
  invalidInput: {
    color: 'red',
    fontSize: 12,
    height: 25
  },
  emailMessage: {
    marginTop: theme.spacing(3)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 50
  }
}))

const startingValues = {
  email: ''
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter valid email address')
    .required('Enter valid email address')
})

function PasswordResetForm (props) {
  const classes = useStyles()

  return (
    <>
      <Formik
        initialValues={startingValues}
        onSubmit={props.onSubmit}
        validationSchema={validationSchema}
        render={({ handleChange, errors, handleBlur, touched }) => (
          <Form className={classes.form}>
            <Field
              component={FormTextField}
              id='email'
              name='email'
              label='Email'
              fullWidth
            />
            <div className={classes.invalidInput}>
              {props.errorMessage ? (
                props.errorMessage
              ) : (
                <ErrorMessage name='email' component='div' />
              )}
            </div>

            <div className={classes.wrapper}>
              {props.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submit}
                disabled={props.loading}
              >
                Request password reset
              </Button>
            </div>
          </Form>
        )}
      />
    </>
  )
}

const PasswordResetSideBar = props => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Reset Password - Signals Application
      </Typography>
      {props.submitted ? (
        <div className={classes.emailMessage}>
          <Typography variant='subtitle1' gutterBottom>
            Please check your email for a reset password link.
          </Typography>
        </div>
      ) : (
        <PasswordResetForm
          onSubmit={props.onSubmit}
          errorMessage={props.errorMessage}
          loading={props.loading}
        />
      )}
    </Paper>
  )
}

export default PasswordResetSideBar
