import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import BackgroundImage from 'img/rev-tech-2024.png' // 'img/Signals_Wordmark_White.svg' // cf_botHead_white.png'
import MobileDetect from 'mobile-detect'
import MessageDiv from 'library/divs/MessageDiv'
import { SessionContext } from 'session-context'
import NetworkBackground from 'img/network_background.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    background: `url(${NetworkBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden'
  },
  backgroundImage: {
    height: '100vh',
    minWidth: '100vh',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '100% 100%',
    backgroundColor: '#F5F5F5',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'auto'
  },
  paperDiv: {
    height: 'auto',
    borderRadius: 10,
    minWidth: 500
  },
  logo: {
    marginTop: 20,
    marginBottom: 20,
    cursor: 'pointer'
  },
  mobile: {
    width: '100%',
    height: 400,
    borderRadius: 10
  }
}))

const RegistrationTemplate = props => {
  const classes = useStyles()
  const { kicked } = useContext(SessionContext)
  const md = new MobileDetect(window.navigator.userAgent)
  const mobile = !!md.phone()
  const showBanner = false // used for banner and link, image and URL will need updated when we change banners
  const [showKicked, setShowKicked] = useState(kicked)

  return (
    <Grid container component='main' className={classes.root} alignContent='center' justifyContent='space-between' alignItems='center' wrap='nowrap'>
      <Grid item xs={12} sm={12} md={12} elevation={6} className={mobile ? classes.mobile : classes.paperDiv}>
        <div className={classes.paper}>
          <MessageDiv
            open={showKicked}
            setOpen={(open) => setShowKicked(open)}
            type='error'
            body='We have detected an additional user utilizing your account simultaneously, please ensure that only one user is logging in to the same account at the same time.'
            dismissable
          />
          <div className={classes.logo} onClick={() => window.open('https://getsignals.ai')}>
            <img src='https://cdn.jsdelivr.net/gh/chatfunnels/cdn@main/signals_wordmark-vector.svg' alt='Signals' />
          </div>
          {props.children}
        </div>
      </Grid>
      {!mobile && showBanner && <Grid className={classes.backgroundImage} onClick={() => window.open('https://bit.ly/3HNKXEY')} />}
    </Grid>
  )
}

export default RegistrationTemplate
