const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function groupSearch ({ search, chatServiceUrl }) {
  const url = `${chatServiceUrl}/keyword_groups:search`
  const params = new URLSearchParams()
  if (search) {
    params.set('filter[search]', search)
  }
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

/**
 * Fetches a keyword group by its id from the chat service.
 *
 * @param {string} chatServiceUrl - The base URL of the chat service.
 * @param {string} group_id - The ID of the keyword group to fetch.
 * @returns {Promise<Object>} A promise that resolves to the keyword group data.
 */
function getGroup ({ chatServiceUrl, group_id }) {
  return fetch(
    `${chatServiceUrl}/keyword_groups/${group_id}`,
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function deleteGroups ({ chatServiceUrl, group_ids }) {
  return fetch(
    `${chatServiceUrl}/keyword_groups`,
    {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({
        data: {
          attributes: {
            group_ids: group_ids
          }
        }
      })
    })
}

export { groupSearch, getGroup, deleteGroups }
