import { doPost, doGet, doDelete } from './api'

export interface AITrainingData {
  id: number
  training: string
  conversation_id: number
  bot_id: number
  conversation_context: string
}

export function defaultAITrainingData (): AITrainingData {
  return {
    id: 0,
    training: '',
    conversation_id: 0,
    bot_id: 0,
    conversation_context: ''
  }
}

export interface AIOnboardingData {
  id: number
  prompt: string
  bot_id: number
  objectives: string[]
}

const getAllTrainingData = () => {
  const path = '/api/core/ai_settings/training_data'
  return doGet({ path, useChatURL: false })
}

const getTrainingData = (id: number) => {
  const path = `/api/core/ai_settings/training_data/${id}`
  return doGet({ path, useChatURL: false })
}

const getTrainingByConversationId = (conversationId: number) => {
  const path = `/api/core/ai/get_training_by_conversation/${conversationId}`
  return doGet({ path, useChatURL: false })
}

const saveTrainingData = (trainingData: AITrainingData) => {
  const path = '/api/core/ai_settings/training_data'
  const data = { attributes: trainingData }
  return doPost({ path, data, useChatURL: false })
}

const deleteTrainingData = (id: number) => {
  const path = `/api/core/ai_settings/training_data/${id}`
  return doDelete({ path, useChatURL: false })
}

const saveOnboardingData = (onboarding: AIOnboardingData) => {
  const path = '/api/core/ai_settings/onboarding'
  const data = { attributes: onboarding }
  return doPost({ path, data, useChatURL: false })
}

const getOnboardingByBotId = (botId: number) => {
  const path = `/api/core/ai_settings/onboarding/${botId}`
  return doGet({ path, useChatURL: false })
}

export {
  getTrainingData,
  saveTrainingData,
  getTrainingByConversationId,
  getAllTrainingData,
  deleteTrainingData,
  saveOnboardingData,
  getOnboardingByBotId
}
