import { useContext, useEffect, useState } from 'react'
import IntegrationPage from '../IntegrationPage/IntegrationPage'
import TabProvider from '../IntegrationPage/TabProvider'
import {
  updateIntegration,
  getIntegration,
  addIntegrationSetting,
  getIntegrationFeature,
  getCredentialsStatus,
  getIntegrationFields,
  INTEGRATIONS
} from 'api/integrations'
import AccountCustomFieldSegmentCard from './AccountCustomFieldSegmentCard'
import AccountFieldMappingCard from './AccountFieldMappingCard'
import ContactFieldMappingCard from './ContactFieldMappingCard'
import CreateAccountCard from './CreateAccountsCard'
import DefaultCampaignCard from './DefaultCampaignCard'
import EventSettingsCard from '../EventSettingsCard'
import LeadContactOwnerCard from './LeadContactOwnerCard'
import LeadFieldMappingCard from './LeadFieldMappingCard'
import OwnershipCard from './OwnershipCard'
import SendingRecordsCard from './SendingRecordsCard'
import SyncCriteriaCard from './SyncCriteriaCard'
import SyncDelayCard from './SyncDelayCard'
import SyncOptionsCard from './SyncOptionsCard'
import UserCard from './UserCard'
import { getABMSettings, updateABMSettings } from 'api/account_management'
import { Typography } from 'library/materialUI'
import InformationIcon from 'library/InformationIcon'
import { ShareContext } from 'share-context'
import ScoreSyncingCard from '../ScoreSyncingCard'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'

/* eslint-disable */
const initialValues = {
  type: 'integrations',
  attributes: {
    integration_name: INTEGRATIONS.Salesforce,
    enabled: false,
    config: {
      min_lead_score: 0,
      delay_seconds: 0,
      campaign: '',
      owner_rules: ['meeting_scheduled', 'last_routed', 'closed_conversation'],
      disable_sync_from_cf: false,
      send_as_leads: false,
      field_custom_mapping: []
    },
    mappings: {
      route_mapping: [],
      user_mapping: [],
      mapping: [
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'FirstName',
          internal_field: 'first_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'LastName',
          internal_field: 'last_name'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Phone',
          internal_field: 'phone'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Email',
          internal_field: 'email'
        },
        {
          type: 'cf_field',
          data_type: 'str',
          overwrite: false,
          object_type: 'lead',
          external_field: 'Company',
          internal_field: 'company_name'
        }
      ]
    },
    event_settings: {
      transcript: {
        enabled: false
      },
      meeting_booked: {
        enabled: false
      },
      map_contact: {
        enabled: false
      },
      email_sent: {
        enabled: false
      }
    }
  }
}

const defaultABMSettings: {
  owner_rules: string[],
  account_sync: boolean,
  fallback_user_id: number,
  segment_rules: {
    segment_id: number,
    rules: string[],
    segment_owner?: number
  }[],
  search_leads: boolean
} = {
  owner_rules: [],
  account_sync: false,
  fallback_user_id: 1,
  segment_rules: [],
  search_leads: false
}

export default function SalesforceIntegrationPages () {
  const { flags } = useContext(ShareContext)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initSave, setInitSave] = useState(false)
  const [integrationSettings, setIntegrationSettings] = useState(initialValues)
  const [hasSalesforceConnection, setHasSalesforceConnection] = useState()
  const [salesforceUsers, setSalesforceUsers] = useState([])
  const [leadExternalFields, setLeadExternalFields] = useState([])
  const [contactExternalFields, setContactExternalFields] = useState([])
  const [accountExternalFields, setAccountExternalFields] = useState([])
  const [allowABE, setAllowABE] = useState(false)
  const [focusMapping, setFocusMapping] = useState(false)
  const [abmSyncSettings, setABMSyncSettings] = useState(defaultABMSettings)
  const { data: hasScoringComponent = false } = useQuery('has-scoring-component', () => hasComponent('score-insights'))

  const handleEdit = ({ type, data }: { type: string, data: any }) => {
    if (type === 'mapping' || type === 'user_mapping' || type === 'route_mapping') {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          mappings: {
            ...integrationSettings.attributes.mappings,
            [type]: data
          }
        }
      })
    } else if (type === 'event_settings') {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          [type]: data
        }
      })
    } else {
      setIntegrationSettings({
        ...integrationSettings,
        attributes: {
          ...integrationSettings.attributes,
          config: {
            ...integrationSettings.attributes.config,
            [type]: data
          }
        }
      })
    }
  }

  const handleABMSettingsChange = (settingName: string, settings: any) => {
    const newSettings = { ...abmSyncSettings }
    switch (settingName) {
      case 'owner_rules':
        newSettings.owner_rules = [...settings]
        break
      case 'search_leads':
        newSettings.search_leads = settings
        break
      case 'account_sync':
        newSettings.account_sync = settings
        break
      case 'fallback_user_id':
        newSettings.fallback_user_id = settings
        break
      case 'segment_rules':
        newSettings.segment_rules = [...settings]
        break
    }
    setABMSyncSettings(newSettings)
  }

  // const sanitize = (integrationSettings: any) => {
  //   const sanitizedMappings = integrationSettings.attributes.mappings.mapping.filter(
  //     (m: any) => m.internal_field !== '' && m.external_field !== ''
  //   )

  //   integrationSettings.attributes.mappings.mapping = sanitizedMappings

  //   return integrationSettings
  // }

  const save = () => {
    // const integration = sanitize(integrationSettings)
    const integration = integrationSettings
    if (Object.prototype.hasOwnProperty.call(integration, 'id')) {
      Promise.all([
        updateIntegration({ integration }).then(res => {
          setIntegrationSettings(res.data)
        }),
        // eslint-disable-next-line
        updateABMSettings({ ABMSettings: { attributes: { sync_settings: abmSyncSettings } } })
      ]).then(() => {
        setSaveEnabled(false)
      })
    }
  }

  const tabs = [
    {
      name: 'CONVERSATION SYNC',
      url: '/settings/salesforce/conversation-sync',
      components: [
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SyncCriteriaCard key='sync-criteria' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SendingRecordsCard key='sending-records' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <DefaultCampaignCard key='default-campaign' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SyncDelayCard key='sync-delay' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <EventSettingsCard
            key='event-settings'
            save={save} handleEdit={handleEdit}
            integrationSettings={integrationSettings}
            title='Event Settings'
            description='Sync the following Signals events. A Salesforce task will be created and attached to the Lead/Contact'
            enabledMap={{
              transcript: {
                enabled: integrationSettings.attributes.event_settings?.transcript?.enabled,
                label: 'Conversations'
              },
              map_contact: {
                enabled: integrationSettings.attributes.event_settings?.map_contact?.enabled,
                label: (
                  <div style={{ display: 'flex' }}>
                    <Typography>Map contacts to existing Accounts</Typography>
                    <div style={{ marginLeft: 20 }}>
                      <InformationIcon
                        message={'If an account can be found that matches the contact\'s domain, the contact will be added to the account rather than created as a lead'}
                        color='grey'
                        placement='right'
                      />
                    </div>
                  </div>
                )
              },
              meeting_booked: {
                enabled: integrationSettings.attributes.event_settings?.meeting_booked?.enabled,
                label: 'Meeting Booked'
              },
              email_sent: {
                enabled: integrationSettings.attributes.event_settings?.email_sent?.enabled,
                label: 'Email Sent'
              }
            }}
          />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <SyncOptionsCard key='sync-options' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>,
        <TabProvider key='firsttab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <LeadContactOwnerCard key='lead-contact-owner' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} />
        </TabProvider>
      ]
    },
    {
      name: 'LEADS',
      url: '/settings/salesforce/leads',
      components: [
        <TabProvider key='secondtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <LeadFieldMappingCard key='lead-field-mapping' save={save} leadExternalFields={leadExternalFields} />
        </TabProvider>
      ]
    },
    {
      name: 'CONTACTS',
      url: '/settings/salesforce/contacts',
      components: [
        <TabProvider key='thirdtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <ContactFieldMappingCard key='contact-field-mapping' save={save} contactExternalFields={contactExternalFields} />
        </TabProvider>
      ]
    },
    {
      name: 'ACCOUNTS',
      url: '/settings/salesforce/accounts',
      components: [
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <CreateAccountCard key='create-account' integration={integrationSettings.attributes.integration_name} />
        </TabProvider>,
        <TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <AccountFieldMappingCard key='account-field-mapping' save={save} accountExternalFields={accountExternalFields} focusMapping={focusMapping} />
        </TabProvider>,
        flags['crm-custom-fields'] && (<TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <AccountCustomFieldSegmentCard key='account-custom-field-segment' handleEdit={handleEdit} setCanSave={setSaveEnabled} fields={integrationSettings.attributes.config?.field_custom_mapping || []} integration={integrationSettings.attributes.integration_name} />
        </TabProvider>),
        hasScoringComponent && (<TabProvider key='fourthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <ScoreSyncingCard key='score-syncing' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} integrationName='Salesforce' externalFields={accountExternalFields} setFocusMapping={setFocusMapping} />
        </TabProvider>)
      ]
    },
    {
      name: 'USERS',
      url: '/settings/salesforce/users',
      components: [
        <TabProvider key='fifthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <UserCard key='user-mapping' save={save} handleEdit={handleEdit} integrationSettings={integrationSettings} salesforceUsers={salesforceUsers} />
        </TabProvider>
      ]
    },
    {
      name: 'OWNERSHIP',
      url: '/settings/salesforce/ownership',
      components: [
        <TabProvider key='sixthtab' setCanSave={setSaveEnabled} shouldSave={initSave}>
          <OwnershipCard key='ownership' save={save} allowABE={allowABE} handleABMSettingsChange={handleABMSettingsChange} abmSyncSettings={abmSyncSettings} />
        </TabProvider>
      ]
    }
  ]

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.Salesforce)
      .then((response: any) => {
        setHasSalesforceConnection(response.data.attributes.has_credentials)
      })
  }, [])

  useEffect(() => {
    if (!hasSalesforceConnection) {
      return
    }
    getIntegrationFeature('crm.abe').then((resp: any) => {
      if (resp.data.attributes.integration.name === 'salesforce') {
        setAllowABE(true)
      }
    })
  }, [hasSalesforceConnection])
  useEffect(() => {
    if (hasSalesforceConnection) {
      getABMSettings()
        .then((response: any) => {
          setABMSyncSettings(response.data.attributes.sync_settings)
        })
    }
  }, [hasSalesforceConnection])

  useEffect(() => {
    if (hasSalesforceConnection) {
      getIntegration(INTEGRATIONS.Salesforce)
        .then((response) => {
          if (response.ok) {
            if (!Object.keys(response.data.attributes.event_settings).length) {
              response.data.attributes.event_settings = initialValues.attributes.event_settings
            }
            if (!Object.keys(response.data.attributes.mappings).length) {
              response.data.attributes.mappings = initialValues.attributes.mappings
            }
            if (!Object.keys(response.data.attributes.config).length) {
              response.data.attributes.config = initialValues.attributes.config
            }
            setIntegrationSettings(response.data)
          } else {
            addIntegrationSetting({ integration: initialValues })
              .then((response) => {
                setIntegrationSettings(response.data)
              })
          }
        })

      const headers = {
        'Content-Type': 'application/vnd.api+json'
      }

      getIntegrationFields('salesforce', 'lead')
        .then((response) => setLeadExternalFields(response.data.map((ef: any) => ef.attributes)))

      getIntegrationFields('salesforce', 'contact')
        .then((response) => setContactExternalFields(response.data.map((ef: any) => ef.attributes)))

      getIntegrationFields('salesforce', 'account')
        .then((response) => setAccountExternalFields(response.data.map((ef: any) => ef.attributes)))

      fetch('/api/salesforce/users', {
        method: 'GET',
        headers: headers
      })
        .then((response) => response.json())
        .then((response) => {
          setSalesforceUsers(response.data)
        })
    }
  }, [hasSalesforceConnection])

  return (
    <IntegrationPage
      integration='Salesforce'
      tabs={tabs}
      saveDisabled={!saveEnabled}
      setShouldSave={setInitSave}
      oauth
      learnMoreLink='https://help.getsignals.ai/article/zzdpoe5ud5-salesforce'
    />
  )
}
