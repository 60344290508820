import { useState, useEffect } from 'react'
import MessagePage from './MessagePage'
import BotEditTab from './BotEditTab'
import { getUsers } from 'api/users'
import { getTabs } from './botConfig'
import { getAgents } from 'api/participants'
import AppPage from 'cf-components/AppPage'

function RoutingBotPage (props) {
  const [users, setUsers] = useState(null)
  const [usersDict, setUsersDict] = useState(null)
  const [agentsDict, setAgentsDict] = useState({})
  const values = props.values
  const setFieldValue = props.setFieldValue
  const bot = props.bot
  const chatServiceUrl = props.chatServiceUrl
  const botID = props.botID
  const botType = props.botType
  const disabled = props.disabled
  const submit = props.submit
  const publishBot = props.publishBot
  const enabled = bot.attributes.enabled
  const activeTest = bot.attributes?.active_test_id
  const botParticipant = props.botParticipant

  const tabs = getTabs(botID, botType)

  useEffect(() => {
    getUsers().then(response => {
      setUsers(response.data)
    })
  }, [])

  useEffect(() => {
    if (chatServiceUrl) {
      getAgents({ chatServiceUrl: chatServiceUrl }).then(response => {
        let dict = {}
        let userDict = {}
        response.data.map(
          p => {
            dict = { ...dict, [p.relationships.is_user.data.id]: p.id }
            userDict = { ...userDict, [p.id]: p.relationships.is_user.data.id }
            return true
          }
        )
        setAgentsDict(dict)
        setUsersDict(userDict)
      })
    }
  }, [chatServiceUrl])

  let bullets = [
    'Your welcome message is the first message the bot sends to visitors',
    'It can come directly from the bot, or you can have it come from one of your agents for a more personal touch',
    'Once a visitor responds to a message, one of your agents will be routed in for live chat according to the rules you specify in the routing section'
  ]
  if (botType === 'abe_greeting') {
    bullets = [
      'The bot will only activate for visitors from owned accounts.',
      'The welcome message will be sent to look like it is coming from the account owner',
      'Once a visitor responds to the welcome message, the account owner will be notified and routed in to the conversation'
    ]
  }

  return (
    <AppPage
      title={values.name}
      saveName={(name) => setFieldValue('name', name)}
      breadcrumbs={props.breadcrumbs}
      editableTitle={Boolean(bot)}
      tabs={tabs}
      alwaysLoaded={[0]}
    >
      <BotEditTab
        save={submit}
        publishBot={publishBot}
        values={values}
        disabled={disabled}
        enabled={enabled}
      >
        <MessagePage
          values={values}
          setFieldValue={setFieldValue}
          users={users}
          usersDict={usersDict}
          agentsDict={agentsDict}
          botParticipant={botParticipant}
          botType={botType}
          activeTest={activeTest}
          participiantSelectionMethod={values.participant_selection_method}
          bullets={bullets}
          botID={botID}
        />
      </BotEditTab>
    </AppPage>
  )
}

export default RoutingBotPage
