import Card from 'library/materialUI/Card'
import IntegrationMapping from '../IntegrationMapping'
import { useContext, useEffect } from 'react'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import Loader from 'library/loading/Loader'
import { TabContext } from '../IntegrationPage/TabProvider'

interface LeadFieldMappingCardProps {
  save: any
  leadExternalFields: any
}

export default function LeadFieldMappingCard ({ save, leadExternalFields }: LeadFieldMappingCardProps) {
  const groups = [{ value: 'leads', name: 'Lead Fields' }, { value: 'custom', name: 'Custom Fields' }]
  const { shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)
  const { data: crmLeadMapping, isLoading } = IntegrationMappingCls.loadOne('contacts', 'salesforce')

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          {isLoading ? <Loader type='spinner' />
          : <IntegrationMapping
              currentMapping={crmLeadMapping.mapping}
              externalMappingFields={leadExternalFields}
              forObject='people'
              mappedGroups={groups}
              integrationName='Salesforce'
              handleEdit={(data: any) => { IntegrationMappingCls.save('contacts', data, 'salesforce') }}
              title='Lead Field Mapping'
              disableOverwrite={undefined}
            />}
        </>
      }
    />
  )
}
