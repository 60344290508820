import { useContext, useState, useEffect } from 'react'
import { SessionContext } from 'session-context'
import { makeStyles } from '@material-ui/core/styles'
import ExplanationCard from './ExplanationCard'
import { Card, CardHeader, CardContent, Select, MenuItem, Divider, Typography, Avatar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BotPreview from '../BotPreview'
import MissingAvatar from 'img/agent-profile-large-invite.svg'
import RoutingDiv from './RoutingDiv'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { EditableChip } from 'pages/bot/flow-builder/nodes/OptionsNode'
// import ButtonTextField from 'library/textFields/ButtonTextField'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import ContentCheckbox from 'library/ContentCheckbox'
import TextField from '@material-ui/core/TextField'
import Picker from 'library/Picker'
import { Select as Selector } from 'cf-components/material-wrappers/MaterialComponents'
import { getOnboardingByBotId } from 'api/ai_settings'

const useStyles = makeStyles(theme => ({
  messagePage: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    width: '100%',
    gridGap: 15
  },
  card: {
    border: '1px solid rgba(142,90,226,0.5)',
    marginBottom: 15,
    backgroundColor: 'rgba(142,90,226,0.01)',
    overflow: 'visible !important'
  },
  header: {
    cursor: 'pointer',
    padding: 10
  },
  cardTitle: {
    fontSize: '1.1em'
  },
  cardAction: {
    margin: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  subHeading: {
    margin: '10px 20px 20px 10px'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: 10
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    gridGap: 20
  },
  previewDiv: {
    backgroundColor: 'rgba(142,90,226,0.08)',
    display: 'inline',
    minHeight: 560,
    padding: 20,
    marginBottom: 'auto'
  },
  settings: {
    backgroundColor: 'white',
    padding: 20
  },
  cardContentRoot: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  aiChip: {
    backgroundColor: '#39C5D7',
    color: 'white',
    fontSize: '12px',
    height: '30px',
    borderRadius: '15px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 15px'
  }
}))

function MessageCard (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const values = props.values
  const setFieldValue = props.setFieldValue
  const bot = props.botParticipant
  const allAgents = props.users.filter(user => Object.keys(props.agentsDict).map(n => parseInt(n)).includes(user.id))
  const possibleAgents = allAgents.filter((user) => user.attributes.perms.send_chats)
  const agentName = props.agentName
  const avatarUrl = props.avatarUrl
  const botID = props.botID

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  function editText (value, delta, source, editor) {
    props.setFieldValue('welcome_message', value)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const responseOptions = [
    { value: 'free', label: 'Free Response' },
    { value: 'ai', label: 'AI Agent' },
    { value: 'button', label: 'Button' }
  ]

  const attributeOptions = [
    { value: 'name', label: 'Name' },
    // { value: 'last_name', label: 'Last Name' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone Number' }
    // { value: 'company_size', label: 'Company Size' },
    // { value: 'company_name', label: 'Company Name' },
    // { value: 'company_location', label: 'Company Location'},
    // { value: 'personal_location', label: 'Personal Location' }
  ]

  function onDragEnd (result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      values.buttons,
      result.source.index,
      result.destination.index
    )

    props.setFieldValue('buttons', items)
  }

  function editOption (index, label) {
    const newButton = values.buttons[index]
    newButton.label = label
    props.setFieldValue('buttons', [
      ...values.buttons.slice(0, index),
      newButton,
      ...values.buttons.slice(index + 1)
    ])
  }

  function removeOption (index) {
    const newOptions = values.buttons.filter((_, i) => i !== index)
    props.setFieldValue('buttons', newOptions)
  }

  // function addOption (label) {
  //   if (label === '') {
  //     return
  //   }
  //   const newOptions = values.buttons.concat({
  //     id: jsPlumbUtil.uuid(),
  //     label: label
  //   })
  //   props.setFieldValue('buttons', newOptions)
  // }

  function getColor (id) {
    if (id.includes('ai-button-id')) {
      return '#39C5D7'
    } else if (id.includes('chat-with-agent-button-id')) {
      return '#4CAF50'
    } else if (id.includes('meeting-button-id')) {
      return '#4CAF50'
    } else if (id.includes('recommend-content-page-id')) {
      return '#4CAF50'
    }
    return undefined
  }

  useEffect(() => {
    if (botID) {
      getOnboardingByBotId(botID).then(response => {
        setFieldValue('onboarding_prompt', response.prompt)
        setFieldValue('onboarding_id', response.id)
        setFieldValue('ai_objectives', response.objectives)
      })
    }
    // eslint-disable-next-line
  }, [botID])

  useEffect(() => {
    if (values?.response_type?.includes('ai')) {
      const hasAI = values?.buttons?.some(button => button.id.includes('ai-button-id'))
      if (!hasAI) {
        setFieldValue('buttons', [...values?.buttons, { id: 'ai-button-id' + jsPlumbUtil.uuid(), label: 'Surprise me' }])
      }
    } else {
      setFieldValue('buttons', values?.buttons?.filter(button => !button.id.includes('ai-button-id')))
    }
    // eslint-disable-next-line
  }, [values?.response_type])

  useEffect(() => {
    if (values?.show_chat_with_agent_button) {
      const hasChatWithAgent = values?.buttons?.some(button => button.id.includes('chat-with-agent-button-id'))
      if (!hasChatWithAgent) {
        setFieldValue('buttons', [...values?.buttons, { id: 'chat-with-agent-button-id', label: values.chat_with_agent_label || 'Chat with human' }])
      } else {
        const newButtons = values?.buttons?.map(button => {
          if (button.id.includes('chat-with-agent-button-id')) {
            return { id: button.id, label: values.chat_with_agent_label }
          }
          return button
        })
        setFieldValue('buttons', newButtons)
      }
    } else {
      setFieldValue('buttons', values?.buttons?.filter(button => !button.id.includes('chat-with-agent-button-id')))
    }
    // eslint-disable-next-line
  }, [values?.show_chat_with_agent_button, values?.chat_with_agent_label])

  useEffect(() => {
    if (values?.show_meeting_button) {
      const hasMeeting = values?.buttons?.some(button => button.id.includes('meeting-button-id'))
      if (!hasMeeting) {
        setFieldValue('buttons', [...values?.buttons, { id: 'meeting-button-id', label: values.meeting_button_label || '', meeting_type: values?.meeting_type || 'Book a demo' }])
      } else {
        const newButtons = values?.buttons?.map(button => {
          if (button.id.includes('meeting-button-id')) {
            return { id: button.id, label: values.meeting_button_label, meeting_type: values?.meeting_type }
          }
          return button
        })
        setFieldValue('buttons', newButtons)
      }
    } else {
      setFieldValue('buttons', values?.buttons?.filter(button => !button.id.includes('meeting-button-id')))
    }
    // eslint-disable-next-line
  }, [values?.show_meeting_button, values?.meeting_button_label, values?.meeting_type])

  useEffect(() => {
    if (values?.show_recommend_content_page) {
      const hasRecommend = values?.buttons?.some(button => button.id.includes('recommend-content-page-id'))
      if (!hasRecommend) {
        setFieldValue('buttons', [...values?.buttons, { id: 'recommend-content-page-id', label: values.recommend_content_page_label || 'Learn more' }])
      } else {
        const newButtons = values?.buttons?.map(button => {
          if (button.id.includes('recommend-content-page-id')) {
            return { id: button.id, label: values.recommend_content_page_label }
          }
          return button
        })
        setFieldValue('buttons', newButtons)
      }
    } else {
      setFieldValue('buttons', values?.buttons?.filter(button => !button.id.includes('recommend-content-page-id')))
    }
    // eslint-disable-next-line
  }, [values?.show_recommend_content_page, values?.recommend_content_page_label])

  const buttonList = values?.buttons?.filter(button => values?.response_type?.includes('button') || button.id.includes('ai-button-id'))
  const previewButtons = buttonList?.map(button => { return { id: button.id, label: button.label } })

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Message Settings'
        className={classes.header}
        classes={{ title: classes.cardTitle, action: classes.cardAction }}
        onClick={handleExpandClick}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <div className={classes.content}>
            <div className={classes.settings}>
              <Typography variant='title'>
                Set your bot's welcome message
              </Typography>
              <Divider className={classes.divider} />
              <div style={{ height: 140 }}>
                <RichTextEditor
                  showMergeFields
                  message={values.welcome_message || ''}
                  onChange={editText}
                  placeholder='Welcome message...'
                />
              </div>
              {props.botType !== 'abe_greeting' && (
                <>
                  {props.botType === 'ai' ? (
                    <>
                      <div>
                        <Typography variant='title'>
                          How can visitors respond to this message?
                        </Typography>
                        <Divider className={classes.divider} />
                        <Selector
                          options={responseOptions}
                          value={values?.response_type || []}
                          placeholder='Select a response type'
                          onChange={(value) => {
                            setFieldValue('response_type', value)
                            !values?.buttons?.length && props.setFieldValue('buttons', [])
                          }}
                          multiple
                        />
                      </div>
                      {values?.response_type?.includes('ai') && !values?.response_type?.includes('button') && (
                        <div style={{ marginTop: '30px' }}>
                          <Typography variant='title'>
                            AI Agent message
                          </Typography>
                          <Divider className={classes.divider} />
                        </div>
                      )}
                      <div style={{ marginTop: '20px' }}>
                        <div className={classes.subsection}>
                          {buttonList?.length < 1 && values?.response_type?.includes('button') && (
                            <Typography variant='subtitle1'>
                              No Buttons Set
                            </Typography>
                          )}
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='droppable' direction='vertical'>
                              {(provided, snapshot) => (
                                <div
                                  className={classes.options}
                                  ref={provided.innerRef}
                                >
                                  {
                                    buttonList?.map((option, index) => (
                                      <Draggable key={option.id} draggableId={option.id} index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <EditableChip
                                              key={option.id}
                                              index={index}
                                              value={option.label}
                                              setValue={editOption}
                                              setButtons={(val) => props.setFieldValue('buttons', val)}
                                              // setOptions={(val) => props.setFieldValue('buttons', val)}
                                              option={option}
                                              removeOption={() => removeOption(index)}
                                              backgroundColor={getColor(option.id)}
                                              color={option.id.includes('ai-button-id') ? 'white' : undefined}
                                              aiDraggable={option.id.includes('ai-button-id')}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))
                                  }
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          {/* {values?.response_type?.includes('button') && (
                            <div style={{ marginTop: '10px' }}>
                              <ButtonTextField
                                addButton={addOption}
                                label={buttonLabel}
                                setLabel={setButtonLabel}
                              />
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div style={{ marginTop: '30px' }}>
                        <Typography variant='title'>
                          AI Onboarding
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography variant='subtitle1'>
                          Give your AI agent a name, role, or description to help the AI learn more about its purpose.
                        </Typography>
                        <TextField
                          value={values.onboarding_prompt}
                          onChange={e => setFieldValue('onboarding_prompt', e.target.value)}
                          variant='outlined'
                          fullWidth
                          margin='dense'
                          maxRows={2}
                          minRows={2}
                          multiline
                          placeholder='You are SignalsBot, a customer service AI that...'
                        />
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <Typography variant='subtitle1'>
                          Select contact fields as objectives that the AI should aim to capture.
                        </Typography>
                        <Selector
                          options={attributeOptions}
                          value={values?.ai_objectives || []}
                          placeholder='AI objectives'
                          onChange={(value) => {
                            setFieldValue('ai_objectives', value)
                          }}
                          multiple
                        />
                      </div>
                      {values?.response_type?.includes('button') && (
                        <>
                          <div style={{ marginTop: '30px' }}>
                            <Typography variant='title'>
                              Book Meeting Settings
                            </Typography>
                            <Divider className={classes.divider} />
                            <Typography variant='subtitle1'>
                              Configure your button settings by selecting which buttons you would like to include in your opening message.
                            </Typography>
                            <ContentCheckbox
                              label='Book Meeting Button'
                              checked={values.show_meeting_button}
                              onChange={() => setFieldValue('show_meeting_button', !values.show_meeting_button)}
                            >
                              <div>
                                <TextField
                                  value={values.meeting_button_label}
                                  onChange={e => setFieldValue('meeting_button_label', e.target.value)}
                                  variant='outlined'
                                  label='Meeting button label'
                                  fullWidth
                                  margin='dense'
                                />
                              </div>
                              <div style={{ marginTop: '10px' }}>
                                <Picker
                                  selection={values?.meeting_type}
                                  setSelection={(value) => { setFieldValue('meeting_type', value.id) }}
                                  objectType='meetingTypes'
                                  label='Select Meeting Type'
                                />
                              </div>
                              <div style={{ marginTop: '15px' }}>
                                <Typography variant='title'>
                                  Contact Details to Capture
                                </Typography>
                                <Divider className={classes.divider} />
                                <Selector
                                  options={attributeOptions}
                                  value={values?.meeting_capture_options || []}
                                  placeholder='Select capture details'
                                  onChange={(value) => {
                                    setFieldValue('meeting_capture_options', value)
                                  }}
                                  multiple
                                />
                              </div>
                            </ContentCheckbox>
                          </div>
                          <div style={{ marginTop: '30px' }}>
                            <Typography variant='title'>
                              Live Chat Settings
                            </Typography>
                            <Divider className={classes.divider} />
                            <ContentCheckbox
                              label='Live Chat Button'
                              checked={values.show_chat_with_agent_button}
                              onChange={() => setFieldValue('show_chat_with_agent_button', !values.show_chat_with_agent_button)}
                            >
                              <div>
                                <TextField
                                  value={values.chat_with_agent_label}
                                  onChange={e => setFieldValue('chat_with_agent_label', e.target.value)}
                                  variant='outlined'
                                  label='Chat with agent button label'
                                  fullWidth
                                  margin='dense'
                                />
                              </div>
                              <div style={{ marginTop: '15px' }}>
                                <Typography variant='title'>
                                  Contact Details to Capture
                                </Typography>
                                <Divider className={classes.divider} />
                                <Selector
                                  options={attributeOptions}
                                  value={values?.live_chat_capture_options || []}
                                  placeholder='Select capture details'
                                  onChange={(value) => {
                                    setFieldValue('live_chat_capture_options', value)
                                  }}
                                  multiple
                                />
                              </div>
                            </ContentCheckbox>
                          </div>
                          <div style={{ marginTop: '30px' }}>
                            <Typography variant='title'>
                              Content Recommendation Settings
                            </Typography>
                            <Divider className={classes.divider} />
                            <ContentCheckbox
                              label='Content Recommendation Button'
                              checked={values.show_recommend_content_page}
                              onChange={() => setFieldValue('show_recommend_content_page', !values.show_recommend_content_page)}
                            >
                              <div>
                                <TextField
                                  value={values.recommend_content_page_label}
                                  onChange={e => setFieldValue('recommend_content_page_label', e.target.value)}
                                  variant='outlined'
                                  label='Recommend content page label'
                                  fullWidth
                                  margin='dense'
                                />
                              </div>
                              <div style={{ marginTop: '15px' }}>
                                <Typography variant='title'>
                                  Contact Details to Capture
                                </Typography>
                                <Divider className={classes.divider} />
                                <Selector
                                  options={attributeOptions}
                                  value={values?.content_recommend_capture_options || []}
                                  placeholder='Select capture details'
                                  onChange={(value) => {
                                    setFieldValue('content_recommend_capture_options', value)
                                  }}
                                  multiple
                                />
                              </div>
                            </ContentCheckbox>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ marginTop: 30 }}>
                        <Typography variant='title'>
                          Who should the message be sent from?
                        </Typography>
                        <Divider className={classes.divider} />
                        <Select
                          value={values.participant_id}
                          displayEmpty
                          margin='dense'
                          fullWidth
                          className={classes.select}
                          variant='outlined'
                          onChange={e => {
                            setFieldValue('participant_id', e.target.value)
                          }}
                        >
                          <MenuItem value='' disabled>Select an agent below</MenuItem>
                          <MenuItem value={props.botParticipant.id}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar alt='User avatar' src={bot.attributes.avatar_url} style={{ height: 30, width: 30 }} />
                              <div style={{ marginLeft: 10 }}>{bot.attributes.agent_name}</div>
                            </div>
                          </MenuItem>
                          {possibleAgents.map((user, index) => (
                            <MenuItem value={props.agentsDict[user.id]} key={index}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt='User avatar' src={user.attributes.profile_picture_url || MissingAvatar} style={{ height: 30, width: 30 }} />
                                <div style={{ marginLeft: 10 }}>{user.attributes.name}</div>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <RoutingDiv
                        values={values}
                        teams={props.teams}
                        setFieldValue={setFieldValue}
                        botType={props.botType}
                      />
                    </>
                  )}
                </>
              )}

            </div>
            <div className={classes.previewDiv}>
              <BotPreview
                message={values.welcome_message === '' ? '<p>Welcome message...</p>' : values.welcome_message}
                avatarUrl={avatarUrl}
                agentName={agentName}
                size={values.prompt_style}
                buttons={previewButtons || values.teams}
                router={props.botType === 'team_live_chat_router' || props.botType === 'ai'}
              />
            </div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}

function MessagePage (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const values = props.values
  const setFieldValue = props.setFieldValue

  const bullets = props.bullets || [
    'Your welcome message is the first message the bot sends to visitors',
    'It can come directly from the bot, or you can have it come from one of your agents for a more personal touch',
    'Once a visitor responds to a message, one of your agents will be routed in for live chat according to the rules you specify in the routing section'
  ]

  if (!(props.botParticipant && props.users && props.usersDict)) {
    return <></>
  }

  let agentName, avatarUrl

  if (props.participiantSelectionMethod === 'account_owner') {
    agentName = user.attributes.name
    avatarUrl = user.attributes.profile_picture_url
  } else if (props.botParticipant.id === values.participant_id) {
    agentName = props.botParticipant.attributes.agent_name
    avatarUrl = props.botParticipant.attributes.avatar_url
  } else {
    const user = props.users.filter(user => user.id === props.usersDict[values.participant_id])[0]
    agentName = user.attributes.name
    avatarUrl = user.attributes.profile_picture_url || MissingAvatar
  }

  return (
    <div className={classes.messagePage}>
      <div>
        <MessageCard
          values={values}
          setFieldValue={setFieldValue}
          users={props.users}
          agentsDict={props.agentsDict}
          botParticipant={props.botParticipant}
          agentName={agentName}
          avatarUrl={avatarUrl}
          botType={props.botType}
          botID={props.botID}
        />
      </div>
      <div>
        <ExplanationCard bullets={bullets} />
      </div>
    </div>
  )
}

export default MessagePage
