import InformationIcon from 'library/InformationIcon'
import { Button, Icon, Typography } from 'library/materialUI'
import SegmentCriteriaGroup from './SegmentCriteriaGroup'
import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'

const useStyles = makeStyles(theme => ({
  filterBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    maxWidth: 550,
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'f20lex',
    alignItems: 'center',
    marginTop: '10px',
    color: theme.palette.primary.main
  },
  preventOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'black'
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  groupOutline: {
    border: '2px dashed #D1D1D1',
    borderRadius: 5,
    padding: '10px',
    width: '100%',
    margin: '5px',
    marginRight: '10px',
    position: 'relative'
  }
}))

export default function SegmentCriteria () {
  const classes = useStyles()
  const { triggers, setTriggers } = useContext(DynamicSegmentContext)

  const deleteCriteriaGroup = (groupIndex: number) => {
    const newTriggers = [...triggers]
    newTriggers.splice(groupIndex, 1)
    setTriggers(newTriggers)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: 10, paddingLeft: 15, minWidth: 330 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
        <Typography style={{ fontSize: 16, marginRight: 10, fontWeight: 'bold' }}> Segment Filter Criteria </Typography>
        <InformationIcon message='Companies must meet all of the criteria in at least one criteria group to be included in the segment' />
      </div>

      {triggers.map((segmentFilter, index) => (
        <SegmentCriteriaGroup key={index} groupIndex={index} showOrText={index < triggers.length - 1} deleteGroup={deleteCriteriaGroup} />
      ))}
      <div className={`${classes.filterBtn} ${classes.center}`}>
        <Button
          startIcon={<Icon icon='addCircleOutline' />}
          size='small'
          style={{ padding: '0px 5px', maxWidth: 500 }}
          onClick={() => {
            const newTriggers = [...triggers]
            newTriggers.push({ filters: [] })
            setTriggers(newTriggers)
          }}
          variant='text'
        >
          <div className={classes.preventOverflow}>
            Add Criteria Group
          </div>
        </Button>
      </div>
    </div>
  )
}
