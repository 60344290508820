const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function domainSearch ({ search, chatServiceUrl }) {
  const url = `${chatServiceUrl}/email_domains:search`
  const params = new URLSearchParams()
  if (search) {
    params.set('filter[search]', search)
  }

  return fetch(url + '?' + params.toString(), {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
}

function deleteDomains ({ domain_ids, chatServiceUrl }) {
  const url = `${chatServiceUrl}/email_domains`
  const body = JSON.stringify({
    data: {
      attributes: {
        domain_ids: domain_ids
      }
    }
  })
  return fetch(url, {
    method: 'DELETE',
    headers: headers,
    body: body
  })
}

function createDomains ({ domains, kind, chatServiceUrl }) {
  const url = `${chatServiceUrl}/email_domains`

  const data = domains.map(domain => ({
    type: 'email_domains',
    attributes: {
      domain: domain,
      kind: kind
    }
  }))

  const method = 'POST'

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify({
      data: data
    })
  })
    .then(response => response.json())
}

export function importFromCSV ({ chatServiceUrl, csvFile, kind }) {
  const url = `${chatServiceUrl}/email_domains/csv_import?kind=${kind}`
  const method = 'POST'
  return fetch(url, {
    method,
    body: csvFile
  }).then(response => response.json())
}

export { domainSearch, deleteDomains, createDomains }
