import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BasePortComponent } from 'jsplumbtoolkit-react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import ExitIcon from '@material-ui/icons/ExitToApp'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import { getBots } from 'api/bots'
import { Modal } from 'cf-components/Modal'
import 'jsplumb'

const useStyles = makeStyles(theme => ({
  exitDiv: {
    backgroundColor: '#fff',
    width: 250,
    padding: 20,
    visibility: 'visible',
    fontFamily: 'Poppins, sans serif'
  },
  exitHeader: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#B14172',
    height: 40,
    borderRadius: 5,
    marginBottom: 5
  },
  tooltip: {
    fontSize: '14px'
  },
  addButton: {
    color: '#B14172',
    border: '1px solid #B14172',
    width: 210
  },
  optionsDiv: {
    padding: '15px 10px 5px 20px'
  },
  option: {
    marginBottom: 7
  }
}))

function PortWrapper (props) {
  const [hover, setHover] = useState(false)
  const base = props.base

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{ marginTop: 5, width: 220, position: 'relative' }}
      >
        <jtk-target
          port-id={base.state.id}
          scope='default'
          style={{
            backgroundColor: '#B14172',
            width: 210,
            height: 40,
            borderRadius: 4,
            paddingLeft: 20,
            display: 'grid',
            gridTemplateColumns: 'auto 40px',
            gridGap: 5,
            alignItems: 'center',
            border: '2px solid #B14172'
          }}
        >
          <input
            id='outputPort'
            onChange={(e) => base.props.updateLabel(base, e.target.value)}
            value={base.state.label}
            placeholder='Click to edit'
            style={{
              backgroundColor: '#B14172',
              color: '#fff',
              fontSize: '14px',
              border: 'none',
              outline: 'none',
              width: '100%',
              height: 30,
              userSelect: 'text'
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') { document.activeElement.blur() }
            }}
          />
          <div style={{ cursor: 'pointer', visibility: hover ? 'visible' : 'hidden' }}>
            <IconButton
              style={{ padding: 6 }}
              onClick={() => base.props.removePort(base.state.id)}
            >
              <DeleteIcon
                style={{ fill: '#fff' }}
              />
            </IconButton>
          </div>
        </jtk-target>
      </div>
    </div>
  )
}

class OutputPort extends BasePortComponent {
  render () {
    return (
      <PortWrapper
        base={this}
      />
    )
  }
}

const reconfigurationOptions = [
  { message: 'Create a new global sequence with the desired exits' }
]

function EditPortsModal (props) {
  const classes = useStyles()
  const length = props.bots?.length || 1
  const botString = length === 1 ? '1 bot' : length + ' bots'

  const message = ' This sequence is used in ' + botString + '. Changing the exits will lead to unexpected behavior in any bots using this sequence. If you still want to reconfigure the exits, please try one of the following options:'

  const options = props.botEditor ? [
    { message: 'Stop tracking the global sequence' }
  ].concat(reconfigurationOptions) : reconfigurationOptions

  return (
    <Modal
      title='Invalid Action'
      onHide={props.onHide}
      open={props.open}
      size='sm'
      rightButtonProps={{
        text: 'Got it',
        action: props.onHide,
        variant: 'outlined'
      }}
    >
      <div>
        <div>
          <b>Warning:</b>
          {message}
        </div>
        <div className={classes.optionsDiv}>
          {options.map((option, index) => (
            <div key={index} className={classes.option}>
              {(index + 1) + '. ' + option.message}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

const SequenceOutputNode = props => {
  const classes = useStyles()
  const base = props.base
  const [bots, setBots] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const sequenceID = window.sequenceID
    if (sequenceID) {
      getBots({ sequenceID })
        .then(response => {
          setBots(response.data)
        })
    }
  }, [base])

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  function updateLabel (p, value) {
    let port = base.state.id + '.' + p.state.id
    let newData = {
      ...p.state,
      label: value
    }
    base.toolkit.updatePort(port, newData)
    const parent = window.sequenceNode
    if (parent) {
      port = parent.state.id + '.' + p.state.id
      newData = {
        ...p.state,
        label: value
      }
      parent.toolkit.updatePort(port, newData)
    }
  }

  function removePort (portID) {
    const currentNode = base.getNode()
    base.toolkit.removePort(currentNode, portID)

    const parent = window.sequenceNode
    if (parent) {
      setTimeout(() => {
        const parentNode = parent.getNode()
        parent.toolkit.removePort(parentNode, portID)
      }, 5)
    }
  }

  function addNewPort () {
    base.state.nextOutputID = base.state.nextOutputID || 1
    const id = 'exit' + base.state.nextOutputID
    const label = 'Exit ' + base.state.nextOutputID
    const port = {
      id: id,
      label: label
    }
    const parent = window.sequenceNode
    const newData =
    {
      ...base.state,
      nextOutputID: base.state.nextOutputID ? base.state.nextOutputID + 1 : 1
    }
    const node = base.getNode()
    base.toolkit.updateNode(node, newData)
    base.addNewPort('default', port)
    if (parent) {
      parent.addNewPort('default', port)
    }
  }

  const url = window.location
  const botEditor = url.hash.includes('bots')
  let disabled = true
  if (bots && bots.length === 1 && botEditor) {
    disabled = false
  } else if (bots && !bots.length) {
    disabled = false
  } else if (!bots) {
    disabled = false
  }

  return (
    <div className={classes.exitDiv}>
      <Tooltip
        title='When you add an exit here, a new port will be added to your sequence node in the bot builder. You can use these exits to connect back to the bot flow.'
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.exitHeader}>
          <ExitIcon
            style={{ marginLeft: 10, marginRight: 10 }}
          />
          Sequence Exits
        </div>
      </Tooltip>
      <div>
        {base.state.ports.map((c, index) => {
          return (
            <OutputPort
              index={index}
              data={c}
              key={assignKey(c)}
              parent={base}
              kind='sequenceOutput'
              updateLabel={updateLabel}
              removePort={disabled ? () => setModalOpen(true) : removePort}
            />
          )
        })}
      </div>
      <div style={{ height: 40, width: 250, marginTop: 5 }}>
        <Button
          variant='outlined'
          fullWidth
          onClick={disabled ? () => setModalOpen(true) : addNewPort}
          startIcon={<AddIcon />}
          classes={{ outlined: classes.addButton }}
        >
          Add sequence exit
        </Button>
      </div>
      <EditPortsModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        bots={bots}
        botEditor={botEditor}
      />
    </div>
  )
}

export default SequenceOutputNode
