import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SegmentCriteriaButtons from './SegmentCriteriaButtons' // Adjust the import path as needed

const useStyles = makeStyles((theme) => ({
  groupOutline: {
    border: '2px dashed #D1D1D1',
    borderRadius: 5,
    padding: '10px',
    width: '100%',
    margin: '5px',
    marginRight: '10px',
    position: 'relative'
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  orText: {
    backgroundColor: '#EEEEEE',
    fontSize: 15,
    minWidth: 38,
    minHeight: 33,
    padding: '0px 2px',
    borderRadius: 5,
    margin: '5px',
    marginRight: '10px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  deleteButton: {
    position: 'absolute',
    top: '5px',
    right: '5px'
  }
}))

interface SegmentCriteriaGroupProps {
  groupIndex: number
  showOrText: boolean
  deleteGroup: (groupIndex: number) => void
}

const SegmentCriteriaGroup: React.FC<SegmentCriteriaGroupProps> = ({ groupIndex, showOrText, deleteGroup }) => {
  const classes = useStyles()

  return (
    <div className={classes.center}>
      <div className={classes.groupOutline}>
        <IconButton
          className={classes.deleteButton}
          onClick={() => deleteGroup(groupIndex)}
          size='small'
        >
          <DeleteIcon />
        </IconButton>
        <SegmentCriteriaButtons groupIndex={groupIndex} />
      </div>
      {showOrText && <div className={classes.orText}>or</div>}
    </div>
  )
}

export default SegmentCriteriaGroup
