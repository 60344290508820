import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardCard from 'pages/dashboard/DashboardCard'
import { getActivityTimeline, getTopicsSentiment } from 'api/account_management'
import ActivityEventsDropdown from './ActivityEventsDropdown'
import Tooltip from '@material-ui/core/Tooltip'
import NoActivity from './NoActivity'
import Loader from 'library/loading/Loader';
import ActivityChart from './ActivityChart'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { Select, MenuItem, Popper, Paper } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import { getScoreHistory } from 'api/scores'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import ActivitySummary from './ActivitySummary'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import { TopicsSentimentModal } from 'components/TopicsSentimentModal'
import { useQuery } from 'react-query'
import { getFeatureFlags } from 'api/feature_flags'

const mywindow = window as any
const useStyles = makeStyles(theme => ({
  activityTable: {
    marginTop: 10,
    maxHeight: 650,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  buttonList: {
    position: 'absolute',
    cursor: 'pointer',
    top: 18,
    right: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  exportIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 18,
    right: 30
  },
  loadingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  viewDropdown: {
    display: 'flex',
    flexDirection: 'row',
    color: '#9933FF',
    fontSize: '14px',
    marginLeft: '10px',
    borderBottom: '1px solid #000000BF',
    cursor: 'pointer'
  },
  popper: {
    zIndex: 10000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      width: 0,
      height: 0,
      border: '1px solid black'
    }
  },
  popperContainer: {
    padding: 20
  }
}))

interface AccountActivityTableProps {
  data: any
  squiggle: any
  iFrame?: boolean
  loading?: boolean
  setContact?: (value: any) => void
  setSubmissionID: (value: number) => void
  setSubmissionModalOpen: (value: boolean) => void
  timeFrame?: string
}

function AccountActivityTable ({ data, squiggle, iFrame = false, loading = false, setContact, setSubmissionID, setSubmissionModalOpen, timeFrame }: AccountActivityTableProps) {
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.loadingDiv}>
        <Loader type='spinner' />
      </div>
    )
  } else if (!data || !data.length) {
    return (
      <NoActivity iFrame={iFrame} days={timeFrame} />
    )
  }
  if (squiggle) {
    return (
      <ActivityChart data={data} />
    )
  }
  return (
    <div className={classes.activityTable}>
      {data.map((dayData: any, index: number) => (
        <ActivityEventsDropdown
          key={index}
          activityData={dayData}
          defaultExpanded={data.length < 3}
          iFrame={iFrame}
          setContact={setContact}
          setSubmissionID={setSubmissionID}
          setSubmissionModalOpen={setSubmissionModalOpen}
        />
      ))}
    </div>
  )
}

interface AccountActivityProps {
  accountID: number | string | undefined
  contactID?: number | undefined
  iFrame?: boolean
  accountName?: string
  setContact?: (value: any) => void
  setSubmissionID: (value: number) => void
  setSubmissionModalOpen: (value: boolean) => void
  domain?: string
}

const timeFrameMap: Record<string, string> = {
  '30 days': 'Last 30 Days',
  '90 days': 'Last 90 Days',
  '180 days': 'Last 6 Months'
}

function AccountActivity ({ accountID, contactID, setContact, iFrame, domain, setSubmissionID, setSubmissionModalOpen }: AccountActivityProps) {
  const classes = useStyles()
  const [squiggle, setSquiggle] = useState(false)
  const [data, setData] = useState<any>([])
  const location = useLocation()
  const sid = queryString.parse(location.search).sid
  const authHeader = { Authorization: `Bearer ${sid}` }
  const [timeFrame, setTimeFrame] = useState('30 days')
  const { data: activityData, isLoading: loading } = getActivityTimeline({ accountID, contactID, authHeader, timeFrame })
  const { data: flags } = useQuery('featureFlags', async () => {
    return await getFeatureFlags()
  })
  const [topicsData, setTopicsData] = useState<any>(null)
  const [topics, setTopics] = useState<any>([])
  const [sentiment, setSentiment] = useState<any>({})
  const [isLoadingTopics, setIsLoadingTopics] = useState(false)
  const [topicsAnchorEl, setScoreAnchorEl] = useState<any>(null)
  const [dataID, setdataID] = useState<string>('')
  const [topicsModalOpen, setTopicsModalOpen] = useState(false)
  const [loaderOpen, setLoaderOpen] = useState(false)
  const [timelineState, setTimelineState] = useState('feed')
  const [sparkData, setSparkData] = useState()
  const [chartId] = useState(jsPlumbUtil.uuid())
  const chartRef = useRef<any>(null)
  const [domainRef] = useState(domain)
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)

  const handleClick = (type?: string) => {
    if (type) {
      setTimelineState(type)
      if (type === 'feed') {
        setSquiggle(false)
      } else if (type === 'timeline') {
        setSquiggle(true)
      }
    } else {
      setSquiggle(!squiggle)
    }
  }

  useEffect(() => {
    if (flags && flags['ai-sentiment']) {
      setIsLoadingTopics(true)
      getTopicsSentiment({ domain: `${accountID}` }).then((data) => {
        setTopicsData(data)
        setIsLoadingTopics(false)
        for (const key in data?.data?.attributes?.topicsByDay) {
          if (data?.data?.attributes?.topicsByDay[key].length === 0) {
            const icon = document.getElementById(`icon-${key}`)
            if (icon) {
              icon.style.visibility = 'hidden'
            }
          }
        }
      })
    } else {
      setTopicsData(null)
    }
    // eslint-disable-next-line
  }, [flags, accountID])

  useEffect(() => {
    if (!loading) {
      // filter out data based on contactID
      if (contactID) {
        let filteredData = activityData?.data?.attributes?.timeline_data || []
        filteredData = filteredData.map((dataItem: any) => {
          const visitData = dataItem.site_visits
          const filteredVisits = visitData.filter((visit: any) => {
            return visit.contact_id === contactID
          })
          // eslint-disable-next-line
          return { ...dataItem, site_visits: filteredVisits }
        })
        const nonEmpty = filteredData.filter((dataItem: any) => {
          return dataItem.site_visits.length > 0
        })
        setData(nonEmpty)
      } else {
        setData(activityData?.data?.attributes?.timeline_data)
      }
    }
    // eslint-disable-next-line
  }, [loading, activityData?.data?.attributes?.timeline_data])

  useEffect(() => {
    if (domainRef) {
      let time = 0
      if (timeFrame === '30 days') {
        time = 30
      } else if (timeFrame === '90 days') {
        time = 90
      } else {
        time = 180
      }
      getScoreHistory(domainRef, time)
        .then(response => {
          if (response.length > 0) {
            setSparkData(response)
          }
        })
    }
  }, [domainRef, timeFrame, timelineState])
  useEffect(() => {
    const wrapper = document.getElementById('timeline-wrapper')
    const url = `/api/core/web/accounts/${domain}/timeline?time_frame=${timeFrame}`
    if (wrapper) {
      wrapper.setAttribute('hx-get', url)
      mywindow.htmx.process(wrapper)
      mywindow.htmx.trigger(wrapper, 'paramChange')
    }
  }, [timeFrame, domain])

  useEffect(() => {
    if (sparkData) {
      am4core.addLicense('CH213476655')
      am4core.useTheme(am4themes_animated)

      const chart = am4core.create(chartId, am4charts.XYChart);
      chart.padding(10, 10, 10, 10);
      chart.data = sparkData || []
      chart.height = am4core.percent(100)
      chart.cursor = new am4charts.XYCursor()

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.renderer.grid.template.disabled = true
      // dateAxis.renderer.labels.template.disabled = true
      dateAxis.cursorTooltipEnabled = true

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.renderer.grid.template.disabled = true
      // valueAxis.renderer.baseGrid.disabled = true
      valueAxis.renderer.labels.template.disabled = false
      valueAxis.max = 100
      valueAxis.min = 0
      valueAxis.strictMinMax = true
      valueAxis.renderer.minGridDistance = 20
      valueAxis.cursorTooltipEnabled = false

      // Create series
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'score'
      series.dataFields.dateX = 'date'
      series.strokeWidth = 2
      series.tensionX = 0.8
      series.tooltipText = '{valueY}'
      series.fillOpacity = 0.25;

      chartRef.current = chart
      return () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    }
    // eslint-disable-next-line
  }, [sparkData])
  /*
      const handleClick = () => {
        props.setSubmissionModalOpen(true)
        props.setSubmissionID(form_sub.id)
        props.setContact()
      }
  */
  const rowEventListener = (e: any) => {
    // const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const anchorDiv = document.getElementById(e.detail.anchor)
    console.log('rowEventListener: ', { detail: e.detail })
    const rowID = e.detail.rowID
    // const anchorDiv = document.getElementById(e.detail.anchor)
    console.log(rowAction)
    switch (rowAction) {
      case 'form-fill':
        setSubmissionID(rowID)
        setSubmissionModalOpen(true)
        break
      case 'alert':
        break
      case 'scoreHoverCancel':
        break
      case 'interestHover':
        setScoreAnchorEl(anchorDiv)
        setdataID(rowID)
        break
      case 'interestHoverCancel':
        setTopicsModalOpen(false)
        setLoaderOpen(false)
        setdataID('')
        break
      case 'scoreHover':
        // if (anchorDiv) {
        // }
        break
      default:
      // console.warn('Unknown row action', rowAction)
    }
  }
  useEffect(() => {
    if (dataID && !isLoadingTopics) {
      setTopicsModalOpen(true)
      setLoaderOpen(false)
    }
    if (dataID && isLoadingTopics) {
      setLoaderOpen(true)
    }
    // eslint-disable-next-line
  }, [dataID, isLoadingTopics])

  useEffect(() => {
    if (topicsData) {
      setTopics(topicsData?.data?.attributes.topicsByDay?.[dataID] || [])
      setSentiment(topicsData?.data?.attributes?.sentimentByDay?.[dataID] || {})
    }
    // eslint-disable-next-line
  }, [topicsData, dataID])
  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data)
        }
      }
      */
      // mywindow.addEventListener('tableEvent', tableEventListener)
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      // mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [isLoadingTopics])

  const url = `/api/core/web/accounts/${domain}/timeline?time_frame=${timeFrame}`
  let dashboardState = (
    <>
      <div hx-get={url} hx-trigger='revealed,paramChange' id='timeline-wrapper'>
        <SkeletonLabel size='fullxl' />
        <div className='w-full flex place-content-center'><div className='divider mt-0 mb-0 h-[1px] w-11/12' /></div>
        <SkeletonLabel size='fullxl' />
        <div className='w-full flex place-content-center'><div className='divider mt-0 mb-0 h-[1px] w-11/12' /></div>
        <SkeletonLabel size='fullxl' />
        <div className='w-full flex place-content-center'><div className='divider mt-0 mb-0 h-[1px] w-11/12' /></div>
        <SkeletonLabel size='fullxl' />
      </div>
    </>
  )
  if (contactID) {
    dashboardState = (
      <AccountActivityTable
        data={data}
        squiggle={squiggle}
        iFrame={iFrame}
        loading={loading}
        setContact={setContact}
        setSubmissionID={setSubmissionID}
        setSubmissionModalOpen={setSubmissionModalOpen}
        timeFrame={timeFrame}
      />
    )
  }

  if (timelineState === 'trend') {
    dashboardState = (
      <div style={{ minHeight: '536px', width: '100%' }}>
        <div id={chartId} style={{ height: '536px', width: '100%' }} />
      </div>
    )
  } else if (timelineState === 'summary') {
    dashboardState = (
      <ActivitySummary
        summaryData={activityData?.data?.attributes?.summary_data}
        isContact={!!contactID}
      />
    )
  }

  return (
    <>
      {iFrame ? (
        <AccountActivityTable
          data={data}
          squiggle={squiggle}
          iFrame={iFrame}
          loading={loading}
          timeFrame={timeFrame}
        />
      ) : (
        <DashboardCard
          title={contactID ? 'Contact Activity - ' : 'Account Activity - '}
          titleAction={(
            <Select
              style={{ marginLeft: 5 }}
              variant='standard'
              margin='dense'
              value={timeFrame}
              onChange={(e: any) => setTimeFrame(e.target.value)}
              MenuProps={{
                variant: 'menu',
                autoFocus: false,
                disableAutoFocusItem: true,
                disableEnforceFocus: true,
                disableAutoFocus: true,
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
              renderValue={selected => {
                return <div style={{ fontSize: 20, fontFamily: 'poppins', fontWeight: 600, color: '#575757' }}>{timeFrameMap[selected as string]}</div>
              }}
              disabled={loading}
            >
              <MenuItem key={1} value='30 days'>Last 30 Days</MenuItem>
              <MenuItem key={2} value='90 days'>Last 90 Days</MenuItem>
              <MenuItem key={3} value='180 days'>Last 6 Months</MenuItem>
            </Select>
          )}
          closeAction
          // noScrollbox={timelineState !== 'feed'}
          noScrollbox
        >
          {contactID ? (
            <div className={classes.buttonList}>
              <Tooltip title='Activity Feed'>
                <div onClick={() => handleClick('feed')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '46px', height: '36px', borderRadius: '5px 0px 0px 5px', border: '1px solid #C4C4C4', backgroundColor: timelineState === 'feed' ? '#F5F5F5' : '#FFFFFF' }}>
                  <Icon icon='feedIcon' color={timelineState === 'feed' ? 'black' : 'grey'} />
                </div>
              </Tooltip>
              <Tooltip title='Summary'>
                <div onClick={() => handleClick('summary')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '46px', height: '36px', borderRadius: '0px 5px 5px 0px', border: '1px solid #C4C4C4', backgroundColor: timelineState === 'summary' ? '#F5F5F5' : '#FFFFFF' }}>
                  <Icon icon='activitySummary' color={timelineState === 'summary' ? 'black' : 'grey'} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <div className={classes.buttonList}>
              <Tooltip title='Activity Feed'>
                <div onClick={() => handleClick('feed')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '46px', height: '36px', borderRadius: '5px 0px 0px 5px', border: '1px solid #C4C4C4', backgroundColor: timelineState === 'feed' ? '#F5F5F5' : '#FFFFFF' }}>
                  <Icon icon='feedIcon' color={timelineState === 'feed' ? 'black' : 'grey'} />
                </div>
              </Tooltip>
              <Tooltip title='Score Trend'>
                <div onClick={() => handleClick('trend')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '46px', height: '36px', border: '1px solid #C4C4C4', backgroundColor: timelineState === 'trend' ? '#F5F5F5' : '#FFFFFF' }}>
                  <Icon icon='trendingUp' color={timelineState === 'trend' ? 'black' : 'grey'} />
                </div>
              </Tooltip>
              <Tooltip title='Summary'>
                <div onClick={() => handleClick('summary')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '46px', height: '36px', borderRadius: '0px 5px 5px 0px', border: '1px solid #C4C4C4', backgroundColor: timelineState === 'summary' ? '#F5F5F5' : '#FFFFFF' }}>
                  <Icon icon='activitySummary' color={timelineState === 'summary' ? 'black' : 'grey'} />
                </div>
              </Tooltip>
            </div>
          )}
          {dashboardState}
        </DashboardCard>
      )}
      <TopicsSentimentModal
        open={topicsModalOpen}
        topics={topics}
        sentiment={sentiment}
        anchorEl={topicsAnchorEl}
        placement='left'
        dataID={dataID}
      />
      {loaderOpen && (
        <Popper
          id={dataID}
          open={loaderOpen}
          anchorEl={topicsAnchorEl}
          placement='left'
          className={classes.popper}
          transition
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef
            }
          }}
        >
          <span className={classes.arrow} ref={setArrowRef} />
          <Paper className={classes.popperContainer}>
            <Loader size='sm' />
          </Paper>
        </Popper>
      )}
    </>
  )
}

export default AccountActivity
