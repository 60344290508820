import { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQueryClient } from 'react-query'
import { getConversationList } from 'api/conversations'
import ConversationList from './ConversationList'
import ConversationView from './ConversationView'
import { usePostageSocket } from 'components/PostageSocket'
import { ConversationParametersContext } from './conversation-parameters-context'
import { ConversationWindowContext } from './conversation-window-context'
import { InitialLoadingScreen } from './ChatLoaders'
import { sendEvent } from 'api/conversation_events'
import MobileAgentChat from '../mobile-chat/MobileAgentChat'
import ChatAlertManager from './ChatAlertManager'
import { TenantDataContext } from './tenant-data-context'
import AgentChatOnboarding from 'cf-components/AgentChatOnboarding'
/* eslint-disable react-hooks/exhaustive-deps */
import { DisplaySettings } from 'classes/displaySettings'
import AudioNotificationPermissionAlert from 'components/AudioNotificationPermissionAlert'
import AudioNotificationPrompt from 'components/AudioNotificationPrompt'
import silent_quarter_second from 'assets/silent_quarter_second.wav'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

const useStyles = makeStyles(theme => ({
  outerChatPage: {
    width: '100%',
    height: 'calc(100vh)',
    position: 'relative'
  },
  chatPage: {
    fontSize: '14px',
    backgroundColor: 'white',
    display: 'grid',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    gridTemplateColumns: 'minmax(280px, min(20%, 300px)) auto',
    fontFamily: theme.typography.fontFamily,
    position: 'relative'
  },
  list: {
    borderRight: 'solid rgba(0, 0, 0, .125) 2px',
    paddingRight: 2,
    height: '100vh'
  },
  listHeader: {
    borderBottom: 'solid rgba(0, 0, 0, .125) 2px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    color: '#2b2b2b',
    fontSize: '15px'
  },
  conversationList: {
    height: 'calc(100vh - 20px)',
    overflow: 'hidden'
  }
}))

const refreshConversationList = ({ dispatch, conversationFilters, limit, activeConversationID }) => {
  const chatServiceUrl = window.chatServiceUrl
  getConversationList({ chatServiceUrl, conversationFilters, limit, activeConversationID })
    .then(response => {
      const readReceipts = {}
      for (const i of response.included) {
        readReceipts[i.id] = Boolean(i.attributes.read === 'True')
      }
      dispatch({
        type: 'refreshList',
        value: response.data,
        meta: response.meta,
        readReceipts: readReceipts
      })
    })
}

const handleSubscribe = ({ state, subscribeToConversations }) => {
  const conversationIDs = state.conversationList?.map(i => i.id) || []
  if (state.activeConversationID) {
    conversationIDs.push(state.activeConversationID)
  }
  subscribeToConversations(conversationIDs)
}

function ChatPage (props) {
  const classes = useStyles()
  const { dispatch, state } = useContext(ConversationWindowContext)
  const activeConversationID = state.activeConversationID
  const { parameters } = useContext(ConversationParametersContext)
  const { activeParticipantID } = useContext(TenantDataContext)
  const conversationFilters = parameters.state.conversationFilters
  const limit = parameters.state.limit
  const queryClient = useQueryClient()
  const setDrawerOpen = props.setDrawerOpen
  const [cardSelectOpen, setCardSelectOpen] = useState(false)
  const [conferenceOpen, setConferenceOpen] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [audioAlert, setAudioAlert] = useState('none')
  const { settings, isLoading } = DisplaySettings.load()
  const [showInAppWarning, setShowInAppWarning] = useState(false)

  const activeConversationQueryKey = activeConversationID + '/conversation-from-list'

  const {
    connectionStatus,
    subscribeToConversations
  } = usePostageSocket((msg) => {
    switch (msg.type) {
      case 'route':
        refreshConversationList({ dispatch, conversationFilters, limit, activeConversationID })
        break
      case 'chat_notifications': {
        let doRefresh = false
        if (conversationFilters.kind !== 'default') {
          doRefresh = true
        } else if (!conversationFilters.values.filter.startsWith('my')) {
          doRefresh = true
        } else if (msg.attributes.activeAgentID === activeParticipantID) {
          doRefresh = true
        }
        if (doRefresh) {
          refreshConversationList({ dispatch, conversationFilters, limit, activeConversationID })
        }
        break
      }
      case 'conversation_events':
        switch (msg.attributes.kind) {
          case 'chat':
            dispatch({ type: 'appendEvent', value: msg })
            dispatch({ type: 'removeTyping', value: msg })
            break
          case 'type':
            dispatch({ type: 'appendTyping', value: msg })
            break
          case 'halt':
            dispatch({ type: 'removeTyping', value: msg })
            break
          case 'capture':
            refreshConversationList({ dispatch, conversationFilters, limit, activeConversationID })
            queryClient.invalidateQueries(activeConversationQueryKey)
            break
          default:
            break
        }
        break
      default:
        break
    }
  })

  useEffect(() => {
    if (!activeConversationID && state.conversationList?.length > 0) {
      dispatch({ type: 'selectConversation', value: state.conversationList[0].id })
    }
  }, [state.conversationList])

  useEffect(() => {
    if (connectionStatus !== WebSocket.OPEN) return
    refreshConversationList({ dispatch, conversationFilters, limit, activeConversationID })
  }, [connectionStatus, conversationFilters, limit, activeConversationID])

  useEffect(() => {
    handleSubscribe({ state, subscribeToConversations })
  }, [state.conversationList])

  useEffect(() => {
    if (activeConversationID && !state.readReceipts[activeConversationID]) {
      const chatServiceUrl = window.chatServiceUrl
      const attributes = { body: '', kind: 'read' }
      sendEvent({ conversationID: activeConversationID, chatServiceUrl, attributes })
      dispatch({ type: 'read', value: activeConversationID })
    }
  }, [activeConversationID])

  useEffect(() => {
    if (!isLoading && settings?.alertBanner) {
      if (settings?.alertBanner?.settings.sound_alert_snoozed?.timestamp) {
        const now = new Date(Date.now())
        const snoozedUntil = Date.parse(settings.alertBanner.settings.sound_alert_snoozed.timestamp)
        if (now > snoozedUntil) {
          DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert_snoozed', settings: { timestamp: null } })
          .then(
            DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert', settings: { state: 'alert' } })
          )
        } else if (audioAlert !== 'none') {
          DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert', settings: { state: 'none' } })
        }
      }
      setAudioAlert(settings?.alertBanner?.settings.sound_alert?.state || 'none')
    } else if (!isLoading) {
      DisplaySettings.create({ page: 'alert_banner', type: 'sound_alert', settings: { state: 'alert' } })
      .then(
        DisplaySettings.update({ page: 'alert_banner', type: 'sound_alert_snoozed', settings: { timestamp: null } })
      )
      setAudioAlert('alert')
    }
  }, [isLoading, settings])

  useEffect(() => {
    const snd = new Audio(silent_quarter_second)
    snd.play().then(() => {
      // Do nothing
    }).catch((e) => {
      if (!e.message.includes('interact with the document first')) {
        console.log('failed to play test sound', { err: e })
      }
      setShowInAppWarning(true)
    })
  }, [])

  const refresh = () => {
    refreshConversationList({ dispatch, conversationFilters, limit, activeConversationID })
  }

  if (props.mobile) {
    return (
      <AccessControl requiredComponent={components.CHAT}>
        <MobileAgentChat />
      </AccessControl>
    )
  }

  document.title = 'Chat | Signals'

  return (
    <AccessControl requiredComponent={components.CHAT}>
      <div className={classes.outerChatPage}>
        <ChatAlertManager activeConversationID={activeConversationID} />
        <div className={classes.chatPage}>
          <InitialLoadingScreen loading={!state.conversationList} />
          <div className={classes.list}>
            <div className={classes.listHeader}>
              {conversationFilters.values.displayName}
            </div>
            <div className={classes.conversationList}>
              <ConversationList
                state={state}
                activeConversationID={activeConversationID}
                dispatch={dispatch}
              />
            </div>
          </div>
          <div>
            <div>
              {!isLoading && showInAppWarning && (
                audioAlert === 'alert' ? (
                  <AudioNotificationPermissionAlert />
                ) : audioAlert === 'prompt' ? (
                  <AudioNotificationPrompt />
                ) : <></>
              )}
            </div>
            <ConversationView
              open={cardSelectOpen}
              setOpen={setCardSelectOpen}
              conferenceOpen={conferenceOpen}
              setConferenceOpen={setConferenceOpen}
              calendarOpen={calendarOpen}
              setCalendarOpen={setCalendarOpen}
              refresh={refresh}
              activeConversationQueryKey={activeConversationQueryKey}
            />
          </div>
        </div>
        <div style={{ position: 'fixed', top: 0, left: 60 }}>
          <AgentChatOnboarding otherModalOpen={conferenceOpen} setDrawerOpen={setDrawerOpen} activeConversation={activeConversationID} setCardSelectOpen={() => setCardSelectOpen(true)} setConferenceOpen={() => setConferenceOpen(true)} setCalendarOpen={setCalendarOpen} />
        </div>
      </div>
    </AccessControl>
  )
}

export default ChatPage
