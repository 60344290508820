import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MemoryIcon from '@material-ui/icons/Memory'
import SettingsIcon from '@material-ui/icons/Settings'
import { getTenants, loginNewTenant } from 'api/tenants'
import { Avatar } from 'cf-components/Avatars'
import { SelectNoOutline } from 'cf-components/material-wrappers/AlternateMuiComponents'
import User from 'classes/users'
import AccessControl from 'components/AccessControl'
import { Fader } from 'components/Fader'
import { ModalSection, Modal } from 'library/Modal'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { components, SessionContext } from 'session-context'
import { roleMap } from './admin_portal/AdminUserListPage'
import { UserOutOfOfficeModal } from './users/UserManagement/UserOutOfOfficeModal'
import { closeInAppBots } from './bot/InAppBot/closeInAppBot'
import { truncateString } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  logoutDiv: {
    display: 'flex',
    marginBottom: 10,
    marginTop: 5,
    cursor: 'pointer',
    fontFamily: 'Poppins, sans serif',
    paddingLeft: 8
  },
  popover: {
    marginLeft: 20
  },
  header: {
    backgroundColor: '#F5F5F5',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    fontWeight: 600,
    fontSize: '18px'
  },
  logoutMenu: {
    minWidth: 260,
    fontFamily: 'Poppins, sans serif'
  },
  menuItem: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 10,
    color: '#343434',
    fontWeight: 600,
    cursor: 'pointer'
  },
  menuIcon: {
    color: '#9C9C9C',
    marginRight: 15,
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  tenantText: {
    fontSize: 12
  },
  userProfileRow: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 170
  }
}))

function LogoutMenu (props) {
  const classes = useStyles()
  const { isOn, setUser } = useContext(SessionContext)
  const [tenantOptions, setTenantOptions] = useState([{ value: null, label: '' }])
  const status = isOn(components.CHAT) ? props.status : null

  useEffect(() => {
    getTenants({ user_id: props.user.id })
      .then(response => {
        if (response.data) {
          setTenantOptions(response.data)
        }
      })
  }, [props.user.id])

  function logout () {
    props.closeMenu()
    closeInAppBots()
    props.logout()
  }
  const changeTenant = (newTenant) => {
    if (props.user.relationships.tenant.data.id !== newTenant) {
      loginNewTenant({ tenant_id: newTenant })
        .then(response => {
          setUser(response.data)
          window.location.reload()
        })
    }
  }

  return (
    <div className={classes.logoutMenu}>
      <div className={classes.header}>
        <Avatar
          status={status}
          size='sm'
          backgroundColor='#08c20b'
          user={props.user}
        />
        <div style={{ marginLeft: 10 }}>
          <div>{props.user.attributes.name}</div>
          {tenantOptions.length > 1 ? (
            <SelectNoOutline
              onChange={changeTenant}
              value={props.user.relationships.tenant.data.id}
              options={tenantOptions}
              style={{ fontSize: 12, color: 'white' }}
            />
          ) : (
            <div className={classes.tenantText}>
              {tenantOptions[0].label}
            </div>
          )}
        </div>
      </div>
      <Link to='/settings/my_settings/profile' className={classes.link}>
        <div className={classes.menuItem} onClick={() => props.closeMenu()}>
          <div className={classes.menuIcon}>
            <PersonIcon />
          </div>
          <div>
            My Profile
          </div>
        </div>
      </Link>
      <AccessControl requiredPermission='manager'>
        <Link to='/settings/subscriptions/plan' className={classes.link}>
          <div className={classes.menuItem} onClick={() => props.closeMenu()}>
            <div className={classes.menuIcon}>
              <AttachMoneyIcon />
            </div>
            <div>
              My Plan & Usage
            </div>
          </div>
        </Link>
      </AccessControl>
      <AccessControl requiredPermission='cf'>
        <Link to='/admin_portal/tenants' className={classes.link}>
          <div className={classes.menuItem} onClick={() => props.closeMenu()}>
            <div className={classes.menuIcon}>
              <LockOpenIcon />
            </div>
            <div>
              Admin Portal
            </div>
          </div>
        </Link>
      </AccessControl>
      <hr />
      <Link to='/settings/my_settings/profile' className={classes.link}>
        <div className={classes.menuItem} onClick={() => props.closeMenu()}>
          <div className={classes.menuIcon}>
            <SettingsIcon />
          </div>
          <div>
            Settings
          </div>
        </div>
      </Link>
      <Link to='/settings/integrations' className={classes.link}>
        <div className={classes.menuItem} onClick={() => props.closeMenu()}>
          <div className={classes.menuIcon}>
            <MemoryIcon />
          </div>
          <div>
            Integrations
          </div>
        </div>
      </Link>
      <AccessControl requiredPermission='manager'>
        <Link to='/user_management/users' className={classes.link}>
          <div className={classes.menuItem} onClick={() => props.closeMenu()}>
            <div className={classes.menuIcon}>
              <PeopleIcon />
            </div>
            <div>
              Users
            </div>
          </div>
        </Link>
      </AccessControl>
      <hr />
      <div className={classes.menuItem} onClick={logout}>
        <div className={classes.menuIcon}>
          <LogoutIcon />
        </div>
        <div>
          Logout
        </div>
      </div>
    </div>
  )
}

function LogoutButton (props) {
  const classes = useStyles()
  const history = useHistory()
  const { user } = props
  const currentUser = User.loadOne(user.id)
  const [outOfOfficeModal, setOutOfOfficeModal] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { participant, isOn } = useContext(SessionContext)
  const outOfOfficeInstances = participant?.attributes?.out_of_office_instances || []

  /** The current OutOfOffice if one exists */
  const currentOutOfOfficeInstance = outOfOfficeInstances.find(({ attributes }) => {
    const startDate = new Date(attributes.start_date)
    if (startDate > new Date()) {
      return false
    }
    const endDate = new Date(attributes.end_date)
    if (endDate < new Date()) {
      return false
    }
    return true
  })

  let status = currentOutOfOfficeInstance ? 'outOfOffice' : participant?.attributes?.status
  if (!isOn(components.CHAT)) {
    status = null
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.offsetParent)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  function handleOpenOutOfOfficeClick () {
    if (!currentUser.isLoading) {
      setOutOfOfficeModal(currentUser.data)
    }
  }

  return (
    <div className={classes.logoutDiv}>
      <div onClick={handleClick} className={classes.userProfileRow}>
        <Avatar
          status={status}
          size='sm'
          user={user}
          backgroundColor='#08c20b'
        />
        <div style={{ marginLeft: 18 }}>
          <Fader
            onScreen={props.isShowing}
            text={truncateString(user.attributes.name, 16, true)}
            color='black'
            fontSize='14px'
            fontStyle='normal'
          />
          <Fader
            onScreen={props.isShowing}
            text={roleMap[user.attributes.role]}
            color='black'
            fontSize='10px'
            fontStyle='normal'
          />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <LogoutMenu
          name={user.attributes.name}
          user={user}
          logout={() => setModalOpen(true)}
          closeMenu={handleClose}
          status={status}
          currentOutOfOfficeInstance={currentOutOfOfficeInstance}
          handleOpenOutOfOfficeClick={handleOpenOutOfOfficeClick}
        />
      </Popover>
      {outOfOfficeModal &&
        <UserOutOfOfficeModal
          user={outOfOfficeModal}
          onHide={() => setOutOfOfficeModal(null)}
        />}
      <Modal
        title='Confirm Logout'
        onHide={() => setModalOpen(false)}
        open={modalOpen}
        saveBtnText='Log out'
        handleSave={() => history.push('/logout')}
        size='xs'
        saveIcon='exit'
      >
        <ModalSection>
          Are you sure you want to log out?
        </ModalSection>
      </Modal>
    </div>
  )
}

export default LogoutButton
