import { Typography } from 'library/materialUI';
import { ModalSection } from 'library/Modal';
import { Select, SelectOption } from 'cf-components/material-wrappers/MaterialComponents';
import { doGet } from 'api/api';
import { useContext, useEffect, useState } from 'react';
import { getComponentOptions, previewLicenseAdjustment, subscriptionHasPayment } from 'api/billing';
import Loader from 'library/loading/Loader';
import UpdatePlanModal from './UpdatePlanModal';
import { QuantityAdjuster } from 'library/QuantityAdjuster';
import { INSIGHTS_PLAN_NAME } from 'library/constants';
import ModalWarning from 'components/ModalWarning';
import { SessionContext } from 'session-context';
import useInterval from 'use-interval';

type SnapshotUpgradeModalProps = {
    open: boolean
    onClose: () => void
    onUpgrade: ({ ipCredits, contactLookups, userCount }: {ipCredits: number, contactLookups: number, userCount: number}) => void
}

type PlanComponent = {
    quantity: number
    maxQuantity: number
}

export default function SnapshotUpgradeModal ({ open, onClose, onUpgrade }: SnapshotUpgradeModalProps): JSX.Element {
    const { setSnackState } = useContext(SessionContext)
    const [defaultPlanComponents, setDefaultPlanComponents] = useState<{ [key: string]: PlanComponent }>({})
    const [planPrice, setPlanPrice] = useState(99)
    const [totalPrice, setTotalPrice] = useState(0)
    const [upgrading, setUpgrading] = useState(false)

    // Reverse IP
    const [ipOptions, setIpOptions] = useState<SelectOption[]>([])
    const [ipPriceMap, setIpPriceMap] = useState<Record<number, number>>({})
    const [currentIpValue, setCurrentIpValue] = useState(0)
    const [loadingIpOptions, setLoadingIpOptions] = useState(true)

    // Contact Lookup
    const [contactCreditOptions, setContactCreditOptions] = useState<SelectOption[]>([])
    const [contactCreditPriceMap, setContactCreditPriceMap] = useState<Record<number, number>>({})
    const [currentContactCreditValue, setCurrentContactCreditValue] = useState(0)
    const [loadingContactCreditOptions, setLoadingContactCreditOptions] = useState(true)

    // Users
    const [userAdjustment, setUserAdjustment] = useState(0)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [userChangeInCost, setUserChangeInCost] = useState(0)
    const maxUsers = defaultPlanComponents.users?.maxQuantity

    const [hasPayment, setHasPayment] = useState(false)
    const [initialPaymentLoading, setInitialPaymentLoading] = useState(true)
    const [serviceURL, setServiceURL] = useState('')

    const recheckPayments = () => {
      subscriptionHasPayment().then(response => {
          if (response.data.attributes.has_payment_profile) {
            setHasPayment(true)
            setSnackState({
              open: true,
              message: 'Payment found. Please click "Update plan" to complete the process.',
              variant: 'success'
            })
          }
      })
    }

    useInterval(() => {
      if (open && !initialPaymentLoading && !hasPayment) {
        recheckPayments()
      }
    }, 10000)

    useEffect(() => {
        doGet({ path: `/api/v2/billing/components/${INSIGHTS_PLAN_NAME}` })
            .then(response => {
                const components = response.data.reduce((acc: { [key: string]: PlanComponent }, component: any) => {
                    acc[component.attributes.name] = { quantity: component.attributes.quantity, maxQuantity: component.attributes.max_quantity }
                    return acc
                }, {})
                setDefaultPlanComponents(components)
                setCurrentIpValue(components['reverse-ip'].quantity || 0)
                setCurrentContactCreditValue(components['contact-lookups'].quantity || 0)
            })

        doGet({ path: '/api/v2/billing/insights_price' })
            .then(response => {
                setPlanPrice(response.data.attributes.price_in_cents / 100)
            })
        subscriptionHasPayment().then(response => {
          setInitialPaymentLoading(false)
          setServiceURL(response.data.attributes.service_url)
          if (response.data.attributes.has_payment_profile) {
            setHasPayment(true)
          }
        })
    }, [])

    useEffect(() => {
        if (defaultPlanComponents['reverse-ip']) {
            getComponentOptions('reverse-ip').then(
                (response: Record<string, any>) => {
                    const allocated = defaultPlanComponents['reverse-ip'].quantity || 0
                    if (response.data) {
                    setIpOptions(response.data
                        .map((option: Record<string, any>) => ({
                        label: `${allocated + option.attributes.quantity} Credits (+$${(option.attributes.cost_in_cents / 100)}/month)`,
                        value: allocated + option.attributes.quantity
                        }))
                    )
                    setLoadingIpOptions(false)
                    setIpPriceMap(response.data.reduce((acc: Record<number, number>, option: Record<string, any>) => {
                        acc[allocated + option.attributes.quantity] = option.attributes.cost_in_cents / 100
                        return acc
                    }, {}))
                    }
                }
            )
        }
        if (defaultPlanComponents['contact-lookups']) {
            const allocated = defaultPlanComponents['contact-lookups'].quantity || 0
            getComponentOptions('contact-lookups').then(
            (response: Record<string, any>) => {
                if (response.data) {
                setContactCreditOptions(response.data.map((option: Record<string, any>) => ({
                    label: `${option.attributes.quantity + allocated} Credits (+$${(option.attributes.cost_in_cents / 100)}/month)`,
                    value: allocated + option.attributes.quantity
                })))
                setLoadingContactCreditOptions(false)
                setContactCreditPriceMap(response.data.reduce((acc: Record<number, number>, option: Record<string, any>) => {
                    acc[allocated + option.attributes.quantity] = option.attributes.cost_in_cents / 100
                    return acc
                }, {}))
                }
            }
            )
        }
    }, [defaultPlanComponents])

    useEffect(() => {
        if (userAdjustment !== 0) {
          setLoadingUsers(true)
          previewLicenseAdjustment('users', userAdjustment).then((r) => {
            const resp = r.data.attributes
            if (resp.change_in_cents_prorated !== 0) {
              setUserChangeInCost(resp.change_in_cents_prorated / 100)
            }
            setLoadingUsers(false)
          })
        } else {
          setUserChangeInCost(0)
        }
      }, [userAdjustment])

    useEffect(() => {
        setTotalPrice(planPrice + (ipPriceMap[currentIpValue] || 0) + (contactCreditPriceMap[currentContactCreditValue] || 0) + userChangeInCost)
    }, [planPrice, currentIpValue, ipPriceMap, currentContactCreditValue, contactCreditPriceMap, userChangeInCost])

    return (
      <UpdatePlanModal
        open={open}
        saveDisabled={loadingIpOptions || loadingContactCreditOptions || loadingUsers || !hasPayment || upgrading}
        onClose={onClose}
        planPrice={planPrice}
        duePrice={totalPrice}
        onSave={hasPayment ? () => {
          onUpgrade({ ipCredits: currentIpValue, contactLookups: currentContactCreditValue, userCount: userAdjustment })
          setUpgrading(true)
        }
          : () => recheckPayments()}
        snapshotUpgrade
      >
        {!upgrading ? (
          <>
            {loadingIpOptions ? (<div style={{ height: 191 }}><Loader size='md' /></div>) : (
              <ModalSection
                title='Reverse IP Lookups'
                subtitle={`Default credit tier: ${defaultPlanComponents['reverse-ip']?.quantity || 0}`}
              >
                <Typography variant='body2'>
                  Select desired credits for your account
                </Typography>
                <Select
                  options={ipOptions}
                  value={currentIpValue}
                  onChange={(value: number) => {
                    setCurrentIpValue(value)
                  }}
                  disableClear
                />
              </ModalSection>
            )}
            {loadingContactCreditOptions ? (<div style={{ height: 191 }}><Loader size='md' /></div>) : (
              <ModalSection
                title='Contact Credits'
                subtitle='Select your contact tier and contact limit'
              >
                <Select
                  options={contactCreditOptions}
                  value={currentContactCreditValue}
                  onChange={(value: number) => setCurrentContactCreditValue(value)}
                  disableClear
                />
              </ModalSection>
            )}
            <ModalSection
              title='Additional Users'
              subtitle={`Your plan contains ${defaultPlanComponents.users?.quantity || 1} user${defaultPlanComponents.users?.quantity > 1 ? 's' : ''}. Add more below.`}
            >
              <QuantityAdjuster
                value={userAdjustment}
                min={0}
                max={maxUsers ? maxUsers - (defaultPlanComponents.users?.quantity || 1) : maxUsers}
                handleDecrement={() => setUserAdjustment(userAdjustment - 1)}
                handleIncrement={() => setUserAdjustment(userAdjustment + 1)}
              />
            </ModalSection>
            {!initialPaymentLoading && !hasPayment && (
              <ModalWarning
                body='To continue you must enter your payment information for your new subscription. Once entered and updated, refresh by clicking the "Check for payment" button and then click "Update plan" to complete the process.'
                helpLink={serviceURL}
                helpButtonText='Open payment page in new tab'
              />
            )}
          </>) : (
            <Loader size='md' title='Upgrading your plan' />
          )}
      </UpdatePlanModal>
    )
}
