function getAllTags ({ token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  return fetch(
    `${chatServiceUrl}/tags?include=deleted`,
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

/**
 * @param {{token: any, chatServiceUrl: string, conversationID?: number;}} param0
 */
function getTags ({ token, chatServiceUrl, conversationID }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  const url = `${chatServiceUrl}/tags`
  const params = new URLSearchParams()

  if (conversationID) {
    params.set('filter[conversation_id]', conversationID)
  }

  return fetch(
    url + '?' + params.toString(),
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function saveTag ({ tag_name, description, is_goal, token, chatServiceUrl, id }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  let method = 'POST'
  let url = `${chatServiceUrl}/tags`

  if (id !== null) {
    method = 'PATCH'
    url += `/${id}`
  }

  const body = {
    data: {
      type: 'tags',
      attributes: {
        tag_name: tag_name,
        description: description,
        is_goal: is_goal
      },
      relationships: {}
    }
  }

  return fetch(
    url,
    {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    })
    .then(response => response.json())
}

function createTag ({ tag_name, description, is_goal, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }

  return fetch(
    `${chatServiceUrl}/tags`,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        data: {
          type: 'tags',
          attributes: {
            tag_name: tag_name,
            description: description,
            is_goal: is_goal
          },
          relationships: {}
        }
      })
    })
    .then(response => response.json())
}

function deleteTags ({ tag_ids, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }
  return fetch(
    `${chatServiceUrl}/tags`,
    {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({
        data: {
          attributes: {
            tag_ids: tag_ids
          }
        }
      })
    })
}

function tagSearch ({ search, token, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }

  const url = `${chatServiceUrl}/tags:search`
  const params = new URLSearchParams()
  if (search) {
    params.set('filter[search]', search)
  }

  return fetch(
    url + '?' + params.toString(),
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function batchTagConversations ({ chatServiceUrl, conversationIDs, tagIDs, token }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`
  }

  const body = {
    data: {
      type: 'tags',
      attributes: {
        conversation_ids: conversationIDs,
        tag_ids: tagIDs
      },
      relationships: {}
    }
  }

  return fetch(
    `${chatServiceUrl}/conversations:add_tags`,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    })
    .then(response => response.json())
}

export { getTags, createTag, deleteTags, tagSearch, saveTag, getAllTags, batchTagConversations }
