import { createContext, useEffect, useState, useRef, useCallback } from 'react'
import useInterval from 'use-interval'
import * as Sentry from '@sentry/browser'
import IdleTimer from 'react-idle-timer'
import { getTenant } from './api/tenants'
import AutoLogoutModal from './components/AutoLogoutModal'
import { Redirect, useLocation } from 'react-router-dom'
import { getMyParticipant } from './api/participants'
import { getToken } from 'api/auth'
import { getSettings, saveSettings } from './api/user_display_settings'
import { getChatSettings, saveChatSettings } from 'api/chat_settings'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import User from 'classes/users'
import queryString from 'query-string'
import { Modal } from 'library/Modal'
import { Typography } from '@material-ui/core'
import { TextBox } from 'library/materialUI'
import { sendBugReport } from 'api/bug_report'
import { Agent } from 'classes/agents'
import ScoringUpgradeModal from 'pages/settings/Scoring/ScoringUpgradeModal'
import { EVENT_TYPE, Emitter } from 'emitter'
import { getPlanLimits } from 'api/billing'

/**
 * @typedef {Object} UserType
 * @property {{ chat_service: string }} links
 * @property {{ perms: Perms, role: number, name: string, assist_login: boolean }} attributes
 * @property {number} id
 * @property {{ tenant: any }} relationships
 */
/**
 * @typedef {Object} Perms
 * @property {boolean} strict_admin
 * @property {boolean} semi_admin
 * @property {boolean} manager
 * @property {boolean} accounts
 * @property {boolean} send_chats
 * @property {boolean} book_meetings
 * @property {boolean} conversations_all
 * @property {boolean} conversations_unclaimed
 * @property {boolean} tenant_admin_alerts
 * @property {boolean} tenant_salesforce_advanced
 * @property {boolean} tenant_bot_testing
 * @property {boolean} cf
 */
/**
 * @typedef {Object} SessionContextValue
 * @property {UserType} user
 * @property {User} userObject
 * @property {boolean} inApp
 * @property {(User) => void} setUser
 * @property {() => void} reloadUser
 * @property {{ [key: string]: any; }} participant
 * @property {() => void} changeAvailability
 * @property {ChatSettings} chatSettings
 * @property {Function} updateChatSettings
 * @property {any} userSettings
 * @property {bool} loadingUserSettings
 * @property {any} updateSettings
 * @property {any} tenantDomain
 * @property {any} subscriptionStatus
 * @property {any} snackState
 * @property {Function} setSnackState
 * @property {Function} saveFailed
 * @property {Function} saveSuccessful
 * @property {Function} openBugModal
 * @property {Function} isOn
 * @property {Function} canAdd
 * @property {Function} componentsLoading
 * @property {Dispatch<SetStateAction<string>>} setSubscriptionStatus
 * @property {Dispatch<SetStateAction<boolean>>} setSalesModalOpen
 * @property {boolean} kicked
 * @property {Dispatch<SetStateAction<boolean>>} setKicked
 */
/** @type {React.Context<SessionContextValue>} */
const SessionContext = createContext({})

function identifyUserForFairy ({ user_id, name, email, created_at, role_id, tenant_id, tenant_name }) {
  if (!window.userpilot) {
    return
  }
  window.userpilot.identify(
    user_id,
    {
      name,
      email,
      created_at: Math.ceil(Date.parse(created_at) / 1000),
      role_id,
      tenant_id,
      company: {
        id: tenant_id,
        name: tenant_name
      }
    }
  )
}

const initialState = {
  'chat-sidebar': { settings: { cards: [{ id: 'contactInfo' }] } },
  'abe-dashboard': {
    settings: { filters: [], mode: 'admin', selectedColumn: 'opp status' }
  }
}

const components = {
  ACCOUNT_ALERTS: 'account-alerts',
  AUTO_CONTACT_LOOKUP: 'auto-contact-lookup',
  AI_PERSONA: 'ai-persona',
  BOT_TESTS: 'bot-testing',
  CALENDAR_ONLY_USERS: 'calendar-only-users',
  CONTACT_ENRICHMENT: 'contact-enrichment',
  CONTACT_LOOKUPS: 'contact-lookups',
  CONTACT_LOOKUPS_PLUS: 'contact-lookups-plus',
  CONTENT_PAGES: 'content-landing-pages',
  EMAILS: 'emails',
  INTELLIGENT_FORMS: 'signals-intelligent-forms',
  NOTIFICATIONS_EMAIL: 'notifications-email',
  NOTIFICATIONS_IN_APP: 'notifications-in-app',
  NOTIFICATIONS_SLACK: 'notifications-slack',
  NOTIFICATIONS_TEAMS: 'notifications-teams',
  NOTIFICATIONS_TEXT: 'notifications-text',
  PERSONAS: 'personas',
  PLAYRUNNER_CREATE_ACCOUNT: 'playrunner-create-accounts',
  REVERSE_IP: 'reverse-ip',
  SCORE_INSIGHTS: 'score-insights',
  SMS: 'sms',
  USERS: 'users',
  AGENT_PROFILE_PAGES: 'agent-profile-pages',
  CALENDAR: 'calendar',
  CHAT: 'chat',
  EMAIL_QUERY: 'email-query',
  EMAIL_UPDATES: 'email-updates',
  FORMS: 'forms',
  GLOBAL_ROUTING: 'global-routing',
  PLAYMAKER: 'playmaker',
  REPORTS: 'reports',
  BREVO: 'brevo',
  MARKETO: 'marketo',
  ELOQUA: 'eloqua',
  SALESLOFT: 'salesloft',
  LIVE_VIEW: 'live-view',
  CONTACTS: 'contacts',
  ACCOUNTS_TABLE: 'accounts-table'
}

function SessionProvider (props) {
  const [user, _setUser] = useState(0)
  const [inApp, setInApp] = useState(false)
  const [participant, _setParticipant] = useState(null)
  const [token, setToken] = useState(null)
  const [active, setActive] = useState(true)
  const [sessionLength, setSessionLength] = useState(null)
  const [tenantDomain, setTenantDomain] = useState(null)
  const [subscriptionStatus, setSubscriptionStatus] = useState(null)
  const [salesModalOpen, setSalesModalOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [kicked, setKicked] = useState(false)

  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved',
    requestID: '0',
    requestURL: '',
    status: 0
  })
  const [bugModalState, setBugModalState] = useState({
    user_message: '',
    error_message: '',
    request_id: '0',
    date_of_event: new Date().toISOString()
  })
  const [storageKey, setStorageKey] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [redirect, setRedirect] = useState()
  const [bugModalOpen, setBugModalOpen] = useState(false)
  const timerRef = useRef(null)
  const queryClient = useQueryClient()
  let location = { search: '' }
  try {
    // this is in a try-catch because the flow surface runs in its own
    // react and does _not_ have a router, thus causing errors
    location = useLocation() // eslint-disable-line
  } catch { }
  const sid = queryString.parse(location.search).sid
  const authHeader = sid ? { Authorization: `Bearer ${sid}` } : null
  const userSettingsKey = `userSettings:${user.id}`
  const chatSettingsKey = `chatSettings:${user.id}`

  const { data: userSettings, isLoading: loadingUserSettings } = useQuery(userSettingsKey, async () => {
    const result = await getSettings({ sid })
    return result?.data?.attributes?.user_settings
  })
  const { data: chatSettings } = useQuery(chatSettingsKey, async () => {
    const settings = await getChatSettings({ authHeader })
    return settings.data.attributes
  })

  function updateUserDisplaySettings ({ kind, type, settings }) {
    const changedUserSettings = { ...userSettings[kind] }
    let post = false

    if ('settings' in changedUserSettings) {
      changedUserSettings.settings[type] = settings
    } else {
      changedUserSettings.settings = { [type]: settings }
      post = true
    }

    const payload = { kind: kind, settings: changedUserSettings.settings, post: post }
    addSettingsMutation.mutate(payload)
  }

  const addSettingsMutation = useMutation(
    payload => {
      saveSettings(payload)
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async payload => {
        await queryClient.cancelQueries(userSettingsKey)

        const previousValue = queryClient.getQueryData(userSettingsKey)
        const { kind, settings } = payload
        queryClient.setQueryData(userSettingsKey, (old) => {
          const updatedContent = { ...old[kind] }
          updatedContent.settings = settings
          const newSettings = { ...old, [kind]: updatedContent }
          return newSettings
        })
        console.log('Mutation complete')
        return previousValue
      },
      // On failure roll back to previous value
      onError: (previousValue) => {
        queryClient.setQueryData(userSettingsKey, previousValue)
      }
    }
  )
  function saveFailed (message, requestID, path, status) {
    setSnackState({
      ...snackState,
      open: true,
      variant: 'error',
      message: message || 'There was an error processing your request',
      requestID: requestID,
      requestURL: path,
      status: status
    })
  }
  function saveSuccessful (message) {
    setSnackState({
      open: true,
      variant: 'success',
      message: message || 'Your changes have been saved'
    })
  }
  function displayError (message, requestID, path, status) {
    setSnackState({
      ...snackState,
      open: true,
      variant: 'error',
      message: message,
      requestID: requestID,
      requestURL: path,
      status: status
    })
  }

  function handleBugReport () {
    sendBugReport(bugModalState)
      .then(() => {
        setSnackState({ open: true, variant: 'success', message: 'Bug report sent!' })
      })
    setBugModalOpen(false)
  }

  function updateChatSettings ({ state }) {
    addChatSettingsMutation.mutate(state)
  }

  const addChatSettingsMutation = useMutation(
    payload => {
      saveChatSettings({ state: payload })
    },
    {
      onMutate: async payload => {
        await queryClient.cancelQueries(chatSettingsKey)
        const previousValue = queryClient.getQueryData(chatSettingsKey)
        queryClient.setQueryData(chatSettingsKey, (old) => {
          return payload
        })
        return previousValue
      },
      onError: (previousValue) => {
        queryClient.setQueryData(chatSettingsKey, previousValue)
      }
    }
  )
  useEffect(() => {
    if (user && user?.attributes?.notification_settings) {
      let hasInApp = false
      Object.keys(user.attributes.notification_settings).forEach(key => {
        hasInApp = hasInApp || user.attributes.notification_settings[key]?.app
      })
      let chime = false
      Object.entries(user.attributes.notification_settings?.chimes).forEach((entry) => {
        chime = chime || entry[1]
      })
      setInApp(hasInApp && chime)
    }
  }, [user])

  useEffect(() => {
    const headers = { 'Content-Type': 'application/vnd.api+json', ...authHeader }
    fetch('/api/auth/me', {
      method: 'GET',
      headers,
      cache: 'no-store'
    })
      .then(response => response.json())
      .then(response => {
        if (response?.meta?.billing_status && response.meta.billing_status !== subscriptionStatus) {
          setSubscriptionStatus(response.meta.billing_status)
        }
        if (response.data && response.data.id) {
          const _user = response.data
          let chat_service = _user.links.chat_service
          if (!chat_service.startsWith('/')) {
            const parts = chat_service.split('/')
            if (parts.length > 1) {
              chat_service = '/' + parts.slice(1).join('/')
            }
          }
          _user.links.chat_service = chat_service
          _setUser(_user)
          window.chatServiceUrl = _user.links.chat_service
          localStorage.setItem('chatServiceUrl', _user.links.chat_service)
          Sentry.setUser({
            id: _user.id,
            email: _user.attributes.email,
            tenant_id: _user.relationships.tenant.data.id
          })
          let tenant
          if (response.included) {
            const matches = response.included.filter(i => i.type === 'tenants')
            if (matches.length) {
              tenant = matches[0]
            }
          }
          identifyUserForFairy({
            user_id: _user.id,
            email: _user.attributes.email,
            name: _user.attributes.name,
            created_at: _user.attributes.created_timestamp,
            role_id: _user.attributes.role,
            tenant_id: _user.relationships.tenant.data.id,
            tenant_name: tenant?.attributes?.name
          })

          if (window.ChatFunnels) {
            setTimeout(() => {
              const names = _user.attributes.name.split(' ', 1)
              const first_name = names[0]
              let last_name = ''
              if (names.length > 1) {
                last_name = names[1]
              }
              window.ChatFunnels.identify({
                email: _user.attributes.email,
                first_name,
                last_name
              })
            }, 0)
          }
        } else {
          _setUser('')
        }
      })
    refreshSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This is for checking the users authorization status and to kick them out if they end up not authorized
  useEffect(() => {
    if (user) {
      const headers = { 'Content-Type': 'application/vnd.api+json', ...authHeader }
      fetch('/api/auth/me', {
        method: 'GET',
        headers,
        cache: 'no-store'
      })
        .then(response => {
          if (user && response.status === 401) {
            setKicked(true)
            setRedirect('/logout')
          }
          return response.json()
        })
        .then(response => {
          if (response?.meta?.other_login) {
            setKicked(true)
            setRedirect('/logout')
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refresh])

  useInterval(() => {
    setRefresh(!refresh)
  }, 30000)

  const changeAvailability = () => {
    const participantId = participant?.id
    const availability = participant?.attributes?.status || 'unavailable'

    if (participantId && availability) {
      let newAvailability = availability
      if (availability === 'unavailable') {
        newAvailability = 'available'
      } else {
        newAvailability = 'unavailable'
      }
      Agent.update(participantId, newAvailability)
        .then(_ => {
          const updatedParticipant = { ...participant }
          updatedParticipant.attributes.status = newAvailability
          _setParticipant(updatedParticipant)
        })
    }
  }

  const reloadUser = useCallback(() => {
    const headers = { 'Content-Type': 'application/vnd.api+json', ...authHeader }
    fetch('/api/auth/me', {
      method: 'GET',
      headers: headers,
      cache: 'no-store'
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          const _user = response.data
          let chat_service = _user.links.chat_service
          if (!chat_service.startsWith('/')) {
            const parts = chat_service.split('/')
            if (parts.length > 1) {
              chat_service = '/' + parts.slice(1).join('/')
            }
          }
          _user.links.chat_service = chat_service
          _setUser(_user)
          Sentry.setUser({
            id: _user.id,
            email: _user.attributes.email,
            tenant_id: _user.relationships.tenant.data.id
          })
          let tenant
          if (response.included) {
            const matches = response.included.filter(i => i.type === 'tenants')
            if (matches.length) {
              tenant = matches[0]
            }
          }
          identifyUserForFairy({
            user_id: _user.id,
            email: _user.attributes.email,
            name: _user.attributes.name,
            created_at: _user.attributes.created_timestamp,
            role_id: _user.attributes.role,
            tenant_id: _user.relationships.tenant.data.id,
            tenant_name: tenant?.attributes?.name
          })
          if (window.ChatFunnels) {
            setTimeout(() => {
              const names = _user.attributes.name.split(' ', 1)
              const first_name = names[0]
              let last_name = ''
              if (names.length > 1) {
                last_name = names[1]
              }
              window.ChatFunnels.identify({
                email: _user.attributes.email,
                first_name,
                last_name
              })
            }, 0)
          }
        } else {
          _setUser('')
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setUser = useCallback((newUser) => {
    let chat_service = newUser.links.chat_service
    if (!chat_service.startsWith('/')) {
      const parts = chat_service.split('/')
      if (parts.length > 1) {
        chat_service = '/' + parts.slice(1).join('/')
      }
    }
    newUser.links.chat_service = chat_service
    reloadUser()
    return _setUser(newUser)
  }, [reloadUser])

  useEffect(() => {
    if (user) {
      const tenantID = parseInt(user.relationships.tenant.data.id)
      getTenant({ tenantID, authHeader }).then(response => {
        setSessionLength(response.data.attributes.session_length)
        const tenantEmail = response.data.attributes.name
        const tenantName = tenantEmail.substring(tenantEmail.lastIndexOf('@') + 1)
        setTenantDomain(tenantName)
      })
      const key = user.id + '_session_expiration'
      setStorageKey(key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (storageKey && user && !active) {
      const expiration = new Date(localStorage.getItem(storageKey))
      const now = new Date()
      if (now > expiration) {
        setRedirect('/logout')
      }
    }
  }, [active, storageKey, user])

  function refreshSession () {
    fetch('/api/auth/refresh_session', {
      method: 'POST',
      // headers: { 'Content-Type': 'application/vnd.api+json', ...authHeader },
      headers: { 'Content-Type': 'application/vnd.api+json' },
      cache: 'no-store'
    })
  }

  useEffect(() => {
    getToken({ authHeader })
      .then(response => {
        setToken(response.token)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const reloadParticipant = useCallback(() => {
    if (user?.links?.chat_service) {
      getMyParticipant({ chatServiceUrl: user?.links.chat_service, authHeader }).then(response => {
        _setParticipant(response.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  /**
   * Reload or set the current Participant
   * @param {Object} [newParticipant] - Omit to just refresh the participant
   */
  const setParticipant = useCallback((newParticipant = null) => {
    reloadParticipant()
    if (newParticipant) {
      _setParticipant(newParticipant)
    }
  }, [reloadParticipant])

  useEffect(() => {
    reloadParticipant()
  }, [reloadParticipant])

  const { data: planComponents, isLoading, isError, refetch: refetchComponents } = useQuery('componentStates', async () => {
    const response = await getPlanLimits()
    const componentsData = {}
    for (const component of response.data) {
      componentsData[component.attributes.name] = component.attributes
    }
    return componentsData
  })

  Emitter.on(EVENT_TYPE.DATA_CHANGED, () => {
    refetchComponents()
  })

  useInterval(() => {
    if (user && active) {
      if (storageKey && sessionLength) {
        const expiration = new Date()
        expiration.setHours(expiration.getHours() + sessionLength)
        localStorage.setItem(storageKey, expiration)
      }
      refreshSession()
    } else if (user) {
      if (!modalOpen) {
        const expiration = new Date(localStorage.getItem(storageKey))
        const now = new Date()
        if (now - expiration > 600000) {
          setRedirect('/logout')
        } else if (now > expiration) {
          setModalOpen(true)
        }
      }
    }
  }, 300000)

  function RedirectElement () {
    if (redirect === '/logout') {
      setRedirect(null)
      setActive(true)
      return <Redirect push to={redirect} />
    } else {
      return <></>
    }
  }

  function openBugModal (requestID, message, requestURL) {
    setBugModalOpen(true)
    setBugModalState({
      user_message: '',
      request_id: requestID,
      request_url: requestURL,
      error_message: message,
      date_of_event: new Date().toISOString()
    })
  }

  function isOn (component) {
    return !isLoading && !isError && planComponents[component] && planComponents[component].on
  }

  function canAdd (component) {
    if (isLoading || isError || !planComponents[component]) {
      return 0
    }
    return Math.max(planComponents[component].max - planComponents[component].qty, 0)
  }

  let open = false
  if (user && modalOpen) {
    open = true
  }

  if (user) {
    window.chatServiceUrl = user.links.chat_service
  }

  return (
    <>
      <IdleTimer
        ref={timerRef}
        element={document}
        onActive={() => setActive(true)}
        onIdle={() => setActive(false)}
        debounce={250}
        timeout={300000}
      />
      <SessionContext.Provider value={{
        inApp,
        user,
        setUser,
        userObject: user ? new User({ row: user }) : null,
        reloadUser,
        participant,
        setParticipant,
        changeAvailability,
        token,
        tenantDomain,
        subscriptionStatus,
        setSubscriptionStatus,
        snackState,
        setSnackState,
        updateSettings: updateUserDisplaySettings,
        userSettings,
        loadingUserSettings,
        chatSettings,
        updateChatSettings,
        saveSuccessful,
        saveFailed,
        displayError,
        openBugModal,
        setSalesModalOpen,
        isOn,
        componentsLoading: isLoading,
        canAdd,
        kicked,
        setKicked
      }}
      >
        {props.children}
      </SessionContext.Provider>
      <AutoLogoutModal
        onHide={() => setModalOpen(false)}
        open={open}
        logOut={() => {
          setRedirect('/logout')
          setModalOpen(false)
        }}
        refreshSession={refreshSession}
      />
      <Modal
        onHide={() => setBugModalOpen(false)}
        open={bugModalOpen}
        title='Send Bug Report'
        saveBtnText='Report Bug'
        handleSave={handleBugReport}
      >
        <div>
          <Typography variant='body2'>*Optional: Enter any additional info about what happened or is blocking you</Typography>
          <TextBox
            onChange={(e) => { setBugModalState({ ...bugModalState, user_message: e }) }}
            value={bugModalState.user_message}
            placeholder='Enter text here...'
            rows={6}
          />
        </div>
      </Modal>
      <ScoringUpgradeModal open={salesModalOpen} onHide={() => setSalesModalOpen(false)} />
      <RedirectElement />
    </>
  )
}

export { SessionContext, SessionProvider, initialState, components }
