import { doGet, doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function userSearch ({ search, user_ids }: { search: string, user_ids: number[] }) {
  const getUrl = window.location
  const baseUrl = getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1]

  const url = new URL(baseUrl + 'api/users:search')
  const params: Record<string, any> = {}
  if (search) {
    params['filter[search]'] = search
  }

  Object.keys(params).forEach(key =>
    url.searchParams.append(key, params[key])
  )

  let body = {}

  if (user_ids) {
    body = {
      data: {
        type: 'user_search',
        attributes: {
          user_ids: user_ids
        }
      }
    }
  }

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
    cache: 'no-store'
  })
    .then(response => response.json())
}

function getUsers (includeDeleted: boolean) {
  let path = '/api/users'
  if (includeDeleted) {
    path += '?include_deleted=1'
  }
  return doGet({ path })
}

function getUsersWithIncludes ({ includes }: { includes: string[] }) {
  let url = '/api/users'
  if (includes) {
    const includeString = includes.join(',')
    url += '?include=' + includeString
  }
  return fetch(url, {
    method: 'GET',
    headers: headers,
    cache: 'no-store'
  })
    .then(response => response.json())
}

function getUser ({ ID }: { ID: number }) {
  const path = `/users/${ID}`
  return doGet({ path })
}

function saveEmail ({ user_id, for_user_id, new_email, new_email_confirmation }: { user_id: number, for_user_id: number, new_email: string, new_email_confirmation: string }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json'
  }
  const method = 'PATCH'
  const path = '/api/user_email'

  const body = {
    data: {
      id: user_id,
      type: 'users',
      attributes: {
        new_email: new_email,
        new_email_confirmation: new_email_confirmation
      },
      relationships: {
        for_user: {
          data: {
            id: for_user_id,
            type: 'users'
          }
        }
      }
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
}

function saveUser ({ userID, state }: { userID: number, state: Record<string, any> }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json'
  }
  const method = 'PATCH'
  const path = `/api/users/${userID}`

  const body = {
    data: {
      id: userID,
      type: 'users',
      attributes: state,
      relationships: {}
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
    cache: 'no-store'
  })
    .then(response => response.json())
}

function deleteUsers ({ user_ids }: { user_ids: number[] }) {
  const method = 'DELETE'
  const path = '/api/users'
  const body = JSON.stringify({
    data: {
      attributes: {
        user_ids: user_ids
      }
    }
  })
  return fetch(path, {
    method: method,
    headers: headers,
    body: body
  })
}

function getRoles () {
  return fetch('/api/user_roles', {
    method: 'GET',
    headers: { 'Content-Type': 'application/vnd.api+json' }
  }).then(res => res.json())
}

function acceptUserRequest (userRequestID: number) {
  return doPost({
    path: `/api/user_requests/${userRequestID}/invite`
  })
}

export { getUsers, getUser, saveUser, deleteUsers, userSearch, saveEmail, getUsersWithIncludes, getRoles, acceptUserRequest }
