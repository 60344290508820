import clsx from 'clsx'
import { components, SessionContext } from 'session-context'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { useCallback, useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LogoutButton from 'pages/LogoutButton'
import { Emitter, EVENT_TYPE } from 'emitter'
import { NavigationListItem } from './NavigationListItem'
import { ExpandButton } from './ExpandButton'
import { DisplaySettings } from 'classes/displaySettings'
import AccessControl from 'components/AccessControl'
import { ShareContext } from 'share-context'
import useInterval from 'use-interval'

const useStyles = makeStyles(theme => ({
  drawer: {
    boxSizing: 'border-box',
    padding: 6,
    width: 60,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    color: theme.palette.primary.main
  },
  drawerSpacing: {
    width: 50,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
  },
  badge: {
    padding: 0,
    backgroundColor: '#FD9038',
    top: -11,
    left: -20,
    position: 'relative',
    minWidth: 15,
    height: 15,
    color: 'white',
    fontSize: 9,
    fontWeight: 'bolder'
  },
  list: {
    padding: 0,
    height: '100%'
  },
  icon: {
    fill: theme.palette.primary.main
  }
}))

export function NavigationSidebar () {
  const classes = useStyles()
  const { user, isOn } = useContext(SessionContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const [notifcationOpen, setNotificationOpen] = useState(false)
  const [supportOpen, setSupportOpen] = useState(false)
  const [hideSidebar, setHideSidebar] = useState(false)
  const [checkFullScreen, setCheckFullScreen] = useState(false)
  const { settings, isLoading } = DisplaySettings.load()
  const emailOn = isOn(components.EMAILS)
  const playmakerOn = isOn(components.PLAYMAKER)
  const chatOn = isOn(components.CHAT)
  const { flags } = useContext(ShareContext)

  const updateSidebarSettings = useCallback(() => {
    DisplaySettings.update({ page: 'sidebar', type: 'state', settings: { expanded: !isExpanded } })
  }, [isExpanded])

  useEffect(() => {
    Emitter.on(EVENT_TYPE.FULLSCREEN, (hide: boolean) => {
      setHideSidebar(hide)
    })
  })

  useInterval(() => {
    if (hideSidebar) {
      setCheckFullScreen(!checkFullScreen)
    }
  }, 1000)

  useEffect(() => {
    if (hideSidebar && !document.fullscreenElement) {
      setHideSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFullScreen])

  useEffect(() => {
    Emitter.on(EVENT_TYPE.NOTIFICATIONS, () => {
      setNotificationOpen(!notifcationOpen)
      setSupportOpen(false)
    })
    Emitter.on(EVENT_TYPE.HELP_CENTER, (payload: any) => {
      setSupportOpen(!payload.open)
      setNotificationOpen(false)
    })
    Emitter.on(EVENT_TYPE.CLOSE_DRAWER, () => {
      setNotificationOpen(false)
      setSupportOpen(false)
    })
  }, [notifcationOpen, supportOpen])

  useEffect(() => {
    if (!isLoading) {
      setIsExpanded(settings.sidebar.settings.state.expanded)
    }
  }, [isLoading, settings])

  if (hideSidebar) return <></>

  return (
    <div>
      <div className={classes.drawerSpacing} />
      <Drawer
        variant='permanent'
        open
        anchor='left'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isExpanded,
          [classes.drawerClose]: !isExpanded
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: isExpanded,
            [classes.drawerClose]: !isExpanded
          })
        }}
      >
        <List className={classes.list}>
          <NavigationListItem item={{ link: '/dashboard', icon: 'logo', title: 'Dashboard', isShowing: isExpanded, navType: 'dashboard' }} />
          <AccessControl requiredComponent={components.LIVE_VIEW}>
            <NavigationListItem item={{ link: '/live_view', icon: 'travelExplore', title: 'Live View', isShowing: isExpanded, navType: 'liveView' }} />
          </AccessControl>
          <AccessControl requiredComponent={components.CHAT}>
            <NavigationListItem item={{ link: '/chat', icon: 'chat', title: 'Chat', isShowing: isExpanded, navType: 'chat' }} />
          </AccessControl>
          {(emailOn || playmakerOn || chatOn) && (
            <NavigationListItem
              item={{
                link: '/engagement/' + (
                  playmakerOn ? (
                    'playmaker/dashboard'
                  ) : (
                    emailOn ? (
                      'emails/emails'
                    ) : 'chatbots/chatbots'
                  )),
                icon: 'playMakerGray',
                title: 'Engagement',
                requiredPermission: 'semi_admin',
                isShowing: isExpanded,
                navType: 'playmaker'
              }}
            />
          )}
          {flags['bot-testing'] && (
            <AccessControl requiredComponent={components.BOT_TESTS}>
              <NavigationListItem item={{ link: '/bot_tests', icon: 'beaker', title: 'Testing', requiredPermission: 'tenant_bot_testing', isShowing: isExpanded, navType: 'botTests' }} />
            </AccessControl>
          )}
          <AccessControl requiredPermission='manager' requiredComponent={components.REPORTS} or>
            <NavigationListItem item={{ link: '/reports/visitors', title: 'Reports', icon: 'barChart', isShowing: isExpanded, navType: 'reports' }} />
          </AccessControl>
          <AccessControl requiredComponent={components.CONTACTS}>
            <NavigationListItem item={{ link: '/contact_management/contacts', title: 'Contacts', icon: 'contacts', isShowing: isExpanded, navType: 'contactManagement' }} />
          </AccessControl>
          <AccessControl requiredComponent={components.ACCOUNTS_TABLE}>
            <NavigationListItem item={{ link: '/account_management/accounts', title: 'Accounts', icon: 'accounts', isShowing: isExpanded, navType: 'accounts' }} />
          </AccessControl>
        </List>
        <NavigationListItem item={{ icon: 'help', title: 'Support', isShowing: isExpanded, isSelected: supportOpen, onClick: () => Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: 'home', open: supportOpen }) }} />
        <NavigationListItem item={{ icon: 'notification', title: 'Notifications', isShowing: isExpanded, isSelected: notifcationOpen, onClick: () => Emitter.emit(EVENT_TYPE.NOTIFICATIONS, {}) }} />
        <div id='availability'><LogoutButton user={user} isShowing={isExpanded} /></div>
        <ExpandButton
          color='black'
          isOpen={isExpanded}
          onClick={() => {
            updateSidebarSettings()
            setIsExpanded(!isExpanded)
          }}
        />
      </Drawer>
    </div>
  )
}
