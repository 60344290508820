import { doPut, doPatch, doPost, doDelete, doGet } from './api'
import { Emitter, EVENT_TYPE } from 'emitter'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function saveEmail ({ name, description, subject, emailBody, layout, emailID, image, chatServiceUrl, plainText, fromEmail, fromType, fromName, attachments }) {
  let url = `${chatServiceUrl}/emails`

  let method = 'POST'
  if (emailID) {
    method = 'PATCH'
    url += `/${emailID}`
  }

  const emailAddress = fromEmail ? fromEmail.trim() : null

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify({
      data: {
        type: 'emails',
        attributes: {
          name: name,
          description: description,
          subject: subject,
          body: emailBody,
          kind: layout,
          image: image,
          plain_text_body: plainText,
          from_email: emailAddress,
          from_name: fromName,
          from_type: fromType,
          attachments: attachments
        }
      }
    })
  })
    .then(response => {
      return response.json()
    })
}

function getEmail ({ emailID, chatServiceUrl }) {
  return fetch(
    `${chatServiceUrl}/emails/${emailID}`,
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function getEmails ({ chatServiceUrl }) {
  return fetch(
    `${chatServiceUrl}/emails`,
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function emailSearch ({ search, chatServiceUrl }) {
  const url = `${chatServiceUrl}/emails:search`
  const params = new URLSearchParams()
  if (search) {
    params.set('filter[search]', search)
  }

  return fetch(
    url + '?' + params.toString(),
    {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
}

function deleteEmails ({ ids }) {
  return doDelete({ path: `/emails/${ids}`, useChatURL: true })
}

function cloneEmail ({ chatServiceUrl, id }) {
  return fetch(
    `${chatServiceUrl}/emails/${id}:clone`,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        data: {}
      })
    }).then(response => response.json())
}

function getEmailSettings () {
  const path = '/integrations/email/setup'
  return doGet({ path, useChatURL: true })
    .catch(err => {
      if (typeof err === 'object' && err.hasOwnProperty('message') && err.message.startsWith('not_found')) {
        return null
      }
      throw err
    })
}

function getEmailSettingsV2 () {
  return doGet({ path: '/integrations/email/setup', useChatURL: true })
}

function postEmailDomain ({ domain, name, emailPrefix }) {
  const path = '/integrations/email/setup'
  const data = {
    attributes: {
      sender_domain: domain,
      sender_name: name,
      sender_email_prefix: emailPrefix
    }
  }
  return doPost({ path, data, useChatURL: true })
}

function sendDNSEmail (recipient, message, txt, txtContent, cname, cnameContent) {
  const path = '/email_setup/send'
  const data = {
    attributes: {
      recipient: recipient,
      message: message,
      txt: txt,
      txt_content: txtContent,
      cname: cname,
      cname_content: cnameContent
    }
  }
  return doPost({ path, data, useChatURL: false })
}

function verifyEmailSetup () {
  return doPut({
    path: '/integrations/email/setup:verify',
    useChatURL: true
  })
}

function updateEmailSettings ({ name, emailPrefix, domain }) {
  const path = '/integrations/email/setup'
  const data = { attributes: {} }
  if (name) {
    data.attributes.default_sender_name = name
  }
  if (emailPrefix) {
    data.attributes.default_sender_email_prefix = emailPrefix
  }
  if (domain) {
    data.attributes.sender_domain = domain
  }

  return doPatch({ path, data, useChatURL: true })
}

function getEmailReport ({ emailID }) {
  return doGet({
    path: `/reports/emails/${emailID}/details`,
    useChatURL: true
  })
}

function getEmailStats ({ chatServiceUrl, startDate, endDate }) {
  const url = `${chatServiceUrl}/reports/emails/send_statistics`
  const params = new URLSearchParams()
  params.set('start_date', startDate)
  params.set('end_date', endDate)

  return fetch(
    url + '?' + params.toString(),
    {
      method: 'GET',
      headers: headers
    }).then((response) => response.json())
}

function getEmailSends ({ startDate = null, endDate = null, emailID }) {
  const params = new URLSearchParams()
  if (startDate) {
    params.set('start_date', startDate)
  }
  if (endDate) {
    params.set('end_date', endDate)
  }

  return doGet({
    useChatURL: true,
    path: `/reports/emails/${emailID}/email_sends?` + params.toString()
  })
}

function sendTestEmail ({ emailID, recipient }) {
  return doPost({
    path: `/emails/send_test/${emailID}`,
    data: { attributes: { to_email: recipient } },
    useChatURL: true
  }).then(_ => {
    Emitter.emit(EVENT_TYPE.API_SUCCESS, { message: 'A test email has been sent to ' + recipient })
  })
}

export {
  saveEmail,
  emailSearch,
  deleteEmails,
  getEmail,
  getEmails,
  cloneEmail,
  getEmailSettings,
  postEmailDomain,
  verifyEmailSetup,
  updateEmailSettings,
  getEmailStats,
  getEmailSends,
  getEmailReport,
  getEmailSettingsV2,
  sendDNSEmail,
  sendTestEmail
}
