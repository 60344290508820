import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import { Icon } from 'library/materialUI/Icon'
import { Button, Tooltip } from 'library/materialUI'
import ScraperModal from './ScraperModal'
import ScraperSummary from 'classes/scraperSummary'
import Loader from 'library/loading/Loader'
import { ApiContext } from 'api-context'
import { Emitter, EVENT_TYPE } from 'emitter'
import CoachingModal from './CoachingModal'
import DeleteModal from 'cf-components/DeleteModal'
import { AITrainingData, defaultAITrainingData, deleteTrainingData } from 'api/ai_settings'
import { ShareContext } from 'share-context'
import { INSIGHTS_PLAN_NAME, SNAPSHOT_PLAN_NAME } from 'library/constants'
import ScoringUpgradeModal from '../Scoring/ScoringUpgradeModal'
import { UpgradeBanner } from 'pages/billing/UpgradeBanner'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    justifyContent: 'center'
  },
  iconDiv: {
    marginRight: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#343434',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#343434',
    display: 'flex'
  },
  lightText: {
    fontSize: '14px',
    color: '#808080'
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divStyle: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  buttonStyle: {
    marginLeft: 'auto',
    marginRight: '10px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    alignItems: 'center'
  }
}))

const mywindow = window as any

export default function AIContentPage () {
  const classes = useStyles()
  const { planName } = useContext(ShareContext)
  const hasPageAccess = planName() !== SNAPSHOT_PLAN_NAME && planName() !== INSIGHTS_PLAN_NAME
  const [salesModalOpen, setSalesModalOpen] = useState(false)
  const { setDeleteObject } = useContext(ApiContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [coachingModalOpen, setCoachingModalOpen] = useState(false)
  const [deleteCoachingModalOpen, setDeleteCoachingModalOpen] = useState(false)
  const [deleteCoachingID, setDeleteCoachingID] = useState<number>(0)
  const [trainingObject, setTrainingObject] = useState<AITrainingData>(defaultAITrainingData())

  // const url = '/api/core/web/scraper_summary/list'
  const trainingUrl = '/api/core/web/training_data/list'

  function onCoachingSave () {
    mywindow.htmx.trigger('#training-data-list', 'revealed')
    setTrainingObject(defaultAITrainingData())
  }

  function confirmDelete () {
    deleteTrainingData(deleteCoachingID).then(() => {
      mywindow.htmx.trigger('#training-data-list', 'revealed')
      setDeleteCoachingID(0)
      setDeleteCoachingModalOpen(false)
    })
  }

  useEffect(() => {
    try {
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data);
        }
      }
      // const ids = ['htmx-table', 'htmx-table2']
      const ids = ['htmx-table2']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
      mywindow.addEventListener('rowEvent', function (e: any) {
        const tableID = e.detail.tableID
        const rowID = e.detail.rowID
        const rowAction = e.detail.action
        if (tableID === 'scraper-summary-list-body') {
          switch (rowAction) {
            case 'refresh':
              ScraperSummary.doScrape(rowID)
              break
            case 'delete':
              mywindow.deleteRow = e.detail.tr
              setDeleteObject(new ScraperSummary({ row: { id: rowID } }))
              break
            default:
              console.warn('Unknown row action', rowAction)
          }
        } else if (tableID === 'training-data-list-body') {
          const editTrainingObject = defaultAITrainingData()
          switch (rowAction) {
            case 'edit':
              editTrainingObject.id = parseInt(rowID)
              setTrainingObject(editTrainingObject)
              setCoachingModalOpen(true)
              break
            case 'delete':
              setDeleteCoachingID(rowID)
              setDeleteCoachingModalOpen(true)
              break
            default:
              console.warn('Unknown row action', rowAction)
          }
        } else {
          console.log('different table event: ', tableID)
        }
      });

      const trainingTable = document.getElementById('training-data-list')
      if (trainingTable) {
        mywindow.htmx.trigger(trainingTable, 'revealed')
      }
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [hasPageAccess])

  Emitter.on(EVENT_TYPE.DATA_CHANGED, (data: any) => {
    if (data?.eventURL.includes('scraper_summar')) {
      if (data?.method === 'DELETE') {
        if (mywindow.deleteRow) {
          mywindow.htmx.swap(
            mywindow.deleteRow,
            '',
            {
              swapDelay: 1000,
              swapStyle: 'outerHTML'
            })
          mywindow.deleteRow = null
        } else {
          console.log('no row anchor')
        }
      } else if (data?.method === 'POST') {
        mywindow.htmx.trigger('#scraper-summary-list', 'revealed')
      }
    } else {
      console.log('no match', data)
    }
  })

  return (
    <AppPage
      title='Content for AI'
      padding={10}
    // saveProps={saveProps}
    >
      <ScoringUpgradeModal open={salesModalOpen} onHide={() => setSalesModalOpen(false)} />
      {!hasPageAccess && (
        <UpgradeBanner
          prompt='Upgrade for AI-powered features, including Chatbots and Signals score'
          action={() => setSalesModalOpen(true)}
        />
      )}
      {hasPageAccess && (
        <>
          {/* <div className={classes.container}>
            <div className={classes.lightText}>
              Sync your website and other content to train Signals AI for things like Signals Score, AI Chatbots, and more.
            </div>
          </div> */}
          {/* <div className={classes.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.iconDiv}>
                <Icon icon='webPage' size='md' color='white' />
              </div>
              <div>
                <div className={classes.title}>
                  Websites
                </div>
                <div className={classes.lightText}>
                  Train the AI on any public website. Sites will be synced every 30 days.
                </div>
              </div>
              <div className={classes.buttonStyle}>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setModalOpen(true)}
                >
                  Add Site
                </Button>
              </div>
            </div>
            <div id='htmx-table' style={{ backgroundColor: 'white', marginTop: '20px' }}>
              <div
                id='scraper-summary-list'
                hx-get={url}
                hx-trigger='revealed'
                hx-indicator='.scraper-summary-list-loader'
              >
                <div style={{ margin: 300 }}>
                  <Loader size='lg' />
                </div>
              </div>
            </div>
          </div> */}
          <div className={classes.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.iconDiv}>
                <Icon icon='botHead' size='md' color='white' />
              </div>
              <div>
                <div className={classes.title}>
                  AI Coaching
                  <Tooltip title='Powered by Artificial Intelligence'>
                    <div>
                      <Icon icon='aiSparkles' />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.lightText}>
                  Coaching for AI chatbot to improve responses and accuracy.
                </div>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => {
                    setCoachingModalOpen(true)
                    setTrainingObject(defaultAITrainingData())
                  }}
                >
                  Add Coaching
                </Button>
              </div>
            </div>
            <div id='htmx-table2' style={{ backgroundColor: 'white', marginTop: '20px' }}>
              <div
                id='training-data-list'
                hx-get={trainingUrl}
                hx-trigger='revealed'
                hx-indicator='.training-data-list-loader'
              >
                <div style={{ margin: 300 }}>
                  <Loader size='lg' />
                </div>
              </div>
            </div>
          </div>
          <div>
            <ScraperModal open={modalOpen} setOpen={setModalOpen} />
            <CoachingModal open={coachingModalOpen} setOpen={setCoachingModalOpen} onSave={onCoachingSave} trainingData={trainingObject} />
            <DeleteModal open={deleteCoachingModalOpen} title='Delete Coaching' object='Coaching data' onHide={() => setDeleteCoachingModalOpen(false)} confirmAction={confirmDelete} />
          </div>
        </>)}
    </AppPage>
  )
}
