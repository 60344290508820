/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import { hasComponent } from 'api/billing'
import { Filter } from 'classes/segments'
import { Button, Icon, Paper, Popover, Tooltip, Typography } from 'library/materialUI'
import { ValueSelector } from 'pages/plays/playBuilder/branching/selectors/ValueSelector'
import ScoringUpgradeModal from 'pages/settings/Scoring/ScoringUpgradeModal'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  // updateIntegration,
  getIntegration,
  // addIntegrationSetting,
  getIntegrationFeature,
  getCredentialsStatus
  // getIntegrationFields,
} from 'api/integrations'
import { DynamicSegmentContext } from './DynamicSegmentContext'
import { getSchema } from 'api/schema'
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot'
import Loader from 'library/loading/Loader'
import { CRMContext } from 'crm-context'

const useStyles = makeStyles(theme => ({
  filterBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    maxWidth: 550,
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'f20lex',
    alignItems: 'center',
    marginTop: 5,
    color: theme.palette.primary.main
  },
  closeBtn: {
    display: 'flex',
    alignContent: 'center'
  },
  selectedFilterBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    maxWidth: 550,
    padding: '2px 10px',
    borderRadius: 5,
    maxHeight: 33,
    minHeight: 33,
    marginRight: 9,
    cursor: 'pointer',
    display: 'flex',
    marginTop: 5,
    color: 'black'
  },
  paper: {
    maxWidth: 'calc(100% - 64px)',
    width: 300,
    maxHeight: 420,
    fontSize: '.9em',
    padding: '0 12px 0 4px'
  },
  expanded: {
    backgroundColor: '#FFFFFF!important',
    '& .cf-tree-multi-select-icon': {
      transform: 'rotate(180deg)'
    }
  },
  preventOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'black'
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  upgradeStyle: {
    borderRadius: '20px',
    height: '15px',
    width: '15px',
    backgroundColor: '#9933FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface selectorOption {
  label: string
  type: string
}
interface selectorFilter {
  cmp: string
  value: any
  prop: string
}
interface selector {
  option: selectorOption
  filter: selectorFilter
  requiresUpgrade?: boolean
}
type selectorMapping = Record<string, selector>
export const filterSelectorMapping: selectorMapping = {
  'Account Owner': { option: { label: 'accountOwner', type: 'accountOwner' }, filter: { cmp: '', value: '', prop: 'owner_user_id' } },
  'Account Type': { option: { label: 'accountType', type: 'accountType' }, filter: { cmp: '', value: [], prop: 'account_type' } },
  'Open Opportunity': { option: { label: 'openOpp', type: 'openOpp' }, filter: { cmp: '', value: true, prop: 'is_open_opportunity' } },
  'Opportunity Type': { option: { label: 'oppType', type: 'oppType' }, filter: { cmp: '', value: '', prop: 'opportunity_type_multiple' } },
  'Opportunity Stage': { option: { label: 'oppStage', type: 'oppStage' }, filter: { cmp: '', value: [''], prop: 'opportunity_stage' } },
  Industry: { option: { label: 'industry', type: 'industry' }, filter: { cmp: '', value: '', prop: 'industry' } },
  Technology: { option: { label: 'technology', type: 'technology' }, filter: { cmp: '', value: '', prop: 'technology' } },
  Domain: { option: { label: 'Domain', type: 'tld' }, filter: { cmp: '', value: '', prop: 'tld' } },
  Location: { option: { label: 'location', type: 'location' }, filter: { cmp: '', value: '', prop: 'location' } },
  'Annual Revenue': { option: { label: 'annualRevenue', type: 'annualRevenue' }, filter: { cmp: '', value: [''], prop: 'annual_revenue' } },
  'Employee Count': { option: { label: 'numberOfEmployees', type: 'numberOfEmployees' }, filter: { cmp: '', value: [''], prop: 'employee_range' } },
  'Signals Score': { option: { label: 'signalsScore', type: 'signalsScore' }, filter: { cmp: '', value: [''], prop: 'engagement_score' }, requiresUpgrade: true },
  'From CRM': { option: { label: 'fromCRM', type: 'fromCRM' }, filter: { cmp: '', value: false, prop: 'is_crm_account' } }
}

const getName = (key: string) => {
  switch (key) {
    case 'account_type':
      return 'Account Type'
    case 'is_open_opportunity':
      return 'Open Opportunity'
    case 'opportunity_type_multiple':
      return 'Opportunity Type'
    case 'opportunity_stage':
      return 'Opportunity Stage'
    case 'owner_user_id':
      return 'Account Owner'
    case 'industry':
      return 'Industry'
    case 'location':
      return 'Location'
    case 'annual_revenue':
      return 'Annual Revenue'
    case 'employee_range':
      return 'Employee Count'
    case 'engagement_score':
      return 'Signals Score'
    default:
      return ''
  }
}

type SelectorMappingProps = 'account_type'
  | 'is_open_opportunity'
  | 'opportunity_type_multiple'
  | 'opportunity_stage'
  | 'owner_user_id'
  | 'industry'
  | 'location'
  | 'annual_revenue'
  | 'employee_range'
  | 'engagement_score'

interface SegmentCriteriaButtonProps {
  segmentTrigger: Filter
  groupIndex: number
  index: number
  deleteTrigger: (index: number) => void
  actionButton: JSX.Element
  buttonProps: any
  editFilter: (groupIndex: number, i: number, filter: Filter) => void
  key: string
}

export default function SegmentCriteriaButton ({ segmentTrigger, deleteTrigger, buttonProps, groupIndex, index, editFilter, actionButton, key }: SegmentCriteriaButtonProps) {
  const classes = useStyles()
  const { setAlternateLabels, setAlternateProps } = useContext(DynamicSegmentContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [secondPopoverContent, setSecondPopoverContent] = useState<JSX.Element | null>(null)
  const [showDeleteFilterTooltip, setShowDeleteFilterTooltip] = useState(false)
  const [localScopedSegmentTrigger, setLocalScopedSegmentTrigger] = useState(segmentTrigger)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [crmCustomFields, setCrmCustomFields] = useState<string[]>([])
  const [crmCustomTypes, setCrmCustomTypes] = useState<string[]>([])
  const [crmAccountProps, setCrmAccountProps] = useState<string[]>([])
  const { crmIntegrationName: crmIName } = useContext(CRMContext)
  const [crmIntegrationName, setCRMIntegrationName] = useState(crmIName)
  // Zach requested these to be available
  // const [crmApiNames, setCrmApiNames] = useState<string[]>([])
  const { data: hasSegmentFieldComponent = false } = useQuery('has-segment-field-component', () => hasComponent('crm-segment-by-custom-field'))
  const _editFilter = (cmp: string, value: any, label: string) => {
    editFilter(groupIndex, index, { cmp, value, prop: label })
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [propName, _] = useState(getName(segmentTrigger?.prop))
  const [hasCRMConnection, setHasCRMConnection] = useState()

  const { data: hasScoringComponent } = useQuery('has-scoring-component', () => hasComponent('score-insights'))
  HandleEditFilterOnClose({ anchorEl, localScopedSegmentTrigger, _editFilter })

  const crmAttributes = ['Account Type', 'Open Opportunity', 'Opportunity Type', 'Opportunity Stage', 'Account Owner']
  const CRMIcon = <div style={{ transform: 'rotate(90deg)' }}><Icon icon='crm' color='black' /></div>

  const filterIcons: Record<SelectorMappingProps, JSX.Element> = {
    account_type: CRMIcon,
    is_open_opportunity: CRMIcon,
    opportunity_type_multiple: CRMIcon,
    opportunity_stage: CRMIcon,
    owner_user_id: CRMIcon,
    industry: <Icon icon='accounts' color='black' />,
    location: <Icon icon='location' color='black' />,
    annual_revenue: <Icon icon='money' color='black' />,
    employee_range: <Icon icon='users' color='black' />,
    engagement_score: <Icon icon='dataUsage' color='black' />
  }

  const filterCriteria = [
    { name: 'Annual Revenue', icon: <Icon icon='money' color='black' /> },
    { name: 'CRM Fields (Standard)', icon: CRMIcon, children: crmAttributes },
    // change this to be any integration instead of just salesforce for whenever we add this to hubspot, etc...
    {
      name:
        !hasSegmentFieldComponent ? (
          <div onClick={() => triggerSalesBot()} style={{ display: 'flex', flexDirection: 'row' }}>
            CRM Fields (Custom) <div style={{ color: '#9933FF', marginLeft: '3px' }}>Upgrade</div>
          </div>
        ) : (
          <div>
            CRM Fields (Custom) <a href={`/#/settings/${crmIntegrationName}/accounts`} target='_blank' rel='noreferrer'>Add</a>
          </div>
        ),
      icon: <Icon icon='edit' color='black' />,
      children: crmCustomFields,
      requiresUpgrade: !hasSegmentFieldComponent
    },
    { name: 'Domain', icon: <Icon icon='webPage' color='black' size='md' /> },
    { name: 'Employee Count', icon: <Icon icon='users' color='black' /> },
    { name: 'Industry', icon: <Icon icon='industry' color='black' size='md' /> },
    { name: 'Location', icon: <Icon icon='location' color='black' /> },
    // If any more filters require upgrades create a mapping for which components need to be active
    { name: 'Signals Score', icon: <Icon icon='dataUsage' color='black' size='md' />, upgrade: <Icon icon='dataUsage' color='black' size='md' upgrade /> },
    { name: 'Technology', icon: <Icon icon='laptop' color='black' size='md' /> },
    { name: 'From CRM', icon: <Icon icon='fromCRM' color='black' size='md' /> }
  ]

  const showIcon = segmentTrigger.prop ? filterIcons[segmentTrigger.prop as SelectorMappingProps] : undefined

  const handleClose = () => {
    setAnchorEl(null)
    setSecondPopoverContent(null)
  }

  function handleEditLocalTrigger (cmp: string, value: string, prop: string) {
    setLocalScopedSegmentTrigger({ cmp, value, prop })
  }

  const handleClick = (selectedOption: keyof selectorMapping, index = -1) => {
    const selectorProps = filterSelectorMapping[selectedOption]
    if (selectorProps?.requiresUpgrade && !hasScoringComponent) {
      setShowUpgradeModal(true)
      return
    }
    let option: Record<any, any> | undefined
    let filter: selectorFilter | undefined
    let picklist: string[] = []
    if (!selectorProps && index !== -1) {
      const type = crmCustomTypes[index]
      if (type === 'picklist') {
        option = { label: crmCustomFields[index], type: 'picklist' }
        filter = { cmp: '', value: [''], prop: crmAccountProps[index] }
        setSecondPopoverContent(
          <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 300, height: 150 }}>
            <Loader type='spinner' title='Loading fields...' size='lg' />
          </Paper>
        )
        getSchema('account', crmAccountProps[index])
          .then(
            (res) => {
              const fieldOptions = res.data.attributes.options
              picklist = fieldOptions.map((option: any) => option.label)
              setSecondPopoverContent(
                <ValueSelector
                  option={option || {}}
                  filter={filter}
                  editFilter={(cmp: string, value: string, label: string) => handleEditLocalTrigger(cmp, value, label)}
                  handleClose={handleClose}
                  pickList={picklist}
                />
              )
            }
          )
        return
      } else if (type === 'true-false') {
        option = { label: crmCustomFields[index], type: 'boolean' }
        filter = { cmp: '', value: '', prop: crmAccountProps[index] }
      } else if (type === 'string') {
        option = { label: crmCustomFields[index], type: 'string' }
        filter = { cmp: '', value: '', prop: crmAccountProps[index] }
      } else {
        option = { label: crmCustomFields[index], type: 'numeric' }
        filter = { cmp: '', value: 0, prop: crmAccountProps[index] }
      }
    } else {
      const sameProp = segmentTrigger.prop === selectorProps.filter.prop && !['technology', 'tld'].includes(segmentTrigger.prop)
      selectorProps.filter.value = segmentTrigger.value && sameProp ? segmentTrigger.value : selectorProps.filter.value
      selectorProps.filter.cmp = segmentTrigger.cmp && sameProp ? segmentTrigger.cmp : selectorProps.filter.cmp
      option = selectorProps.option
      filter = selectorProps.filter
    }
    setSecondPopoverContent(
      <div>
        <div onClick={() => setSecondPopoverContent(null)} style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px', cursor: 'pointer', color: '#9933FF' }}>
          <Icon icon='leftArrow' />
          <div style={{ marginTop: '3px' }}>
            back
          </div>
        </div>
        {crmCustomTypes[index] === 'string' && (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px', cursor: 'pointer', color: '#9933FF', width: 300, flexWrap: 'wrap' }}>
            <Typography variant='body2' style={{ marginTop: '3px' }}>
              <Icon icon='info' /> This is a string field. Be aware of spelling.
            </Typography>
          </div>
        )}
        <ValueSelector
          option={option}
          filter={filter}
          editFilter={(cmp: string, value: string, label: string) => handleEditLocalTrigger(cmp, value, label)}
          handleClose={handleClose}
        />
      </div>
    )
  }

  useEffect(() => {
    if (hasSegmentFieldComponent) {
      if (crmIntegrationName) {
        getCredentialsStatus(crmIntegrationName)
          .then((response: any) => {
            setHasCRMConnection(response.data.attributes.has_credentials)
          })
      } else {
        getIntegrationFeature('crm.abe')
          .then((response: any) => {
            if (response.data.attributes.integration) {
              setCRMIntegrationName(response.data.attributes.integration.name)
              getCredentialsStatus(response.data.attributes.integration.name)
                .then((response: any) => {
                  setHasCRMConnection(response.data.attributes.has_credentials)
                })
            }
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSegmentFieldComponent])

  useEffect(() => {
    if (hasCRMConnection) {
      getIntegration(crmIntegrationName)
        .then((response) => {
          if (response.ok) {
            const customMapping = response.data.attributes.config.field_custom_mapping
            if (!customMapping) return
            const fieldNames: string[] = []
            const fieldTypes: string[] = []
            const fieldProps: string[] = []
            const apiNames: string[] = []
            customMapping.map((row: any) => {
              fieldNames.push(row.label)
              fieldTypes.push(row.signalsType)
              fieldProps.push(row.accountProp)
              apiNames.push(row.apiName)
              return row
            })
            setCrmCustomFields(fieldNames)
            setCrmCustomTypes(fieldTypes)
            setCrmAccountProps(fieldProps)
            // Zach requested for these to be available
            // setCrmApiNames(apiNames)
            setAlternateLabels(fieldNames)
            setAlternateProps(fieldProps)
          }
        })
    }
    // eslint-disable-next-line
  }, [hasCRMConnection])
  return (
    <>
      <div
        key={key}
        style={{
          justifyContent: 'flex-start'
        }}
        className={classes.center}
      >
        <div className={`${buttonProps.class} ${classes.center}`}>
          <Tooltip title={buttonProps.text}>
            <div className={classes.center}>
              {showIcon && showIcon}
              <Button
                startIcon={buttonProps.startIcon}
                size='small'
                style={{ padding: '0px 5px', maxWidth: 500 }}
                onClick={(e) => { setAnchorEl(e.currentTarget); propName && handleClick(propName) }}
                variant='text'
              >
                <div className={classes.preventOverflow}>
                  {buttonProps.text}
                </div>
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title='Delete Filter'
            disableHoverListener
            open={showDeleteFilterTooltip}
            onMouseEnter={() => setShowDeleteFilterTooltip(true)}
            onMouseLeave={() => setShowDeleteFilterTooltip(false)}
          >
            <div
              onClick={() => {
                setShowDeleteFilterTooltip(false)
                return deleteTrigger(index)
              }}
              className={classes.closeBtn}
            >
              {buttonProps.endIcon}
            </div>
          </Tooltip>
        </div>
        {actionButton}
      </div>
      <Popover
        open={anchorEl !== null && !secondPopoverContent}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={() => { if (!secondPopoverContent) { setAnchorEl(null) } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <TreeView
          style={{ padding: 6 }}
        >
          {filterCriteria.map(({ name, icon, children, upgrade, requiresUpgrade }: { name: any; icon: any; children?: Array<string>; upgrade?: any, requiresUpgrade?: boolean }) => {
            let hasUpgrade = true
            if (upgrade) {
              hasUpgrade = hasScoringComponent
            }
            const labelContent = (name: string) => (
              <div
                style={{ display: 'flex', whiteSpace: 'nowrap' }}
              >
                {name}
                {(children && !requiresUpgrade) &&
                  <div
                    className='cf-tree-multi-select-icon'
                    style={{ marginLeft: 'auto', height: 24 }}
                  >
                    <Icon
                      icon='expand'
                      color='black'
                    />
                  </div>}
              </div>
            )
            return (
              children
                ? (
                  <TreeItem
                    style={{ padding: 5 }}
                    nodeId={name}
                    label={labelContent(name)}
                    icon={icon}
                    classes={{ expanded: classes.expanded }}
                  >
                    {requiresUpgrade ? <></> : children.length < 1 ? (<div style={{ color: 'grey', margin: '5px', fontSize: '14px' }}>No custom fields have been added yet</div>) : children.map((childName, index) => (
                      <TreeItem
                        nodeId={childName}
                        label={childName}
                        key={`treeitem_${childName}`}
                        onClick={() => handleClick(childName, index)}
                      />))}
                  </TreeItem>
                )
                : (
                  <TreeItem
                    style={{ padding: 5 }}
                    nodeId={name}
                    label={hasUpgrade && name === 'Signals Score' ? (
                      <div style={{ display: 'flex' }}>
                        {name}
                        <Tooltip title='Powered by Artificial Intelligence'>
                          <div>
                            <Icon icon='aiSparkles' />
                          </div>
                        </Tooltip>
                      </div>
                    ) : hasUpgrade ? (
                      name
                    ) : (
                      <div style={{ display: 'flex' }}>
                        {name}
                        <div style={{ color: '#9933FF', textDecoration: 'underline', marginLeft: 5 }}>
                          Upgrade
                        </div>
                      </div>
                    )}
                    icon={hasUpgrade ? icon : upgrade}
                    onClick={() => handleClick(name)}
                  />
                )
            )
          }
          )}
        </TreeView>
      </Popover>
      <Popover
        open={Boolean(secondPopoverContent)}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {secondPopoverContent}
      </Popover>
      <ScoringUpgradeModal
        open={showUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
      />
    </>
  )
}

function HandleEditFilterOnClose ({ anchorEl, localScopedSegmentTrigger, _editFilter }: { anchorEl: HTMLElement | null, localScopedSegmentTrigger: Filter, _editFilter: (cmp: string, value: any, label: string) => void }) {
  // this is done to ensure we don't close the popover for multiple selects
  useEffect(() => {
    if (anchorEl === null && localScopedSegmentTrigger.value) {
      const { cmp, value, prop: label } = localScopedSegmentTrigger
      _editFilter(cmp, value, label)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl])
}
