
export const allBotTypes = [
  {
    type: 'ai',
    title: 'BDR AI Agent',
    contents: 'Deliver personalized experiences with intelligent content recommendations, effortless demo scheduling, and instant routing to the right team.',
    icon: 'aiSparklesBlack',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'flow',
    title: 'Custom Bot',
    contents: 'This bot has all the bells and whistles. Use it to send messages, ask questions, tag conversations, share calendars, and more.',
    icon: 'funnelBot',
    requiredPerm: null,
    requiredFlag: null
  },
  {
    type: 'content_page',
    title: 'Interactive Landing Page',
    contents: 'Create a landing page that contains a standalone chat window, or display a piece of content next to a chat drawer.',
    icon: 'conversationalContentPageIcon',
    requiredPerm: 'tenant_content_landing_pages',
    requiredFlag: 'content_pages'
  }
]
