import React, { useEffect, useState } from 'react'
import { Modal, ModalSection } from 'library/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { doPost } from 'api/api'
import { checkLength, checkComplexity, PasswordConfirmationBlock } from 'pages/registration/PasswordConfirmationBlock'

const changePassword = (newPassword) => {
  return doPost({
    path: '/api/auth/password_change',
    data: {
      type: 'password',
      attributes: {
        password: newPassword
      }
    }
  })
}

function ChangePasswordModal (props) {
  const [password, setPassword] = useState('')
  const [lengthCheck, setLengthCheck] = useState(false)
  const [complexityCheck, setComplexityCheck] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setLengthCheck(checkLength(password))
    setComplexityCheck(checkComplexity(password))
  }, [password])

  const allowSave = lengthCheck && complexityCheck
  return (
    <Modal
      title='Change your password'
      onHide={() => {
        props.onHide()
        setPassword('')
      }}
      open={props.open}
      handleSave={() => {
        changePassword(password).then(() => {
          props.submit()
        })
      }}
      saveButtonText='Change Password'
      saveDisabled={!allowSave}
      size='sm'
      saveBtnText='Change Password'
    >
      <ModalSection>
        <div style={{ marginBottom: 10 }}>
          <Typography>To change your password, please enter the new password here.</Typography>
        </div>
        <TextField
          type='hidden'
          id='username'
          name='username'
          value={props.email}
        />
        <PasswordConfirmationBlock
          password={password}
          handleChange={e => { setPassword(e.target.value) }}
          handleBlur={() => false}
          setFocused={() => true}
          autoFocus
          visible={visible}
          setVisibility={setVisible}
          textLabel='New Password'
        />
      </ModalSection>
    </Modal>
  )
}

export default ChangePasswordModal
