import { useContext, useRef } from 'react'
import UserInviteList from './UserInviteList'
import { UserListPage } from './UserListPage'
import AppPage from 'cf-components/AppPage'
import TeamListPage from '../Teams/TeamListPage'
import { components, SessionContext } from 'session-context'
import AccessControl from 'components/AccessControl'
import { ShareContext } from 'share-context'
import { SNAPSHOT_PLAN_NAME } from 'library/constants'

function UserManagementPage (): JSX.Element {
  const { isOn, user } = useContext(SessionContext)
  const { planName } = useContext(ShareContext)
  const teamActionRef = useRef(() => (placeholder: any) => placeholder)
  const inviteActionRef = useRef(() => (placeholder: any) => placeholder)
  const isSnapshot = planName() === SNAPSHOT_PLAN_NAME
  const tabs: Array<Record<string, any>> = [
    { name: 'Users', url: '/user_management/users', action: () => inviteActionRef.current(), actionText: 'Invite User', disabled: false }
  ]
  const playMaker = isOn(components.PLAYMAKER)
  if (playMaker) {
    tabs.push({ name: 'Teams', url: '/user_management/teams', action: () => teamActionRef.current(), actionText: 'New Team' })
  }

  if (user.attributes.perms.strict_admin && !isSnapshot) {
    tabs.push({ name: 'Pending Invitations', url: '/user_management/pending_invitations', action: () => inviteActionRef.current(), actionText: 'Invite User' })
  }

  document.title = 'User Management | Signals'

  return (
    <AppPage title='User Management' tabs={tabs}>
      <UserListPage
        actionRef={inviteActionRef}
        isSnapshot={isSnapshot}
      />
      <AccessControl requiredComponent={components.PLAYMAKER}>
        <TeamListPage actionRef={teamActionRef} />
      </AccessControl>
      <AccessControl requiredPermission='strict_admin'>
        <UserInviteList actionRef={inviteActionRef} />
      </AccessControl>
    </AppPage>
  )
}

export default UserManagementPage
