import React, { useState } from 'react'
import RegistrationTemplate from './RegistrationTemplate'
import ResetPasswordSideBar from './ResetPasswordSideBar'

const ResetPassword = props => {
  const [submitted, setSubmitted] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  function resetPassword (values) {
    setLoading(true)
    fetch('/api/auth/reset_password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({ email: values.email })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setSubmitted(true)
        } else {
          setLoginErrorMessage(response.message)
        }
        setLoading(false)
      })
  }

  return (
    <RegistrationTemplate>
      <ResetPasswordSideBar
        onSubmit={resetPassword}
        submitted={submitted}
        errorMessage={loginErrorMessage}
        loading={loading}
      />
    </RegistrationTemplate>
  )
}

export default ResetPassword
