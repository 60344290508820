import { makeStyles } from '@material-ui/core/styles'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { useEffect, useState } from 'react'
import { AppendFilters, RemoveFilter, parameterizeFilters, QueryFilter, SearchParams } from 'classes/queryHelpers'
import { Emitter, EVENT_TYPE } from 'emitter'
import { CSVDownload } from 'classes/csvdownload'
import DateFormat from 'components/DateFormat'
import { downloadCSV } from 'helpers/csv'
import { SignalsScoreHistory } from 'cf-components/SignalsScoreHistory'
import FilterDrawer from 'pages/dashboard/filters/FilterDrawer'
import ProspectModal from 'library/modals/ProspectModal'
import { doGet } from 'api/api'

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))
interface AccountListTableProps {
  handleAlert: (domain: string) => void
}

const mywindow = window as any
const exportCSV = async (searchParams: SearchParams) => {
  const exportClass = 'Account'
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}

export default function AccountListTable ({ handleAlert }: AccountListTableProps) {
  const classes = useStyles()
  const searchParams: SearchParams = {
    sortColumn: 'created_timestamp',
    sortOrder: 'desc',
    pageSize: 100,
    filters: []
  }
  const [currDomain, setCurrDomain] = useState('')
  const [scoreAnchorEl, setScoreAnchorEl] = useState<HTMLElement | null>(null)
  const [historyOpen, setHistoryOpen] = useState(true)
  const [currScore, setCurrScore] = useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<QueryFilter[][]>([])
  const [url, setUrl] = useState(parameterizeFilters({ path: '/api/core/web/accounts', searchParams }))
  const [lookupModalOpen, setLookupModalOpen] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [lookupProcessing, setLookupProcessing] = useState(false)

  const tableEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const action = e.detail.action
    const domains: Array<string> = []
    if (tableID === 'accounts-body') {
      switch (action) {
        case 'export':
          // This logic includes accounts that have not loaded onto the screen yet
          // when the Select All box is checked
          if ((document.getElementById(`${tableID}-select-all-checkbox`) as HTMLInputElement)?.checked) {
            const excludedDomains: Array<string> = []
            Array.from(document.getElementsByClassName(`${tableID}-rowcheckbox`)).forEach((checkbox: any) => {
              if (!checkbox.checked) {
                excludedDomains.push(checkbox.value)
              }
            })
            if (excludedDomains.length === 0) {
              exportCSV(searchParams)
            } else {
              const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'domain', value: excludedDomains, operator: 'not in' }]]) }
              exportCSV(exportParams)
            }
          } else {
            Array.from(document.getElementsByClassName(`${tableID}-rowcheckbox`)).forEach((checkbox: any) => {
              if (checkbox.checked) {
                domains.push(checkbox.value)
              }
            })
            if (domains.length === 0) {
              const exportParams = ({ ...searchParams, filters: RemoveFilter(searchParams.filters || [], 'domain') })
              exportCSV(exportParams)
            } else {
              const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'domain', value: domains, operator: 'in' }]]) }
              exportCSV(exportParams)
            }
          }
          break
        case 'filter':
          setDrawerOpen(true)
          break
        case 'contactLookup':
          if (!lookupProcessing) {
            setLookupProcessing(true)
            // This logic includes accounts that have not loaded onto the screen yet
            // when the Select All box is checked
            if ((document.getElementById(`${tableID}-select-all-checkbox`) as HTMLInputElement)?.checked) {
              const excludedDomains: Array<string> = []
              Array.from(document.getElementsByClassName(`${tableID}-rowcheckbox`)).forEach((checkbox: any) => {
                if (!checkbox.checked) {
                  excludedDomains.push(checkbox.value)
                }
              })

              if (excludedDomains.length === 0) {
                doGet({ path: '/api/core/accounts' }).then((response) => {
                  const accountDomains = response.data.map((account: any) => account.id)
                  setSelectedAccounts(accountDomains)
                })
              } else {
                const contactLookupParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'domain', value: excludedDomains, operator: 'not in' }]]) }
                doGet({ path: '/api/core/accounts', searchParams: contactLookupParams }).then((response) => {
                  const accountDomains = response.data.map((account: any) => account.id)
                  setSelectedAccounts(accountDomains)
                })
              }
              setLookupModalOpen(true)
            } else {
              Array.from(mywindow.htmx.findAll(
                mywindow.htmx.find('#' + tableID),
                `.${tableID}-rowcheckbox`
              )).forEach((checkbox: any) => {
                if (checkbox.checked) {
                  domains.push(checkbox.value)
                }
              })
              setSelectedAccounts(domains)
              if (domains.length !== 0) {
                setLookupModalOpen(true)
              }
           }
            setTimeout(() => {
              setLookupProcessing(false)
            }, 1000)
          }
          break
        default:
          console.warn('Unknown table action', action)
      }
    }
  }
  const rowEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    const anchorDiv = document.getElementById(e.detail.anchor)
    if (tableID === 'accounts-body') {
      switch (rowAction) {
        case 'alert':
          handleAlert(rowID)
          break
        case 'scoreHoverCancel':
          setHistoryOpen(false)
          break
        case 'scoreHover':
          if (anchorDiv) {
            setScoreAnchorEl(anchorDiv)
            setCurrDomain(rowID)
            setHistoryOpen(true)
            setCurrScore(e.detail.score)
          }
          break
        default:
        // console.warn('Unknown row action', rowAction)
      }
    }
  }

  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data)
        }
      }
      */
      mywindow.addEventListener('tableEvent', tableEventListener)
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [currDomain, filters])

  useEffect(() => {
    searchParams.filters = filters
    setUrl(parameterizeFilters({ path: '/api/core/web/accounts', searchParams }))
    // eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    if (url) {
      const tableElement = document.getElementById('accounts')
      if (tableElement) {
        tableElement.setAttribute('hx-get', url)
        mywindow.htmx.process(tableElement)
        mywindow.htmx.trigger(tableElement, 'paramChange')
      }
    }
  }, [url])

  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <div
          id='accounts'
          hx-get={url}
          hx-trigger='revealed,paramChange'
        >
          <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
          </div>
          <SkeletonTableLoader
            rowCount={10}
            types={['squareAvatar&Label', 'smLabel', 'number', 'smLabel', 'name&Subtitle', 'avatar']}
            columns='2fr 1fr 1fr 1fr 1fr 50px 50px'
            justifyCenter
          />
        </div>
      </div>
      {currDomain && (
        <SignalsScoreHistory
          score={currScore}
          placement='left'
          domain={currDomain}
          anchorEl={scoreAnchorEl}
          open={historyOpen}
        />
      )}
      <FilterDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        filters={filters[0] || []}
        setFilters={(filterGroup: QueryFilter[]) => setFilters([filterGroup])}
      />
      {lookupModalOpen && <ProspectModal onHide={() => setLookupModalOpen(false)} selectedAccounts={selectedAccounts} />}
    </>
  )
}
