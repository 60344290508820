import { Divider, Fade, Paper, Popper, PopperPlacementType, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  popperContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 20,
    width: 280
  },
  popperHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  popper: {
    zIndex: 10000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      width: 0,
      height: 0,
      border: '1px solid black'
    }
  }
}))

interface TopicsSentimentProps {
  anchorEl: any
  open: boolean
  placement: PopperPlacementType
  topics: string[]
  sentiment: {
    buyer: number
    researcher: number
    // eslint-disable-next-line
    job_seeker: number
    // eslint-disable-next-line
    customer_seeking_support: number
    investor: number
    competitor: number
  }
  dataID: string
}

export function TopicsSentimentModal ({ anchorEl, placement, open, topics, sentiment, dataID }: TopicsSentimentProps): JSX.Element {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)
  // eslint-disable-next-line
  const allSentimentsZero = Object.values(sentiment).every(value => value === 0)
  const noTopics = topics.length === 0

  const sentimentString = `Buyer: ${sentiment.buyer}%\nResearcher: ${sentiment.researcher}%\nJob Seeker: ${sentiment.job_seeker}%\nCustomer Seeking Support: ${sentiment.customer_seeking_support}%\nInvestor: ${sentiment.investor}%\nCompetitor: ${sentiment.competitor}%`
  let topicsString = ''
  topics.forEach((topic: string) => {
    if (topic === topics[topics.length - 1]) {
      topicsString += topic
      return
    }
    topicsString += topic + ', '
  })

  return (
    <>
      <Popper
        id={dataID}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        className={classes.popper}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <>
              <span className={classes.arrow} ref={setArrowRef} />
              <Paper className={classes.popperContainer}>
                {allSentimentsZero || noTopics ? (
                  <Typography variant='body1'>
                    Not enough information about pages viewed to determine vistor sentiment and topics of interest.
                  </Typography>
                ) : (
                  <>
                    <div className={classes.popperHeader}>
                      <Typography variant='h6'>Sentiment</Typography>
                    </div>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {sentimentString}
                    </div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <div className={classes.popperHeader}>
                      <Typography variant='h6'>Topics of Interest</Typography>
                    </div>
                    <Typography variant='body1'>{topicsString}</Typography>
                  </>
                )}
              </Paper>
            </>
          </Fade>
        )}
      </Popper>
    </>
  )
}
