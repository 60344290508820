import { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import twemoji from 'twemoji'
import sanitizeHTML from 'sanitize-html'
import linkifyHtml from 'linkifyjs/html'
import DOMPurify from 'dompurify'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
  }
})

const useStyles = makeStyles(theme => ({
  preview: {
    padding: '10px 10px 10px 20px',
    borderLeft: '3px solid #aaa',
    minHeight: 90,
    maxHeight: 110,
    overflow: 'hidden',
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 650,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)'
    }
  },
  closeIcon: {
    color: '#777',
    fontSize: '1em',
    cursor: 'pointer'
  },
  linkifyHyperlink: {
    color: '#777',
    textDecoration: 'underline'
  }
}))

const defaultPreviewData = {
  title: '',
  description: '',
  image: ''
}

function LinkPreview (props) {
  const classes = useStyles()
  const [previewData, setPreviewData] = useState(defaultPreviewData)
  const [emptyPreviewData, setEmptyPreviewData] = useState(true)
  let url = ''
  const candidates = props.text.split(' ').filter(t => t.includes('href'))
  if (candidates.length) {
    const lastCandidate = candidates[candidates.length - 1]
    url = lastCandidate.split('"')[1]
    if (!url.includes('http')) {
      url = 'https://' + url
    }
  }

  useEffect(() => {
    setPreviewData(defaultPreviewData)
    if (url) {
      fetch('/api/link-preview-service/preview?' + new URLSearchParams({ url }), {
        method: 'GET',
        header: {
          'Content-Type': 'application/vnd.api+json',
          Pragma: 'no-cache'
        }
      }).then(response => response.json()).then(response => {
        // Check if the response data doesn't exist or is empty
        for (const attr in response?.data?.attributes) {
          if (response.data.attributes[attr] !== '') {
            setEmptyPreviewData(false)
            break
          }
        }
        if (response?.data?.attributes) {
          setPreviewData({
            title: response.data.attributes.title,
            description: response.data.attributes.description,
            image: response.data.attributes.image
          })
        } else {
          setPreviewData({ title: 'Error loading preview', description: 'An error occurred while loading the preview for ' + url, image: '' })
        }
      }).catch(() => {
        setPreviewData({ title: 'Error loading preview', description: 'An error occurred while loading the preview for ' + url, image: '' })
      })
    }
  }, [url])

  return (
    <div
      className={props.className}
      key={props.key}
    >
      <div
        ref={props.divRef}
        dangerouslySetInnerHTML={{ __html: props.text }}
        style={{ color: 'inherit' }}
      />
      {url && !emptyPreviewData &&
        <div style={{ position: 'relative' }}>
          <a href={url} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className={classes.preview}>
              <div style={{ display: 'flex' }}>
                {previewData.image &&
                  <div style={{ maxHeight: 100, marginRight: 10 }}>
                    <img src={previewData.image} width={100} alt='link-preview' />
                  </div>}
                <div style={{ fontSize: '1em', fontWeight: 600 }}>
                  {previewData.title}
                </div>
              </div>
              <div style={{
                fontSize: '0.9em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical'
              }}
              >
                {previewData.description}
              </div>
            </div>
          </a>
        </div>}
    </div>
  )
}

function RenderEmoji (props) {
  const classes = useStyles()
  const divRef = useRef()
  const emojiCountRef = useRef(0)

  useEffect(() => {
    twemoji.parse(divRef.current, {
      callback: (icon, options, variant) => {
        emojiCountRef.current = emojiCountRef.current + 1
        return ''.concat(options.base, options.size, '/', icon, options.ext)
      }
    })
    if (emojiCountRef.current === 1 && !divRef.current.innerText.trim()) {
      divRef.current.classList.add('big')
    }
  }, [props.text])

  const linked = props.onlyRenderEmojis ? linkifyHtml(
      props.text,
      {
        className: classes.linkifyHyperlink,
        defaultProtocol: 'https',
        validate: { url: (value) => /twemoji/.test(value) } // Only linkify twemoji URLs
      })
    : linkifyHtml(
      props.text,
      {
        className: classes.linkifyHyperlink,
        defaultProtocol: 'https'
      }
    )
  if (linked.includes('<a href=') && !(linked.includes('mailto'))) {
    return (
      <LinkPreview
        className={props.className}
        text={DOMPurify.sanitize(linked)}
        divRef={divRef}
      />
    )
  }

  if (props.rich) {
    return (
      <div
        className={props.className}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        ref={divRef}
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(
            props.text.replaceAll('<br>', '').replaceAll('<ul>', '<ul class="tailwind-bullet-dot">').replaceAll('<ol>', '<ol class="tailwind-bullet-number">').replaceAll('\t', '&nbsp;&nbsp;&nbsp;&nbsp;'),
            {
              allowedSchemes: ['http', 'https'],
              allowedSchemesByTag: {
                img: ['data']
              },
              allowedAttributes: {
                a: ['href', 'name', 'target'],
                p: ['class'],
                li: ['class'],
                ul: ['class'],
                ol: ['class'],
                img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
              },
              allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'u', 's'])
            }
          )
        }}
      />
    )
  }

  const text = props.text
  // SplitOnEmails(text);

  return (
    <div className={props.className} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} ref={divRef}>{text}</div>
  )
}

export default RenderEmoji
