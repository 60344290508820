import { Modal } from 'library/Modal';

type SnapshotUpgradePromptModalProps = {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export default function SnapshotUpgradePromptModal ({ open, onClose, children }: SnapshotUpgradePromptModalProps): JSX.Element {
    return (
      <Modal
        title='Upgrade plan'
        size='md'
        saveBtnText='Schedule a meeting to upgrade'
        open={open}
        onHide={onClose}
        handleSave={() => {
          window.open('https://getsignals.ai/insights-plan', '_blank')
          // const searchParams = new URLSearchParams(window.location.hash.split('?')[1] || window.location.search)
          // searchParams.set('snapshot_upgrade', 'true')
          // const currentHash = window.location.hash.split('?')[0]
          // window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}${currentHash}?${searchParams.toString()}`)
          // onClose()
          // window.dispatchEvent(new CustomEvent('queryParamChange'))
        }}
      >
        {children}
      </Modal>
    )
}
