import { PopperPlacementType, Tooltip } from '@material-ui/core'
import { useDoQuery } from 'classes/useDoQuery'
import { Icon } from 'library/materialUI'
import { useState, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { EVENT_TYPE, Emitter } from 'emitter'
import { SignalsScoreHistory } from './SignalsScoreHistory'

interface SignalsScoreGearProps {
  score: number
  placement: PopperPlacementType
  domain: string
  specificSize?: number
  noPadding?: boolean
  liveView?: boolean
}

interface ReasonLoad {
  isLoading: boolean
  data: ReasonData
}

interface ReasonData {
  data: Reason[]
}

interface Reason {
  attributes: string
}

export function SignalsScoreGear ({ score, placement, domain, specificSize, noPadding, liveView }: SignalsScoreGearProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { data: reasons }: ReasonLoad = useDoQuery({ path: `/accounts/${domain}/score/reasons`, objectClass: undefined, useChatURL: true })
  const [hovering, setHovering] = useState(false)
  const handleHover = (event: any) => {
    if (score && reasons.data.length > 0) {
      setAnchorEl(event.currentTarget)
      setOpen(true)
      Emitter.emit(EVENT_TYPE.CLOSE_GEAR_POPUPS, { openedID: domain, target: event.currentTarget })
    }
  }

  const handleLeave = () => {
    if (!hovering) {
      setAnchorEl(null)
      setOpen(false)
    }
  }

  Emitter.on(EVENT_TYPE.CLOSE_GEAR_POPUPS, ({ openedID, target }: any) => {
    if (domain !== openedID) {
      setAnchorEl(null)
      setOpen(false)
    } else {
      setAnchorEl(target)
      setOpen(true)
    }
  })

  useEffect(() => {
    if (!hovering) {
      setAnchorEl(null)
      setOpen(false)
    }
  }, [hovering])

  return (
    <>
      <div
        style={{ height: specificSize || 60, width: specificSize || 65, paddingLeft: noPadding ? 0 : 10, marginRight: noPadding ? 0 : 10, display: 'flex' }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <CircularProgressbar
          value={score}
          text={score ? score.toString() : '0'}
          strokeWidth={13}
          styles={{
            path: {
              stroke: '#5AD8E6'
            },
            trail: {
              stroke: '#f5f5f5'
            },
            text: {
              fontSize: '36px',
              fontWeight: 'bolder',
              fill: liveView ? '#fff' : '#505050',
              transform: 'translateY(3px)'
            }
          }}
        />
        <Tooltip title='Powered by Artificial Intelligence'>
          <div style={{ marginLeft: -5 }}>
            <Icon icon='aiSparkles' />
          </div>
        </Tooltip>
      </div>
      <SignalsScoreHistory
        score={score}
        placement={placement}
        domain={domain}
        anchorEl={anchorEl}
        open={open}
        setHovering={setHovering}
      />
    </>
  )
}
