const homepageFAQFlow = {
  type: 'flows',
  attributes: {
    created_timestamp: '2020-07-31T22:46:16.294097+00:00',
    flow: {
      data: {
        edges: [
          {
            data: {
              id: 'b0df2709-7af1-43a9-a2ee-1c91a5b346ea',
              type: 'common'
            },
            source: 'start.default',
            target: '10714c55-76df-4aac-af19-bf6e646434f0'
          },
          {
            data: {
              id: '47f88f9f-cb30-47c0-9072-c63700f93177',
              type: 'common'
            },
            source: '10714c55-76df-4aac-af19-bf6e646434f0.8ee9cf67-a668-464d-8ac9-628ff2f41dbb',
            target: 'e484cd5f-f78b-434a-8108-e755f52cd865'
          },
          {
            data: {
              id: 'eec2fb33-7bfc-43c6-8ba8-4fb3ebe99883',
              type: 'common'
            },
            source: 'e484cd5f-f78b-434a-8108-e755f52cd865.default',
            target: 'ceef4eb3-c8c2-437f-ba8b-e451f1dd22d7'
          },
          {
            data: {
              id: 'ad95c8f0-53b8-46a2-bedb-02ad32f61116',
              type: 'common'
            },
            source: 'ca483f63-63bc-469e-a27f-cb0c1e7f901d.default',
            target: 'bf565475-912a-453e-9cde-8a45cd120d1c'
          },
          {
            data: {
              id: '2e24e644-9d7a-40c6-b518-3e99cd664f39',
              type: 'common'
            },
            source: 'bf565475-912a-453e-9cde-8a45cd120d1c.invalid',
            target: '8ae5e029-896c-4235-9376-67159c5dc7ee'
          },
          {
            data: {
              id: '04c3f9b4-6a77-4e2d-820a-62d8826b0c34',
              type: 'common'
            },
            source: '8ae5e029-896c-4235-9376-67159c5dc7ee.default',
            target: '806e9da3-2387-4d13-876f-9b13ff1a6f80'
          },
          {
            data: {
              id: 'c8f82eee-eb6c-4931-8af3-3b1ab68c3270',
              type: 'common'
            },
            source: '806e9da3-2387-4d13-876f-9b13ff1a6f80.default',
            target: '87158ac2-c0b5-4019-a323-2169ab9a4b25'
          },
          {
            data: {
              id: '27cfb68f-c3e7-42f5-86c4-4e190babfc6a',
              type: 'common'
            },
            source: 'bf565475-912a-453e-9cde-8a45cd120d1c.valid',
            target: '7b5f9c7f-be18-41d1-93c7-a307627dc167'
          },
          {
            data: {
              id: 'e8fa2e75-fd93-4b59-b940-fc51e3cefa79',
              type: 'common'
            },
            source: '7b5f9c7f-be18-41d1-93c7-a307627dc167.default',
            target: 'd122bf3c-ee65-4784-8d1a-7fbf47b05618'
          },
          {
            data: {
              id: '61b366d3-d817-45fe-b50b-8cd6209a5638',
              type: 'common'
            },
            source: 'eb37d6f3-92fd-41d4-93dd-cced220795a6.routed',
            target: '0a29c7f9-cdb1-4dbe-9bc7-69e92a9319c2'
          },
          {
            data: {
              id: '22d8f8d6-ad92-47f7-a0bd-12b1d660752d',
              type: 'common'
            },
            source: 'd122bf3c-ee65-4784-8d1a-7fbf47b05618.complete',
            target: '170fa6ee-a4cd-47db-8206-4314ccc488a9'
          },
          {
            data: {
              id: '981c9dc3-58d1-4c35-b752-c1a33195410e',
              type: 'common'
            },
            source: 'ceef4eb3-c8c2-437f-ba8b-e451f1dd22d7.fb4544d2-12a4-4d09-81da-f0e37087d252',
            target: 'c3358a0d-dae3-4ada-89b1-fe32d27af181'
          },
          {
            data: {
              id: '9be370bf-7438-4eb3-9e64-c5234a3a07b5',
              type: 'common'
            },
            source: 'fa73c281-85f4-4482-90cb-3e8b3ce0fa17.routed',
            target: '013061b9-9bd3-46f7-9d2b-aae1ee69cfe1'
          },
          {
            data: {
              id: 'c072b4be-d9e2-4ee9-a805-97830143b5eb',
              type: 'common'
            },
            source: '013061b9-9bd3-46f7-9d2b-aae1ee69cfe1.a9623ac9-d10a-4e36-acfb-1009f21cfe46',
            target: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9'
          },
          {
            data: {
              id: '59cdc8c9-07cd-4bbb-a75f-8ee1a2aab00b',
              type: 'common'
            },
            source: '013061b9-9bd3-46f7-9d2b-aae1ee69cfe1.70a34ff7-2adb-4824-afb7-966b69baa47b',
            target: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9'
          },
          {
            data: {
              id: 'af729e6a-2454-4bcd-a8c7-e75349be02ba',
              type: 'common'
            },
            source: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9.bf6fdacd-61e8-4afd-8c6c-9f76f23ad1f2',
            target: '900cae12-91fe-4e05-b6ee-d04c41b31ae8'
          },
          {
            data: {
              id: 'bab307b7-910b-4b6c-be3d-fc646468183a',
              type: 'common'
            },
            source: '900cae12-91fe-4e05-b6ee-d04c41b31ae8.default',
            target: '81ea5664-529f-45c0-b11b-941c8675b51f'
          },
          {
            data: {
              id: '25c12326-adb5-4d94-a6b2-f3c111d919dc',
              type: 'common'
            },
            source: '013061b9-9bd3-46f7-9d2b-aae1ee69cfe1.bbc2c44d-d271-4cdf-aa71-ba070c8d155e',
            target: '900cae12-91fe-4e05-b6ee-d04c41b31ae8'
          },
          {
            data: {
              id: 'd8173f56-ba6c-4e25-a25b-6ac202eaefe6',
              type: 'common'
            },
            source: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9.aba067a1-5c66-4e4d-bfd7-c42cc0161501',
            target: '2397f091-cb85-4316-a75a-1ed38aa61c80'
          },
          {
            data: {
              id: 'b1436650-4d17-4852-9c14-df0cba677b83',
              type: 'common'
            },
            source: '2397f091-cb85-4316-a75a-1ed38aa61c80.invalid',
            target: 'be855de4-05fd-4f0c-a8f4-33fc803825b0'
          },
          {
            data: {
              id: 'cc3713cb-9234-4abf-aea7-f3e3ef4dbda4',
              type: 'common'
            },
            source: 'be855de4-05fd-4f0c-a8f4-33fc803825b0.default',
            target: '3fa89a43-7a5a-4692-bfa5-0bd15520df68'
          },
          {
            data: {
              id: '9ea932ee-ea88-4b0c-a710-65d6a8f4428e',
              type: 'common'
            },
            source: '2397f091-cb85-4316-a75a-1ed38aa61c80.valid',
            target: 'a65b8af7-a5e8-4bc7-b11f-af88fb5af432'
          },
          {
            data: {
              id: '80b98efc-50a5-45ae-b8a8-f46e97c9de0b',
              type: 'common'
            },
            source: 'a65b8af7-a5e8-4bc7-b11f-af88fb5af432.complete',
            target: '75e18e0b-f943-4074-9a80-25320a64b743'
          },
          {
            data: {
              id: '2dd71594-6808-4a40-90b1-ef1ce26d17ad',
              type: 'common'
            },
            source: 'edb959a8-61e4-4092-9fd7-bc8a0e69359f.response',
            target: '9b40dd0e-065b-4c6d-8846-1327b7fd1191'
          },
          {
            data: {
              id: '6a66fef5-583e-4f34-a86b-47102faf9abc',
              type: 'common'
            },
            source: '9b40dd0e-065b-4c6d-8846-1327b7fd1191.default',
            target: '444fa6c0-cba4-4f01-a808-aed55ffd917b'
          },
          {
            data: {
              id: '09e0062c-cf02-417a-89fb-78f3f806e5f5',
              type: 'common'
            },
            source: 'fb647e66-548d-4441-9144-9c0f86a14d8d.8c078ce6-3bf6-47d6-98aa-ad5c0f9ff50d',
            target: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c'
          },
          {
            data: {
              id: '0b2892bc-85ef-424f-8d73-db350cfa9b7c',
              type: 'common'
            },
            source: 'fb647e66-548d-4441-9144-9c0f86a14d8d.47751af5-10b5-4170-a91d-7a31f8552abd',
            target: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c'
          },
          {
            data: {
              id: 'f988ce55-e779-40fb-ad7e-c2b982f78683',
              type: 'common'
            },
            source: 'fb647e66-548d-4441-9144-9c0f86a14d8d.71a35935-8a99-4b4d-8745-34bcc4ee6093',
            target: 'd5633f44-7519-4c28-88b7-b2880a48ada5'
          },
          {
            data: {
              id: 'fe06a2cc-6d8c-403d-bb79-816fa666ff01',
              type: 'common'
            },
            source: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c.f6d8b113-4596-4133-96fb-0eab84aa0fda',
            target: 'd5633f44-7519-4c28-88b7-b2880a48ada5'
          },
          {
            data: {
              id: 'd16c7872-0e7e-4912-9ba8-cf4dea96a12a',
              type: 'common'
            },
            source: 'd5633f44-7519-4c28-88b7-b2880a48ada5.default',
            target: 'da69c06a-860c-4abb-a4be-dea3d6596af3'
          },
          {
            data: {
              id: '416746d2-08d4-4c2f-b3c5-020431587933',
              type: 'common'
            },
            source: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c.e0dd6713-2810-4395-a5e3-9d754d9e46c7',
            target: '1896b9af-b4b4-4148-ba51-957a61bff683'
          },
          {
            data: {
              id: 'adc570d8-48f8-468a-b71b-6889d1fb63eb',
              type: 'common'
            },
            source: '444fa6c0-cba4-4f01-a808-aed55ffd917b.default',
            target: 'a3b0e8b1-36cc-409c-9659-068633114a80'
          },
          {
            data: {
              id: 'e1e350ac-55b1-49af-b679-3198b46d5bed',
              type: 'common'
            },
            source: '3fa89a43-7a5a-4692-bfa5-0bd15520df68.default',
            target: '0886dfc8-88b0-4def-a7ab-e3b33bb5cbc2'
          },
          {
            data: {
              id: 'aad07e33-95dc-4a55-b5dc-3f5d8490334a',
              type: 'common'
            },
            source: '1896b9af-b4b4-4148-ba51-957a61bff683.invalid',
            target: 'fc39ec75-05cc-4c7e-9120-c3636d0f62aa'
          },
          {
            data: {
              id: 'de79b488-e4a5-4936-aa29-790bffeac7bd',
              type: 'common'
            },
            source: 'fc39ec75-05cc-4c7e-9120-c3636d0f62aa.default',
            target: 'e590bc4e-f7c9-47d9-89cc-07b3cc73f28c'
          },
          {
            data: {
              id: 'db93ac23-b248-4678-bb3a-53067231d1c7',
              type: 'common'
            },
            source: '1896b9af-b4b4-4148-ba51-957a61bff683.valid',
            target: 'a8185590-770c-48a1-8890-37155201391d'
          },
          {
            data: {
              id: 'c97c3554-a7e0-4ac0-a44c-27e698a361ea',
              type: 'common'
            },
            source: 'a8185590-770c-48a1-8890-37155201391d.no_email',
            target: '52802a53-7cfe-4a1f-892a-c7e9faa346a0'
          },
          {
            data: {
              id: '9160f1ef-7d6e-4893-891a-c358baab1616',
              type: 'common'
            },
            source: 'c3358a0d-dae3-4ada-89b1-fe32d27af181.default',
            target: 'fa73c281-85f4-4482-90cb-3e8b3ce0fa17'
          },
          {
            data: {
              id: '49a63f01-5b1a-408f-a7f7-d91df3291c6b',
              type: 'common'
            },
            source: 'fa73c281-85f4-4482-90cb-3e8b3ce0fa17.away',
            target: 'fb647e66-548d-4441-9144-9c0f86a14d8d'
          },
          {
            data: {
              id: 'f13046b0-7eee-441c-bbf8-bbd2392607d3',
              type: 'common'
            },
            source: 'e590bc4e-f7c9-47d9-89cc-07b3cc73f28c.default',
            target: 'e647d597-7013-407c-93c8-bc8208bca7fa'
          },
          {
            data: {
              id: 'e1f072b5-1375-4b43-867c-33fd45675284',
              type: 'common'
            },
            source: 'a8185590-770c-48a1-8890-37155201391d.complete',
            target: 'd24c0e65-6757-48a0-9279-6a0cd8f97f40'
          },
          {
            data: {
              id: '68c35926-caee-4441-b167-dc9e8934929b',
              type: 'common'
            },
            source: '75e18e0b-f943-4074-9a80-25320a64b743.default',
            target: 'edb959a8-61e4-4092-9fd7-bc8a0e69359f'
          },
          {
            data: {
              id: 'f7f37342-49d7-40e4-94a0-ea8c446e724a',
              type: 'common'
            },
            source: 'd24c0e65-6757-48a0-9279-6a0cd8f97f40.default',
            target: '6a26f358-987b-4519-b731-a7bd80d17964'
          },
          {
            data: {
              id: '033bea7b-011d-4ddf-953a-abceeed6a766',
              type: 'common'
            },
            source: '6a26f358-987b-4519-b731-a7bd80d17964.response',
            target: 'c35c3350-6d7e-4460-b517-b0cb6008f39b'
          },
          {
            data: {
              id: 'b06acec6-c999-4738-8d78-0498f7cbf75b',
              type: 'common'
            },
            source: 'c35c3350-6d7e-4460-b517-b0cb6008f39b.default',
            target: 'b07e5735-1759-4b0c-971c-e9c7d16f2c23'
          },
          {
            data: {
              id: '759a64e2-7cc8-4419-9ef9-dd742bd6277b',
              type: 'common'
            },
            source: 'b07e5735-1759-4b0c-971c-e9c7d16f2c23.default',
            target: '8ea15fc7-1478-4741-b83d-ff5aaa77adce'
          },
          {
            data: {
              id: 'a77e6482-5317-4578-9897-8c36259b36dd',
              type: 'common'
            },
            source: 'ceef4eb3-c8c2-437f-ba8b-e451f1dd22d7.bf126611-e3a6-49bc-a7ce-658549ded31d',
            target: 'eb37d6f3-92fd-41d4-93dd-cced220795a6'
          },
          {
            data: {
              id: 'f0384793-5eaa-4d64-ae0e-0f804d1228c3',
              type: 'common'
            },
            source: 'eb37d6f3-92fd-41d4-93dd-cced220795a6.away',
            target: 'ca483f63-63bc-469e-a27f-cb0c1e7f901d'
          },
          {
            data: {
              id: '9652c203-94c3-47b2-9b2c-56ff6fe2df75',
              type: 'common'
            },
            source: '7cd7186a-8e62-4cba-8066-779007f7f7ae.default',
            target: '5e3f8cbc-7b6f-4adf-9c02-c86df01041ca'
          },
          {
            data: {
              id: '8dd7f8f0-2d9f-46e4-bbf4-8809089049e9',
              type: 'common'
            },
            source: '170fa6ee-a4cd-47db-8206-4314ccc488a9.response',
            target: '7cd7186a-8e62-4cba-8066-779007f7f7ae'
          },
          {
            data: {
              id: 'c146f21e-2b65-4519-b7f3-acfbdc865a8c',
              type: 'common'
            },
            source: 'd122bf3c-ee65-4784-8d1a-7fbf47b05618.no_email',
            target: '53be110a-58d9-45e1-9c1b-f425a094ce94'
          },
          {
            data: {
              id: '71dfb946-eb7f-455b-af2f-67ca343cc68b',
              type: 'common'
            },
            source: '87158ac2-c0b5-4019-a323-2169ab9a4b25.default',
            target: 'd2bce55f-f07f-4fb3-b5f0-39b7ab65bad2'
          },
          {
            data: {
              id: '4a0a9eec-7610-44e1-bc85-eef3d2a380fb',
              type: 'common'
            },
            source: 'da69c06a-860c-4abb-a4be-dea3d6596af3.default',
            target: '96335eec-1811-4d2a-a84c-92d2a27b9729'
          },
          {
            data: {
              id: 'ff8661a5-9170-41b9-bfbf-88bc18681609',
              type: 'common'
            },
            source: 'a65b8af7-a5e8-4bc7-b11f-af88fb5af432.no_email',
            target: '9d365499-d539-499d-8951-f7e1c217fb9d'
          },
          {
            data: {
              id: '554bc63e-94cd-45f1-a773-1f3b05656948',
              type: 'common'
            },
            source: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9.dc5c3021-f864-4014-87a6-1a36bf05acc2',
            target: '2397f091-cb85-4316-a75a-1ed38aa61c80'
          },
          {
            data: {
              id: '0db5810f-64b8-436b-87cc-ba07161b8fe7',
              type: 'common'
            },
            source: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c.3552ce99-1cf2-4004-a5d8-3feef7b55f11',
            target: '1896b9af-b4b4-4148-ba51-957a61bff683'
          },
          {
            data: {
              id: '54a4393d-a27d-4e2b-8d13-5d62f5537eb3',
              type: 'common'
            },
            source: '81ea5664-529f-45c0-b11b-941c8675b51f.default',
            target: 'd713e1da-0873-4467-a1ee-2dec150d0e01'
          },
          {
            data: {
              id: '55cebdee-e866-4f1f-96ce-7bfa96d2f9ae',
              type: 'common'
            },
            source: '0a29c7f9-cdb1-4dbe-9bc7-69e92a9319c2.default',
            target: '5bcf3aff-5823-4d3a-8a82-6de078cb45f8'
          },
          {
            data: {
              id: '0bcf292b-70ee-479f-898a-c6b0acb4b679',
              type: 'common'
            },
            source: '5bcf3aff-5823-4d3a-8a82-6de078cb45f8.default',
            target: '11a87d1e-8eda-4277-9a29-754e9b82bf77'
          },
          {
            data: {},
            source: 'ceef4eb3-c8c2-437f-ba8b-e451f1dd22d7.45295ddb-3f3e-443a-84df-ed230162f319',
            target: 'a63719cf-81a9-46f7-8412-1f5aaf5c4ef5'
          },
          {
            data: {
              id: '32ff3017-be25-4462-83df-85b469551562',
              type: 'common'
            },
            source: 'a63719cf-81a9-46f7-8412-1f5aaf5c4ef5.a449e63c-0fb5-41ca-92b3-ca3fc380dd97',
            target: '15120cc1-840b-4dea-904e-a16353384ea3'
          },
          {
            data: {
              id: 'af2090b5-4ced-4661-87cf-e6108ed322b6',
              type: 'common'
            },
            source: '4cc88639-3cca-4620-a733-0cad3dcd0913.47bb680b-d2e8-41a6-9963-334163cd0e56',
            target: '15120cc1-840b-4dea-904e-a16353384ea3'
          },
          {
            data: {
              id: '7c404e49-b904-422f-a79e-9335e0988d6a',
              type: 'common'
            },
            source: '15120cc1-840b-4dea-904e-a16353384ea3.resolved',
            target: '4cc88639-3cca-4620-a733-0cad3dcd0913'
          },
          {
            data: {
              id: '646f3fb9-c9e6-44b5-ad68-263a95c6b8c4',
              type: 'common'
            },
            source: '4cc88639-3cca-4620-a733-0cad3dcd0913.f173e772-4b66-4676-911c-bb6cbb750ec4',
            target: '83e091a8-96a1-44f1-ae94-276e6ceac7a9'
          },
          {
            data: {
              id: '73c0450c-755b-4527-aa82-5a5e12ee8070',
              type: 'common'
            },
            source: '83e091a8-96a1-44f1-ae94-276e6ceac7a9.default',
            target: '7bbc9147-4e71-4fec-afc0-56f3723ac526'
          },
          {
            data: {
              id: '65ac8245-752c-413b-b7f6-eb0563e8ef0f',
              type: 'common'
            },
            source: '7bbc9147-4e71-4fec-afc0-56f3723ac526.default',
            target: 'c07da9ae-cfc5-4707-897e-37acc95a4bcf'
          },
          {
            data: {
              id: 'fa1e4170-3d98-4b3b-ac92-e31d8382b367',
              type: 'common'
            },
            source: 'a63719cf-81a9-46f7-8412-1f5aaf5c4ef5.a6c19c7b-4ae2-41c9-8203-8f1326c06bc9',
            target: '47e78335-8fac-48de-8ecf-cb4c529b823b'
          },
          {
            data: {
              id: '8cb599e3-fd65-44f3-bda3-7acffa1bf4c1',
              type: 'common'
            },
            source: '47e78335-8fac-48de-8ecf-cb4c529b823b.273fedb1-ba48-42f5-acbe-31727738a1bb',
            target: '15120cc1-840b-4dea-904e-a16353384ea3'
          }
        ],
        nodes: [
          {
            id: 'start',
            top: -150,
            left: 440,
            type: 'start',
            ports: [
              {
                id: 'default'
              }
            ]
          },
          {
            id: '10714c55-76df-4aac-af19-bf6e646434f0',
            top: 170,
            body: '<p>Ready to increase [<em>positive</em>] and solve [<em>problem</em>]?</p>',
            left: 440,
            type: 'options',
            ports: [
              {
                id: '8ee9cf67-a668-464d-8ac9-628ff2f41dbb',
                label: 'Yeah!',
                _reactKey: 'a3901d0f-ac7f-450d-a686-c0516bc4b769'
              }
            ],
            buttons: [
              {
                id: '8ee9cf67-a668-464d-8ac9-628ff2f41dbb',
                label: 'Yeah!',
                _reactKey: 'a3901d0f-ac7f-450d-a686-c0516bc4b769'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'e484cd5f-f78b-434a-8108-e755f52cd865',
            top: 550,
            body: '<p>Great! [<em>Company name</em>] is utilized by [<em>description</em>] companies to [<em>business value</em>].</p>',
            left: 440,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '5fc8881f-e175-488a-a864-cee14bf175cd'
              }
            ]
          },
          {
            id: 'ceef4eb3-c8c2-437f-ba8b-e451f1dd22d7',
            top: 870,
            body: '<p>Can I show you a demo, so you can see how it works?</p>',
            left: 830,
            type: 'options',
            ports: [
              {
                id: 'fb4544d2-12a4-4d09-81da-f0e37087d252',
                label: 'Yes please!',
                _reactKey: 'a01e223a-cf97-44fd-8cba-9f4f30b50a2a'
              },
              {
                id: 'bf126611-e3a6-49bc-a7ce-658549ded31d',
                label: "I'd like to chat with a human first.",
                _reactKey: '3d5e6176-f3fd-4851-8ea1-e58064994563'
              },
              {
                id: '45295ddb-3f3e-443a-84df-ed230162f319',
                label: 'Nah, I need customer support.',
                _reactKey: '0714626f-a04c-4715-9159-44b3b4a0bafc'
              }
            ],
            buttons: [
              {
                id: 'fb4544d2-12a4-4d09-81da-f0e37087d252',
                label: 'Yes please!',
                _reactKey: 'a01e223a-cf97-44fd-8cba-9f4f30b50a2a'
              },
              {
                id: 'bf126611-e3a6-49bc-a7ce-658549ded31d',
                label: "I'd like to chat with a human first.",
                _reactKey: '3d5e6176-f3fd-4851-8ea1-e58064994563'
              },
              {
                id: '45295ddb-3f3e-443a-84df-ed230162f319',
                label: 'Nah, I need customer support.',
                _reactKey: '0714626f-a04c-4715-9159-44b3b4a0bafc'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'eb37d6f3-92fd-41d4-93dd-cced220795a6',
            top: 1280,
            body: '',
            left: 450,
            type: 'route',
            ports: [
              {
                id: 'routed',
                label: 'Routed',
                _reactKey: '557020cb-734c-4154-ae71-6c28774adc47'
              },
              {
                id: 'away',
                label: 'Away',
                _reactKey: '043ad96d-3427-4ab0-8c2c-96bbfa027776'
              }
            ],
            method: 'round_robin_team',
            team_id: null,
            user_ids: [],
            route_type: ''
          },
          {
            id: '0a29c7f9-cdb1-4dbe-9bc7-69e92a9319c2',
            top: 1300,
            body: '<p><span style="color: rgb(0, 0, 0);">Let me grab one of my humans for you. It will take about [</span><em style="color: rgb(0, 0, 0);">number</em><span style="color: rgb(0, 0, 0);">] minutes.</span></p>',
            left: -260,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '3dbe7c34-c7ba-4092-8ea6-8fe1de6c7c1c'
              }
            ]
          },
          {
            id: '11a87d1e-8eda-4277-9a29-754e9b82bf77',
            top: 1860,
            body: '',
            left: -260,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'ca483f63-63bc-469e-a27f-cb0c1e7f901d',
            top: 1360,
            body: '<p>Looks like no one is available right now, but I can connect you with someone ASAP.</p>',
            left: 1220,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '50d20339-db68-4049-9060-7c58c23580f0'
              }
            ]
          },
          {
            id: 'bf565475-912a-453e-9cde-8a45cd120d1c',
            top: 1690,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">So we can send you a calendar invite, what's your business email?</span></p>",
            left: 1220,
            type: 'email',
            ports: [
              {
                id: 'invalid',
                _reactKey: 'df5b75b6-7897-4116-b13a-9cd8c51eb1ad'
              },
              {
                id: 'valid',
                _reactKey: '96ed49be-c480-4871-9160-6ce6ef386aef'
              }
            ],
            unwanted: {
              spam: true,
              blocked: true,
              personal: true
            },
            captureTimes: 3,
            secondResponse: "<p><span style=\"color: rgb(0, 0, 0);\">Last try; what's your </span>[<em>work</em>]<span style=\"color: rgb(0, 0, 0);\"> email address?</span></p>",
            invalidResponse: "<p>I don't recognize that as a valid email address. Please try again</p>",
            unwantedResponse: '<p>Please provide a valid business email</p>'
          },
          {
            id: '8ae5e029-896c-4235-9376-67159c5dc7ee',
            top: 1760,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">I'm sorry, I can only schedule a time if I have a valid email for the calendar invite.</span></p>",
            left: 1750,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'fe021684-3723-4af6-842a-da50e272d9d7'
              }
            ]
          },
          {
            id: '806e9da3-2387-4d13-876f-9b13ff1a6f80',
            top: 2060,
            body: '<p><span style="color: rgb(0, 0, 0);">Feel free to check out our </span>resources<span style="color: rgb(0, 0, 0);"> for useful insights on [</span><em style="color: rgb(0, 0, 0);">business value</em><span style="color: rgb(0, 0, 0);">].</span></p>',
            left: 1870,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '0514f690-8b84-4056-b6a0-b9fd79c3f417'
              }
            ]
          },
          {
            id: '87158ac2-c0b5-4019-a323-2169ab9a4b25',
            top: 2320,
            body: '',
            left: 1870,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: 'ab4a8019-cafe-4459-85b5-3cc3d16fa073'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: '7b5f9c7f-be18-41d1-93c7-a307627dc167',
            top: 2000,
            body: '<p>Got it! Select a time below:</p>',
            left: 810,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '843deba2-36ed-4f5e-a3d0-ddeef470cbca'
              }
            ]
          },
          {
            id: 'd122bf3c-ee65-4784-8d1a-7fbf47b05618',
            top: 2230,
            body: '<p>Book a meeting:</p>',
            left: 810,
            type: 'calendar',
            ports: [
              {
                id: 'complete',
                label: 'Meeting Scheduled',
                _reactKey: '654ae595-c103-4f6d-b923-0824655d00fa'
              },
              {
                id: 'no_email',
                label: 'Email Unkown',
                _reactKey: 'feec61a1-0245-4eda-9465-0eb1f5fd0590'
              }
            ],
            method: 'round_robin_team',
            team_id: null,
            user_ids: [],
            route_type: '',
            meeting_type: null
          },
          {
            id: '170fa6ee-a4cd-47db-8206-4314ccc488a9',
            top: 2240,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">We're looking forward to it! If you'd like me to pass on a message to the rep, type below:</span></p>",
            left: 430,
            type: 'options',
            ports: [
              {
                id: 'response',
                label: 'Visitor Response',
                _reactKey: '21733891-c1c1-4b42-842a-9fd110b0c59b'
              }
            ],
            buttons: [],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'free_response',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: '5e3f8cbc-7b6f-4adf-9c02-c86df01041ca',
            top: 2850,
            body: '',
            left: 430,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'c3358a0d-dae3-4ada-89b1-fe32d27af181',
            top: 920,
            body: '<p><span style="color: rgb(0, 0, 0);">I just need you to answer [</span><em style="color: rgb(0, 0, 0);">number</em><span style="color: rgb(0, 0, 0);">] questions, so I can [</span><em>find you the best sales rep</em><span style="color: rgb(0, 0, 0);">]</span></p>',
            left: -2380,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'a3c216e1-a793-45b4-8cb0-5e5324892275'
              }
            ]
          },
          {
            id: 'fa73c281-85f4-4482-90cb-3e8b3ce0fa17',
            top: 1250,
            body: '',
            left: -2380,
            type: 'route',
            ports: [
              {
                id: 'routed',
                label: 'Routed',
                _reactKey: '187d8510-5126-49a5-b4b6-fb5ec3f8639a'
              },
              {
                id: 'away',
                label: 'Away',
                _reactKey: '9142b68d-bea4-4848-b39b-521cba0d9889'
              }
            ],
            method: 'round_robin_team',
            team_id: null,
            user_ids: [],
            route_type: ''
          },
          {
            id: '013061b9-9bd3-46f7-9d2b-aae1ee69cfe1',
            top: 1360,
            body: '<p><span style="color: rgb(0, 0, 0);">Qualifying Question #1 (Description of question type, industry, integration, name)</span></p>',
            left: -3440,
            type: 'options',
            ports: [
              {
                id: 'a9623ac9-d10a-4e36-acfb-1009f21cfe46',
                label: 'Qualifying Option #1',
                _reactKey: '1a9689cc-85f4-4bfb-996b-0fba00c20441'
              },
              {
                id: '70a34ff7-2adb-4824-afb7-966b69baa47b',
                label: 'Qualifying Option #2',
                _reactKey: '4c5c67f3-5cfc-4f82-a910-b1443e88a2d9'
              },
              {
                id: 'bbc2c44d-d271-4cdf-aa71-ba070c8d155e',
                label: 'Disqualifying Option #1',
                _reactKey: 'e9b9cad0-22f0-43b1-840d-e7e37b61b9ef'
              }
            ],
            buttons: [
              {
                id: 'a9623ac9-d10a-4e36-acfb-1009f21cfe46',
                label: 'Qualifying Option #1',
                _reactKey: '1a9689cc-85f4-4bfb-996b-0fba00c20441'
              },
              {
                id: '70a34ff7-2adb-4824-afb7-966b69baa47b',
                label: 'Qualifying Option #2',
                _reactKey: '4c5c67f3-5cfc-4f82-a910-b1443e88a2d9'
              },
              {
                id: 'bbc2c44d-d271-4cdf-aa71-ba070c8d155e',
                label: 'Disqualifying Option #1',
                _reactKey: 'e9b9cad0-22f0-43b1-840d-e7e37b61b9ef'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'c30975d4-5371-4a68-ab94-b48ab5f63bb9',
            top: 1860,
            body: '<p>Qualifying Question #2</p>',
            left: -3860,
            type: 'options',
            ports: [
              {
                id: 'dc5c3021-f864-4014-87a6-1a36bf05acc2',
                label: 'Qualifying Response #1',
                _reactKey: '815c6449-ee3e-4eae-92a0-7d8e2c76da36'
              },
              {
                id: 'aba067a1-5c66-4e4d-bfd7-c42cc0161501',
                label: 'Qualifying Response #2',
                _reactKey: '3847f3a7-d62e-4175-849a-54902f9ef6fd'
              },
              {
                id: 'bf6fdacd-61e8-4afd-8c6c-9f76f23ad1f2',
                label: 'Disqualifying Response #1',
                _reactKey: 'bcfcbfb2-4cf4-4a4a-9181-5f70e00de0ca'
              }
            ],
            buttons: [
              {
                id: 'dc5c3021-f864-4014-87a6-1a36bf05acc2',
                label: 'Qualifying Response #1',
                _reactKey: '815c6449-ee3e-4eae-92a0-7d8e2c76da36'
              },
              {
                id: 'aba067a1-5c66-4e4d-bfd7-c42cc0161501',
                label: 'Qualifying Response #2',
                _reactKey: '3847f3a7-d62e-4175-849a-54902f9ef6fd'
              },
              {
                id: 'bf6fdacd-61e8-4afd-8c6c-9f76f23ad1f2',
                label: 'Disqualifying Response #1',
                _reactKey: 'bcfcbfb2-4cf4-4a4a-9181-5f70e00de0ca'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: '900cae12-91fe-4e05-b6ee-d04c41b31ae8',
            top: 1800,
            body: '<p>Sorry, but based on this info, it looks like we may not be a good fit for your team. But please feel free to check out <a href="enter link here..." rel="noopener noreferrer" target="_blank">our resources</a> for useful insights on [<em>business value</em>]. We hope you find the info helpful!</p>',
            left: -2730,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'a7630277-f025-4667-ad41-68a704975542'
              }
            ]
          },
          {
            id: '81ea5664-529f-45c0-b11b-941c8675b51f',
            top: 2040,
            body: '',
            left: -2730,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: 'a67a5550-953c-4905-886b-c491eb12f5e2'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: '2397f091-cb85-4316-a75a-1ed38aa61c80',
            top: 1920,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">What's the best email address to send your calendar invite?</span></p>",
            left: -4530,
            type: 'email',
            ports: [
              {
                id: 'invalid',
                _reactKey: '1671e8d8-50fe-4870-a3a1-5419c2681308'
              },
              {
                id: 'valid',
                _reactKey: '0c55e097-022b-401e-8d82-cfee962a7bbb'
              }
            ],
            unwanted: {
              spam: true,
              blocked: true,
              personal: true
            },
            captureTimes: 3,
            secondResponse: "<p><span style=\"color: rgb(0, 0, 0);\">Last try; what's your </span>[<em>work</em>]<span style=\"color: rgb(0, 0, 0);\"> email address?</span></p>",
            invalidResponse: "<p>I don't recognize that as a valid email address. Please try again</p>",
            unwantedResponse: '<p>Please provide a valid business email</p>'
          },
          {
            id: 'be855de4-05fd-4f0c-a8f4-33fc803825b0',
            top: 2480,
            body: "<p>Looks like you're not ready to book a demo. In the meantime, Feel free to check out <a href=\"Enter link here...\" rel=\"noopener noreferrer\" target=\"_blank\">our resources</a> for useful insights on [<em>business value</em>].</p>",
            left: -3730,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '479f3ce8-f65b-46a3-8a41-89ee7838bd71'
              }
            ]
          },
          {
            id: '3fa89a43-7a5a-4692-bfa5-0bd15520df68',
            top: 2800,
            body: '',
            left: -3730,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '29d634ad-4b97-4a63-8ff9-e446bacbe3f8'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: 'a65b8af7-a5e8-4bc7-b11f-af88fb5af432',
            top: 2100,
            body: '<p><span style="color: rgb(0, 0, 0);">Book Meeting: </span>[<em>Team or Individual</em>]</p>',
            left: -4990,
            type: 'calendar',
            ports: [
              {
                id: 'complete',
                label: 'Meeting Scheduled',
                _reactKey: 'dac7d457-f48b-4c9f-974b-e6432591b34e'
              },
              {
                id: 'no_email',
                label: 'Email Unkown',
                _reactKey: '9cd03143-aad4-4e58-a963-0bae9723db3d'
              }
            ],
            method: 'round_robin_team',
            team_id: null,
            user_ids: [],
            route_type: '',
            meeting_type: null
          },
          {
            id: '75e18e0b-f943-4074-9a80-25320a64b743',
            top: 2150,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">That's all!</span></p>",
            left: -5480,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '0b7ba8f7-2224-45a5-ac88-d824d7e0a1ed'
              }
            ]
          },
          {
            id: 'edb959a8-61e4-4092-9fd7-bc8a0e69359f',
            top: 2480,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">We're looking forward to your demo! Feel free to leave a message for the sales rep below.</span></p>",
            left: -5480,
            type: 'options',
            ports: [
              {
                id: 'response',
                label: 'Visitor Response',
                _reactKey: 'a02b0f81-1096-4619-a716-548ca0518645'
              }
            ],
            buttons: [],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'free_response',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: '9b40dd0e-065b-4c6d-8846-1327b7fd1191',
            top: 2930,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">Great, I'll pass that along!</span></p>",
            left: -5480,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'ddf010d9-0475-43ca-8ca0-d915b7505b83'
              }
            ]
          },
          {
            id: '444fa6c0-cba4-4f01-a808-aed55ffd917b',
            top: 3170,
            body: '',
            left: -5480,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '9f3830fe-47e5-4326-9746-03d482f3e333'
              }
            ],
            status: 'open',
            closedMessage: ''
          },
          {
            id: 'fb647e66-548d-4441-9144-9c0f86a14d8d',
            top: 1380,
            body: '<p><span style="color: rgb(0, 0, 0);">Qualifying Question #1</span></p>',
            left: -1280,
            type: 'options',
            ports: [
              {
                id: '8c078ce6-3bf6-47d6-98aa-ad5c0f9ff50d',
                label: 'Qualifying Question #1',
                _reactKey: 'a1bb0385-4964-40e0-8d69-4ee21ba1208c'
              },
              {
                id: '47751af5-10b5-4170-a91d-7a31f8552abd',
                label: 'Qualifying Question #2',
                _reactKey: '64379d21-c959-42c1-ae18-37d748545b32'
              },
              {
                id: '71a35935-8a99-4b4d-8745-34bcc4ee6093',
                label: 'Disqualifying Question #1',
                _reactKey: '05ba84fb-8b25-46a7-bfc6-0d03ff4b53a4'
              }
            ],
            buttons: [
              {
                id: '8c078ce6-3bf6-47d6-98aa-ad5c0f9ff50d',
                label: 'Qualifying Question #1',
                _reactKey: 'a1bb0385-4964-40e0-8d69-4ee21ba1208c'
              },
              {
                id: '47751af5-10b5-4170-a91d-7a31f8552abd',
                label: 'Qualifying Question #2',
                _reactKey: '64379d21-c959-42c1-ae18-37d748545b32'
              },
              {
                id: '71a35935-8a99-4b4d-8745-34bcc4ee6093',
                label: 'Disqualifying Question #1',
                _reactKey: '05ba84fb-8b25-46a7-bfc6-0d03ff4b53a4'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'aaa9bfb8-bfb9-48a0-b46e-9a02dce7eb4c',
            top: 1710,
            body: '<p><span style="color: rgb(0, 0, 0);">Qualifying Question #2</span></p>',
            left: -1790,
            type: 'options',
            ports: [
              {
                id: 'e0dd6713-2810-4395-a5e3-9d754d9e46c7',
                label: 'Qualifying Response #1',
                _reactKey: '73880e41-34ec-4d6d-ab99-54b91b16aada'
              },
              {
                id: '3552ce99-1cf2-4004-a5d8-3feef7b55f11',
                label: 'Qualifying Response #2',
                _reactKey: 'b7fd4891-9b06-4dc5-b181-a4cd06907d0b'
              },
              {
                id: 'f6d8b113-4596-4133-96fb-0eab84aa0fda',
                label: 'Disqualifying Response #1',
                _reactKey: '61594304-3eef-40b1-9bc8-d0bb180b0d86'
              }
            ],
            buttons: [
              {
                id: 'e0dd6713-2810-4395-a5e3-9d754d9e46c7',
                label: 'Qualifying Response #1',
                _reactKey: '73880e41-34ec-4d6d-ab99-54b91b16aada'
              },
              {
                id: '3552ce99-1cf2-4004-a5d8-3feef7b55f11',
                label: 'Qualifying Response #2',
                _reactKey: 'b7fd4891-9b06-4dc5-b181-a4cd06907d0b'
              },
              {
                id: 'f6d8b113-4596-4133-96fb-0eab84aa0fda',
                label: 'Disqualifying Response #1',
                _reactKey: '61594304-3eef-40b1-9bc8-d0bb180b0d86'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'd5633f44-7519-4c28-88b7-b2880a48ada5',
            top: 1710,
            body: '<p>Sorry, but based on this info, it looks like we may not be a good fit for your team. But please Feel free to check out <a href="enter link here..." rel="noopener noreferrer" target="_blank">our resources</a> for useful insights on [<em>business value</em>]. We hope you find the info helpful!</p>',
            left: -840,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'bb14d270-13fb-4c5f-9b8e-5622b14778a4'
              }
            ]
          },
          {
            id: 'da69c06a-860c-4abb-a4be-dea3d6596af3',
            top: 2030,
            body: '',
            left: -840,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '8f05f498-926f-44a2-95bc-e2701be40cdc'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: '1896b9af-b4b4-4148-ba51-957a61bff683',
            top: 2270,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">What's the best email address to send your calendar invite?</span></p>",
            left: -2230,
            type: 'email',
            ports: [
              {
                id: 'invalid',
                _reactKey: 'c0379027-219e-40f5-be46-d4cdb774e763'
              },
              {
                id: 'valid',
                _reactKey: '2d5f8a38-7c4f-4be5-9f14-618dd1062000'
              }
            ],
            unwanted: {
              spam: true,
              blocked: true,
              personal: true
            },
            captureTimes: 3,
            secondResponse: "<p><span style=\"color: rgb(0, 0, 0);\">Last try; what's your </span>[<em>work</em>]<span style=\"color: rgb(0, 0, 0);\"> email address?</span></p>",
            invalidResponse: "<p>I don't recognize that as a valid email address. Please try again</p>",
            unwantedResponse: '<p>Please provide a valid business email</p>'
          },
          {
            id: 'a3b0e8b1-36cc-409c-9659-068633114a80',
            top: 3410,
            body: '',
            left: -5480,
            type: 'end_flow',
            ports: []
          },
          {
            id: '0886dfc8-88b0-4def-a7ab-e3b33bb5cbc2',
            top: 3060,
            body: '',
            left: -3730,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'fc39ec75-05cc-4c7e-9120-c3636d0f62aa',
            top: 2340,
            body: "<p>Looks like you're not ready to book a demo. In the meantime, Feel free to check out <a href=\"Enter link here...\" rel=\"noopener noreferrer\" target=\"_blank\">our resources</a> for useful insights on [<em>business value</em>].</p>",
            left: -1800,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '479f3ce8-f65b-46a3-8a41-89ee7838bd71'
              }
            ]
          },
          {
            id: 'e590bc4e-f7c9-47d9-89cc-07b3cc73f28c',
            top: 2640,
            body: '',
            left: -1800,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '96646d74-1d02-4bf7-a437-cd11c6d2e785'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: 'a8185590-770c-48a1-8890-37155201391d',
            top: 2440,
            body: '<p>Book Meeting: [<em>Team or Individual</em>]</p>',
            left: -2610,
            type: 'calendar',
            ports: [
              {
                id: 'complete',
                label: 'Meeting Scheduled',
                _reactKey: 'e0285178-ac02-4d33-9b3a-1465cc0bddb0'
              },
              {
                id: 'no_email',
                label: 'Email Unkown',
                _reactKey: 'd981c938-73f8-49b2-a057-485ae674299b'
              }
            ],
            method: 'round_robin_team',
            team_id: null,
            user_ids: [],
            route_type: '',
            meeting_type: null
          },
          {
            id: '52802a53-7cfe-4a1f-892a-c7e9faa346a0',
            top: 2780,
            body: '',
            left: -2610,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'e647d597-7013-407c-93c8-bc8208bca7fa',
            top: 2840,
            body: '',
            left: -1800,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'd24c0e65-6757-48a0-9279-6a0cd8f97f40',
            top: 2490,
            body: "<p>That's all!</p>",
            left: -3030,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'f38cb235-c105-4e94-b7d1-b93a4189ee0e'
              }
            ]
          },
          {
            id: '6a26f358-987b-4519-b731-a7bd80d17964',
            top: 2750,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">We're looking forward to your demo! Feel free to leave a message for the sales rep below.</span></p>",
            left: -3030,
            type: 'options',
            ports: [
              {
                id: 'response',
                label: 'Visitor Response',
                _reactKey: '117d1015-3543-453d-a70c-ec3b5120562e'
              }
            ],
            buttons: [],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'free_response',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: 'c35c3350-6d7e-4460-b517-b0cb6008f39b',
            top: 3050,
            body: "<p><span style=\"color: rgb(0, 0, 0);\">Great, I'll pass that along!</span></p>",
            left: -3030,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: 'ecab17a6-b975-4de0-8a03-4ba3646310f1'
              }
            ]
          },
          {
            id: 'b07e5735-1759-4b0c-971c-e9c7d16f2c23',
            top: 3360,
            body: '',
            left: -3030,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '8aa1e121-60d9-43b2-bbf1-2a2e7e6f5d23'
              }
            ],
            status: 'open',
            closedMessage: ''
          },
          {
            id: '8ea15fc7-1478-4741-b83d-ff5aaa77adce',
            top: 3660,
            body: '',
            left: -3030,
            type: 'end_flow',
            ports: []
          },
          {
            id: '7cd7186a-8e62-4cba-8066-779007f7f7ae',
            top: 2580,
            body: '',
            left: 430,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: 'c255f6df-cab2-4d14-b03f-545339be34d5'
              }
            ],
            status: 'open',
            closedMessage: ''
          },
          {
            id: '53be110a-58d9-45e1-9c1b-f425a094ce94',
            top: 2580,
            body: '',
            left: 810,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'd2bce55f-f07f-4fb3-b5f0-39b7ab65bad2',
            top: 2590,
            body: '',
            left: 1870,
            type: 'end_flow',
            ports: []
          },
          {
            id: '96335eec-1811-4d2a-a84c-92d2a27b9729',
            top: 2380,
            body: '',
            left: -840,
            type: 'end_flow',
            ports: []
          },
          {
            id: '9d365499-d539-499d-8951-f7e1c217fb9d',
            top: 2460,
            body: '',
            left: -4990,
            type: 'end_flow',
            ports: []
          },
          {
            id: 'd713e1da-0873-4467-a1ee-2dec150d0e01',
            top: 2260,
            body: '',
            left: -2730,
            type: 'end_flow',
            ports: []
          },
          {
            id: '5bcf3aff-5823-4d3a-8a82-6de078cb45f8',
            top: 1600,
            body: '',
            left: -260,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: 'c1c4f536-4cf1-4768-9efe-be71cd4d1ffe'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: 'a63719cf-81a9-46f7-8412-1f5aaf5c4ef5',
            top: 990,
            body: '<p>You can reach our support team by phone {phone number}, email {email}, or choose from the following:</p>',
            left: 2970,
            type: 'options',
            ports: [
              {
                id: 'a449e63c-0fb5-41ca-92b3-ca3fc380dd97',
                label: 'Ask a Question',
                _reactKey: '261b256c-1165-4b59-8a2f-79b0bcafa337'
              },
              {
                id: 'a6c19c7b-4ae2-41c9-8203-8f1326c06bc9',
                label: 'Submit a Ticket',
                _reactKey: 'f3b8fcd5-a8b8-4d17-b97c-45dfa763611b'
              }
            ],
            buttons: [
              {
                id: 'a449e63c-0fb5-41ca-92b3-ca3fc380dd97',
                label: 'Ask a Question',
                _reactKey: '261b256c-1165-4b59-8a2f-79b0bcafa337'
              },
              {
                id: 'a6c19c7b-4ae2-41c9-8203-8f1326c06bc9',
                label: 'Submit a Ticket',
                _reactKey: 'f3b8fcd5-a8b8-4d17-b97c-45dfa763611b'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: '15120cc1-840b-4dea-904e-a16353384ea3',
            top: 1220,
            body: '<p>Great. Please type your question below.</p>',
            left: 2620,
            tags: [],
            faqSource: 'cf-faq',
            categories: [],
            type: 'faq',
            ports: [
              {
                id: 'resolved',
                label: 'That answered my question.',
                _reactKey: '4f0cc7e8-fead-4bb4-9f79-db938f95c2e0'
              },
              {
                id: 'again',
                label: 'Let me ask again.'
              }
            ],
            retryMessage: '<p>Please keep it short and sweet.</p>',
            failureMessage: "<p>I'm sorry, I didn't understand your question. </p>"
          },
          {
            id: '4cc88639-3cca-4620-a733-0cad3dcd0913',
            top: 1250,
            body: '<p>Is there anything else I can help you with?</p>',
            left: 2170,
            type: 'options',
            ports: [
              {
                id: 'f173e772-4b66-4676-911c-bb6cbb750ec4',
                label: "That's all",
                _reactKey: 'f51c9f3c-8781-465f-ae67-5694cd132e2d'
              },
              {
                id: '47bb680b-d2e8-41a6-9963-334163cd0e56',
                label: 'Ask another question',
                _reactKey: 'b77a41d6-c4fd-4981-8544-ca059a552449'
              }
            ],
            buttons: [
              {
                id: 'f173e772-4b66-4676-911c-bb6cbb750ec4',
                label: "That's all",
                _reactKey: 'f51c9f3c-8781-465f-ae67-5694cd132e2d'
              },
              {
                id: '47bb680b-d2e8-41a6-9963-334163cd0e56',
                label: 'Ask another question',
                _reactKey: 'b77a41d6-c4fd-4981-8544-ca059a552449'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          },
          {
            id: '83e091a8-96a1-44f1-ae94-276e6ceac7a9',
            top: 1340,
            body: '<p>Thanks for chatting! Feel free to <a href="check out our resources" rel="noopener noreferrer" target="_blank">check out our resources</a> for useful insights on {business value}.</p>',
            left: 1830,
            type: 'chat',
            ports: [
              {
                id: 'default',
                _reactKey: '34ffab5d-6cbc-45c2-b6fd-4404e0d9f236'
              }
            ]
          },
          {
            id: '7bbc9147-4e71-4fec-afc0-56f3723ac526',
            top: 1570,
            body: '',
            left: 2030,
            type: 'conversation_status',
            ports: [
              {
                id: 'default',
                _reactKey: '95ab3f1f-df80-4b56-8030-3b292eb0f168'
              }
            ],
            status: 'auto-close',
            closedMessage: ''
          },
          {
            id: 'c07da9ae-cfc5-4707-897e-37acc95a4bcf',
            top: 1780,
            body: '',
            left: 2210,
            type: 'end_flow',
            ports: []
          },
          {
            id: '47e78335-8fac-48de-8ecf-cb4c529b823b',
            top: 1340,
            body: '<p>Alright, support requests can be<a href=" submitted here" rel="noopener noreferrer" target="_blank"> submitted here</a>.</p>',
            left: 3250,
            type: 'options',
            ports: [
              {
                id: '273fedb1-ba48-42f5-acbe-31727738a1bb',
                label: 'Try asking a question',
                _reactKey: '5ce1380d-99ad-4b31-9032-7374c2a355a4'
              }
            ],
            buttons: [
              {
                id: '273fedb1-ba48-42f5-acbe-31727738a1bb',
                label: 'Try asking a question',
                _reactKey: '5ce1380d-99ad-4b31-9032-7374c2a355a4'
              }
            ],
            captureTimes: 3,
            mapAttribute: false,
            responseType: 'buttons',
            keywordOptions: [],
            secondResponse: '',
            invalidResponse: ''
          }
        ]
      }
    },
    targeting: {
      triggers: [
        {
          cause: {
            kind: 'delay',
            value: 2
          },
          enabled: true,
          filters: [
            {
              cmp: 'is',
              prop: 'url',
              value: 'Enter your Homepage URL...'
            }
          ]
        }
      ],
      trigger_on: 'selected_criteria',
      use_triggers: false
    },
    frequency: 'session',
    template: {
      kind: 'flow',
      teams: [
        {
          away: 'Sorry, no one is available to chat right now',
          label: 'Click here',
          method: 'single_agent',
          team_id: null,
          welcome: 'Routing you to an agent now',
          user_ids: [],
          route_method: 'round_robin'
        }
      ],
      bot_delay: 0,
      url_matches: '',
      welcome_message: 'Welcome to our site!',
      availability_criteria: 'both'
    },
    prompt_style: 'big'
  }
}

export default homepageFAQFlow
