/* eslint-disable @typescript-eslint/naming-convention */
import { Filter, Segment, SegmentFilter, SegmentFilterValue } from 'classes/segments';
import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { cloneDeep, values } from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import { filterSelectorMapping } from './SegmentCriteriaButton';
import { formatRevenue } from 'helpers/numbers';
import { Icon } from 'library/materialUI/Icon';
import { User } from 'classes/users';

const useStyles = makeStyles(theme => ({
  filterBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    maxWidth: 550,
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'f20lex',
    alignItems: 'center',
    marginTop: 5,
    color: theme.palette.primary.main
  },
  addBtn: {
    backgroundColor: '#EDE5F8',
    fontSize: 20,
    minWidth: 38,
    maxWidth: 38,
    height: '65%',
    padding: '5px 10px',
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 5,
    color: theme.palette.primary.main
  },
  andText: {
    backgroundColor: '#EEEEEE',
    fontSize: 15,
    minWidth: 38,
    minHeight: 33,
    padding: '0px 2px',
    borderRadius: 5,
    marginTop: 5,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

interface DynamicSegmentContextInterface {
  triggers: Segment['triggers']
  saveTriggers: (triggers: Segment['triggers']) => void
  editFilter: (groupIndex: number, idx: number, filter: any) => void
  determineActionButton: (groupIndex: number, filters: Filter[], currentIndex: number) => JSX.Element
  handleDeleteTrigger: (groupIndex: number, index: number) => void
  getStartingBtnValue: (segmentTrigger: Filter) => void
  setTriggers: Dispatch<SetStateAction<SegmentFilter[]>>
  setAlternateLabels: (alternateLabels: string[]) => void
  setAlternateProps: (alternateProps: string[]) => void
  resetTriggers: () => void
}

export const DynamicSegmentContext = createContext<DynamicSegmentContextInterface>({
  triggers: [{ filters: [] }],
  saveTriggers: () => undefined,
  editFilter: () => undefined,
  determineActionButton: () => <></>,
  handleDeleteTrigger: () => undefined,
  getStartingBtnValue: () => undefined,
  setTriggers: () => undefined,
  setAlternateLabels: () => undefined,
  setAlternateProps: () => undefined,
  resetTriggers: () => undefined
})

interface DynamicSegmentProviderProps {
  segment: Segment
  save: (values: {
    triggers: Segment['triggers']
  }) => void
  children: JSX.Element | JSX.Element[]
  initialSegment?: Segment
}

const compareMapping: Record<string, string> = {
  not_in: 'is not',
  eq: 'is',
  in: 'is',
  ne: 'is not',
  gt: 'is greater than',
  lt: 'is less than',
  'jsonb intersect': 'includes',
  'jsonb contains': 'contains'
}

const getPrefix = (label: string) => {
  // Split camel case and capitalize first letter
  return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str: string) { return str.toUpperCase(); })
}

const generateLabel = (cmp: string, value: SegmentFilterValue, prop: string, users: User[], alternateLabels: string[] = [], alternateProps: string[] = []) => {
  let label = prop
  if (label.includes('crm_txt_field') || label.includes('crm_num_field')) {
    alternateProps.forEach((_, index) => {
      if (prop === alternateProps[index]) {
        label = alternateLabels[index]
      }
    })
  } else {
    Object.keys(filterSelectorMapping).forEach((k) => {
      if (prop === filterSelectorMapping[k].filter.prop) {
        label = filterSelectorMapping[k].option.label
      }
    })
  }
  const prefix = getPrefix(label)
  const compareText = cmp in compareMapping ? compareMapping[cmp] : cmp
  let btnLabel = prefix + ' ' + compareText + ' '

  if (Array.isArray(value)) {
    if (cmp === 'between') {
      value.map((string: any, index: number) => {
        if (index <= 1) {
          let newString = string
          if (prop === 'annual_revenue') { newString = formatRevenue(string).toString() }
          btnLabel = btnLabel + newString + ' and '
        } return null
      })
    } else if (prop === 'owner_user_id') {
      const selectedUserNames: string[] = []
      users.map(user => { if ((value as number[]).includes(user.id as number)) { selectedUserNames.push(user.name) } return null })
      let userString = ''
      selectedUserNames.map((userName, index) => { if (index < 2) { userString = userString.concat(userName + ' or ') } return null })
      btnLabel = btnLabel + userString
    } else if (cmp === 'jsonb contains') {
      value.map((string: any, index: number) => { if (index <= 1) { btnLabel = btnLabel + string + ' and ' } return null })
    } else {
      value.map((string: any, index: number) => { if (index <= 1) { btnLabel = btnLabel + string + ' or ' } return null })
    }
    btnLabel = btnLabel.slice(0, -4)
    if (value.length > 2) {
      const moreText = ` and ${value.length - 2} more`
      btnLabel = btnLabel + moreText
    }
  } else if (prop === 'number') {
    const valueToStr = (!!value).toString()
    btnLabel = btnLabel + valueToStr
  } else if (prop === 'is_crm_account') {
    let infix = 'IS'
    if (cmp !== 'eq') {
      infix += ' NOT'
    }
    btnLabel = 'COMPANY ' + infix + ' FROM CRM'
  } else {
    btnLabel = btnLabel + value
  }
  return btnLabel
}

export function DynamicSegmentProvider ({ segment, save, children, initialSegment }: DynamicSegmentProviderProps) {
  const [triggers, setTriggers] = useState(cloneDeep(segment.triggers))
  const [alternateLabels, setAlternateLabels] = useState<string[]>([])
  const [alternateProps, setAlternateProps] = useState<string[]>([])
  const classes = useStyles()
  const { data: users } = User.loadAll()

  useMemo(() => {
    triggers.forEach((segmentFilter) => {
      if (segmentFilter.filters.length === 0) {
        segmentFilter.filters.push({ cmp: '', prop: '', value: '' })
      }
    })
  }, [triggers])

  const andLabel = (
    <div className={classes.andText}>and</div>
  )

  const getStartingBtnValue = (segmentTrigger: Filter) => {
    if (segmentTrigger.cmp !== '') {
      return { text: generateLabel(segmentTrigger.cmp, segmentTrigger.value, segmentTrigger.prop, users.list, alternateLabels, alternateProps), startIcon: null, endIcon: <Icon icon='close' />, class: classes.filterBtn }
    }
    return { text: 'ADD FILTER CRITERIA', startIcon: <Icon icon='add' color='primary' />, endIcon: null, class: classes.filterBtn }
  }

  const handleAddTrigger = (groupIndex: number) => {
    const newTriggers = [...triggers]
    newTriggers[groupIndex].filters.push({ cmp: '', value: '', prop: '' })
    setTriggers(newTriggers)
  }

  const saveTriggers = (triggers: Segment['triggers']) => {
    save({ triggers: triggers })
  }

  const handleEditTrigger = (groupIndex: number, index: number, filter: Filter) => {
    const newTriggers = [...triggers]
    newTriggers[groupIndex].filters[index] = filter
    setTriggers(newTriggers)
  }

  const handleDeleteTrigger = (groupIndex: number, index: number) => {
    const newTriggers = [...triggers]
    newTriggers[groupIndex].filters.splice(index, 1)
    newTriggers[groupIndex].filters = [...newTriggers[groupIndex].filters]
    setTriggers(newTriggers)
  }

  const editFilter = (groupIndex: number, idx: number, filter: any) => {
    handleEditTrigger(groupIndex, idx, { cmp: filter.cmp, value: filter.value, prop: filter.prop })
  }

  const determineActionButton = (groupIndex: number, filters: Filter[], currentIndex: number) => {
    const isLastElement = currentIndex === filters.length - 1
    const filterIsGood = !values(filters[currentIndex]).includes('')
    const nextFilterIsGood = isLastElement ? false : !values(filters[currentIndex + 1]).includes('')
    if (filterIsGood && isLastElement) {
      handleAddTrigger(groupIndex);
    }
    if (!isLastElement && nextFilterIsGood) {
      return andLabel
    }

    return <></>
  }

  const resetTriggers = () => {
    if (initialSegment) {
      setTriggers(cloneDeep(initialSegment.triggers) || [{ filters: [] }])
    }
  }

  const contextValues: DynamicSegmentContextInterface = {
    triggers,
    saveTriggers,
    editFilter,
    determineActionButton,
    handleDeleteTrigger,
    getStartingBtnValue,
    setTriggers,
    setAlternateLabels,
    setAlternateProps,
    resetTriggers
  }

  return (
    <DynamicSegmentContext.Provider value={contextValues}>
      {children}
    </DynamicSegmentContext.Provider>
  )
}
