import { doGet } from './api'

const getUserFollows = () => {
  return doGet({
    path: '/follows',
    useChatURL: true
  })
  // const method = 'GET'

  // return fetch(`${chatServiceUrl}/follows`, {
  //   method: method,
  //   headers: headers
  // }).then((response) => response.json())
}

export { getUserFollows }
