import { doDelete, doGet, doPost, doPatch } from './api'

function getBillingSubscription (sync: number) {
  const syncValue = sync || 0
  return doGet({
    path: `/api/v2/billing/subscription?force_sync=${syncValue}`
  })
}

function getTenantBillingSubscription (sync: number, tenantID: number) {
  const syncValue = sync || 0

  return doGet({
    path: `/api/v2/billing/subscription/${tenantID}?sync=${syncValue}`
  })
}

function cancelSubscription () {
  return doPost({ path: '/api/v2/billing/subscription/cancel' })
}

function resumeSubscription () {
  return doPost({ path: '/api/v2/billing/subscription/resume' })
}

const getInvoices = () => {
  return doGet({ path: '/api/v2/billing/invoices' })
}

function downloadInvoice (invoiceId: string) {
  const url = `/api/v2/billing/invoices/${invoiceId}`
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/pdf' }
  })
    .then(response => response.blob())
}

function getComponentUsage () {
  return doGet({ path: '/api/v2/billing/usage' })
}

function getRenewalPreview ({ adjustments }: { adjustments: Record<string, any>[] }) {
  const items = adjustments.map((a) => { return { attributes: { component: a.component, qty: a.qty } } })
  return doPost({
    path: '/api/v2/billing/preview_renewal', data: items
  })
}

function getIntegrationUsage () {
  return doGet({ path: '/api/integrations' })
}

function getPaymentProfiles () {
  return doGet({ path: '/api/v2/billing/payment_profiles' })
}

function addCreditCard (token: string) {
  return doPost({
    path: '/api/v2/billing/payment_profiles',
    data: {
      type: 'credit_card',
      attributes: {
        token: token
      }
    }
  })
}

function swapPaymentProfile (profileId: string) {
  return doPost({
    path: '/api/v2/billing/payment_profiles/default',
    data: {
      attributes: {
        payment_profile_id: profileId
      }
    }
  })
}

function removePaymentProfile (profileId: string) {
  return doDelete({
    path: '/api/v2/billing/payment_profiles',
    data: {
      attributes: {
        payment_profile_id: profileId
      }
    }
  })
}

function getOffers () {
  return doGet({
    path: '/api/v2/billing/offers'
  })
}

function purchaseOffer (offerId: string, paymentProfileId: string) {
  return doPost({
    path: '/api/v2/billing/offers/purchase',
    data: {
      attributes: {
        offer_id: offerId,
        payment_profile_id: paymentProfileId
      }
    }
  })
}

function getOfferPreview (offerId: string, paymentProfileId: string) {
  return doPost({
    path: '/api/v2/billing/offers',
    data: {
      attributes: {
        offer_id: offerId,
        payment_profile_id: paymentProfileId
      }
    }
  })
}

function previewLicenseAdjustment (component: string, quantity: number) {
  return doPost({
    path: `/api/v2/billing/components/${component}/preview_allocation`,
    data: { attributes: { quantity } }
  })
}
function adjustLicenseQuantity (component: string, quantity: number, append: boolean) {
  return doPost({
    path: `/api/v2/billing/components/${component}/adjust_allocation`,
    data: { attributes: { quantity, append } }
  })
}

function previewQuantityLimit (component_name: string, quantity: number) {
  return doGet({
    path: `/api/v2/billing/quantity_limits?component_name=${component_name}&quantity=${quantity}`
  })
}

function getBillingSettings () {
  return doGet({ path: '/api/v2/billing/settings' })
}

function setBillingSettings (settings: Record<string, any>) {
  return doPatch({ path: '/api/v2/billing/settings', data: { attributes: settings } })
}

function getSubscriptionComponents () {
  return doGet({ path: '/api/v2/billing/components' })
}
function hasComponent (handle: string) {
  return getSubscriptionComponents().then((result) => {
    // TODO: update this to use tenant plan
    return result.data.find(
      (component: any) => {
        const qty = component?.attributes?.quantity || 0
        const addonQty = component?.attributes?.addon_quantity || 0
        return component?.attributes?.name === handle &&
          qty + addonQty > 0
      }) || false
  })
}
function getComponentOptions (component: string) {
  return doGet({ path: `/api/v2/billing/components/${component}/allocations/options` })
}

async function getPlanLimits () {
  return doGet({ path: '/api/core/plan/limits' })
}

function getNeededIPLookups (contactLookups: number) {
  return doGet({ path: `/api/core/lookups_needed/${contactLookups}` })
}

function sendCancelledEmail ({ reason, otherReason, comments, tenantDomain }: { reason: string, otherReason: string, comments: string, tenantDomain: any }) {
  return doPost({
    path: '/admin_portal/cancelled',
    data: {
      attributes: {
        reason: reason,
        other_reason: otherReason,
        comments: comments,
        customer: tenantDomain
      }
    },
    useChatURL: true
  })
}

function updateToInsights ({ ipCredits, contactLookups, userCount }: { ipCredits: number, contactLookups: number, userCount: number }) {
  return doPatch({
    path: '/api/v2/billing/subscription',
    data: {
      attributes: {
        upgrade_plan_name: 'insights',
        ip_lookups: ipCredits,
        contact_lookups: contactLookups,
        user_quantity: userCount,
        contact_lookups_plus: false
      }
    }
  })
}

function subscriptionHasPayment () {
  return doGet({ path: '/api/v2/billing/subscription/profile/payment' })
}

export {
  addCreditCard,
  cancelSubscription,
  downloadInvoice,
  getBillingSubscription,
  getInvoices,
  getOfferPreview,
  getOffers,
  getPaymentProfiles,
  getSubscriptionComponents,
  hasComponent,
  previewQuantityLimit,
  purchaseOffer,
  removePaymentProfile,
  resumeSubscription,
  swapPaymentProfile,
  getComponentUsage,
  getTenantBillingSubscription,
  getIntegrationUsage,
  previewLicenseAdjustment,
  adjustLicenseQuantity,
  getRenewalPreview,
  getPlanLimits,
  getBillingSettings,
  setBillingSettings,
  getComponentOptions,
  getNeededIPLookups,
  sendCancelledEmail,
  updateToInsights,
  subscriptionHasPayment
}
