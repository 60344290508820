import { doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getToken ({ authHeader }) {
  let newHeaders = headers
  if (authHeader) {
    newHeaders = { ...headers, ...authHeader }
  }
  return fetch('/api/auth/token', {
    method: 'GET',
    headers: newHeaders
  })
    .then(response => response.json())
}

function checkEmailAvailability (email) {
  return fetch('/api/auth/email_availability', {
    method: 'POST',
    headers: { 'Content-Type': 'application/vnd.api+json' },
    body: JSON.stringify({ email: email })
  })
    .then((response) => response.json())
}

function signup (full_name, email, phone, ignoreHeader, options) {
  let headers = { 'Content-Type': 'application/vnd.api+json' }
  if (ignoreHeader) {
    headers = {
      'Content-Type': 'application/vnd.api+json',
      'X-Ignore-Org': true
    }
  }
  return fetch('/api/v2/auth/signup', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        attributes: {
          full_name: full_name,
          email: email,
          phone: phone,
          password: options.password || '',
          dev_registration: options.dev_registration || false,
          send_verification_email: options.send_verification_email || true
        }
      }
    })
  })
    .then(response => response.json())
    .catch(() => ({
      errors: [{ title: 'An unexpected error occured trying create an account' }]
    }))
}

function requestToJoin (fullName, email, password, tenantID) {
  return doPost({
    path: '/v2/auth/request',
    data: {
      attributes: {
        full_name: fullName,
        email: email,
        password: password,
        tenant_id: tenantID
      }
    }
  })
}

function resendVerificationEmail (email) {
  return fetch('/api/auth/email_verification:resend', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        attributes: {
          email: email
        }
      }
    })
  })
    .then(response => response.json())
}

export { getToken, resendVerificationEmail, signup, checkEmailAvailability, requestToJoin }
