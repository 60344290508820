import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TestIcon from '@material-ui/icons/DriveEta'
import SaveIcon from '@material-ui/icons/Save'
import HistoryIcon from '@material-ui/icons/History'
import { Modal } from 'library/Modal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import dateFormat from 'dateformat'
import RestoreIcon from '@material-ui/icons/Restore'
import VisibilityIcon from '@material-ui/icons/Visibility'
import FlowPreviewModal from './FlowPreviewModal'
import { flowSearch, restoreArchivedBot } from 'api/bots'
import { sequenceFlowSearch } from 'api/sequences'
import { getUsers } from 'api/users'
import { getSettings } from 'api/settings'
import { ReactComponent as TestingBeakerIcon } from 'img/testing_beaker_icon.svg'
import ActionsMenu from 'cf-components/ActionsMenu'
import theme from 'theme'
import { ThemeProvider } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles(theme => ({
  menuBar: {
    height: 37,
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    marginRight: 10
  },
  modalContents: {
    maxHeight: 500
  }
}))

function VersionRow (props) {
  const [hover, setHover] = useState(false)
  const row = props.row
  let style = {}
  if (props.active) {
    style = {
      backgroundColor: 'rgba(193, 166, 237, 0.2)'
    }
  }

  const actions = [
    { name: 'Preview', action: () => props.setPreviewFlowID(row.id), icon: VisibilityIcon },
    { name: 'Restore', action: () => props.loadFlow(row.id), icon: RestoreIcon }
  ]

  return (
    <TableRow
      hover
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={style}
    >
      <TableCell>{props.number}</TableCell>
      <TableCell>{row.modifiedTimestamp}</TableCell>
      <TableCell>{row.modifiedBy}</TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0, width: 100 }} align='right'>
        {!props.active &&
          <ActionsMenu
            hover={hover}
            actions={actions}
          />}
      </TableCell>
    </TableRow>
  )
}

function VersionTable (props) {
  const count = props.rows.length

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell>Modified Date</TableCell>
          <TableCell>Modified By</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, index) => {
          const number = count - index
          const active = row.id === props.currentFlowID
          return (
            <VersionRow
              number={number}
              row={row}
              key={index}
              loadFlow={props.loadFlow}
              active={active}
              setPreviewFlowID={props.setPreviewFlowID}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

function VersionHistoryModal (props) {
  const [flows, setFlows] = useState([])
  const [users, setUsers] = useState([])
  const [previewFlowID, setPreviewFlowID] = useState(0)
  const classes = useStyles()
  const sequence = !!props.sequenceID

  useEffect(() => {
    const botID = props.botID
    const sequenceID = props.sequenceID
    if (botID) {
      flowSearch({ botID })
        .then(response => {
          setFlows(response.data)
        })
    } else if (sequenceID) {
      sequenceFlowSearch({ sequenceID })
        .then(response => {
          setFlows(response.data)
        })
    }

    getUsers()
      .then(response => {
        let userDict = {}
        response.data.map(
          user => (userDict = { ...userDict, [user.id]: user.attributes })
        )
        setUsers(userDict)
      })
  }, [props.botID, props.sequenceID])

  if (!(Object.keys(users)?.length && flows?.length)) {
    return (<></>)
  }

  const rows = flows.map(flow => {
    let name = 'Unknown User'
    const id = flow.relationships.created_by.data.id
    if (id in users) { name = users[id].name }
    return ({
      id: flow.id,
      modifiedTimestamp: dateFormat(flow.attributes.created_timestamp, 'm/d/yyyy h:MM TT'),
      modifiedBy: name
    })
  })

  function restoreFlow () {
    props.loadFlow(previewFlowID)
    props.onHide()
    setPreviewFlowID(0)
  }

  return (
    <>
      <Modal
        title='Version History'
        onHide={props.onHide}
        open={props.open}
        hideSaveButton
        handleSave={() => null}
        cancelBtnText='Close'
        size='lg'
      >
        <div className={classes.modalContents}>
          <VersionTable
            rows={rows}
            loadFlow={props.loadFlow}
            currentFlowID={props.currentFlowID}
            setPreviewFlowID={setPreviewFlowID}
          />
        </div>
      </Modal>
      <FlowPreviewModal
        open={previewFlowID > 0}
        close={() => setPreviewFlowID(0)}
        flowID={previewFlowID}
        restoreFlow={restoreFlow}
        sequence={sequence}
      />
    </>
  )
}

function DangerModal (props) {
  const handleSubmit = props.onSubmit
  return (
    <Modal
      title='Warning'
      onHide={props.onHide}
      open={props.open}
      saveBtnText={props.saveText}
      closeBtnFunction={props.onBack}
      cancelBtnText={props.backText}
      handleSave={handleSubmit}
      size='xs'
    >
      <div>
        {props.message}
      </div>
    </Modal>
  )
}

function ArchiveButton (props) {
  const botID = props.botID
  const chatServiceUrl = props.chatServiceUrl
  const [archived, setArchived] = useState(props.archived)

  function beginRestore () {
    restoreArchivedBot({ bot_id: botID, chatServiceUrl }).then(() => {
      setArchived(false)
    }
    )
  }

  if (!archived) {
    return (
      <Button
        startIcon={<DeleteIcon />}
        color='primary'
        onClick={props.handleArchiveModal}
      >
        Archive Bot
      </Button>
    )
  }
  return (
    <Button
      startIcon={<RestoreIcon />}
      color='primary'
      onClick={beginRestore}
    >
      Restore Bot
    </Button>
  )
}

function TopMenuBar (props) {
  const classes = useStyles()
  const [testing, setTesting] = useState(false)
  const [chatSettings, setChatSettings] = useState(null)
  const [botDriveWarningModalOpen, setBotDriveWarningModalOpen] = useState(false)
  const setUnavailableBot = props.setUnavailableBot
  const setAvailableBot = props.setAvailableBot

  let chatServiceUrl = props.chatServiceUrl
  if (chatServiceUrl.startsWith('/')) {
    chatServiceUrl = window.location.host + chatServiceUrl
  }
  const appUrl = window.location.origin + '/chat-client/index.html'
  const srcUrl = window.location.origin + '/chat-client/chat-loader.js'
  const packetCode = chatSettings?.attributes.packet_code
  const botID = parseInt(props.botID)
  const handleSave = props.initiateSave
  const handleVersionModal = props.showVersionHistory
  const handleArchiveModal = props.showArchiveModal
  const handleCreateTest = props.createTest
  const testButtonDisabled = (Boolean(props.activeTest) || Boolean(!props.disabled))
  const script = `
      <body>
        <script>
          function cf_chat_loader() {     
            return {         
              packet_code: "${packetCode}",         
              app_url: "${appUrl}",         
              service_address: "${chatServiceUrl}",
              preventCriteriaPrompts: true,
              mode: "test-drive",
              appLoaded: (cf, chatEl) => {
                cf._internal.previewBot(${botID})
              }    
            } 
          } 
          (function() {     
            var el = document.createElement('script');     
            el.async = true;     
            el.src = "${srcUrl}";     
            window.document.body.appendChild(el); 
          }());
        </script>
      </body>`

  useEffect(() => {
    getSettings()
      .then(response => {
        setChatSettings(response.data)
        setUnavailableBot(response.data.attributes.out_office_greeting_bot_id === botID)
        setAvailableBot(response.data.attributes.in_office_greeting_bot_id === botID)
      })
  }, [botID, setAvailableBot, setUnavailableBot])

  const handleTest = () => {
    if (!props.disabled) {
      setBotDriveWarningModalOpen(true)
    } else {
      openPopUpTestDrive()
    }
  }

  const openPopUpTestDrive = () => {
    setTesting(true)
    const left = window.screenX + 50
    const top = window.screenTop + 50
    const popUp = window.open('', 'Bot Preview', `width=405,height=705,top=${top},left=${left}`)
    popUp.document.write(script)
    popUp.document.title = 'Bot Preview'
    popUp.onbeforeunload = () => setTesting(false)
  }

  return (
    <ThemeProvider
      theme={theme}
    >
      <div className={classes.menuBar}>
        <ArchiveButton
          botID={botID}
          handleArchiveModal={handleArchiveModal}
          chatServiceUrl={chatServiceUrl}
          archived={props.archived}
        />
        <Button
          startIcon={<TestingBeakerIcon style={{ fill: testButtonDisabled ? '#CCC' : '#343434' }} />}
          color='primary'
          // className={classes.button}
          style={{ alignItems: 'flex-end' }}
          onClick={handleCreateTest}
          disabled={testButtonDisabled}
        >
          START AN A/B TEST
        </Button>
        <Button
          startIcon={<HistoryIcon />}
          color='primary'
          className={classes.button}
          onClick={handleVersionModal}
          disabled={Boolean(props.activeTest)}
        >
          Version History
        </Button>
        <Button
          startIcon={<TestIcon />}
          color='primary'
          className={classes.button}
          onClick={handleTest}
          disabled={testing}
        >
          Test Drive Bot
        </Button>
        <Button
          startIcon={<SaveIcon />}
          color='primary'
          className={classes.button}
          onClick={handleSave}
          disabled={props.saveDisabled}
        >
          Save
        </Button>
        <DangerModal
          saveText='Test Drive Last Saved Bot'
          open={botDriveWarningModalOpen}
          onSubmit={() => {
            openPopUpTestDrive()
            setBotDriveWarningModalOpen(false)
          }}
          message='Your changes will not be visible on the test drive until you save your changes to the current bot.'
          onBack={() => setBotDriveWarningModalOpen(false)}
          onHide={() => setBotDriveWarningModalOpen(false)}
        />
      </div>
    </ThemeProvider>
  )
}

export { TopMenuBar, DangerModal, VersionHistoryModal }
