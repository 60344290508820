import React, { useState } from 'react';
import { FilterComponentProps } from 'library/table/FilterButton'
import { LabeledCheckbox } from 'library/materialUI';

const allBotTypes = [
  {
    value: 'abe_greeting',
    title: 'ABE Greeting'
  },
  {
    value: 'content_page',
    title: 'Interactive Landing Page'
  },
  {
    value: 'flow',
    title: 'Custom Bot'
  },
  {
    value: 'team_live_chat_router',
    title: 'Live Chat Router Bot'
  },
  {
    value: 'welcome',
    title: 'Welcome Bot'
  },
  {
    value: 'ai',
    title: 'BDR AI Agent'
  }
]

function BotTypeFilterValue (value: string) {
  const formattedObject: {[key: string]: {name: string, value: string}} = {}
  for (const botType of allBotTypes) {
    formattedObject[botType.title] = { name: botType.title, value: botType.value }
  }
  return formattedObject[value]?.name;
}

function BotTypeFilter (props: FilterComponentProps) {
  const [selected, setSelected] = useState(props.initialValues || []);

  const toggleSelection = (botTypeValue: string) => {
    if (selected.includes(botTypeValue)) {
      setSelected(selected.filter((type: string) => type !== botTypeValue));
    } else {
      setSelected([...selected, botTypeValue]);
    }
  };

  props.filterRef.current = selected.length ? { field: 'kind', operator: 'in', value: selected } : null;

  return (
    <div>
      {allBotTypes.map((botType, index) => (
        <div key={index}>
          <LabeledCheckbox
            checked={selected.includes(botType.title)}
            onChange={() => toggleSelection(botType.title)}
            label={botType.title}
          />
        </div>
      ))}
    </div>
  );
}

  export const filterOptions = {
    botType: { title: 'Bot Type', menu: BotTypeFilter, field: 'kind', filterValue: BotTypeFilterValue }
  }
