import { AITrainingData, getTrainingData, saveTrainingData } from 'api/ai_settings'
import { getConversationEvents } from 'api/conversations'
import { Modal, ModalSection } from 'library/Modal'
import Loader from 'library/loading/Loader'
import { Button, TextField } from 'library/materialUI'
import { useState, useEffect } from 'react'

interface CoachingModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  trainingData: AITrainingData
  participantID?: number
  onSave?: () => void
}

export default function CoachingModal ({ open, setOpen, trainingData, onSave, participantID }: CoachingModalProps) {
  const [file, setFile] = useState<File | null>(null)
  const [fileUploading, setFileUploading] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [coachingText, setCoachingText] = useState(trainingData.training)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileUploading(true)
      setFile(event.target.files[0])
    }
  }

  const handleFileUpload = async () => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const result = event.target?.result
        if (typeof result === 'string') {
          setCoachingText(result)
          setCanSave(true)
        }
        setFileUploading(false)
      }
      reader.readAsText(file)
    }
  }

  const handleSave = () => {
    saveTrainingData(
      trainingData
    ).then(() => {
        setFile(null)
        setOpen(false)
        if (onSave) {
          onSave()
        }
        setCoachingText('')
      })
      .catch(err => {
        console.error('Error saving training data:', err)
      })
  }

  useEffect(() => {
    if (file) {
      handleFileUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    trainingData.training = coachingText
    setCanSave(true)
    // eslint-disable-next-line
  }, [coachingText])

  useEffect(() => {
    if (trainingData.id) {
      getTrainingData(trainingData.id).then(response => {
        if (response.data) {
          trainingData.bot_id = response.data.attributes.bot_id
          trainingData.conversation_id = response.data.attributes.conversation_id
          trainingData.training = response.data.attributes.training
          trainingData.conversation_context = response.data.attributes.conversation_context
          setCoachingText(response.data.attributes.training)
        }
      })
    }
    // eslint-disable-next-line
  }, [trainingData.id])

  useEffect(() => {
    if (trainingData.conversation_id && participantID) {
        getConversationEvents({ conversationID: trainingData.conversation_id }).then(response => {
        if (response.data) {
          let newContext = ''
          for (const conversationEvent of response.data) {
            if (conversationEvent.attributes.kind !== 'chat') {
              continue
            }
            if (conversationEvent.relationships.from_participant.data.id === participantID) {
              newContext += `{role: 'user', content: '${conversationEvent.attributes.body}'},`
            } else {
              newContext += `{role: 'assistant', content: '${conversationEvent.attributes.body}'},`
            }
          }
          trainingData.conversation_context = newContext
        }
      })
    }
    // eslint-disable-next-line
  }, [trainingData.conversation_id, participantID])

  return (
    <Modal
      open={open}
      title='Add AI Coaching'
      onHide={() => {
        setOpen(false)
        setFile(null)
        setCoachingText('')
      }}
      handleSave={handleSave}
      saveBtnText='Save Coaching'
      saveDisabled={!canSave}
      saveIcon='save'
    >
      <ModalSection
        subtitle='Enter or upload text that you want to use to coach your AI model. Such as tone corrections, FAQs, product information, URLs of Pages you want to prioritize, etc.'
      >
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <input
            type='file'
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id='upload-file-input'
            accept='.txt'
          />
          <label htmlFor='upload-file-input'>
            <Button variant='outlined' size='medium' component='span'>
              Choose File
            </Button>
          </label>
          {fileUploading && (
            <div style={{ marginLeft: '10px' }}>
              <Loader size='sm' type='spinner' title='Reading file...' />
            </div>
          )}
        </div>

        {/* Text area for manual editing */}
        <TextField
          variant='outlined'
          multiline
          maxRows={10}
          value={coachingText}
          onChange={(e) => {
            setCoachingText(e.target.value)
            setCanSave(true)
          }}
          fullWidth
        />
      </ModalSection>
    </Modal>
  )
}
