import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MappingRow from './MappingRow'
import Button from '@material-ui/core/Button'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { getCustomFields } from 'api/custom_fields'
import { Icon, Tooltip } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  bodyText: {
    marginBottom: 40,
    fontSize: 16
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowHeader: {
    alignItems: 'center',
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 210px auto 210px 150px',
    gridGap: 10,
    gridTemplateAreas: '"a cf b integration c"',
    maxWidth: 1100
  },
  rowHeaderExtra: {
    alignItems: 'center',
    paddingBottom: 20,
    fontSize: 16,
    display: 'grid',
    gridTemplateColumns: '48px 210px auto 210px 150px 210px',
    gridGap: 10,
    gridTemplateAreas: '"a cf b integration c defaultField"',
    maxWidth: 1320
  }
}))

/* eslint-disable-next-line */
const tracking_parameters = [
  'first_utm_source',
  'current_utm_source',
  'first_utm_medium',
  'current_utm_medium',
  'first_utm_campaign',
  'current_utm_campaign',
  'first_utm_term',
  'current_utm_term',
  'first_utm_content',
  'current_utm_content',
  'first_gclid',
  'current_gclid',
  'first_gclsrc',
  'current_gclsrc',
  'first_dclid',
  'current_dclid',
  'first_fbclid',
  'current_fbclid',
  'first_zanpid',
  'current_zanpid',
  'full_tracking_string',
  'first_utm_campid',
  'first_campid',
  'start_url',
  'simple_start_url',
  'first_referrer',
  'current_referrer'
]

const webEngagement = [
  'page_views',
  'site_visits',
  'unique_visitors',
  'last_visit_date',
  'first_visit_date',
  'bot_conversations',
  'live_chats',
  'form_submissions',
  'calendar_drops',
  'meetings_booked',
  'high_intent_page',
  'content_page',
  'conversation_tags'
]

const emailEngagement = [
  'email_opens',
  'email_clicks'
]

interface IntergraionMappingProps {
  currentMapping: any
  externalMappingFields: any
  forObject: string
  integrationName?: string
  title?: string
  handleEdit: (mapping: any) => void
  disableOverwrite?: any
  mappedGroups: any
  objectType?: string
  defaultFieldEnabled?: boolean
  focusMapping?: any
}

const IntegrationMapping = ({ currentMapping, externalMappingFields, forObject, integrationName = 'Integration', title = 'Field Mapping', handleEdit, disableOverwrite, mappedGroups, defaultFieldEnabled = false, focusMapping = false }: IntergraionMappingProps) => {
  const classes = useStyles()
  const [mapping, setMapping] = useState(currentMapping || [])
  const [customFields, setCustomFields] = useState([])

  const editMapping = ({ field, value, index }: { field: string, value: any, index: number }) => {
    if (field === 'internal_field') {
      let type = 'cf_field'
      if (value === 'custom_value') {
        type = 'static'
      } else if (/\d/.test(value)) {
        type = 'custom_field'
      } else if (tracking_parameters.includes(value)) {
        type = 'tracking_parameters'
      } else if (webEngagement.includes(value)) {
        type = 'web_engagement'
      } else if (emailEngagement.includes(value)) {
        type = 'email_engagement'
      }
      setMapping([...mapping], mapping[index].type = type, mapping[index][field] = value)
    } else {
      setMapping([...mapping], (mapping[index][field] = value))
    }
    handleEdit(mapping)
  }

  /* eslint-disable */
  const addMapping = () => {
    mapping.push({
      internal_field: '',
      external_field: '',
      object_type: '',
      data_type: '',
      overwrite: false,
      type: ''
    })
    handleEdit(mapping)
  }
  /* eslint-enable */

  const deleteMapping = (index: number) => {
    mapping.splice(index, 1)
    handleEdit(mapping)
  }

  useEffect(() => {
    getCustomFields(forObject).then((response) => {
      const fields = response.data.map((field: Record<any, any>) => ({
        name: field.attributes.label,
        value: field.id,
        group: 'Custom Fields'
      }))
      setCustomFields(fields)
    })
  }, [forObject])

  useEffect(() => {
    if (currentMapping && currentMapping.length > 0) {
      setMapping(currentMapping)
    }
  }, [currentMapping])

  useEffect(() => {
    if (focusMapping) {
      const index = mapping.findIndex(item => item.internal_field === 'signals_score');
      if (index !== -1) {
        document.getElementById(`mapping-row-${index}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      } else {
        /* eslint-disable */
        const newMapping = {
          internal_field: 'signals_score',
          external_field: '',
          object_type: '',
          data_type: '',
          overwrite: false,
          type: ''
        };
        /* eslint-enable */
        setMapping(prevMapping => [...prevMapping, newMapping]);
        document.getElementById(`mapping-row-${mapping.length - 1}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
    // eslint-disable-next-line
  }, [focusMapping]);

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.subtitle1}>
          {title}
        </Typography>
      </div>
      <Typography variant='body2' className={classes.bodyText}>
        Map Signals contact fields to their corresponding {integrationName} fields. {disableOverwrite ? '' : 'Check the overwrite box if you wish to overwrite existing data when updating a lead'}
      </Typography>
      <div>
        <div className={defaultFieldEnabled ? classes.rowHeaderExtra : classes.rowHeader}>
          <div style={{ gridArea: 'cf' }}>Signals Field</div>
          <div style={{ gridArea: 'integration' }}>{integrationName} Field</div>
          {defaultFieldEnabled && <div style={{ gridArea: 'defaultField', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>Default Value <Tooltip title='This value will be assigned if the field is empty'><div style={{ marginLeft: '8px' }}><Icon icon='info' color='#757575' size='sm' /></div></Tooltip></div>}
        </div>
        {mapping.map((row: any, index: number) => (
          <div id={`mapping-row-${index}`} key={index}>
            <MappingRow
              key={`${index}.${row.internal_field}`}
              disableOverwrite={disableOverwrite}
              defaultFieldEnabled={defaultFieldEnabled}
              index={index}
              row={row}
              deleteMapping={deleteMapping}
              editMapping={editMapping}
              externalFields={externalMappingFields}
              customFields={customFields}
              mappedGroups={mappedGroups}
            />
          </div>
        ))}
        <div style={{ marginTop: 20 }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={addMapping}
            startIcon={<ControlPointIcon />}
          >
            Add Field
          </Button>
        </div>
      </div>
    </>
  )
}

export default IntegrationMapping
