import { useContext } from 'react'
import { Avatar, getColorByID } from 'cf-components/Avatars'
import RenderEmoji from '../RenderEmoji'
import { makeStyles } from '@material-ui/core/styles'
import DateFormat from 'components/DateFormat'
import Button from '@material-ui/core/Button'
import { TenantDataContext } from './tenant-data-context'
import './messages.css'
import StarRateIcon from '@material-ui/icons/StarRate'
import VerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import SatisfiedIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import NeutralIcon from '@material-ui/icons/SentimentSatisfied'
import DissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import VeryDissatisfiedIcon from '@material-ui/icons/MoodBad'
import { removeHtmlTags } from '../../../helpers/strings'

const ratingIconheight = 40
const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 8,
    marginTop: 4,
    borderRadius: 25,
    textTransform: 'none'
  },
  messageGroup: {
    marginLeft: 20,
    marginBottom: 35
  },
  senderName: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#222',
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  timeSent: {
    fontSize: '12px',
    color: '#929292',
    marginLeft: 10
  },
  messageText: {
    fontSize: '15px',
    overflowWrap: 'anywhere',
    color: '#3B3B3B',
    '& p': {
      margin: 0
    },
    '& p img': {
      maxWidth: 300
    },
    '& a': {
      color: 'blue',
      textDecoration: 'underline'
    }
  },
  messageBlock: {
    display: 'grid',
    gridTemplateColumns: '42px auto',
    gridGap: 10
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  messages: {
    marginTop: 6
  },
  rating: {
    display: 'flex',
    alignItems: 'center'
  },
  star: {
    height: ratingIconheight,
    width: ratingIconheight,
    fill: 'none',
    stroke: 'gold',
    '&.checked': { fill: 'gold' }
  },
  emoji: {
    height: ratingIconheight,
    width: ratingIconheight,
    opacity: 0.2,
    '&.v1': { fill: '#F0706F' },
    '&.v2': { fill: '#eba21a' },
    '&.v3': { fill: '#efb700' },
    '&.v4': { fill: '#59e3da' },
    '&.v5': { fill: '#1bd40b' },
    '&.checked': { opacity: 1 }
  }
}))

const moodArray = [
  { icon: VeryDissatisfiedIcon, color: '#F0706F', value: 1 },
  { icon: DissatisfiedIcon, color: '#eba21a', value: 2 },
  { icon: NeutralIcon, color: '#efb700', value: 3 },
  { icon: SatisfiedIcon, color: '#59e3da', value: 4 },
  { icon: VerySatisfiedIcon, color: '#1bd40b', value: 5 }
]

function ButtonDisplay (props) {
  const classes = useStyles()

  return (
    <div>
      {
        props.buttons.map((button, index) => (
          props.message === button.label
            ? (
              <Button
                key={index}
                variant='outlined'
                className={classes.button}
                style={{ cursor: 'default', backgroundColor: props.primaryColor, color: props.textColor }}
              >
                {button.label}
              </Button>
            ) : (
              <Button
                key={index}
                variant='outlined'
                className={classes.button}
                style={{ cursor: 'default', backgroundColor: 'transparent', color: props.buttonColor }}
              >
                {button.label}
              </Button>
            )
        ))
      }
    </div>
  )
}

const getDisplayInfo = (state, firstMessage) => {
  const pid = firstMessage.relationships.from_participant.data.id
  const participant = state.participants[pid]
  if (!participant) {
    return { name: 'Site Visitor', avatarUrl: '', participant: {} }
  }
  let name = participant.agent_name
  let avatarUrl = participant.avatar_url
  if (participant.kind === 'visitor') {
    const a = state.conversation.attributes
    if (a.name) {
      name = a.name
    } else if (a.email) {
      name = a.email.split('@')[0]
    } else {
      name = 'Site Visitor'
    }
    avatarUrl = a.contact_avatar
  } else if (participant.kind === 'bot') {
    name = participant.agent_name || 'Bot'
  }
  return { name, avatarUrl, participant }
}

function Message (props) {
  const classes = useStyles()
  const messageEvent = props.message.attributes
  const botResponse = messageEvent.data.bot_response
  const botOptions = messageEvent.data.options
  const body = props.allowHtml ? messageEvent.body : removeHtmlTags(messageEvent.body)
  const { settings } = useContext(TenantDataContext)

  if (botOptions) {
    return (
      <ButtonDisplay
        message={body}
        buttons={messageEvent.data?.bot_response?.options || botOptions}
        primaryColor={'#' + settings?.primaryColor || '#000000'}
        buttonColor={'#' + settings?.buttonColor || '#000000'}
        textColor={'#' + settings?.textColor || '#ffffff'}
      />
    )
  } else if (botResponse?.options) {
    return (
      <Button
        variant='outlined'
        className={classes.button}
        style={{ cursor: 'default', backgroundColor: '#' + settings?.primaryColor || '#000000', color: '#' + settings?.textColor || '#ffffff' }}
      >
        {body}
      </Button>
    )
  } else if (messageEvent.kind === 'rating_complete') {
    if (messageEvent.data.ratingType === 'five_stars') {
      return (
        <div className={classes.rating}>
          {[...Array(5).keys()].map(i => (
            <StarRateIcon key={i} className={classes.star + (i < messageEvent.data.rating ? ' checked' : '')} />
          ))}
        </div>
      )
    } else {
      return (
        <div className={classes.rating}>
          {moodArray.map(mood => (
            <mood.icon key={mood.value} className={classes.emoji + ' v' + mood.value + (mood.value === messageEvent.data.rating ? ' checked' : '')} />
          ))}
        </div>
      )
    }
  } else {
    return (
      <div>
        {body.split('\n').map((message, index) => (
          <RenderEmoji
            key={index}
            text={message}
            rich
            className={classes.messageText}
            chatServiceUrl={window.chatServiceUrl}
          />
        ))}
      </div>
    )
  }
}

function MessageGroup (props) {
  const classes = useStyles()
  const messages = props.group.events.messages
  const firstMessage = messages[0]
  const state = props.state
  const { name, avatarUrl, participant } = getDisplayInfo(state, firstMessage)
  const p = participant
  const first_name = name.split(' ')[0]
  const last_name = name.split(' ')[1] || ''
  const id = p.contact_id || p.user_id || p.id
  let allowHtml = false
  if (p.kind === 'bot' || p.user_id) {
    allowHtml = true
  }

  return (
    <div className={classes.messageGroup}>
      <div className={classes.messageBlock}>
        <Avatar
          participantID={id}
          size='md'
          avatarUrl={avatarUrl}
          initials={(name !== 'Site Visitor') ? (first_name ? first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase() : '') : ''}
          color={getColorByID(p?.id)}
        />
        <div>
          <div className={classes.header}>
            <div className={classes.senderName}>{name}</div>
            <div className={classes.timeSent}>
              <DateFormat dateToFormat={firstMessage.attributes.created_timestamp} format='h:MM TT' />
            </div>
          </div>
          <div className={classes.messages}>
            {messages.map((message, index) => (
              <Message
                key={index}
                message={message}
                allowHtml={allowHtml}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageGroup
