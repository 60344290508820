import { Dialog, Button } from 'library/materialUI'
import ScoringIntroBanner from 'img/scoring-intro-banner.svg'
import { makeStyles } from '@material-ui/core/styles'
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot'

const useStyles = makeStyles(theme => ({
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageDiv: {
    width: '648px',
    height: '299px'
  },
  title: {
    fontFamily: 'poppins',
    fontSize: '24px',
    paddingBottom: '10px',
    fontWeight: 'bold'
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#000000BF',
    opacity: '.75',
    paddingBottom: '10px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  paper: {
    minWidth: '648px',
    borderRadius: '10px'
  },
  noThanksButton: {
    backgroundColor: 'white',
    color: '#00000080',
    fontSize: '14px',
    margin: '10px',
    marginBottom: '20px'
  },
  setupButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: '10px',
    marginBottom: '20px',
    marginRight: '20px'
  },
  iconDiv: {
    marginRight: '5px',
    marginTop: '2px'
  }
}))

interface ScoringUpgradeModalProps {
  open: boolean
  onHide: () => void
}

export default function ScoringUpgradeModal ({ open, onHide }: ScoringUpgradeModalProps) {
  const classes = useStyles()

  const handleClose = () => {
    onHide()
  }

  // const handleLearnMore = () => {
  //   //window.open('https://support.6sense.com/hc/en-us/articles/360052000791-Scoring-Overview')
  // }

  const handleTalkToSales = () => {
    triggerSalesBot()
  }

  return (
    <div>
      {open && (
        <Dialog
          onClose={handleClose}
          open={open}
          fullWidth
          classes={{ paper: classes.paper }}
        >
          <div className={classes.containerDiv}>
            <div className={classes.imageDiv}>
              <img src={ScoringIntroBanner} alt='scoring_intro_banner' style={{ width: '648px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '30px' }}>
              <div className={classes.title}>
                Upgrade for better scoring and more insights
              </div>
              <div className={classes.body}>
                On a company plan, you'll get extra benefits like:
              </div>
              <div className={classes.body}>
                <ul>
                  <li>Customizable, AI-powered Signals score and AI Chatbots</li>
                  <li>Insights into the reasons for companies' scores</li>
                  <li>Email updates about companies with surging scores</li>
                  <li>Segment companies based on Signals Score</li>
                </ul>
              </div>
            </div>
            <div className={classes.footer}>
              {/* <div>
                <Button
                  className={classes.noThanksButton}
                  variant='contained'
                  onClick={() => handleLearnMore()}
                >
                  LEARN MORE
                </Button>
              </div> */}
              <div>
                <Button
                  className={classes.setupButton}
                  variant='contained'
                  onClick={() => handleTalkToSales()}
                >
                  TALK TO SALES
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
