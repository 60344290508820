import React, { useEffect, useRef, useState } from 'react'
import { getConversationCountryDrillDown, getFormCountryDrillDown } from 'api/reports'
import Loader from 'library/loading/Loader'
import { createGeoChart } from './charts/CreateGeoChart'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'

function GeoChart (props) {
  const chartRef = useRef(null)

  const [visibility, setVisibility] = useState('hidden')

  useEffect(() => {
    // This is so the loading icon shows up when switching filters
    setVisibility('hidden')

    if (props.dateValues) {
      if (chartRef.current) {
        chartRef.current.dispose()
      }
      const attributes = convertDateRange(props.dateValues)
      attributes.source_filter = props.sourceFilter
      const getCountryDrillDown = props.reportType === 'Chatbots Only' ? getConversationCountryDrillDown : getFormCountryDrillDown
      getCountryDrillDown({ attributes }).then(response => {
        const id = 'geochart'
        const chartData = response.data.attributes.chart_data
        const dataLabels = props.reportType === 'Chatbots Only' ? ['Conversations', 'Emails Captured', 'Meetings Booked'] : ['Submissions', 'Live Chats', 'Meetings Booked']
        const chart = createGeoChart({ chartData, id, dataLabels, setVisible: setVisibility })
        chartRef.current = chart
      })
    }
    return (
      () => {
        if (chartRef.current) {
          chartRef.current.dispose()
        }
      }
    )
  }, [props.dateValues, props.sourceFilter, props.reportType])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'block',
          ...props.loaderStyles
        }}
      >
        <Loader type='spinner' />
      </div>
      <div style={{ height: 360, visibility: visibility }}>
        <div id='geochart' style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default GeoChart
