import { makeStyles, withStyles } from '@material-ui/core/styles'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { getSettings, saveSettings } from 'api/user_display_settings'
import DragNDrop from 'cf-components/DragNDrop'
import { Integration } from 'classes/integrations'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import { Emitter, EVENT_TYPE } from 'emitter'
import { SettingsDrawer } from 'library/SettingsDrawer'
import SalesloftCreateTask from 'pages/settings/Integrations/Salesloft/SalesloftAddTask'
import AddCard from './cards/AddCard'
import { cardList, mapping } from './cards/mappings'
import { defaultSettings } from './helpers'
import { ConversationWindowContext } from '../conversation-window-context'
import AvailabilityControl from 'pages/chat/top-bar/AvailabilityControl'
import { SessionContext } from 'session-context'

const useStyles = makeStyles(theme => ({
  sidebar: {
    height: '100%',
    backgroundColor: '#F4F2F7',
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexFlow: 'column'
  },
  topControls: {
    paddingLeft: 8,
    paddingRight: 5,
    marginTop: 15
  },
  liveViewButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  contents: {
    padding: '5px 12px',
    overflowY: 'auto'
  },
  details: {
    color: '#585858',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    marginBottom: 10,
    alignItems: 'center'
  },
  editIcon: {
    cursor: 'pointer',
    fontSize: '20px'
  },
  icons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10
  }
}))

function LiveViewButton (props) {
  const classes = useStyles()
  // const [redirect, setRedirect] = useState(null)

  // if (redirect) {
  //   return <Redirect to={redirect} push />
  // }

  return (
    <div className={classes.liveViewButton} onClick={() => window.open('/#/live_view')}> {/* setRedirect('/live_view')}> */}
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', lineHeight: '14px', color: '#00001080', whiteSpace: 'nowrap' }}>
        <span style={{
          height: '5px',
          width: '5px',
          backgroundColor: '#00D855',
          borderRadius: '50%',
          marginRight: '10px'
        }}
        />
        <span style={{ color: '#343434', marginRight: 5 }}>{props.onlineVisitors}</span>
        {props.onlineVisitors === 1 ? 'visitor online now' : 'visitors online now'} <a href='#/live_view' target='_blank' style={{ textDecoration: 'underline', marginLeft: '10px', color: '#9933FF' }}>See all</a>
      </div>
    </div>
  )
}

const CardComponent = withStyles(theme => ({
  cardCtn: {
    paddingBottom: 10
  }
}))(
  function (props) {
    const card = props.item.id
    const Component = mapping[card]
    return (
      <div className={props.classes.cardCtn + ' no-focus-outline'}>
        <Component
          id={card}
        />
      </div>
    )
  }
)

const kind = 'chat-sidebar'

export const SidebarContext = createContext(null)

function Sidebar (props) {
  const classes = useStyles()
  // TODO: switch to storing settings in sessionContext so that the db is not accessed as often
  const [settings, setSettings] = useState({ cards: [] })
  const addCardRef = useRef(null)
  const [slCreateTaskOpen, setSLCreateTaskOpen] = useState(false)
  const [personName, setPersonName] = useState('')
  const [personID, setPersonID] = useState('')
  const { state } = useContext(ConversationWindowContext)
  const { participant } = useContext(SessionContext)
  const [snackState, setSnackState] = useState({ open: false, message: 'Add Task Successful' })
  const { data: slIntegration, isLoading: loadingInt } = Integration.loadOne('salesloft')
  const open = props.open
  const setOpen = props.setOpen
  const outOfOfficeInstances = participant?.attributes?.out_of_office_instances || []

  /** The current OutOfOffice if one exists */
  const currentOutOfOfficeInstance = outOfOfficeInstances.find(({ attributes }) => {
    const startDate = new Date(attributes.start_date)
    if (startDate > new Date()) {
      return false
    }
    const endDate = new Date(attributes.end_date)
    if (endDate < new Date()) {
      return false
    }
    return true
  })

  const status = currentOutOfOfficeInstance ? 'outOfOffice' : participant?.attributes?.status

  useEffect(() => {
    Emitter.on(EVENT_TYPE.SALESLOST_TASK, (payload) => {
      setPersonName(payload.personName)
      setPersonID(payload.personID)
      setSLCreateTaskOpen(true)
    })
    getSettings({ kind })
      .then(response => {
        const settings = response.data.attributes.settings || defaultSettings
        const id = response.data.id
        // TODO: switch to saving settings in sessionContext
        setSettings({ ...settings, id: id })
      })
  }, [])

  const finishDrag = (items) => {
    const newSettings = { ...settings, cards: items }
    // TODO: switch to saving settings in sessionContext
    setSettings(newSettings)
    const post = !settings.id
    saveSettings({ kind, settings: newSettings, post })
      .then(response => {
        // TODO: switch to saving settings in sessionContext
        setSettings(prev => ({ ...prev, id: response.data.id }))
      })
  }

  const addCardToList = (id) => {
    const newList = [...settings.cards]
    newList.push({ id: id })
    setSettings({ ...settings, cards: newList })
    const post = !settings.id
    saveSettings({ kind, settings: { ...settings, cards: newList }, post })
  }

  const removeCardFromList = (id) => {
    const newList = settings.cards.filter(c => c.id !== id)
    setSettings({ ...settings, cards: newList })
    const post = !settings.id
    saveSettings({ kind, settings: { ...settings, cards: newList }, post })
  }

  return (
    <SidebarContext.Provider value={{ removeCardFromList }}>
      <div id='conversation_details' className={classes.sidebar}>
        <div className={classes.topControls}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ paddingLeft: 15 }}>
              {status === 'available' ? 'Available' : 'Unavailable'}
            </div>
            <AvailabilityControl
              participantStatus={status}
              participantID={participant?.id}
            />
          </div>
          <LiveViewButton onlineVisitors={state.onlineVisitors.length} />
        </div>
        <div className={classes.contents}>
          <div className={classes.details} ref={addCardRef}>
            Conversation Details
            <AddCard
              open={open}
              anchorEl={addCardRef.current}
              setOpen={setOpen}
              show
              currentCards={settings.cards}
              availableCards={cardList}
              addCardToList={addCardToList}
              removeCardFromList={removeCardFromList}
            />
          </div>
          <DragNDrop
            itemList={settings.cards}
            setItemList={finishDrag}
            direction='vertical'
            component={CardComponent}
            divClass='no-focus-outline'
          />
        </div>

        {!loadingInt && slIntegration?.enabled ? (
          <>
            <SettingsDrawer
              open={slCreateTaskOpen}
              handleClose={() => setSLCreateTaskOpen(false)}
              handleSave={() => undefined}
              title='Create Task'
              orientation='right'
              setWidth='auto minmax(290px, min(340px, 25%))'
              minimize
            >
              <SalesloftCreateTask
                personName={personName}
                personID={personID}
                onSave={() => {
                  setSLCreateTaskOpen(false)
                  setSnackState({ ...snackState, open: true })
                }}
              />
            </SettingsDrawer>
            <CustomizedSnackbar state={snackState} handler={setSnackState} />
          </>
        ) : <></>}
      </div>
    </SidebarContext.Provider>
  )
}

export default Sidebar
