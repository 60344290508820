import { useEffect, useState } from 'react'
import SocialMediaLink from './SocialMediaLink'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { TextField, Typography } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import { IconButton } from 'library/materialUI/Button'

const useStyles = makeStyles(theme => ({
  subContainer: {
    width: '45%'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}))

function SocialPage (props) {
  const classes = useStyles()
  const disabled = !props.editable
  const updateAgentProfileSettings = props.updateAgentProfileSettings
  const [changeBio, setChangeBio] = useState(false)

  let twitter = ''
  let linkedin = ''
  let facebook = ''
  let instagram = ''
  let youtube = ''

  if (props.profiles) {
    twitter = props.profiles.twitter || ''
    linkedin = props.profiles.linkedin || ''
    facebook = props.profiles.facebook || ''
    instagram = props.profiles.instagram || ''
    youtube = props.profiles.youtube || ''
  }

  useEffect(() => {
    if (changeBio) {
      document.getElementById('bio').focus()
    }
  }, [changeBio])

  return (
    <div>
      <h3>Contact Details</h3>
      <Divider />
      <br />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <Typography variant='body'>Personal Bio</Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              id='bio'
              value={props?.info?.agent_profile_settings?.contact_details?.bio}
              onChange={(e) => updateAgentProfileSettings('contact_details', { ...props?.info?.agent_profile_settings?.contact_details, bio: e.target.value })}
              multiline
              variant='outlined'
              maxRows={4}
              minRows={4}
              margin='dense'
              disabled={!changeBio}
              style={{ marginBottom: 15, width: '95%' }}
            />
            <div style={{ alignItems: 'top', padding: 10 }}>
              <IconButton
                onClick={() => {
                  setChangeBio(true)
                  document.getElementById('bio').focus()
                }}
                icon='edit'
              />

            </div>
          </div>
          <TextField
            id='position'
            label={<div style={{ display: 'flex', alignItems: 'center', color: '#9CA3AF' }}><div style={{ marginRight: 10 }}><Icon icon='briefcase' size='sm' /></div> Position</div>}
            className={classes.textField}
            value={props?.info?.agent_profile_settings?.contact_details?.position}
            onChange={(e) => updateAgentProfileSettings('contact_details', { ...props?.info?.agent_profile_settings?.contact_details, position: e.target.value })}
            margin='dense'
            variant='outlined'
            style={{ width: '60%', marginBottom: 15 }}
          />
          <TextField
            id='location'
            label={<div style={{ display: 'flex', alignItems: 'center', color: '#9CA3AF' }}><div style={{ marginRight: 10 }}><Icon icon='newLocation' size='sm' /></div> Location</div>}
            className={classes.textField}
            value={props?.info?.agent_profile_settings?.contact_details?.location}
            onChange={(e) => updateAgentProfileSettings('contact_details', { ...props?.info?.agent_profile_settings?.contact_details, location: e.target.value })}
            margin='dense'
            variant='outlined'
            style={{ width: '60%' }}
          />
        </div>
        <div className={classes.subContainer}>
          <Typography variant='body'>Social Media Links</Typography>
          <SocialMediaLink
            name='LinkedIn'
            url={linkedin}
            disabled={disabled}
            handler={props.updateSocialLink}
          />
          <SocialMediaLink
            name='Twitter'
            url={twitter}
            disabled={disabled}
            handler={props.updateSocialLink}
          />
          <SocialMediaLink
            name='Facebook'
            url={facebook}
            disabled={disabled}
            handler={props.updateSocialLink}
          />
          <SocialMediaLink
            name='Instagram'
            url={instagram}
            disabled={disabled}
            handler={props.updateSocialLink}
          />
          <SocialMediaLink
            name='YouTube'
            url={youtube}
            disabled={disabled}
            handler={props.updateSocialLink}
          />
        </div>
      </div>
    </div>
  )
}

export default SocialPage
