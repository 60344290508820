/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'
import { FilterBar } from 'cf-components/FilterBar/FilterBar'
import { useContext, useEffect, useState } from 'react'
import { ShareContext } from 'share-context'
import SummaryTimelineCard from 'pages/dashboard/SummaryTimelineCard'
import PieChartCard from 'pages/dashboard/PieChartCard'
import { getAccountPieCharts } from 'api/account_management'
import SegmentsCard from 'pages/dashboard/SegmentsCard'
import { SessionContext } from 'session-context'
import PieChartModal from 'pages/dashboard/PieChartModal'
import AccessControl from 'components/AccessControl'
import { SNAPSHOT_PLAN_NAME } from 'library/constants'

const useStyles = makeStyles(theme => ({
  // dashboardCards: {
  //   display: 'grid',
  //   gridTemplateColumns: '1fr 1fr',
  //   gridTemplateAreas: `
  //   "timeline timeline"
  //   "charts charts"
  //   "left right"`,
  //   gridGap: 20
  // },
  // column: {
  //   display: 'grid',
  //   gridGap: 20
  // },
  // charts: {
  //   display: 'grid',
  //   gridTemplateColumns: '1fr 1fr 1fr',
  //   gridGap: 20
  // },
  dashboardCard: {
    marginBottom: '20px'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '20px'
  }
}))

export default function VisitorReportPage () {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }

  const [dateRange, setDateRange] = useState(startingDate)
  const [segments, setSegments] = useState<any>([])
  const [segmentFilter, setSegmentFilter] = useState<any>([])
  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange,
    segmentFilter: segmentFilter,
    setSegmentFilter: setSegmentFilter
  }
  const { planName } = useContext(ShareContext)
  if (planName() !== SNAPSHOT_PLAN_NAME) {
    filterOptions.filterValues.push({ value: 'Segment', label: 'Filter by Segment', toggle: false })
  }

  const [chartData, setChartData] = useState({
    employee_data: null,
    industry_data: null,
    revenue_data: null
  })

  // Modals
  const [modalData, setModaldata] = useState()
  const [modalTitle, setModalTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalID, setModalID] = useState('')

  const handleModalClick = (data: any, title: string, id: string) => {
    setModaldata(data)
    setModalTitle(title)
    setModalID(id)
    setModalOpen(true)
  }

  useEffect(() => {
    getAccountPieCharts(dateRange.startDate, dateRange.endDate, segments)
      .then(response => setChartData(response.data.attributes))
  }, [dateRange, dateRange.startDate, dateRange.endDate, segments])

  useEffect(() => {
    setSegments(segmentFilter.map((segment: any) => segment.value))
  }, [segmentFilter])

  document.title = 'Visitor Dashboard | Signals'

  return (
    <AccessControl requiredPermission='manager'>
      <AppPage
        title='Visitor Dashboard'
        padding={0}
      >
        <div>
          <FilterBar
            filterOptions={filterOptions}
          />
          <div style={{ margin: '20px' }}>
            <div id='summary-timeline' className={classes.dashboardCard}>
              <SummaryTimelineCard
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                segments={segments}
              />
            </div>
            <div className={classes.cardRow}>
              <div style={{ width: '100%' }}>
                <PieChartCard
                  title='Top Visitor Industries'
                  data={chartData.industry_data}
                  id='industry-pie-chart'
                  onFullScreen={() => handleModalClick(chartData.industry_data, 'Top Visitor Industries', 'industry-pie-chart')}
                />
              </div>
              <div style={{ width: '100%' }}>
                <PieChartCard
                  title='Top Visitor Company Sizes'
                  data={chartData.employee_data}
                  id='employee-pie-chart'
                  onFullScreen={() => handleModalClick(chartData.employee_data, 'Top Visitor Company Sizes', 'employee-pie-chart')}
                />
              </div>
            </div>
            <div className={classes.cardRow}>
              <div style={{ width: '100%' }}>
                <PieChartCard
                  title='Top Visitor Company Revenues'
                  data={chartData.revenue_data}
                  id='revenue-pie-chart'
                  onFullScreen={() => handleModalClick(chartData.revenue_data, 'Top Visitor Company Revenues', 'revenue-pie-chart')}
                />
              </div>
              <div id='segment-card' style={{ width: '100%' }}>
                <SegmentsCard
                  chatServiceUrl={chatServiceUrl}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  segmentIDs={segments}
                />
              </div>
            </div>
          </div>
          <div>
            <PieChartModal
              data={modalData}
              title={modalTitle}
              open={modalOpen}
              id={modalID}
              onHide={() => setModalOpen(false)}
            />
          </div>
          <br />
          {/* <div style={{ textAlign: 'right', fontSize: 12 }}>
            Powered by 6sense
          </div> */}
        </div>
      </AppPage>
    </AccessControl>
  )
}
