import {
  Emitter,
  EVENT_TYPE
} from 'emitter'
import { snakeCase } from 'change-case'
import { parameterizeFilters, SearchParams } from 'classes/queryHelpers'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

export const cleanErrorMsg = (error: string) => {
  let cleanedErrorMsg = error
  const indexOfSecondColon = error.indexOf(':', error.indexOf(':') + 1)
  if (indexOfSecondColon !== 0) cleanedErrorMsg = error.substring(indexOfSecondColon + 1)
  return cleanedErrorMsg
}

function handleErrorResponse (path: string, status: any, err: string, msg: string, method: string, requestID: string, requestURL: string): any {
  console.error('Server returned ' + status, { method, path, err, msg, requestID })
  // msg should never be null
  // changes/additions to this list will affect src/components/CustomizedSnackbar.jsx
  // if you make changes here, be sure to update that as well if needed
  switch (status) {
    case 400:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('bad_request:|:' + msg)
    case 401:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('unauthorized:|:' + msg)
    case 403:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('forbidden:|:' + msg)
    case 404:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('not_found:|:' + msg)
    case 409:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('duplicate_violation:|:' + msg)
    case 422:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('unprocessable_entity:|:' + msg)
    case 429:
      throw Error('too_many_requests')
    case 501:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('not_implemented:|:' + msg)
    case 502:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('bad_gateway:|:' + msg)
    case 504:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg, requestID, requestURL })
      throw Error('gateway_timeout:|:' + msg)
    case 500:
    default:
      Emitter.emit(EVENT_TYPE.API_ERROR, { status, method, message: msg || 'An unexpected error occurred! If issue persists, contact Customer Support for assistance', requestID, path })
      throw Error('system_error:|:' + msg)
  }
}

const convertData = (data: Record<string, any>): any => {
  const original = data.attributes
  const attributes: Record<string, any> = {}
  for (const key in original) {
    attributes[snakeCase(key.replace('ID', 'Id'))] = original[key]
  }
  data.attributes = attributes
  return data
}

const eventMethods = ['POST', 'PATCH', 'PUT', 'DELETE']
/**
 * This function will emit a DATA_CHANGED event for any of the given eventMethods (which
 * is really anything besides GET). This will allow other parts of the application to subscribe to
 * the same event and expire their query caches, if necessary.
 */
function handleFetchResponse (path: string, method: string, promise: Promise<any>, returnText?: boolean, silent = false): Promise<any> {
  return promise.then((response: Response) => {
    const reqID = response?.headers?.get('x-request-id') || ''
    const reqURL = response?.url || ''
    if (response.status >= 200 && response.status < 300) {
      if (eventMethods.includes(method)) {
        let eventURL = path
        if (method !== 'POST') {
          eventURL = eventURL.substring(1, eventURL.lastIndexOf('/') - 1)
        }
        Emitter.emit(EVENT_TYPE.DATA_CHANGED, {
          path,
          eventURL,
          method,
          silent
        })
      }
      if (response.status === 204) {
        return Promise.resolve(true)
      }
      if (returnText) {
        return response.text()
      } else {
        return response.json()
      }
    } else {
      return response.json().then((js: any) => {
        if (js.errors) {
          const msg = js.errors.map((e: any) => {
            let m = e.title + ' - ' + e.detail
            if (e?.source?.pointer) {
              m += ' @ ' + e.source.pointer
            }
            return m
          }).join(', ')

          return handleErrorResponse(path, response.status, msg, msg, method, reqID, reqURL)
        } else {
          return response.text().then((txt: string) => {
            return handleErrorResponse(path, response.status, txt, txt, method, reqID, reqURL)
          })
        }
      }).catch((err: Error) => {
        // if we've already gone through handleErrorResponse, just bubble up the error
        const parts = err.message.split(':|:')
        if (parts.length > 1) {
          let errMsg = parts[0]
          if (parts[1] !== '') {
            errMsg += ':|:' + parts[1]
          }
          throw Error(errMsg)
        } else {
          return handleErrorResponse(path, response.status, err.message, '', method, reqID, reqURL)
        }
      })
    }
  })
}

interface FetchParameters {
  path: string
  data?: Record<string, any>
  useChatURL?: boolean
  extraHeaders?: Record<string, string>
  silent?: boolean
  returnText?: boolean
  rawBody?: string
  searchParams?: SearchParams
  skipDataConvert?: boolean
}

interface DoFetchParamaters extends FetchParameters {
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET'
}

export function doFetch ({ path, method, data, useChatURL, returnText, extraHeaders, rawBody, silent, searchParams, skipDataConvert = false }: DoFetchParamaters): Promise<any> {
  let requestHeaders = headers
  if (extraHeaders) {
    requestHeaders = {
      ...headers,
      ...extraHeaders
    }
  }
  if (searchParams) {
    path = parameterizeFilters({ searchParams, path })
  }
  const params: { method: string, headers: any, body?: any } = {
    method: method,
    headers: requestHeaders
  }

  if (rawBody) {
    params.body = rawBody
  } else if (data) {
    if (!skipDataConvert) {
      data = convertData(data)
    }
    params.body = JSON.stringify({ data })
  }

  let fetchURL

  if (path.startsWith('http')) {
    fetchURL = path
  } else if (useChatURL) {
    if (window.hasOwnProperty('chatServiceUrl')) {
      fetchURL = String((window as any).chatServiceUrl)
    } else {
      let hostname = window.location.hostname
      if (hostname.includes('local.chatfunnels.dev')) {
        hostname += ':' + window.location.port
      }
      fetchURL = '/api/chat-service/a'
      console.warn('requested chatServiceUrl, but it is missing on the window, using default:', fetchURL, path)
    }
    fetchURL += path
  } else {
    fetchURL = path
    if (!fetchURL.startsWith('/api')) {
      fetchURL = '/api' + fetchURL
    }
  }

  if (method === 'PATCH') {
    Emitter.emit(EVENT_TYPE.OPTIMISTIC_UPDATE, {
      path,
      data
    })
  }

  return handleFetchResponse(path, method, fetch(fetchURL, params), returnText, silent)
}

export function doPost<T = any> ({ path, data, useChatURL, returnText, rawBody, skipDataConvert, extraHeaders, silent }: FetchParameters): Promise<T> {
  return doFetch({ path, data, method: 'POST', useChatURL, returnText, rawBody, skipDataConvert, extraHeaders, silent })
}
export function doPut<T = any> ({ path, data, extraHeaders, useChatURL }: FetchParameters): Promise<T> {
  return doFetch({ path, data, method: 'PUT', extraHeaders, useChatURL })
}
export function doPatch<T = any> ({ path, data, useChatURL, silent }: FetchParameters): Promise<T> {
  return doFetch({ path, data, method: 'PATCH', useChatURL, silent })
}
export function doDelete<T = any> ({ path, data, useChatURL, extraHeaders, silent }: FetchParameters): Promise<T> {
  return doFetch({ path, data, method: 'DELETE', useChatURL, extraHeaders, silent })
}

export function doGet<T = any> ({ path, useChatURL, extraHeaders, returnText, searchParams }: FetchParameters): Promise<T> {
  return doFetch({ path, method: 'GET', useChatURL, extraHeaders, returnText, searchParams })
}

export async function doStreamFetch ({ path, data }: FetchParameters): Promise<ReadableStreamDefaultReader<Uint8Array>> {
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    body: JSON.stringify({ data })
  }
  try {
    const response = await fetch(path, params)
    if (!response.ok) {
      throw Error(response.statusText)
    }
    if (!response.body) {
      throw Error('Coudln\'t get stream reader')
    }
    const streamReader = response.body.getReader()
    return streamReader
  } catch (err: any) {
    throw Error(err)
  }
}
