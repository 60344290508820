import { Cronofy } from 'classes/cronofy'
import { DisplaySettings } from 'classes/displaySettings'
import { Tenant } from 'classes/tenants'
import User from 'classes/users'
import { useContext, useState } from 'react'
import { AdminSetupWizard } from './adminSetup/AdminSetupWizard'
import { IntroWizardModal } from './IntroWizardModal'
import { PersonalSetupWizard } from './personalSetup/PersonalSetupWizard'
import { UserContextProvider } from './personalSetup/UserContext'
import { SnapshotIntroWizard } from './snapshotSetup/SnapshotIntroWizard'
import { components, SessionContext } from 'session-context'

type StateType = '' | 'intro' | 'personal' | 'admin' | 'snapshot_intro'

type ModalProps = {
  showAdmin: boolean
  startState: StateType
  tenant: Tenant
}

function ModalContents (props: ModalProps) {
  const [state, setState] = useState(props.startState)

  const introModalComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'intro_modal', settings: { complete: true } })
    setState('personal')
  }

  const snapshotIntroComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'snapshot_intro_modal', settings: { complete: true } })
    setState('')
  }

  const personalModalComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'personal', settings: { complete: true } })
    setState(props.showAdmin ? 'admin' : '')
  }

  const adminModalComplete = () => {
    DisplaySettings.update({ page: 'onboarding', type: 'admin', settings: { complete: true } })
    const updates: Partial<Tenant> = {
      onboardingCompleteTimestamp: new Date()
    }
    Tenant.update(Number(props.tenant.id), updates)
    setState('')
  }

  if (state === 'snapshot_intro') {
    return (
      <SnapshotIntroWizard
        open
        handleFinish={snapshotIntroComplete}
      />
    )
  } else if (state === 'intro') {
    return (
      <IntroWizardModal
        open={state === 'intro'}
        onClose={introModalComplete}
      />
    )
  } else if (state === 'personal') {
    return (
      <PersonalSetupWizard
        open
        handleFinish={personalModalComplete}
      />
    )
  } else if (state === 'admin') {
    return (
      <AdminSetupWizard
        open
        handleFinish={adminModalComplete}
      />
    )
  } else {
    return (
      <></>
    )
  }
}

const defaultOnboardingSettings = {
  // eslint-disable-next-line
  intro_modal: { complete: false },
  personal: { complete: false },
  // eslint-disable-next-line
  snapshot_intro_modal: { complete: false },
  admin: { complete: false }
}

export function SetupModal () {
  const { settings, isLoading } = DisplaySettings.load()
  const { isOn, componentsLoading } = useContext(SessionContext)
  const { data: user, isLoading: userLoading } = User.loadMe()
  const { data: tenant, isLoading: tenantLoading } = Tenant.load()

  Cronofy.load()

  if (isLoading || tenantLoading || userLoading) {
    return (<></>)
  }

  const tenantComplete = tenant.onboardingCompleteTimestamp

  const showAdmin = user.role === 'Admin' && !tenantComplete

  const onboarding = settings.onboarding?.settings || defaultOnboardingSettings

  let startState: StateType = ''
  if (!componentsLoading && !isOn(components.CONTACTS) && !onboarding?.snapshot_intro_modal?.complete) {
    startState = 'snapshot_intro'
  } else if (isOn(components.PLAYMAKER)) {
    if (!onboarding.intro_modal.complete) {
      startState = 'intro'
    } else if (!onboarding.personal.complete) {
      startState = 'personal'
    } else if (!tenantComplete) {
      startState = 'admin'
    }
  }

  return (
    <UserContextProvider userID={user.id}>
      <ModalContents
        startState={startState}
        showAdmin={showAdmin}
        tenant={tenant}
      />
    </UserContextProvider>
  )
}
