import { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Redirect, Link } from 'react-router-dom'
import NetworkBackground from 'img/network_background.png'
import Typography from '@material-ui/core/Typography'
import { SessionContext } from 'session-context'
import { signup, requestToJoin } from 'api/auth'
import InsightsUpgradeImg from 'img/insights_upgrade.png'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { isValidPassword } from 'pages/registration/PasswordConfirmationBlock'
import { FormControlLabel, FormLabel, TextField } from '@material-ui/core'
import { Button, Checkbox } from 'library/materialUI'
import { Modal } from 'library/Modal'

const useStyles = makeStyles(() => ({
  page: {
    height: '100%',
    background: `url(${NetworkBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    width: 980
  },
  card: {
    flex: 1,
    padding: 50,
    margin: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white'
  },
  partnerImage: {
    flex: 1,
    height: '100%',
    margin: 10
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left'
  },
  formContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  submitButton: {
    width: '100%',
    height: 40,
    marginTop: 20
  },
  logo: {
    paddingTop: 30,
    paddingLeft: 30,
    cursor: 'pointer',
    width: 200
  },
  link: {
    textDecoration: 'none',
    color: '#9933ff',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

const FreeTrialPage = (props) => {
  const devMode = props.dev || false
  const classes = useStyles()
  const width = 'auto'
  const [termsChecked, setTermsChecked] = useState(false)
  const [useEmailVerification, setUseEmailVerification] = useState(true)
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)
  const small = useMediaQuery('(max-width: 1000px)')
  const { setUser } = useContext(SessionContext)
  const [loggedIn, setLoggedIn] = useState(false)
  const [personalEmail, setPersonalEmail] = useState(false)
  const [existingOrg, setExistingOrg] = useState({})
  const [ignoreOrg, setIgnoreOrg] = useState(false)
  const [values, setValues] = useState({
    full_name: '',
    email: '',
    password: '',
    useEmailVerification: false
  })
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)

  const completeRegistration = values => {
    signup(
      values.full_name,
      values.email,
      values.phone,
      ignoreOrg,
      {
        password: devMode ? values.password : '',
        dev_registration: devMode,
        send_verification_email: useEmailVerification
      }
    )
      .then(response => {
        if (response?.data?.attributes?.personal) {
          setPersonalEmail(true)
          return
        }
        if (response?.data?.attributes?.domain) {
          setExistingOrg({
            domain: response.data.attributes.domain,
            tenantID: response.data.attributes.tenant_id
          })
          return
        }
        if (response?.data?.success) {
          setVerificationEmailSent(true)
        }
        if (response?.data?.id) {
          setTimeout(() => {
            setUser(response.data)
            setLoggedIn(true)
          }, 4000)
        }
        if (response.errors) {
          console.log(response.error)
        }
      })
  }

  const phoneRegEx = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/

  const FreeTrialFormSchema = Yup.object().shape({
    full_name: Yup.string().matches(/[^\s]+ [^\s].*/).required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().matches(phoneRegEx, 'Invalid phone number').required('Required')
  })

  const myStyle = { ...props.style, width: width }

  const startingValues = {
    full_name: '',
    email: '',
    phone: '',
    password: ''
  }

  if (loggedIn) {
    const profileRoute = '/'
    return <Redirect to={profileRoute} />
  }

  return (
    <div className={classes.page}>
      <div className={classes.logo} onClick={() => window.open('https://getsignals.ai')}>
        <img src='https://cdn.jsdelivr.net/gh/chatfunnels/cdn@main/signals_wordmark-vector.svg' alt='Signals' />
      </div>
      <div className={classes.container}>
        <div className={classes.cardsContainer}>
          <Card raised className={classes.card} style={myStyle}>
            <div className={small ? classes.formContainerMobile : classes.formContainer}>
              <Typography variant='h1' style={{ marginBottom: 20, fontSize: '1.8rem', fontWeight: 'bold' }}>
                Set up your free account
              </Typography>
              <Formik
                initialValues={startingValues}
                onSubmit={values => {
                  setValues(values)
                  if (!useEmailVerification && !isValidPassword(values.password)) { return }
                  completeRegistration(values)
                }}
                validationSchema={FreeTrialFormSchema}
                render={({ handleChange, values, errors, handleBlur, submitForm, touched }) => {
                  return (
                    <Form>
                      <Typography variant='body2' style={{ marginBottom: 10 }}>
                        Already have an account?{' '}
                        <Link to='/' className={classes.link}>
                          Log In
                        </Link>
                      </Typography>

                      <FormLabel>
                        Full Name <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <TextField
                        variant='outlined'
                        fullWidth
                        id='full_name'
                        name='full_name'
                        value={values.full_name}
                        placeholder='John Doe'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        style={{ marginBottom: 10 }}
                      />

                      <FormLabel>
                        Business Email <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <TextField
                        variant='outlined'
                        fullWidth
                        id='email'
                        name='email'
                        value={values.email}
                        placeholder='username@mybusiness.com'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        style={{ marginBottom: 10 }}
                      />

                      <FormLabel>
                        Phone Number <span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <TextField
                        variant='outlined'
                        fullWidth
                        id='phone'
                        name='phone'
                        value={values.phone}
                        placeholder='1(800)555-1234'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        style={{ marginBottom: 10 }}
                      />

                      {!useEmailVerification
                      ? (
                        <>
                          <FormLabel>
                            Password <span style={{ color: 'red' }}>*</span>
                          </FormLabel>
                          <TextField
                            variant='outlined'
                            fullWidth
                            id='password'
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type='password'
                            required
                            style={{ marginBottom: 10 }}
                          />
                        </>
                      ) : <></>}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value='terms'
                            color='primary'
                            checked={termsChecked}
                            onChange={(checked) => setTermsChecked(checked)}
                          />
                        }
                        label={
                          <>
                            I agree to the{' '}
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://getsignals.ai/terms-and-conditions/'
                              className={classes.link}
                            >
                              Terms and Conditions
                            </a>
                          </>
                        }
                      />
                      {devMode
                      ? <FormControlLabel
                          control={
                            <Checkbox
                              value={values.useEmailVerification}
                              color='primary'
                              checked={useEmailVerification}
                              onChange={(checked) => {
                                values.password = ''
                                setUseEmailVerification(checked)
                                handleChange()
                              }}
                            />
                          }
                          label={
                            <>Use Email Validation</>
                          }
                        /> : <></>}
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.submitButton}
                        onClick={submitForm}
                        disabled={
                          !termsChecked ||
                          errors.email ||
                          errors.full_name ||
                          errors.phone
                        }
                      >
                        Get Started
                      </Button>
                    </Form>
                  )
                }}
              />
            </div>
          </Card>
          {!small && (
            <a href='https://getsignals.ai/insights-plan'>
              <img src={InsightsUpgradeImg} className={classes.partnerImage} alt='Insights plan information' />
            </a>
          )}
        </div>
      </div>
      <Modal
        open={existingOrg?.domain}
        title='We may have found your organization'
        subtitle='It looks like your company already has an account with us. Would you like to request to join that organization?'
        onHide={() => {
          setValues({})
          setIgnoreOrg(true)
          setExistingOrg({})
        }}
        handleSave={() => {
          requestToJoin(values.full_name, values.email, values.password, existingOrg.tenantID).then(
            () => {
              setExistingOrg({})
              setConfirmationModalOpen(true)
            }
          ).catch((e) => {
            const parts = e.message.split(':|:')
            if (parts[0] === 'duplicate_violation') {
              setExistingOrg({})
              setDuplicateModalOpen(true)
            } else {
              throw e
            }
          })
        }}
        disableBackdropClick
        disableEscClick
        noCloseBtn
        saveBtnText='Request to join'
        cancelBtnText='Cancel'
      />
      <Modal
        open={confirmationModalOpen}
        onHide={() => setConfirmationModalOpen(false)}
        title='Request to join sent'
        subtitle='We have notified your account admin that you are requesting access. Please contact them for further instructions.'
        noCloseBtn
        disableBackdropClick
        disableEscClick
        hideCancelButton
        handleSave={() => {
          window.location.reload()
        }}
        saveBtnText='Sounds good'
      />
      <Modal
        open={duplicateModalOpen}
        onHide={() => window.location.reload()}
        title='Sign up already requested'
        subtitle='It looks like you have already requested to sign up. Please check your email for further instructions from your admin or you can sign up with a new account.'
        noCloseBtn
        disableBackdropClick
        disableEscClick
        handleSave={() => {
          setIgnoreOrg(true)
          setExistingOrg({})
          completeRegistration(values)
        }}
        saveBtnText='Continue with new account'
      />
      <Modal
        open={personalEmail}
        onHide={() => { setPersonalEmail(false) }}
        title='Please use a business email'
        subtitle='It looks like you are trying to sign up with a personal email address. Please use a business email address to sign up for Signals.'
        noCloseBtn
        disableBackdropClick
        disableEscClick
        handleSave={() => {
          setPersonalEmail(false)
        }}
        saveBtnText='Sounds good'
      />
      <Modal
        open={verificationEmailSent}
        onHide={() => { setVerificationEmailSent(false) }}
        title='Verification email sent'
        subtitle='We have sent you an email to verify your account. Please check your email and click the link to continue.'
        noCloseBtn
        hideCancelButton
        disableBackdropClick
        disableEscClick
        handleSave={() => {
          setVerificationEmailSent(false)
        }}
        saveBtnText='Sounds good'
      />
    </div>
  )
}

export default FreeTrialPage
