import { makeStyles } from '@material-ui/core/styles'
import { Button, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  freeHeader: {
    width: '100%',
    height: '58px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconStyle: {
    borderRadius: '20px',
    height: '20px',
    width: '20px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  }
}))

interface UpgradeBannerProps {
  prompt: string,
  action: () => void
}

export function UpgradeBanner (props: UpgradeBannerProps): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.freeHeader}>
      <div className={classes.iconStyle}>
        <Icon icon='upArrow' size='sm' color='#9933FF' />
      </div>
      <div>
        {props.prompt}
      </div>
      <div>
        <Button
          color='primary'
          variant='contained'
          style={{ marginLeft: '20px' }}
          onClick={props.action}
        >
          Upgrade
        </Button>
      </div>
    </div>
  )
}
