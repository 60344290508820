import Company from 'classes/companies'
import SearchBar from 'library/search/SearchBar';
import Loader from 'library/loading/Loader'
import { Button, Typography } from 'library/materialUI'
import { useMemo, useState } from 'react'
import CompanyCardList from './CompanyCardList'
import { SegmentSetupProps } from './SegmentSetupModal'
import { SearchParams } from 'classes/queryHelpers';
import AccountModal from 'pages/accounts/AccountManagement/AccountModal'
import { importFromCSV, updateAccount } from 'api/account_management';
import { EVENT_TYPE, Emitter } from 'emitter';
import { useDebounce } from 'use-debounce/lib';
import { debounceDelay } from 'library/constants'

export const StaticSegmentModal = ({ editTriggers, segment }: SegmentSetupProps): JSX.Element => {
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([])
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, debounceDelay)
  const [modalOpen, setModalOpen] = useState(false)
  const [csvImportErrors, setCSVImportErrors] = useState<any>([])
  const filters = segment.id ? [{ filters: [{ prop: 'segment_filter_id', cmp: 'eq', value: segment.id.toString() }] }] : [{ filters: [{ prop: '', cmp: '', value: '' }] }]
  const searchParams: SearchParams = {
    pageNumber: 1,
    pageSize: 20,
    search: debouncedSearch,
    sortColumn: 'name',
    sortOrder: 'asc'
  }
  const { data: companiesInSegment, isLoading: selectedIsLoading } = Company.get_segment_membership({ filters: filters, pageSize: 1500 })
  const { data: companies, isLoading } = Company.loadAll({ searchParams })

  const handleSubmit = (accountState: any) => {
    // Call function to post to our API
    return updateAccount({ account: accountState.attributes })
      .then(_ => {
        setSearch(accountState.attributes.name)
        setModalOpen(false)
      })
  }

  const handleCSVImport = (file: any, csvFallbackUserID: number) => {
    importFromCSV({ csvFile: file, fallbackUserID: csvFallbackUserID })
      .then(response => {
        if (response.ok) {
          setModalOpen(false)
          setCSVImportErrors([])
          Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'accounts' })
        } else if (response.errors) {
          setCSVImportErrors(response.errors)
          Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'error' })
        }
      }).catch(e => {
        if (e.message.indexOf(':|:') > -1) {
          const msg = e.message.split(':|:')[1]
          setCSVImportErrors([{ detail: 'There was an error while importing your CSV: ' + msg }])
        } else {
          setCSVImportErrors([{ detail: 'There was an unexpected error while importing your CSV: ' + e.message }])
        }
        Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'error' })
      })
  }

  const handleCheckChange = (obj: Company) => {
    const newSelectedCompanies = [...selectedCompanies]
    const isIncluded = (selectedCompanies.findIndex(company => company.domain === obj.domain))
    if (isIncluded > -1) {
      newSelectedCompanies.splice(isIncluded, 1)
    } else {
      newSelectedCompanies.push(obj)
    }
    const domains = newSelectedCompanies.map((c) => c.domain)
    const newSegment = Object.assign(segment, { objectIDs: domains })
    editTriggers(newSegment)
    setSelectedCompanies(newSelectedCompanies)
  }

  const handleUnselectAll = () => {
    const newSegment = Object.assign(segment, {
      triggers: [{ filters: [] }]
    })
    editTriggers(newSegment)
    setSelectedCompanies([])
  }

  useMemo(() => {
    if (!selectedIsLoading) {
      setSelectedCompanies(companiesInSegment.list)
    }
  }, [selectedIsLoading, companiesInSegment.list])

  const emptyState = (
    <div style={{ display: 'flex', width: '100%', height: 500, alignItems: 'center', justifyContent: 'center' }}>
      <Typography style={{ textAlign: 'center', padding: '10%' }} variant='h6'>Search for accounts above or upload a CSV to add accounts to your segment</Typography>
    </div>
  )

  const accountNotFound = (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 500, alignItems: 'center', justifyContent: 'center' }}>
      <Typography style={{ textAlign: 'center', marginBottom: 5 }} variant='h5'>Account not found</Typography>
      <Typography style={{ textAlign: 'center', marginBottom: 8 }} variant='body2'>Click below to create the account</Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalOpen(true)}
      >
        Create Account
      </Button>
    </div>
  )

  return (
    <div style={{ display: 'flex', minHeight: 500 }}>
      <div style={{ width: '65%' }}>
        <div style={{ display: 'flex', alignItems: 'center', height: 51, borderBottom: 'solid rgba(0,0,0,0.12) 1px', paddingLeft: 10, paddingRight: 10 }}>
          <SearchBar
            searchString={search}
            setSearchString={setSearch}
          />
        </div>
        {!isLoading ? (
          <div>
            {search ? (
              companies?.list.length > 0 ? (
                <div>
                  <CompanyCardList
                    rows={companies?.list || []}
                    isLoading={isLoading}
                    selectedList={selectedCompanies}
                    onChange={(obj: Company) => handleCheckChange(obj)}
                  />
                </div>
              ) : accountNotFound
            ) : emptyState}
          </div>
        ) : (
          <div style={{ minHeight: 500 }}>
            <Loader type='spinner' />
          </div>
        )}
      </div>
      <div style={{ width: '35%', border: 'solid rgba(0,0,0,0.12) 1px' }}>
        <div style={{ borderBottom: 'solid rgba(0,0,0,0.12) 1px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: 51 }}>
          <Typography>{selectedCompanies.length} selected</Typography>
          <Button onClick={() => handleUnselectAll()}>Unselect All</Button>
        </div>
        <div>
          <CompanyCardList
            rows={selectedCompanies}
            variant='small'
            onRemove={(obj: Company) => handleCheckChange(obj)}
          />
        </div>
      </div>
      <AccountModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        handleSubmit={handleSubmit}
        handleCSVImport={handleCSVImport}
        CSVImportErrors={csvImportErrors}
      />
    </div>
  )
}
