import { ChatSettings } from 'classes/chatSettings';
import { TextBox } from 'library/materialUI';
import { useState } from 'react';
import { ContentsProps } from './wizard/OnboardingWizard';

export const domainSubtitle = "Enter your organization's domain below. For example, if your " +
'website is https://www.mydomain.com, you would enter mydomain.com.'

const isDomainValid = (domain: string) => {
  if (domain.length < 5) {
    return false
  } else if (!domain.includes('.')) {
    return false
  } else if (domain.indexOf('.') > domain.length - 3) {
    return false
  }
  return true
}

export function DomainSelection (props: ContentsProps) {
  const { data } = ChatSettings.loadOne()
  const startingState = data?.domains?.length ? data.domains[0] : ''
  const [state, setState] = useState(startingState)

  props.handleNextRef.current = () => {
    const updates: Partial<ChatSettings> = {
      domains: [state],
      excludedDomains: [state]
    }
    ChatSettings.update(updates)
  }

  if (isDomainValid(state)) {
    props.setDisabled(false)
  } else {
    props.setDisabled(true)
  }

  return (
    <div>
      <TextBox
        value={state}
        onChange={(value: string) => setState(value)}
        label='Domain'
      />
    </div>
  )
}
