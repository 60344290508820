import { ChatSettings } from 'classes/chatSettings';
import User from 'classes/users';
import Loader from 'library/loading/Loader';
import { OnboardingWizard, WizardSectionType } from 'pages/onboarding/wizard/OnboardingWizard';
import { DomainSelection, domainSubtitle } from '../DomainSelection';
import { InstallationSection, installationSubtitle } from 'pages/onboarding/Installation';

const snapshotSteps = [
  'snapshot',
  'domain',
  'installation',
  'integrations'
]

type WizardProps = {
  open: boolean
  handleFinish: () => void
}

export function SnapshotIntroWizard ({ open, handleFinish }: WizardProps) {
  ChatSettings.loadOne()
  const { data: user, isLoading } = User.loadMe()
  const initialState = {
    open: open,
    step: 0
  }

  if (isLoading) {
    return <Loader type='spinner' />
  }

  const snapshotSections: Record<string, WizardSectionType> = {
    snapshot: {
      title: 'Welcome to Signals Snapshot, ' + user.firstName + '!',
      subtitle: 'Let\'s get you set up. It\'ll take just a few minutes to set up your profile and get everything up and running.',
      contents: () => <></>
    },
    domain: {
      title: 'Set your domain',
      subtitle: domainSubtitle,
      contents: DomainSelection
    },
    installation: {
      title: 'Signals Installation',
      subtitle: installationSubtitle,
      contents: InstallationSection
    },
    integrations: {
      title: 'Connect & Sync your CRM',
      subtitle: 'Signals Snapshot requires that you connect your CRM. This allows you access to Signal\'s vast source of account information.',
      contents: () => <></>
    }
  }

  return (
    <OnboardingWizard
      sections={snapshotSections}
      steps={snapshotSteps}
      initialState={initialState}
      title='Snapshot Setup'
      handleFinish={handleFinish}
    />
  )
}
