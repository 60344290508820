import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { PopoverDiv } from 'pages/plays/playBuilder/nodes/PopoverDiv'

const useStyles = makeStyles(theme => ({
  selector: {
    height: '42px',
    color: '#6B7280',
    border: '1px solid #D1D5DB',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    flexDireciton: 'row',
    flexWrap: 'wrap',
    width: '234px',
    height: '234px',
    padding: '5px'
  },
  iconCell: {
    width: '54px',
    height: '54px',
    border: '1px solid #D1D5DB',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '10px'
  }
}))

interface IconPickerProps {
  value: string
  options: string[]
  onChange: (v: any) => void
  label: string
}

export default function IconPicker ({ value, onChange }: IconPickerProps): JSX.Element {
  const classes = useStyles()
  const iconOptions = [
    { label: 'calendarPick', value: 'calendar' },
    { label: 'chartPick', value: 'chart' },
    { label: 'chatPick', value: 'chat' },
    { label: 'contactPick', value: 'contact' },
    { label: 'giftPick', value: 'gift' },
    { label: 'globePick', value: 'globe' },
    { label: 'groupPick', value: 'group' },
    { label: 'locationPick', value: 'location' },
    { label: 'ticketPick', value: 'ticket' }
  ]

  return (
    <div>
      <PopoverDiv
        popoverContents={
          <div className={classes.container}>
            {iconOptions.map((icon, index) => {
              return (
                <div onClick={() => onChange(icon.value)} key={index} className={classes.iconCell} style={{ backgroundColor: icon.value === value ? '#F0F0F0' : undefined }}>
                  <Icon icon={icon.label} color='#0CA6F9' />
                </div>
              )
            })}
          </div>
        }
        divContents={
          <div className={classes.selector}>
            <div style={{ marginLeft: '10px' }}>
              {!value ? 'Select Icon' : value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
            <Icon icon='expand' />
          </div>
        }
      />
    </div>
  )
}
