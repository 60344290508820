/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, Reducer, useReducer } from 'react'
import Account from 'classes/accounts'
import { revenue_values, employee_counts } from '../definedFields'
import { ICP, ICPFilters, ICPRequiredFields, initialICPFilters } from 'classes/icp';
import { camelCase, snakeCase } from 'change-case';

export { revenue_values, employee_counts, getRevenueRange, getEmployeeRange } from '../definedFields'

export const validICPFilters = (filters: ICPFilters<'formatted'>): boolean => {
  const revMin = filters.annualRevenue?.gt
  const revMax = filters.annualRevenue?.lt
  const validRev = revMin !== initialICPFilters.annualRevenue.gt || revMax !== initialICPFilters.annualRevenue.lt
  const empMin = filters.employeeCount?.gt || initialICPFilters.employeeCount.gt
  const empMax = filters.employeeCount?.lt || initialICPFilters.employeeCount.lt
  const validEmp = empMin !== initialICPFilters.employeeCount.gt || empMax !== initialICPFilters.employeeCount.lt
  if (filters.country?.length ||
    filters.industry?.length ||
    validRev ||
    validEmp ||
    filters.technologies?.length
  ) {
    return true
  }
  return false
}

export const icpFiltersToSegments = (filters: ICPFilters<'formatted'>): ICPFilters<'formatted'>[] => {
  const mappedFilters: ICPFilters<'formatted'> = { ...filters }
  if (filters.rawData) {
    mappedFilters.rawData = filters.rawData
  }
  return [mappedFilters]
}

/**
 * Check if an account is in a list of segments
 *
 * If it is not, you will receive an array of the fields that failed
 */
export const isAccountInSegment = (segments: ICPFilters<'formatted'>[], account: Account, icp: ICP): true | string[] => {
  const reasons = [] as string[]
  if (!account.country && !account.industry && account.annualRevenue === 0 && account.employeeCount === 0) {
    reasons.push('country')
    reasons.push('industry')
    reasons.push('technologies')
    reasons.push('annualRevenue')
    reasons.push('employeeCount')
    const evaluatedReasons = evaluateReasons(reasons, icp)
    if (evaluatedReasons) {
      return evaluatedReasons
    }
  }
  for (const segment of segments) {
    if (segment.country && segment.country.length > 0 && !segment.country.includes(account.country)) reasons.push('country')
    if (segment.industry && segment.industry.length > 0 && !segment.industry.includes(account.industry || '')) reasons.push('industry')
    if (segment.technologies && segment.technologies.length > 0) {
      if (icp.filterComparators.technologies === 'has_one' && !account.technologies?.some(t => segment.technologies?.includes(t))) reasons.push('technologies')
      if (icp.filterComparators.technologies === 'has_all' && !segment.technologies?.every(t => account.technologies?.includes(t))) reasons.push('technologies')
      if (icp.filterComparators.technologies === 'not_has' && account.technologies?.some(t => segment.technologies?.includes(t))) reasons.push('technologies')
    }
    if (segment.annualRevenue) {
      if (segment.annualRevenue.gt && revenueValueMap[segment.annualRevenue.gt] > account.annualRevenue) reasons.push('annualRevenue')
      if (segment.annualRevenue.lt && revenueValueMap[segment.annualRevenue.lt] < account.annualRevenue) reasons.push('annualRevenue')
    }
    if (segment.employeeCount) {
      if (segment.employeeCount.gt && employeeValueMap[segment.employeeCount.gt] > account.employeeCount) reasons.push('employeeCount')
      if (segment.employeeCount.lt && employeeValueMap[segment.employeeCount.lt] < account.employeeCount) reasons.push('employeeCount')
    }
  }

  if (missingAccountData({ account, icpFilters: segments })) {
    return []
  }

  if (reasons.length) {
    const removedDuplicates = Array.from(new Set(reasons))
    const evaluatedReasons = evaluateReasons(removedDuplicates, icp)
    if (evaluatedReasons) {
      return evaluatedReasons
    }
    return true
  }
  return true
}

/**
 * Evaluates the reasons for an account not being in ICP
 *
 * Determines if reasons are required or not
 *
 * Returns false if should be included, otherwise list of reasons for exclusion
 */
const evaluateReasons = (reasons: string[], icp: ICP): string[] | false => {
  const requiredCount = getRequiredCount(icp.requiredFields)
  const requiredAndMatchDiff = requiredCount - icp.requiredMatchCount
  const requiredFieldsArray = Object.keys(icp.requiredFields).filter(function (k) { return icp.requiredFields[k as keyof ICPRequiredFields] }).map(String)
  const reasonsAndRequiredIntersectionArray = requiredFieldsArray.filter(function (n) {
    if (n === 'location') return reasons.indexOf('country') !== -1
    return reasons.indexOf(camelCase(n)) !== -1
  })

  // reasons contains a requiredField
  if (reasonsAndRequiredIntersectionArray.length) {
    // we are ignoring optional fields
    if (!requiredAndMatchDiff) {
      const requiredReasons = reasons.filter(reason => {
        if (reason === 'country') return requiredFieldsArray.includes('location')
        return requiredFieldsArray.includes(snakeCase(reason))
      })
      return requiredReasons
    } else {
      return reasons
    }
  }

  // more fields than just the ones marked required are needed
  if (requiredAndMatchDiff) {
    const totalCountOfRequiredFields = Object.entries(icp.requiredFields).map((field) => field).length
    const maxNumberOfAllowedReasons = totalCountOfRequiredFields - icp.requiredMatchCount
    // there are more reasons than allowed
    if (reasons.length > maxNumberOfAllowedReasons) {
      return reasons
    } else {
      return false
    }
  }
  return false
}

/**
 * Looks to see if account has at least one column of data
 *
 * key from icpfilters are looked for on the account, they need to match
 *
 * Returns false if there is data, otherwise returns true
 */
const missingAccountData = ({ account, icpFilters }: { account: Account, icpFilters: ICPFilters<'formatted'>[] }): boolean => {
  const filters = icpFilters[0]
  const keys: Array<keyof Account> = []
  let count = 0
  for (const [key] of Object.entries(filters)) {
    keys.push(key as keyof Account)
  }
  keys.forEach((key) => {
    if (!account[key]) {
      count += 1
    }
  })
  if (count === keys.length) {
    return true
  }
  return false
}

export function useSimpleReducer<T extends Record<string, any>> (initialState: T): [T, Dispatch<Partial<T>>] {
  const reducer: Reducer<T, Partial<T>> = (state, changes) => ({ ...state, ...changes })
  return useReducer(reducer, initialState)
}

export const singleIcpReducer = (state: ICPFilters<'formatted'>, changes: Partial<ICPFilters<'formatted'>>): ICPFilters<'formatted'> => {
  return { ...state, ...changes }
}

export const getRequiredCount = (requiredFields: ICP['requiredFields']) => {
  let count = 0
  for (const [, value] of Object.entries(requiredFields)) {
    if (value) count += 1
  }
  return count
}
export const getMaxRequired = (filters: ICPFilters<'formatted'> | undefined) => {
  let maxRequired = 0
  if (!filters) return maxRequired

  if ((filters?.annualRevenue?.gt && filters.annualRevenue.gt !== initialICPFilters.annualRevenue.gt) || (filters?.annualRevenue?.lt && filters.annualRevenue.lt !== initialICPFilters.annualRevenue.lt)) {
    maxRequired++
  }
  if ((filters?.employeeCount?.gt && filters.employeeCount.gt !== initialICPFilters.employeeCount.gt) || (filters?.employeeCount?.lt && filters.employeeCount.lt !== initialICPFilters.employeeCount.lt)) {
    maxRequired++
  }
  if (filters?.industry && filters.industry.length > 0) {
    maxRequired++
  }
  if ((filters?.country && filters.country.length > 0) || (filters?.state && filters.state.length > 0)) {
    maxRequired++
  }
  if ((filters?.technologies && filters.technologies.length > 0)) {
    maxRequired++
  }
  return maxRequired
}

export const handleSetRequiredFields = (setLocalICP: Dispatch<Partial<ICP>>, icp: ICP, requiredField: keyof ICPRequiredFields) => {
  const currentVal = icp.requiredFields[requiredField]
  const clonedRequiredFields = { ...icp.requiredFields }
  clonedRequiredFields[requiredField] = !currentVal
  const requiredCount = getRequiredCount(clonedRequiredFields)
  let setterObj: Partial<ICP> = { requiredFields: clonedRequiredFields }
  if (requiredCount > icp.requiredMatchCount) {
    setterObj = { ...setterObj, requiredMatchCount: requiredCount }
  }
  setLocalICP(setterObj)
}

export const revenueTextMap = [
  '$1',
  '$1M',
  '$5M',
  '$10M',
  '$25M',
  '$50M',
  '$100M',
  '$250M',
  '$500M',
  '$1B',
  '$2.5B',
  '$5B',
  '$5B+'
]

export const revenueValueMap = [
  1,
  1000000,
  5000000,
  10000000,
  25000000,
  50000000,
  100000000,
  250000000,
  500000000,
  1000000000,
  2500000000,
  5000000000
]

export const getRevenueIndex = (revenue: number): number => {
  if (revenue === 0 || revenue === 1) {
    return 0
  } else if (revenue > 1 && revenue <= 1000000) {
    return 1
  } else if (revenue > 1000000 && revenue <= 5000000) {
    return 2
  } else if (revenue > 5000000 && revenue <= 10000000) {
    return 3
  } else if (revenue > 10000000 && revenue <= 25000000) {
    return 4
  } else if (revenue > 25000000 && revenue <= 50000000) {
    return 5
  } else if (revenue > 50000000 && revenue <= 100000000) {
    return 6
  } else if (revenue > 100000000 && revenue <= 250000000) {
    return 7
  } else if (revenue > 250000000 && revenue <= 500000000) {
    return 8
  } else if (revenue > 500000000 && revenue <= 1000000000) {
    return 9
  } else if (revenue > 1000000000 && revenue <= 2500000000) {
    return 10
  } else if (revenue > 2500000000 && revenue <= 5000000000) {
    return 11
  } else {
    return 12
  }
}

export const noRestrictionAnnualRevenue = { gt: revenueValueMap[0], lt: revenueValueMap[revenueValueMap.length - 1] }

export const employeeTextMap = [
  '1',
  '10',
  '20',
  '50',
  '100',
  '250',
  '500',
  '1,000',
  '1,500',
  '2,000',
  '2,500',
  '5,000',
  '10,000',
  '10,000+'
]

export const employeeValueMap = [
  1,
  10,
  20,
  50,
  100,
  250,
  500,
  1000,
  1500,
  2000,
  2500,
  5000,
  10000
]

export const getEmployeeIndex = (employeeCount: number): number => {
  if (employeeCount === 0 || employeeCount === 1) {
    return 0
  } else if (employeeCount > 1 && employeeCount <= 10) {
    return 1
  } else if (employeeCount > 10 && employeeCount <= 20) {
    return 2
  } else if (employeeCount > 20 && employeeCount <= 50) {
    return 3
  } else if (employeeCount > 50 && employeeCount <= 100) {
    return 4
  } else if (employeeCount > 100 && employeeCount <= 250) {
    return 5
  } else if (employeeCount > 250 && employeeCount <= 500) {
    return 6
  } else if (employeeCount > 500 && employeeCount <= 1000) {
    return 7
  } else if (employeeCount > 1000 && employeeCount <= 1500) {
    return 8
  } else if (employeeCount > 1500 && employeeCount <= 2000) {
    return 9
  } else if (employeeCount > 2000 && employeeCount <= 2500) {
    return 10
  } else if (employeeCount > 2500 && employeeCount <= 5000) {
    return 11
  } else if (employeeCount > 5000 && employeeCount <= 10000) {
    return 12
  } else {
    return 13
  }
}

export const noRestrictionEmployeeCount = { gt: employeeValueMap[0], lt: employeeValueMap[employeeValueMap.length - 1] }

export const revenueRangeConfig = {
  min: 0,
  dots: true,
  pushable: 1,
  max: revenue_values.length,
  marks: {
    0: revenueTextMap[0],
    1: revenueTextMap[1],
    3: revenueTextMap[3],
    5: revenueTextMap[5],
    8: revenueTextMap[8],
    11: revenueTextMap[11],
    12: '+'
  }
}

export const employeeRangeConfig = {
  min: 0,
  dots: true,
  pushable: 1,
  max: employee_counts.length,
  marks: {
    0: employeeTextMap[0],
    4: employeeTextMap[4],
    7: employeeTextMap[7],
    10: employeeTextMap[10],
    12: employeeTextMap[12],
    13: '+'
  }
}

export const getRevenueDisplay = ([low, high]: [number, number]): string => {
  if (low < 1 && high > 11) {
    return 'No Restriction'
  }
  if (high > 11) {
    return `${revenueTextMap[low]}+`
  }
  return `${revenueTextMap[low]} - ${revenueTextMap[high]}`
}

export const getEmployeeDisplay = ([low, high]: [number, number]): string => {
  if (low < 1 && high > 12) {
    return 'No Restriction'
  }
  if (high > 12) {
    return `${employeeTextMap[low]}+`
  }
  return `${employeeTextMap[low]} - ${employeeTextMap[high]}`
}

export const COUNTRIES = [
  'United States',
  'Andorra',
  'Argentina',
  'Australia',
  'Austria',
  'Bahamas',
  'Belgium',
  'Brazil',
  'Brunei',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Egypt',
  'Ethiopia',
  'Finland',
  'France',
  'Germany',
  'Ghana',
  'Greece',
  'Guam',
  'Guinea',
  'Hong Kong',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'Kuwait',
  'Libya',
  'Luxembourg',
  'Macao',
  'Malaysia',
  'Malta',
  'Mexico',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nigeria',
  'Northern Mariana Islands',
  'Norway',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Russia',
  'Saudi Arabia',
  'San Marino',
  'Singapore',
  'South Africa',
  'South Korea',
  'Spain',
  'Sweden',
  'Switzerland',
  'Thailand',
  'Turkey',
  'United Arab Emirates',
  'United Kingdom'
]

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]
