import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'

interface AccountTypeList {
  list: AccountType[]
  dict: { [id: AccountType['id']]: AccountType }
}

const initialState = {
  label: '',
  default: false
}

const defaultOptionIds = [
  'Prospect',
  'Customer - Direct',
  'Customer - Channel',
  'Channel Partner / Reseller',
  'Installation Partner',
  'Investor',
  'Partner',
  'Press',
  'Competitor',
  'Other'
]

export class AccountType extends GenericObject {
  name: string
  default: boolean

  static loadAll (): { data: AccountTypeList, isLoading: boolean } {
    const result = useDoQuery<AccountTypeList>({ path: '/api/integrations/crm/accounts/fields/type/options', objectClass: AccountType, initialData: { list: [], dict: {} } })
    // Provide a default list if no crm integration is enabled
    if (result.isError && result.error.message.includes('no integrations enabled')) {
      const defaultOptionsList: AccountType[] = defaultOptionIds.map((accountId) => new AccountType({
        row: {
          id: accountId,
          type: 'field_option',
          attributes: {
            label: accountId,
            value: accountId,
            default: false
          }
        }
      }))
      const defaultOptionsDict = defaultOptionsList.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [id: AccountType['id']]: AccountType });
      result.data = {
        list: defaultOptionsList,
        dict: defaultOptionsDict
      }
    }
    return result
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const accountType = row?.attributes || initialState
    this.name = accountType.label
    this.default = accountType.default
  }
}
