import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { InputAdornment } from '@material-ui/core'
import { Typography, Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  passwordRequirements: {
    height: 'auto',
    marginTop: 16
  },
  topTextBox: {
    marginBottom: 8,
    marginTop: 0
  },
  bottomTextBox: {
    marginTop: 16,
    marginBottom: 4
  },
  icon: {
    marginRight: 8
  },
  textBoxContainer: {
    display: 'flex'
  },
  visibility: {
    cursor: 'pointer'
  },
  listItem: {
    marginBottom: 8
  },
  criteriaContainer: {
    display: 'flex',
    marginTop: 8,
    alignItems: 'center'
  },
  list: {
    marginTop: 8
  }
}))

const passwordCriteria = [
  '• An uppercase letter',
  '• A lowercase letter',
  '• A number',
  '• A special character'
]

export function checkLength (value) {
  return value && value.length >= 10
}

export function checkComplexity (value) {
  if (!value) return false
  let count = 0
  if (/[A-Z]/.test(value)) count++
  if (/[a-z]/.test(value)) count++
  if (/[0-9]/.test(value)) count++
  if (/[!@#$%^&*]/.test(value)) count++
  return count >= 2
}

export function isValidPassword (value) {
  return checkLength(value) && checkComplexity(value)
}

export function PasswordConfirmationBlock (props) {
  const classes = useStyles()
  const [lengthCheck, setLengthCheck] = useState(false)
  const [complexityCheck, setComplexityCheck] = useState(false)

  useEffect(() => {
    setLengthCheck(props.password && checkLength(props.password))
    if (props.password) {
      setComplexityCheck(checkComplexity(props.password))
    } else {
      setComplexityCheck(false)
    }
  }, [props.password])

  return (
    <>
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        id='password'
        label={props.textLabel || 'Password'}
        name='password'
        type={props.visible ? 'text' : 'password'}
        error={props.passwordError}
        autoFocus={props.autoFocus}
        onChange={props.handleChange}
        onBlur={e => {
          props.handleBlur(e)
          props.setFocused(false)
        }}
        onFocus={() => props.setFocused(true)}
        className={classes.topTextBox}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {props.visible ? (
                <VisibilityIcon
                  onClick={() => props.setVisibility(false)}
                  className={classes.visibility}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => props.setVisibility(true)}
                  className={classes.visibility}
                />
              )}
            </InputAdornment>
          )
        }}
      />
      {!props.hideRequirements &&
        <div className={classes.passwordRequirements}>
          <Typography variant='h2'>Your password must:</Typography>
          <div className={classes.criteriaContainer}>
            <div className={classes.icon}>
              <Icon icon={lengthCheck ? 'lightGreenCheckCircle' : 'uncheckedRadio'} specificSize={15} />
            </div>
            <Typography variant='body2'>Be at least 10 characters long</Typography>
          </div>
          <div className={classes.criteriaContainer}>
            <div className={classes.icon}>
              <Icon icon={complexityCheck ? 'lightGreenCheckCircle' : 'uncheckedRadio'} specificSize={15} />
            </div>
            <Typography variant='body2'>Include at least two of the following:</Typography>
          </div>
          <ul className={classes.list}>
            {passwordCriteria.map((criteria, index) => (
              <li key={index} className={classes.listItem}>
                <Typography variant='body2'>{criteria}</Typography>
              </li>
            ))}
          </ul>
        </div>}
    </>
  )
}

export default PasswordConfirmationBlock
