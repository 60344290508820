import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'

const useStyles = makeStyles(theme => ({
  textField: {
    width: 450,
    marginBottom: 15
  },
  iconButton: {
    marginTop: '4px'
  }
}))

function SocialMediaLink (props) {
  const classes = useStyles()
  const GetIcon = () => {
    switch (props.name) {
      case 'Twitter':
        return <TwitterIcon />
      case 'LinkedIn':
        return <LinkedInIcon />
      case 'Facebook':
        return <FacebookIcon />
      case 'Instagram':
        return <InstagramIcon />
      case 'YouTube':
        return <YouTubeIcon />
      default:
        return <></>
    }
  }

  const CustomIcon = GetIcon()

  function handleChange (event) {
    const value = event.target.value.trim()
    const name = props.name.toLowerCase()
    props.handler(name, value)
  }

  const disabled = props.disabled
  const buttonDisabled = props.url === ''
  // let urlRegex = "^[a-zA-Z]+://";
  // let url = !props.url.match(urlRegex) ? "https://" + props.url : props.url;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        id={props.name}
        className={classes.textField}
        value={props.url}
        disabled={disabled}
        onChange={handleChange}
        margin='dense'
        variant='outlined'
      />
      <IconButton
        className={classes.iconButton}
        disabled={buttonDisabled}
        color='primary'
        target='_blank'
        href={props.url}
      >
        {CustomIcon}
      </IconButton>
    </div>
  )
}

export default SocialMediaLink
