/* eslint-disable @typescript-eslint/naming-convention */
import { Segment, SegmentFilter, SegmentFilterValue } from 'classes/segments'
import { snakeCase } from 'change-case';
import { FilterComp, QueryFilter, SearchParams } from 'classes/queryHelpers';
import Company from 'classes/companies';

export const defaultSegment: Segment = new Segment(
  {
    row: {
      attributes: {
        triggers: [{ filters: [] }],
        segment_type: 'dynamic'
      }
    }
  }
)

export const getSegmentColor = (index: number): string => index % 2 ? '#fe9d10' : '#8e5ae2'

export const capitalizeFirstLetter = <Str extends string> (val: Str): Capitalize<Str> => val.charAt(0).toUpperCase() + val.slice(1) as Capitalize<Str>

export function getCmp (cmp: string, prop: string) {
  if (cmp === 'is' && prop === 'accountType') {
    return 'in'
  } else if (cmp === 'is' && prop === 'industry') {
    return 'eq'
  } else { return cmp }
}

export function createSearchParams (triggers: SegmentFilter[], companyName: string) {
  const queryFiltersList: QueryFilter[][] = []
  const queryFilters: QueryFilter[] = []
  let searchParams: SearchParams = { sortColumn: 'name', sortOrder: 'desc', pageNumber: 1, filters: queryFiltersList }
  triggers.map((trigger: SegmentFilter) => {
    trigger.filters.map(({ cmp, prop, value }: { cmp: string, prop: string, value: SegmentFilterValue }) => {
      if (cmp === '' || prop === '' || value === '') {
        return null
      }
      const filter: QueryFilter = { field: snakeCase(prop), operator: getCmp(cmp, prop) as FilterComp, value: value }
      queryFilters.push(filter)
      return null
    })
    return null
  })
  if (companyName) {
    const search = { search: companyName }
    searchParams = { ...searchParams, ...search }
  }
  if (queryFilters.length > 0) {
    queryFiltersList.push(queryFilters)
  }
  return searchParams
}

export function getSegmentFilters (newSelectedCompanies: Company[]) {
  const newFilters: SegmentFilter[] = newSelectedCompanies.map(company => {
    const newFilter = {
      filters: [{
        cmp: 'eq',
        prop: 'name',
        value: company.name
      }]
    }
    return newFilter
  })

  return newFilters
}

export function createHeaders (headers: Array<string>) {
  const headerMapping: Array<{label: string, api_name: string, data_type: string}> = []
  headers.map(headerString => {
    headerMapping.push({ label: headerString, api_name: headerString, data_type: headerString })
    return null
  })
  return headerMapping
}
