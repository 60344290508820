import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { FormControlLabel, Checkbox, Button, TextField, Typography } from '@material-ui/core'
import { ListItemCard } from '../../cf-components/CardList'
import { Link } from 'react-router-dom'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import LinkIcon from '@material-ui/icons/Link'
import ActionsMenu from '../../components/ActionsMenu'
import Table from 'cf-components/Table'
import SocialPage from './SocialMediaPage'
import Card from 'library/materialUI/Card'
import { Icon } from 'library/materialUI'
import { IconButton } from 'library/materialUI/Button'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import IconPicker from 'components/IconPicker'
import QRCodeCanvas from 'qrcode.react'

const useStyles = makeStyles(theme => ({
  select: {
    borderRadius: 30
  },
  selectMenu: {
    padding: '5px 30px 5px 15px',
    minWidth: 30
  },
  link: {
    textDecoration: 'none'
  },
  archived: {
    display: 'inline-block',
    padding: 5,
    border: '1px solid rgba(0,0,0,0.6)',
    fontSize: '.85em',
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 600,
    borderRadius: 3
  },
  beakerIcon: {
    marginTop: 12,
    transform: 'scale(1.3)',
    fill: 'rgba(0, 135, 7, 0.7)'
  },
  table: {
    width: '100%',
    fontFamily: 'Poppins, sans serif'
  },
  meetingCardContents: {
    padding: 15,
    display: 'grid',
    gridTemplateColumns: '300px auto 200px 50px',
    gridGap: 10
  },
  botCard: {
    borderRadius: 5
  },
  centerColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10
  },
  meetingCardColumn: {
    display: 'flex',
    alignItems: 'center'
  },
  headerDiv: {
    display: 'flex',
    cursor: 'pointer'
  },
  meetingsHeader: {
    padding: '5px 10px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '300px auto 200px 50px',
    gridGap: 10
  },
  container: {
    display: 'flex',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: 'center'
  },
  addBtn: {
    textTransform: 'none',
    boxShadow: 'none',
    color: '#4338CA',
    backgroundColor: '#EEF2FF',
    '&:hover': {
      backgroundColor: '#E0E7FF',
      boxShadow: 'none'
    }
  }

}))

const MeetingTypeCard = (props) => {
  const classes = useStyles()
  const meeting = props.row

  let addOrRemoveAction = { name: 'Add to my Agent Page', action: () => props.buttonAction({ id: meeting.id }), icon: AddCircleOutlineOutlinedIcon }
  if (props.selected) {
    addOrRemoveAction = { name: 'Remove from my Agent Page', action: () => props.buttonAction({ id: meeting.id }), icon: RemoveCircleOutlineOutlinedIcon }
  }

  const actions = [addOrRemoveAction]

  if (props.canShare) {
    const canShare = { name: 'Copy Link', action: () => props.copyLink(meeting.id), icon: LinkIcon }
    actions.push(canShare)
  }

  return (
    <ListItemCard>
      <div className={classes.meetingCardContents}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ fontSize: '1em', color: 'rgba(0,0,0,0.8)' }}>
              {meeting.name}
            </div>
            <div style={{ fontSize: '0.8em', color: 'rgba(0,0,0,0.5)' }}>
              Meeting Type
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ fontSize: '1em', color: 'rgba(0,0,0,0.8)' }}>
              {meeting.description}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ fontSize: '1em', color: 'rgba(0,0,0,0.8)' }}>
              {meeting.lengthInMinutes} minutes
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ActionsMenu
            actions={actions}
          />
        </div>
      </div>
    </ListItemCard>
  )
}

const AgentProfileControl = props => {
  const info = props.info
  const classes = useStyles()
  const updateAgentProfileSettings = props.updateAgentProfileSettings
  const updateSocialLink = props.updateSocialLink
  const updateContactInfo = props.updateContactInfo
  const meetingTypes = props.meetingTypes
  const meetingSettingsError = props.meetingSettingsError
  const socialProfiles = props.profiles

  let prefix = ''
  let hostname = 'mychatfunnels.com'
  if (window.location.hostname === 'beta.chatfunnels.com') {
    // this will allow the agent-profile service to send requests to beta instead of app
    prefix = '0/'
  } else if (window.location.hostname === 'local.chatfunnels.dev') {
    hostname = 'local.chatfunnels.dev:12347'
  }
  const profileURL = `https://${hostname}/${prefix}${info.agent_profile_page_handle}`

  // conditional disable for the checkbox marked 'Enable "Chat with me" feature.'
  let disableChatWithMe = false
  if (!info.perms.send_chats || !info.display_agent_profile_page) {
    // disable if the profile page is not enabled or if the chat permission has not been enabled for the user
    disableChatWithMe = true
  }

  // conditional disable for the checkbox marked 'Allow visitors to book a meeting with me.'
  let disableBookMeeting = false
  if (!info.perms.book_meetings || !info.display_agent_profile_page || meetingSettingsError) {
    // disable if the user does not have the correct permissions, if the profile page has not been enabled, or if a user does not have their calendar set up
    disableBookMeeting = true
  }

  const sortValues = [
    { label: 'Name', id: 'name', header: true },
    { label: 'Description', id: 'description', header: true },
    { label: 'Duration', id: 'length', header: true }
  ]

  // This one is the property
  const userMeetingIds = info.agent_pages_meeting_type_ids || []

  const addMeetingTypeToList = ({ id }) => {
    userMeetingIds.push(id)
    updateContactInfo('agent_pages_meeting_type_ids', [...userMeetingIds])
  }

  const removeMeetingTypeFromList = ({ id }) => {
    const tempUserMeetingIds = userMeetingIds.filter(m_id => m_id !== id)
    updateContactInfo('agent_pages_meeting_type_ids', [...tempUserMeetingIds])
  }

  const copyMeetingLinkToClipboard = (id) => {
    navigator.clipboard.writeText(`${profileURL}?m_id=${id}`)
    props.onCopy()
  }

  if (!info) {
    return <></>
  }

  const selectedRows = []
  const unselectedRows = []
  const meetingTypeIds = Object.keys(meetingTypes)

  const downloadQRCode = () => {
    const qrCodeURL = document?.getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const ref = document.createElement('a')
    ref.href = qrCodeURL
    ref.download = 'QR_Code.png'
    document.body.appendChild(ref)
    ref.click()
    document.body.removeChild(ref)
  }

  for (const meetingTypeId of meetingTypeIds) {
    const meetingType = meetingTypes[meetingTypeId]
    const row = {
      name: meetingType.attributes.name,
      description: meetingType.attributes.description,
      id: meetingType.id,
      lengthInMinutes: meetingType.attributes.length_in_minutes,
      searchField: meetingType.attributes.name
    }

    if (userMeetingIds.includes(parseInt(meetingTypeId))) {
      selectedRows.push(row)
    } else {
      unselectedRows.push(row)
    }
  }

  return (
    <>
      <div>
        <Card
          content={
            <div>
              <h3>Agent Profile Settings</h3>
              <Divider />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={info.display_agent_profile_page}
                    onChange={(e) => updateContactInfo('display_agent_profile_page', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 20, display: 'block', width: 'fit-content' }}
                label='Enable my public profile page.'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disableChatWithMe}
                    checked={info.agent_profile_settings.display_chat_with_me}
                    onChange={(e) => updateAgentProfileSettings('display_chat_with_me', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 55, display: 'block', width: 'fit-content' }}
                label='Enable "Chat with me" feature.'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disableBookMeeting}
                    checked={info.agent_profile_settings.display_calendar}
                    onChange={(e) => updateAgentProfileSettings('display_calendar', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 55, display: 'block', width: 'fit-content' }}
                label={meetingSettingsError ? (<>Allow visitors to book a meeting with me. <Link to='/settings/my_settings/my_calendar'> ( Complete the calendar setup )</Link></>) : 'Allow visitors to book a meeting with me.'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!info.display_agent_profile_page}
                    checked={info.agent_profile_settings.allow_save_contact}
                    onChange={(e) => updateAgentProfileSettings('allow_save_contact', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 90, display: 'block', width: 'fit-content' }}
                label='Allow visitors to save your contact.'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!info.display_agent_profile_page}
                    checked={info.agent_profile_settings.allow_share_contact}
                    onChange={(e) => updateAgentProfileSettings('allow_share_contact', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 90, display: 'block', width: 'fit-content' }}
                label='Enable visitors to share your contact.'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!info.display_agent_profile_page}
                    checked={info.agent_profile_settings.allow_offers}
                    onChange={(e) => updateAgentProfileSettings('allow_offers', e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginLeft: 55, display: 'block', width: 'fit-content' }}
                label='Enable Offers'
              />
              {info.agent_profile_page_handle && (
                <>
                  <div style={{
                    display: info.display_agent_profile_page ? 'flex' : 'none',
                    alignItems: 'center',
                    backgroundColor: 'rgba(193, 166, 237, 0.1)',
                    padding: 20,
                    borderRadius: 10,
                    marginBottom: 20,
                    marginTop: 20,
                    marginLeft: 20,
                    width: 'fit-content'
                  }}
                  >
                    <div>
                      Your link is: <b>{profileURL}</b>
                    </div>
                    <Button
                      variant='outlined'
                      style={{ marginLeft: 20 }}
                      onClick={() => {
                        navigator.clipboard.writeText(`${profileURL}`)
                        props.onCopy()
                      }}
                    >
                      Copy to Clipboard
                    </Button>
                    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                      - or -
                    </div>
                    <div>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          downloadQRCode()
                          props.onDownload()
                        }}
                      >
                        Download QR Code
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          }
        />

        <br />
        {info.display_agent_profile_page && (
          <Card
            content={
              <SocialPage
                profiles={socialProfiles}
                updateSocialLink={updateSocialLink}
                updateAgentProfileSettings={updateAgentProfileSettings}
                info={info}
                editable
              />
            }
          />
        )}
        <br />

        {info.display_agent_profile_page && info.agent_profile_settings.allow_offers && (
          <Card
            content={
              <div>
                <h3>Offer Details</h3>
                <Divider />
                <br />
                {(info.agent_profile_settings.offers || []).map((offer, index) => (
                  <div className={classes.container} key={index}>
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant='subtitle2'>Offer {index + 1}</Typography>
                      <TextField
                        id={`offerTitle-${index}`}
                        label={<div style={{ color: '#9CA3AF' }}>Title</div>}
                        value={offer.title}
                        onChange={(e) => {
                          const updatedOffers = [...info.agent_profile_settings.offers]
                          updatedOffers[index].title = e.target.value
                          updateAgentProfileSettings('offers', updatedOffers)
                        }}
                        margin='dense'
                        variant='outlined'
                        fullWidth
                        style={{ marginBottom: 15 }}
                      />
                      <TextField
                        id={`offerLink-${index}`}
                        label={<div style={{ color: '#9CA3AF', display: 'flex' }}><div style={{ marginRight: 10 }}><Icon icon='newLink' size='sm' /></div>Link</div>}
                        value={offer.link}
                        onChange={(e) => {
                          const updatedOffers = [...info.agent_profile_settings.offers]
                          updatedOffers[index].link = e.target.value
                          updateAgentProfileSettings('offers', updatedOffers)
                        }}
                        margin='dense'
                        fullWidth
                        style={{ marginBottom: 25 }}
                        variant='outlined'
                      />
                      <IconPicker
                        value={offer.icon}
                        onChange={(icon) => {
                          const updatedOffers = [...info.agent_profile_settings.offers]
                          updatedOffers[index].icon = icon
                          updateAgentProfileSettings('offers', updatedOffers)
                        }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', padding: 10 }}>
                      <IconButton
                        onClick={() => {
                          const updatedOffers = info.agent_profile_settings.offers.filter((_, i) => i !== index)
                          updateAgentProfileSettings('offers', updatedOffers)
                        }}
                        icon='delete'
                      />
                    </div>
                  </div>
                ))}
                <Button
                  variant='contained'
                  style={{ marginLeft: 20, marginTop: 20 }}
                  className={classes.addBtn}
                  onClick={() => {
                    const newOffer = { title: '', link: '', icon: '' }
                    const existingOffers = info?.agent_profile_settings?.offers || []
                    updateAgentProfileSettings('offers', [...existingOffers, newOffer])
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                      <Icon icon='add' />
                    </div>
                    Add Additional Offer
                  </div>
                </Button>
              </div>
            }
          />
        )}
        <br />

        {info.display_agent_profile_page && info.agent_profile_settings.display_calendar && (
          <Card
            content={
              <div>
                <h3>Agent Profile Meetings</h3>
                <Divider />
                <br />
                <div className={classes.container}>
                  <div>
                    <Typography variant='subtitle2' style={{ marginBottom: 10 }}>Select meeting type</Typography>
                    <Select
                      value={info.agent_profile_settings.meeting_type_id}
                      onChange={(value) => updateAgentProfileSettings('meeting_type_id', value)}
                      options={meetingTypeIds.map(id => ({ value: id, label: meetingTypes[id].attributes.name }))}
                      width={300}
                      disableClear
                    />
                  </div>
                </div>
              </div>
            }
          />
        )}
        <br />
        <div style={{ marginLeft: 20 }}>
          <h4>My Meetings</h4>
          <Divider />
          <Typography variant='body2' style={{ marginLeft: 20, marginTop: 10 }}>
            Copy meeting links to use different scheduling types in emails.
          </Typography>
          {selectedRows.length ? (
            <Table
              rows={selectedRows}
              card={MeetingTypeCard}
              sortValues={sortValues}
              header
              headerClass={classes.meetingsHeader}
              startingSortColumn='name'
              cardProps={{
                selected: true,
                canShare: true,
                copyLink: copyMeetingLinkToClipboard,
                buttonAction: removeMeetingTypeFromList
              }}
            />
          )
            : (
              <div style={{
                textAlign: 'center',
                backgroundColor: '#e4e4e4',
                padding: 20,
                borderRadius: 5,
                margin: 20
              }}
              >
                No meetings selected.
              </div>
            )}
          <br />
          <div className={classes.meetingCardColumn} style={{ justifyContent: 'space-between', paddingRight: 20 }}>
            <h4>All Meetings</h4>
          </div>
          <Divider />
          {unselectedRows.length ? (
            <Table
              rows={unselectedRows}
              card={MeetingTypeCard}
              sortValues={sortValues}
              header
              headerClass={classes.meetingsHeader}
              cardProps={{
                buttonAction: addMeetingTypeToList,
                canShare: false
              }}
            />)
            : (
              <div style={{
                textAlign: 'center',
                backgroundColor: '#e4e4e4',
                padding: 20,
                borderRadius: 5,
                margin: 20
              }}
              >
                No meetings available.
              </div>
            )}
        </div>
      </div>
      <div style={{ display: 'none', position: 'absolute' }}>
        <QRCodeCanvas
          id='qrCodeEl'
          includeMargin
          size={350}
          value={profileURL}
        />
      </div>
    </>
  )
}

export default AgentProfileControl
