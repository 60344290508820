import { useState, useEffect, useContext, useCallback, Fragment, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GoogleAnalyticsIcon from 'img/ga_icon.png'
import SalesforceIcon from 'img/salesforce_icon.png'
import SalesforceLogo from 'img/salesforce.svg'
import HubSpotIcon from 'img/hubspot.svg'
import ZapierIcon from 'img/zapier_icon.png'
import SearchBar from 'components/SearchBar'
import { Redirect } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IntegrationCard from './IntegrationCard'
import DomoIcon from 'img/domo_icon.png'
import ClearbitIcon from 'img/clearbit_icon.png'
import MarketoIcon from 'img/marketo_icon.png'
import ZoomInfoIcon from 'img/zoominfo_icon.png'
import OktaLogo from 'img/okta-circle-logo.png'
import SalesloftLogo from 'img/Salesloft_Logo.png'
import HubSpotLogo from 'img/hubspotLogo.svg'
import { SessionContext } from 'session-context'
import { getIntegrations, INTEGRATIONS, getIntegrationFeature, sendIntegrationFeatureRequest, getIntegrationFeatureRequest, getAPIKeys } from 'api/integrations'
import EloquaLogo from 'img/eloqua_logo.png'
import SlackIcon from 'img/slack_icon.png'
import ZendeskLogo from 'img/zendesk_logo.svg'
import GoogleAdsLogo from 'img/google_ads_logo.svg'
import TeamsIcon from 'img/ms_teams_logo.png'
import HelpdocsLogo from 'img/helpdocs_logo.png'
import BrevoLogo from 'img/brevo_logo.png'
import SalesloftName from 'img/SalesloftWordmark.svg'
import ConnectAndSellIcon from 'img/connectandsell.png'
import ConnectAndSellLogo from 'img/ConnectAndSellLogo.png'
import { Button, TextField } from 'library/materialUI'
import { Modal } from 'cf-components/Modal'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { ShareContext } from 'share-context'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  searchBar: {
    width: '20%',
    minWidth: 200,
    height: '100%',
    marginLeft: 40,
    marginRight: 40,
    marginTop: 30
  },
  integrationsDashboard: {
    height: '100%',
    width: '85%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    padding: '20px 20px 10px 20px',
    boxShadow: '0px 4px 4px -5px rgba(0,0,0,0.5)',
    height: 40
  },
  integrationCard: {
    width: 285,
    margin: 15,
    marginTop: 30,
    marginBottom: 30
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  icon: {
    width: 40
  },
  description: {
    height: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2'
  },
  categoryTitle: {
    fontWeight: 'bold',
    fontSize: 18
  },
  category: {
    fontSize: 16,
    paddingBottom: 5
  },
  manageBlock: {
    marginTop: 30,
    marginBottom: 40
  },
  crmHelpContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: 10,
    marginTop: 30,
    marginLeft: 15,
    backgroundColor: '#9933FF1A',
    border: '1px solid #9933FF',
    borderRadius: 5
  },
  crmButton: {
    marginLeft: 10
  }
})

export const FullIntegrationsList = [
  {
    key: INTEGRATIONS.GoogleAnalytics,
    path: '/settings/google-analytics-sync',
    name: 'Google Analytics',
    icon: GoogleAnalyticsIcon,
    description: 'Sync events from Signals to Google Analytics ',
    required_perm: 'tenant_google_analytics',
    category: 'analytics'
  },
  {
    key: INTEGRATIONS.GoogleAds,
    flag: 'google-ads',
    path: '/settings/google-ads',
    name: 'Google Ads',
    icon: GoogleAdsLogo,
    description: 'Sync conversion actions like conversations, emails captured, and meetings booked to Google Ads',
    required_perm: 'tenant_google_analytics',
    category: 'advertising'
  },
  {
    key: INTEGRATIONS.Zapier,
    path: '/settings/zapier',
    name: 'Zapier',
    icon: ZapierIcon,
    description: 'Send events and contacts to other applications using Zapier',
    required_perm: false,
    category: 'automation'
  },
  {
    key: INTEGRATIONS.Salesforce,
    path: '/settings/salesforce',
    name: 'Salesforce',
    icon: SalesforceIcon,
    appLogo: SalesforceLogo,
    description: 'Use Salesforce for account ownership, segment creation and send new leads and contacts to Salesforce from Signals',
    required_perm: 'tenant_salesforce',
    category: 'CRM'
  },
  {
    key: INTEGRATIONS.Domo,
    path: '/settings/domo',
    name: 'Domo',
    icon: DomoIcon,
    description: 'Sync Signals data to Domo for analysis, visualization, and sharing',
    required_perm: 'tenant_domo',
    category: 'analytics'
  },
  {
    key: INTEGRATIONS.Marketo,
    path: '/settings/marketo',
    name: 'Marketo',
    icon: MarketoIcon,
    description:
      'Add contacts to Marketo',
    required_perm: 'tenant_marketo',
    category: 'CRM'
  },
  {
    key: INTEGRATIONS.Clearbit,
    path: '/settings/clearbit',
    name: 'Clearbit',
    icon: ClearbitIcon,
    description:
      'Enrich data on contacts you collect in Signals',
    required_perm: 'tenant_clearbit',
    category: 'enrichment'
  },
  {
    key: INTEGRATIONS.ZoomInfo,
    path: '/settings/zoominfo',
    name: 'ZoomInfo',
    icon: ZoomInfoIcon,
    description:
      'Enrich data on contacts you collect in Signals',
    required_perm: 'tenant_zoominfo',
    category: 'enrichment'
  },
  {
    key: INTEGRATIONS.HubSpot,
    path: '/settings/hubspot',
    name: 'HubSpot',
    icon: HubSpotIcon,
    appLogo: HubSpotLogo,
    description:
      'Use HubSpot for account ownership, segment creation and send new leads and contacts to HubSpot from Signals',
    required_perm: 'tenant_hubspot',
    category: 'CRM'
  },
  {
    key: INTEGRATIONS.Sendinblue,
    path: '/settings/sendinblue',
    name: 'Brevo',
    icon: BrevoLogo,
    description:
      'Add contacts to Brevo',
    required_perm: 'tenant_sendinblue',
    category: 'CRM'
  },
  {
    key: INTEGRATIONS.Eloqua,
    path: '/settings/eloqua',
    name: 'Eloqua',
    icon: EloquaLogo,
    description:
      'Send contacts and leads to Eloqua',
    required_perm: 'tenant_eloqua',
    category: 'CRM'
  },
  {
    key: INTEGRATIONS.Slack,
    path: '/settings/slack',
    name: 'Slack',
    icon: SlackIcon,
    description:
      'Get alerts in Slack when visitors are on your site, start conversations, book meetings, etc.',
    required_perm: 'tenant_slack',
    category: 'communication'
  },
  {
    key: INTEGRATIONS.Zendesk,
    path: '/settings/zendesk',
    name: 'Zendesk',
    icon: ZendeskLogo,
    description:
      'Create tickets and integrate your Knowledge Base with Signals.',
    required_perm: ['tenant_zendesk_knowledgebase', 'tenant_zendesk_ticketing'],
    category: 'Customer Support'
  },
  {
    key: INTEGRATIONS.Teams,
    flag: 'msteams',
    path: '/settings/teams',
    name: 'Teams',
    icon: TeamsIcon,
    description:
      'Get alerts in Teams when visitors are on your site, start conversations, book meetings, etc.',
    required_perm: 'tenant_msteams',
    category: 'communication'
  },
  {
    key: INTEGRATIONS.Helpdocs,
    path: '/settings/helpdocs',
    name: 'HelpDocs',
    icon: HelpdocsLogo,
    description:
      'Integrate your HelpDocs Knowledge Base in chatbot flows to help visitors quickly find answers to FAQs.',
    required_perm: 'tenant_helpdocs_knowledgebase',
    category: 'Customer Support'
  },
  {
    key: INTEGRATIONS.Okta,
    path: '/settings/okta',
    name: 'Okta',
    icon: OktaLogo,
    description:
      'Easily and securely log in to Signals with Okta',
    required_perm: 'tenant_okta',
    category: 'SSO'
  },
  {
    key: INTEGRATIONS.Salesloft,
    path: '/settings/salesloft',
    name: 'Salesloft',
    icon: SalesloftLogo,
    appLogo: SalesloftName,
    description:
      'Enroll visitors in cadences and sync activity to and from Signals',
    required_perm: 'tenant_salesloft',
    category: 'Sales Engagement',
    flag: 'salesloft'
  },
  {
    key: INTEGRATIONS.ConnectAndSell,
    path: '/settings/connectandsell',
    name: 'ConnectAndSell',
    icon: ConnectAndSellIcon,
    appLogo: ConnectAndSellLogo,
    description:
      'See how your dialing efforts are translating into website traffic',
    required_perm: 'tenant_connectandsell',
    category: 'Sales Engagement',
    flag: 'connectandsell'
  }
]

const crmOptions = [
  { value: 'Pipedrive', label: 'Pipedrive' },
  { value: 'Zoho', label: 'Zoho' },
  { value: 'Microsoft Dynamics', label: 'Microsoft Dynamics' },
  { value: 'else', label: 'Something Else' }
]

export const integrationNames: Record<string, string> = {}
FullIntegrationsList.forEach(integration => {
  integrationNames[integration.name] = integration.name
})

const IntegrationsList = (props) => {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const { flags } = useContext(ShareContext)
  const [search, setSearch] = useState('')
  const [redirect, setRedirect] = useState()
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [activeIntegrations, setActiveIntegrations] = useState([])
  const [searchedIntegrations, setSearchedIntegrations] = useState([])
  const [unsupportedCRMModalOpen, setUnsupportedCRMModalOpen] = useState(false)
  const [crmSelect, setCRMSelect] = useState(null)
  const [crm, setCRM] = useState(null)
  const [crmIntegration, setCRMIntegration] = useState(null)
  const [supportedIntegration, setSupportedIntegration] = useState(null)
  const [requestedIntegration, setRequestedIntegration] = useState(false)

  const integrationsList = useMemo(() =>
    FullIntegrationsList.filter((f) => {
      if (f.flag === undefined) {
        return true
      }
      return flags[f.flag]
    }
    ), [flags])

  const categoryNames = Array.from(new Set(integrationsList.map(i => i.category)))
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))

  const handleClick = (integrationRoute) => {
    setRedirect(integrationRoute)
  }

  const hasPerm = (required) => {
    if (required === false) {
      return true
    }
    if (Array.isArray(required)) {
      for (let i = 0; i < required.length; i++) {
        if (user.attributes.perms.hasOwnProperty(required[i])) {
          return true
        }
      }
      return false
    } else {
      return user.attributes.perms.hasOwnProperty(required)
    }
  }

  const searchIntegration = useCallback(() => {
    if (search) {
      const filteredIntegrations = integrationsList.filter((integration) =>
        integration.name.toLowerCase().includes(search.toLowerCase())
      )
      setSearchedIntegrations(filteredIntegrations)
    }
  }, [integrationsList, search])

  useEffect(() => {
    if (integrationsList.length === 0) {
      setActiveIntegrations([])
    } else {
      getIntegrations()
        .then((response) => {
          const activeIntegrations = response.data
            .filter((integration) => integration.attributes.enabled && integration.attributes.integration_name !== 'apollo')
            .map((integration) => integration.attributes.integration_name)
          setActiveIntegrations(activeIntegrations)
        })
      getIntegrationFeature('crm.abe').then((response) => {
        setCRMIntegration(response.data.attributes)
      })
      getIntegrationFeatureRequest('crm.abe').then((response) => {
        if (response.data) {
          setRequestedIntegration(true)
        }
      }).catch((error) => {
        if (error.message.includes('not_found')) {
          setRequestedIntegration(false)
        } else {
          throw error
        }
      })
      getAPIKeys('Zapier')
        .then((response) => {
          if (response.data.length > 0) {
            setActiveIntegrations(activeIntegrations => [...activeIntegrations, 'zapier'])
          }
        })
    }
  }, [integrationsList.length])

  useEffect(() => {
    searchIntegration()
  }, [search, searchIntegration])

  if (redirect) {
    return <Redirect to={redirect} push />
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.searchBar}>
          <SearchBar search={search} setSearch={setSearch} />
          <div className={classes.manageBlock}>
            <Typography
              gutterBottom
              variant='subtitle2'
              className={classes.categoryTitle}
            >
              MANAGE
            </Typography>
            <ListItem
              button
              alignItems='center'
              onClick={() => {
                setSelectedCategory('active')
                setSearch()
              }}
            >
              <ListItemText primary='Active' />
              <ListItemText
                primary={activeIntegrations.length}
                style={{ textAlign: 'right' }}
              />
            </ListItem>
          </div>
          <div>
            <Typography
              gutterBottom
              variant='subtitle2'
              className={classes.categoryTitle}
            >
              BROWSE
            </Typography>
            <List component='nav'>
              <ListItem
                button
                alignItems='center'
                onClick={() => {
                  setSelectedCategory('all')
                  setSearch()
                }}
              >
                <ListItemText primary='All' />
                <ListItemText
                  primary={integrationsList.length}
                  style={{ textAlign: 'right' }}
                />
              </ListItem>
              {categoryNames.map((categoryName, index) => (
                <ListItem
                  button
                  key={index}
                  alignItems='center'
                  onClick={() => {
                    setSelectedCategory(categoryName)
                    setSearch()
                  }}
                >
                  <ListItemText
                    primary={
                      categoryName.charAt(0).toUpperCase() +
                      categoryName.slice(1)
                    }
                  />
                  <ListItemText
                    primary={integrationsList.filter(i => i.category === categoryName).length}
                    style={{ textAlign: 'right' }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
        <div className={classes.integrationsDashboard}>
          {search ? (
            <>
              <div className={classes.header}>
                <Typography variant='h6'>Search</Typography>
              </div>
              <div className={classes.cardContainer}>
                {searchedIntegrations.map((integration, index) => (
                  <IntegrationCard
                    onClick={() =>
                      handleClick(integration.path)}
                    integration={integration}
                    key={index}
                    disabled={hasPerm(integration.required_perm) === false}
                    activeIntegrations={activeIntegrations}
                  />
                ))}
              </div>
            </>
          ) : selectedCategory === 'all' ? (
            categoryNames.map((category, index) => (
              <Fragment key={index}>
                <div className={classes.header}>
                  <Typography variant='h6'>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </Typography>
                </div>
                {category === 'CRM' && !crmIntegration?.integration && !supportedIntegration && !requestedIntegration && (
                  <div className={classes.crmHelpContainer}>
                    <Typography variant='h6'>Using a CRM that's not yet supported by Signals?</Typography>
                    <Button
                      style={{ marginLeft: 10 }}
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        setUnsupportedCRMModalOpen(true)
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ marginLeft: 10 }}
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        setSupportedIntegration(true)
                      }}
                    >
                      No
                    </Button>
                  </div>
                )}
                <div className={classes.cardContainer}>
                  {integrationsList.filter(i => i.category === category).map((integration, index) => (
                    <IntegrationCard
                      onClick={() =>
                        handleClick(integration.path)}
                      integration={integration}
                      key={index}
                      activeIntegrations={activeIntegrations}
                      disabled={hasPerm(integration.required_perm) === false}
                    />
                  ))}
                </div>
              </Fragment>
            ))
          ) : selectedCategory === 'active' ? (
            <>
              <div className={classes.header}>
                <Typography variant='h6'>Active</Typography>
              </div>
              <div className={classes.cardContainer}>
                {integrationsList
                  .filter((integration) =>
                    activeIntegrations
                      .filter((active) => active === integration.key)
                      .includes(integration.key)
                  )
                  .map((integration, index) => (
                    <IntegrationCard
                      onClick={() =>
                        handleClick(integration.path)}
                      integration={integration}
                      key={index}
                      activeIntegrations={activeIntegrations}
                      disabled={hasPerm(integration.required_perm) === false}
                    />
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className={classes.header}>
                <Typography variant='h6'>
                  {selectedCategory.charAt(0).toUpperCase() +
                    selectedCategory.slice(1)}
                </Typography>
              </div>
              <div className={classes.cardContainer}>
                {integrationsList.filter(i => i.category === selectedCategory).map((integration, index) => (
                  <IntegrationCard
                    onClick={() =>
                      handleClick(integration.path)}
                    integration={integration}
                    key={index}
                    activeIntegrations={activeIntegrations}
                    disabled={hasPerm(integration.required_perm) === false}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        open={unsupportedCRMModalOpen}
        title='Request a CRM Integration'
        onHide={() => setUnsupportedCRMModalOpen(false)}
        rightButtonProps={{
          action: () => {
            sendIntegrationFeatureRequest('crm.abe', crm)
            setSupportedIntegration(true)
            setUnsupportedCRMModalOpen(false)
          },
          text: 'Submit',
          disabled: !crm
        }}
        leftButtonProps={{
          action: () => setUnsupportedCRMModalOpen(false),
          text: 'Cancel'
        }}
      >
        <Typography style={{ marginBottom: 5 }} variant='body1'>Which CRM are you using?</Typography>
        <Select
          options={crmOptions}
          value={crmSelect}
          onChange={(e) => {
            setCRMSelect(e)
            if (e !== 'else') {
              setCRM(e)
            } else {
              setCRM(null)
            }
          }}
          label='Select a CRM'
        />
        {crmSelect === 'else' && (
          <TextField
            style={{ marginTop: 20 }}
            variant='outlined'
            placeholder='Enter CRM name...'
            value={crm}
            onChange={(e) => setCRM(e.target.value)}
          />)}
      </Modal>
    </>
  )
}

export default IntegrationsList
