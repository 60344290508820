import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'library/materialUI/Button'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isValidPassword, PasswordConfirmationBlock } from './PasswordConfirmationBlock'
import { Paper } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 8,
    marginBottom: 8
  },
  buttonProgress: {
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 5
  },
  wrapper: {
    position: 'relative'
  },
  emailMessage: {
    marginTop: theme.spacing(3)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    padding: '10px 50px 50px 50px'
  }
}))

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .test(
      'complexity',
      'Your password must match the criteria below',
      isValidPassword
    )
})

function ResetPasswordFinishForm (props) {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const [visible, setVisibility] = useState(false)

  const onSubmit = values => {
    setVisibility(false)
    props.onSubmit(validationSchema.cast(values))
  }

  return (
    <>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          submitForm,
          validateForm,
          touched
        }) => {
          function trimPasswords () {
            values.password = values.password.trim()
            submitForm()
          }
          return (
            <Form className={classes.form}>
              <PasswordConfirmationBlock
                setFocused={setFocused}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                autoFocus={false}
                visible={visible}
                setVisibility={setVisibility}
                password={values.password}
                passwordError={errors.password && touched.password && !focused}
              />

              <div className={classes.wrapper}>
                {props.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  disabled={props.loading}
                  onClick={trimPasswords}
                >
                  Change Password
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

function ResetPasswordSuccessful (props) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.emailMessage}>
        <Typography variant='subtitle1' gutterBottom>
          Your password has been successfully reset. Click the button below to
          return to the login page.
        </Typography>
      </div>
      <Button
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
        onClick={props.setResetComplete}
      >
        Return to login
      </Button>
    </>
  )
}

const ResetPasswordFinishSideBar = props => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon color='primary' />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Reset Signals Password
      </Typography>
      {props.resetSuccessful ? (
        <ResetPasswordSuccessful
          setResetComplete={props.setResetComplete}
          loading={props.loading}
        />
      ) : (
        <ResetPasswordFinishForm onSubmit={props.onSubmit} />
      )}
    </Paper>
  )
}

export default ResetPasswordFinishSideBar
