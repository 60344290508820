import { Button } from 'library/materialUI/Button';
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { ReactComponent as TrashIcon } from 'img/icon-material-delete.svg'
import { useCallback, useEffect, useState } from 'react';
import HighIntentURL from './HighIntentURL';
import TextField from '@material-ui/core/TextField'
import { deleteURL, getSuggestions, getURLS, postURL } from 'api/high-intent';
import { Modal, ModalSection } from 'library/Modal';
import Loader from 'library/loading/Loader';
import InputSuggestion from 'library/InputSuggestion';

const useStyles = makeStyles(theme => ({
  categoryTitle: {
    display: 'flex',
    overflow: 'visible',
    marginTop: 30,
    marginBottom: 5,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottom: '#E0E0E0',
    borderBottomStyle: 'solid',
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none'
  },
  categoryTitleEntry: {
    marginBottom: 5,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: 'fit-content'
  },
  categoryURLEntry: {
    marginRight: theme.spacing(1),
    marginBottom: 5,
    width: '100%'
  },
  categoryButtonContainer: {
    display: 'flex',
    border: 'none',
    marginBottom: '20px'
  },
  categoryButtonIcon: {
    marginRight: 5,
    backgroundColor: '#985CE6',
    borderRadius: '100%',
    color: 'white'
  },
  categoryEditIcon: {
    marginLeft: 10,
    color: '#00000080',
    width: 15,
    height: 15
  },
  categoryTrashIcon: {
    marginLeft: 10,
    color: '#00000080',
    width: 10,
    height: 15,
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  urlDeleteIcon: {
    color: '#985CE6',
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  categorySuggestion: {
    display: 'flex',
    verticalAlign: 'center',
    width: '100%',
    alignItems: 'center',
    fontSize: '12px'
  },
  indexButton: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginRight: '10px',
    marginLeft: '10px',
    cursor: 'pointer'
  }
}))

interface HighIntentCategoryProps {
  categoryID: number
  deleteCategory?: (categoryID: number) => Promise<any>
  changeCategory?: (id: number, pagetype: string) => Promise<any>
  disableChange?: boolean
  pageType: string
}

export default function HighIntentCategorySection ({ categoryID, deleteCategory, changeCategory, disableChange = false, pageType }: HighIntentCategoryProps) {
  const [category, setCategory] = useState<string>(pageType)
  const [urlList, setURLList] = useState<any[]>([])
  const [emptyEntryList, setEmptyEntryList] = useState<number[]>([])
  const [suggestionList, setSuggestionList] = useState<any[]>([])
  const [stateChanged, setStateChanged] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [curIndex, setCurIndex] = useState(0)
  const classes = useStyles()
  const categorySubtitle = category + ' URL';

  const loadURLS = useCallback(() => {
    getURLS(categoryID)
      .then(response => {
        setURLList(response.data)
        if (response.data.length === 0) {
          getSuggestions(categoryID)
            .then(response => {
              setIsLoading(false)
              setSuggestionList(response.data)
            })
        } else {
          setIsLoading(false)
        }
      })
  }, [categoryID])

  useEffect(() => {
    loadURLS()
  }, [loadURLS])

  function removeCategory () {
    if (typeof deleteCategory !== 'undefined') {
      deleteCategory(categoryID)
        .then(() => handleClose)
    }
  }

  function updateCategory (id: number, pageType: string) {
    if (typeof changeCategory !== 'undefined') {
      changeCategory(id, pageType)
        .then(() => setStateChanged(false))
    }
  }

  function addEntryField () {
    const newID = -1 - emptyEntryList.length
    // eslint-disable-next-line
    setURLList(urlList.concat({ attributes: { id: newID, page_url: '', isNew: true } }))
    setEmptyEntryList(emptyEntryList.concat(newID))
  }

  function removeURL (id: number) {
    if (id < 0) {
      setURLList(urlList.filter(url => url.attributes.id !== id))
    } else {
      deleteURL(id, categoryID)
        .then(() => {
          setURLList(urlList.filter(url => url.attributes.id !== id))
        })
    }
  }

  function addSuggestionURL (pageURL: string) {
    postURL(categoryID, pageURL)
      .then(() => {
        loadURLS()
      })
  }

  function openDeleteModal () {
    setDeleteModalOpen(true)
  }

  const handleClose = () => {
    setDeleteModalOpen(false)
  }

  const handlePreviousIndex = () => {
    if (curIndex > 0) {
      setCurIndex(curIndex - 1)
    }
  }

  const handleNextIndex = () => {
    if (curIndex < suggestionList.length - 1) {
      setCurIndex(curIndex + 1)
    }
  }

  useEffect(() => {
    if (suggestionList?.length && curIndex > suggestionList.length - 1) {
      setCurIndex(suggestionList.length - 1)
    }
  }, [curIndex, suggestionList.length])

  return (
    <div>
      <Modal
        title={'Delete ' + category}
        open={deleteModalOpen}
        onHide={handleClose}
        handleSave={removeCategory}
        size='xs'
        saveIcon='delete'
      >
        <ModalSection>
          Are you sure you want to delete this category and all urls linked to it?
        </ModalSection>
      </Modal>
      {disableChange ? <ModalSection title={category} />
        : (
          <div className={classes.categoryTitle}>
            <TextField
              className={classes.categoryTitleEntry}
              value={category}
              onChange={e => {
                setCategory(e.target.value)
                setStateChanged(true)
              }}
              onBlur={() => {
                if (stateChanged) {
                  updateCategory(categoryID, category)
                }
              }}
            />
            <TrashIcon
              className={classes.categoryTrashIcon}
              onClick={openDeleteModal}
            />
          </div>)}
      {isLoading ? <div><Loader type='spinner' title='Fetching your high intent suggestions' /></div> : suggestionList.map((suggest, index) => {
        if (index !== curIndex) {
          return (
            <></>
          )
        }
        return (
          <InputSuggestion
            key={index}
            padding='0px'
            body={
              <>
                <div className={classes.categorySuggestion}>
                  <div className={classes.indexButton} onClick={() => handlePreviousIndex()}>
                    {'<'}
                  </div>
                  <div>
                    {index + 1}/{suggestionList.length}
                  </div>
                  <div className={classes.indexButton} onClick={() => handleNextIndex()}>
                    {'>'}
                  </div>
                  <div>
                    <p>It looks like <a target='_blank' rel='noopener noreferrer' href={suggest}>{suggest} </a> might be your {category}. Want to add it?</p>
                  </div>
                </div>
              </>
            }
            onAccept={() => {
              addSuggestionURL(suggest)
              setSuggestionList(suggestionList.filter(item => item !== suggest))
            }}
            onIgnore={() => { setSuggestionList(suggestionList.filter(item => item !== suggest)) }}
          />
        )
      })}
      {urlList.map((url, index) => {
        let isNew = false
        if (url.attributes.isNew) {
          isNew = url.attributes.isNew
        }
        return (
          <HighIntentURL
            id={url.attributes.id}
            key={url.attributes.id}
            url={url.attributes.page_url}
            category={category}
            categoryID={categoryID}
            subtitle={categorySubtitle}
            removeURL={removeURL}
            loadURLs={loadURLS}
            isNew={isNew}
            loc={index}
            moveOn={addEntryField}
          />
        )
      })}
      {isLoading ? <></> : (
        <Button
          className={classes.categoryButtonContainer}
          onClick={addEntryField}
          variant='outlined'
          color='primary'
        >
          <AddIcon
            className={classes.categoryButtonIcon}
          />
          Add URL
        </Button>
      )}
    </div>
  )
}
