/* eslint-disable @typescript-eslint/naming-convention */
import { useContext } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'
import SegmentCriteriaButton from './SegmentCriteriaButton'

export default function SegmentCriteriaButtons ({ groupIndex }: { groupIndex: number }) {
  const { triggers, editFilter, getStartingBtnValue, handleDeleteTrigger, determineActionButton } = useContext(DynamicSegmentContext)
  return (
    <>
      {triggers[groupIndex].filters.map((segmentTrigger, index) => {
        return (
          <SegmentCriteriaButton
            segmentTrigger={segmentTrigger}
            groupIndex={groupIndex}
            index={index}
            buttonProps={getStartingBtnValue(segmentTrigger)}
            editFilter={editFilter}
            deleteTrigger={(index) => handleDeleteTrigger(groupIndex, index)}
            actionButton={determineActionButton(groupIndex, triggers[groupIndex].filters, index)}
            key={index + segmentTrigger.prop + segmentTrigger.value + segmentTrigger.cmp}
          />
        )
      })}
    </>
  )
}
