import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { getBotParticipant } from 'api/bots'
import { getSettings } from 'api/settings'
import MoodIcon from '@material-ui/icons/Mood'
import SendIcon from '@material-ui/icons/Send'
import RenderEmoji from '../chat/RenderEmoji'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import PoweredBy from './PoweredBy'
// import { getColoredSVG } from './getColoredSVG'
import { BuildPromptIconSvgs } from 'pages/settings/ChatSettings/Avatars/svgs/BuildSvgs'
import './botPreview.css'

const useStyles = makeStyles(theme => ({
  preview: {
    boxShadow: '0 0 6px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    height: '100%'
  },
  header: {
    display: 'grid',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    gridTemplateColumns: '50px auto',
    gridGap: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 600,
    alignItems: 'center'
  },
  avatar: {
    height: 50,
    width: 50
  },
  miniAvatar: {
    height: 30,
    width: 30
  },
  body: {
    minHeight: 'calc(100% - 175px)',
    backgroundColor: 'white',
    padding: 20
  },
  response: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTop: '1px solid rgba(0,0,0,0.2)',
    padding: '10px 15px'
  },
  prompt: {
    height: 50,
    color: 'rgba(0,0,0,0.3)'
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    fill: '#AAA'
  },
  messageDiv: {
    marginTop: 3,
    display: 'flex',
    alignItems: 'center'
  },
  botName: {
    marginLeft: 30,
    color: '#7C7C7C',
    fontSize: '12px',
    marginBottom: -2
  },
  botMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    backgroundColor: '#F2F2F2',
    padding: '4px 12px',
    borderRadius: 16,
    marginTop: 3
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    alignItems: 'flex-end',
    marginTop: 15,
    flexWrap: 'wrap'
  },
  button: {
    marginLeft: 8,
    marginTop: 4,
    borderRadius: 25,
    textTransform: 'none'
  },
  fab: {
    height: 60,
    width: 60
  },
  chatIcon: {
    height: 30,
    width: 30,
    color: '#FFF'
  },
  logo: {
    fill: '#FFF'
  },
  promptIcon: {
    height: 60,
    width: 60,
    margin: 0,
    minHeight: '100%',
    objectFit: 'cover',
    borderRadius: '1000px',
    pointerEvents: 'none'
  }
}))

function BotPreview (props) {
  const classes = useStyles()
  const [botResponse, setBotResponse] = useState(null)
  const [settings, setSettings] = useState(null)
  const [getColoredSVG] = BuildPromptIconSvgs()
  const botParticipant = props.botParticipant?.data.attributes
  let agentName, avatarUrl

  useEffect(() => {
    getSettings().then(
      response => {
        setSettings(response.data)
      }
    )
  }, [])

  useEffect(() => {
    if (!botParticipant) {
      if (!(props.agentName && props.avatarUrl) && settings) {
        const participantID = settings.relationships.bot_participant.data.id
        getBotParticipant({ participantID })
          .then(response => {
            setBotResponse(response.data.attributes)
          })
      }
    }
  }, [botParticipant, props.agentName, props.avatarUrl, settings])

  const bot = botResponse || botParticipant

  if (!props.agentName && !props.avatarUrl && !bot) {
    return <></>
  }

  if (!settings) {
    return <></>
  }

  avatarUrl = bot?.avatar_url

  if (props.agentName) {
    agentName = props.agentName
  } else {
    agentName = bot?.agent_name || ''
  }

  if (props.avatarUrl) {
    avatarUrl = props.avatarUrl
  }

  const primaryColor = props.primaryColor ? props.primaryColor : '#' + settings.attributes.primary_color
  const contrastColor = props.contrastColor ? props.contrastColor : '#' + settings.attributes.contrast_color
  const buttonColor = props.buttonColor ? props.buttonColor : '#' + (settings.attributes.button_color || settings.attributes.primary_color)
  const iconID = props.iconID || 1

  let promptIcon
  if (!props.promptIconUrl) {
    const coloredSVG = getColoredSVG({ index: iconID, primaryColor: contrastColor })
    const base64data = btoa(unescape(encodeURIComponent(coloredSVG)))
    promptIcon = `data:image/svg+xml;base64,${base64data}`
  } else {
    promptIcon = props.promptIconUrl
  }

  return (
    <>
      <div className={classes.preview} style={props.showChatIcon ? { minHeight: 'calc(100% - 70px)' } : null}>
        <div className={classes.header} style={primaryColor ? { backgroundColor: primaryColor, color: contrastColor } : null}>
          <Avatar alt='Bot avatar' src={avatarUrl + '?x=' + new Date()} className={classes.avatar} />
          <div>{agentName}</div>
        </div>
        <div className={classes.body}>
          <div className={classes.botName}>
            {agentName}
          </div>
          <div className={classes.botMessage}>
            <Avatar alt='Bot avatar' src={avatarUrl + '?x=' + new Date()} className={classes.miniAvatar} />
            <div className={classes.messageDiv}>
              <RenderEmoji
                rich='true'
                className='agent-message'
                text={props.message}
              />
            </div>
          </div>
          <div className={classes.buttonDiv}>
            {
              props.buttons && props.router
                ? props.buttons.map((button, index) => {
                  return (
                    <Button
                      key={index}
                      variant='outlined'
                      className={classes.button}
                      style={{
                        color: button?.id?.includes('ai-button-id') ? '#22D3EE' : buttonColor,
                        border: button?.id?.includes('ai-button-id') ? '1px solid #22D3EE' : '1px solid #F2F2F2'
                      }}
                    >
                      {button.label} {button?.id?.includes('ai-button-id') ? '✨' : ''}
                    </Button>
                  )
                })
                : <></>
            }
          </div>
        </div>
        <div className={classes.response}>
          <div className={classes.prompt}>
            Type your message...
          </div>
          <div className={classes.controls}>
            <MoodIcon className={classes.icon} />
            <SendIcon className={classes.icon} />
          </div>
        </div>
      </div>
      {props.showChatIcon
        ? (
          <div style={{ display: 'flex', justifyContent: props.branding ? 'space-between' : 'flex-end', marginTop: 10 }}>
            {props.branding && <PoweredBy />}
            <Fab className={classes.fab} style={props.primaryColor ? { backgroundColor: props.primaryColor, color: props.contrastColor } : null}>
              <img src={promptIcon} className={classes.promptIcon} alt='Chat Icon' />
            </Fab>
          </div>)
        : <></>}
    </>
  )
}

export default BotPreview
