import { doPatch, doPost } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getBotTest = ({ chatServiceUrl, testID }) => {
  return fetch(`${chatServiceUrl}/bot_tests/${testID}`, {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const getBotTests = ({ chatServiceUrl, includeDeleted }) => {
  const url = `${chatServiceUrl}/bot_tests`
  const params = new URLSearchParams()
  if (includeDeleted) {
    params.set('filter[include_deleted]', includeDeleted)
  }

  return fetch(url + '?' + params.toString(), {
    method: 'GET',
    headers: headers
  }).then((response) => response.json())
}

const deleteBotTest = ({ chatServiceUrl, testID }) => {
  return fetch(`${chatServiceUrl}/bot_tests/${testID}`, {
    method: 'DELETE',
    headers: headers
  }).then((response) => response.json())
}

const updateBotTest = ({ state }) => {
  let path = '/bot_tests'

  if (state.id) {
    path = path + `/${state.id}`
    return doPatch({ path, data: state, useChatURL: true })
  }
  return doPost({ path, data: state, useChatURL: true })
}

const patchBotTest = ({ chatServiceUrl, id, attributes }) => {
  const url = `${chatServiceUrl}/bot_tests/${id}`

  const body = JSON.stringify({
    data: {
      id: id,
      type: 'bot-tests',
      attributes: attributes
    }
  })

  return fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: body
  }).then((response) => response.json())
}

export { getBotTests, updateBotTest, deleteBotTest, getBotTest, patchBotTest }
