import { UserOutOfOffice } from 'pages/users/UserManagement/UserOutOfOffice'
import { useState } from 'react'
import { startOfDay, endOfWeek } from 'date-fns'
import { doPost } from 'api/api'
import { dateHelper } from 'cf-components/DateRangeSelector'

function OutOfOfficePage (props) {
  const fallbackStartDate = startOfDay(new Date())
  const fallbackEndDate = startOfDay(endOfWeek(fallbackStartDate))
  const initialState = { attributes: { user_id: props.user.id, rotation_availability: { liveChat: false, calendarDrops: false }, start_date: fallbackStartDate, end_date: fallbackEndDate } }
  const firstSelection = props.user.outOfOffice?.length > 0 ? props.user.outOfOffice[0] : initialState
  const [currentSelection, setCurrentSelection] = useState(firstSelection)
  const [updates, setUpdates] = useState(firstSelection)
  const type = currentSelection?.id ? 'update' : 'post'
  //  eslint-disable-next-line
  const [updateType, setUpdateType] = useState(type) // will need when we can edit out of office
  const editOutOfOffice = (values) => {
    let userOutOfOfficeObj
    if (values) {
      userOutOfOfficeObj = { attributes: { ...values.attributes } }
    } else {
      setUpdates(values)
      return
    }
    if (values.id) {
      userOutOfOfficeObj.id = values.id
    }
    setUpdates(userOutOfOfficeObj)
  }

  const formatUpdates = () => {
      const formattedUpdates = { ...updates }
      if (formattedUpdates.attributes && typeof formattedUpdates.attributes.start_date === typeof String()) {
        formattedUpdates.attributes.start_date = (formattedUpdates.attributes.start_date).split('T')[0]
        formattedUpdates.attributes.end_date = (formattedUpdates.attributes.end_date).split('T')[0]
      }

      if (formattedUpdates.attributes && typeof formattedUpdates.attributes.start_date !== typeof String()) {
        formattedUpdates.attributes.start_date = dateHelper.dateToString(new Date(formattedUpdates.attributes.start_date), 'yyyy-mm-dd')
        formattedUpdates.attributes.end_date = dateHelper.dateToString(new Date(formattedUpdates.attributes.end_date), 'yyyy-mm-dd')
      }

      return formattedUpdates
    }

  props.saveOutOfOfficeRef.current = () => {
    const formattedUpdates = formatUpdates()
    doPost({
      path: '/user_out_of_office',
      useChatURL: true,
      data: {
        attributes: {
          ...formattedUpdates.attributes
        }
      }
    })
  }

  return (
    <UserOutOfOffice
      user={props.user}
      editOutOfOffice={editOutOfOffice}
      setCurrentSelection={setCurrentSelection}
      currentSelection={currentSelection}
      initialState={initialState}
      setUpdateType={setUpdateType}
    />
  )
}

export default OutOfOfficePage
