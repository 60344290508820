import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Divider, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'

const useStyles = makeStyles(theme => ({
  field: {
    '& iframe': {
      width: '100% !important'
    }
  },
  nameField: {
    '& iframe': {
      width: '100% !important'
    }
  },
  dateField: {
    '& iframe': {
      width: '75% !important',
      float: 'left'
    },
    marginTop: '-20px'
  },
  nameFields: {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'space-between'
  },
  securityField: {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'flex-start'
  },
  expirationField: {
    marginTop: '-20px',
    width: '90% !important'
  },
  securityCodeField: {
    '& iframe': {
      width: 'calc(100% - 20px)'
    },
    marginTop: '-20px'
  },
  addressField: {
    '& iframe': {
      width: '85% !important'
    }
  },
  billingAddress: {
    display: 'flex !important',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: '-20px'
  }
}))

const ChargifyForm = (props) => {
  const chargifyForm = useRef()
  const chargify = useRef(new window.Chargify())
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)

  const buttonText = props.buttonText || 'Save card details'

  const [, setToken] = useState('')
  const handleSubmit = (e) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 10000)
    e.preventDefault()
    chargify.current.token(
      chargifyForm.current,

      (token) => {
        setToken(token)
        props.onAddPaymentMethod(token)
      },

      (error) => {
        setLoading(false)
        if (error.errors) {
          setFormError(error)
        }
      }
    )
  }

  let publicKey = 'chjs_s6p32hs9cr4b84mds5rncq43'
  let serverHost = 'https://chatfunnels-sandbox.chargify.com'
  if ([
    'beta.chatfunnels.com',
    'app.chatfunnels.com',
    'beta.getsignals.ai',
    'app.getsignals.ai'
  ].includes(window.location.hostname)) {
    publicKey = 'chjs_nssgg2qs7wvj3s8rq3s69d6b'
    serverHost = 'https://chatfunnels.chargify.com'
  }

  useEffect(
    () => {
      chargify.current.load({
        publicKey: publicKey,
        type: 'card',
        serverHost: serverHost,
        hideCardImage: false,
        optionalLabel: '(optional field)',
        requiredLabel: '*',
        fields: {
          firstName: {
            selector: '#first-name',
            label: 'FIRST NAME',
            placeholder: 'John',
            required: true,
            message: 'First name is not valid. Please update it.',
            maxlength: '30'
          },
          lastName: {
            selector: '#last-name',
            label: 'LAST NAME',
            placeholder: 'Doe',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '30'
          },

          number: {
            selector: '#card-number',
            label: 'Number',
            placeholder: 'xxxx xxxx xxxx xxxx',
            message: 'This field is not valid. Please update it.'
          },

          month: {
            selector: '#month',
            label: 'Mon',
            placeholder: 'mm',
            message: 'This field is not valid. Please update it.'
          },
          year: {
            selector: '#year',
            label: 'Year',
            placeholder: 'yyyy',
            message: 'This field is not valid. Please update it.'
          },

          cvv: {
            selector: '#cvv',
            label: 'CVV code',
            placeholder: '123',
            required: true,
            message: 'This field is not valid. Please update it.'
          },

          address: {
            selector: '#address1',
            label: 'Address',
            placeholder: '1234 Hill St',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '70'
          },
          address2: {
            selector: '#address2',
            label: 'Address 2',
            placeholder: '1234 Hill St',
            required: false,
            maxlength: '70'
          },
          city: {
            selector: '#city',
            label: 'City',
            placeholder: 'Austin',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '30'
          },

          state: {
            selector: '#state',
            label: 'State',
            placeholder: 'TX',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '2'
          },

          zip: {
            selector: '#zip',
            label: 'Zip Code',
            placeholder: '10001',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '5'
          },
          country: {
            selector: '#country',
            label: 'Country',
            placeholder: 'US',
            required: true,
            message: 'This field is not valid. Please update it.',
            maxlength: '30'
          }
        }
      })

      return undefined
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <>
      <div className={classes.formContainer}>
        <form onSubmit={handleSubmit} ref={chargifyForm}>
          <div className={classes.nameFields}>
            <div id='first-name' className={classes.nameField} style={{ paddingRight: 20 }} />
            <div id='last-name' className={classes.nameField} style={{ paddingLeft: 20 }} />
          </div>
          <div id='card-number' className={classes.field} style={{ marginTop: '-20px' }} />
          <div className={classes.securityField}>
            <div className={classes.securityField}>
              <div id='month' className={classes.dateField} />
              <div id='year' className={classes.dateField} />
            </div>
            <div id='cvv' className={classes.field} style={{ marginTop: '-20px' }} />
          </div>
          <div id='address1' className={classes.field} style={{ marginTop: '-20px' }} />
          <div id='address2' className={classes.field} style={{ marginTop: '-20px' }} />
          <div className={classes.billingAddress}>
            <div id='city' className={classes.addressField} />
            <div id='state' className={classes.addressField} />
            <div id='zip' className={classes.addressField} />
            <div id='country' className={classes.addressField} />
          </div>
          {formError &&
            <div style={{ border: '1px solid #ff5f5f', borderRadius: 15, padding: 10, marginBottom: 20, color: '#ff5f5f', fontSize: 14 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoOutlinedIcon style={{ color: '#ff5f5f' }} />
                  <div style={{ marginLeft: 15 }}>
                    Something went wrong. Please try again.
                  </div>
                </div>
                <div>
                  <HighlightOffOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => setFormError(null)} />
                </div>
              </div>
              <Divider style={{ backgroundColor: 'rgb(255 200 200)', marginBottom: 10 }} />
              <div style={{ fontWeight: 500 }}>
                Details:
              </div>
              <div>
                <b>Status Code:</b> {formError?.status}
              </div>
              <div>
                <b>Error:</b> {formError?.errors}
              </div>

            </div>}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant='outlined'
              type='submit'
              color='primary'
              ref={props.buttonRef}
              onSubmit={props.onSubmit}
              disabled={props.disabled || loading}
              size='medium'
            >
              {buttonText}
            </Button>
            {loading ? <CircularProgress size={25} style={{ marginLeft: 15 }} /> : <></>}
          </div>
        </form>
      </div>
    </>
  )
}

export default ChargifyForm
